import {createStore,applyMiddleware,combineReducers } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { Login } from './Login'
import { Register } from './Register'
import { Lock } from './Lock'
import { Users } from './Users'
export const configStore = () => {
    const store = createStore(
        combineReducers({
            Login,
            Register,
            Lock,
            Users
 
        }),applyMiddleware(thunk,logger)
    )
    return store
}