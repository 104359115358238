/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import cookie from "react-cookies";
import { baseUrl } from "../../Shared/baseUrl";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Loader from "react-loader-spinner";
import LoadingOverlay from "react-loading-overlay";
import Switch from "react-bootstrap-switch";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import AddInseminationEvents from "./Add-Inceminasion-Events";
import {
	GoogleMap,
	withScriptjs,
	withGoogleMap,
	InfoWindow,
	Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Button,
	Badge,
} from "reactstrap";
Geocode.setApiKey(window._env_.REACT_APP_GOOGLE_MAP_API_KEY);
Geocode.enableDebug();
var dropDown;
var memo;
var uId;
var uName;
let AsyncMap;
let phoneNo;
class EditCustomer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			db_id: "",
			name: "",
			father_name: "",
			cnic: "",
			dob: "",
			phone: "",
			customer_type: [
				{ label: "AIT", value: "ait" },
				{ label: "FARM", value: "farm" },
				{ label: "DIST", value: "dist" },
			],
			customer_status: [
				{ label: "Lead", value: "lead" },
				{ label: "Prospect", value: "prospect" },
				{ label: "Onboard", value: "onboard" },
			],
			tehsil: "",
			village: "",
			region: "",
			salespoint: "",
			memo: [],
			alert: null,
			loader: null,
			files: null,
			uploading: false,
			uploadProgress: {},
			successfullUploaded: false,
			selectedCustomer_type: "",
			selectedCustomer_status: "",
			changedCustomer_status: "",
			changedCustomer_type: "",
			selectedRegion: "",
			selectedSalesPoints: "",
			pressed: false,
			isActive: true,
			repName: "",
			repRelation: "",
			customerRep: [],
			fetchedReps: [],
			memos: [],
			Memo: [],
			image: "",
			address: "",
			city: "",
			area: "",
			state: "",
			newLat: "",
			newLng: "",
			addressArray: [],
			center: {
				lat: 31.484269618543166,
				lng: 74.3263890899658,
			},
			mapPosition: {
				lat: 31.484269618543166,
				lng: 74.3263890899658,
			},
			markerPosition: {
				lat: 31.484269618543166,
				lng: 74.3263890899658,
			},
			customerDeleted: false,
			allowedRegions: [],
			slaesPoints: [],
		};
		this.AddInceminasionEvents = this.AddInceminasionEvents.bind(this);
		this.updateUser = this.updateUser.bind(this);
		this.handleswitchChange = this.handleswitchChange.bind(this);
		this.onFilesAdded = this.onFilesAdded.bind(this);
		this.onAddMemosPress = this.onAddMemosPress.bind(this);
		this.showInseminationEvents = this.showInseminationEvents.bind(this);
	}
	hide() {
		this.setState({
			isActive: false,
			pressed: false,
		});
	}

	AddInceminasionEvents() {
		if (this.state.selectedCustomer_type.value === "AIT") {
			let path = "/admin/Add_InceminasionEvents/" + this.state.db_id;
			this.props.history.push(path);
		} else {
			this.customerTypeErrorAlert();
		}
		// this.props.history.pushState( '/admin/Add_InceminasionEvents');
		// this.props.history.push(`/admin/Add_InceminasionEvents`)
	}

	handleCheckboxChange = (event) => {
		this.setState({ checked: event.target.checked });
	};
	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: "block", marginTop: "-100px" }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={
						this.state.customerDeleted
							? () => this.hideDeleteAlert()
							: () => this.hideAlert()
					}
					onCancel={
						this.state.customerDeleted
							? () => this.hideDeleteAlert()
							: () => this.hideAlert()
					}
					btnSize=''
				>
					{(this.state.customerDeleted && "Customer Deleted Successfully") ||
						"Customer Update Successfully !"}
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	hideDeleteAlert = () => {
		this.setState({
			alert: null,
		});

		this.props.history.push("/admin/customersList");
	};

	errorAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: "block", marginTop: "-100px" }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					User with this email Already Exsit !!!!!
				</ReactBSAlert>
			),
		});
	};

	customerTypeErrorAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: "block", marginTop: "-100px" }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					Customer is not Eligible !!!!!
				</ReactBSAlert>
			),
		});
	};
	handleswitchChange(checked, event) {
		this.setState({ active: event });
	}

	handleRemoveShareholder = (idx) => () => {
		this.setState({
			customerRep: this.state.customerRep.filter((s) => idx !== s.id),
		});
		this.setState({
			Roles_Permissions: this.state.Roles_Permissions.filter(
				(s) => idx !== s.id,
			),
		});
	};

	onFilesAdded(event) {
		this.setState({
			files: event.target.files[0],
		});
	}
	settingStates = () => {
		this.setState({
			center: { lat: 30.37532, lng: 69.345116 },
			mapPosition: {
				lat: this.state.center.lat,
				lng: this.state.center.lng,
			},
			markerPosition: {
				lat: this.state.center.lat,
				lng: this.state.center.lng,
			},
			height: "300px",
		});
	};

	componentWillMount() {
		AsyncMap = withScriptjs(
			withGoogleMap((props) => (
				<GoogleMap
					google={this.props.google}
					defaultZoom={15}
					defaultCenter={{
						lat: this.state.mapPosition.lat,
						lng: this.state.mapPosition.lng,
					}}
				>
					<InfoWindow
						onClose={this.onInfoWindowClose}
						position={{
							lat: this.state.markerPosition.lat + 0.0018,
							lng: this.state.markerPosition.lng,
						}}
					>
						<div>
							<span style={{ padding: 0, margin: 0 }}>
								{this.state.address}
							</span>
						</div>
					</InfoWindow>
					<Marker
						google={this.props.google}
						draggable={true}
						onDragEnd={this.onMarkerDragEnd}
						position={{
							lat: this.state.markerPosition.lat,
							lng: this.state.markerPosition.lng,
						}}
					/>

					<Autocomplete
						style={{
							width: "100%",
							height: "40px",
							paddingLeft: "16px",
							marginTop: "2px",
							marginBottom: "100px",
						}}
						onPlaceSelected={this.onPlaceSelected}
						types={["(regions)"]}
					/>
				</GoogleMap>
			)),
		);

		var user = JSON.parse(window.localStorage.getItem("user"));
		var data = JSON.parse(user);
		if (data.permissions !== null) {
			const permissions = data.Permissions;
			const getRoleId = (Permissions) => Permissions.regionCode;
			const getRoleIdArray = (permissions) => permissions.map(getRoleId);
			const regions = getRoleIdArray(permissions);

			var regionCodes = regions.filter(function (el) {
				return el !== undefined;
			});

			const getNameId = (Permissions) => Permissions.regionName;
			const getNameIdArray = (permissions) => permissions.map(getNameId);
			const regionsname = getNameIdArray(permissions);
			var regionName = regionsname.filter(function (el) {
				return el !== undefined;
			});

			var regionsJson = [];

			for (var i in regionCodes) {
				var code = regionCodes[i];
				var label = regionName[i];

				regionsJson.push({
					code: code,
					label: label,
				});
			}
			var result = [
				...new Map(regionsJson.map((o) => [JSON.stringify(o), o])).values(),
			];

			var user = JSON.parse(window.localStorage.getItem("user"));
			var data = JSON.parse(user);
			var token = cookie.load("Token");
			let details = {
				userid: data.id,
				token: token,
				permissions: data.Permissions,
			};

			fetch(`${baseUrl}/auth/users/getRegion`, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + details.token,
					id: details.userid,
					reg: JSON.stringify(details.permissions),
				},
				method: "Post",
			})
				.then((response) => response.json())
				.then((jsonResponse) => {
					if (jsonResponse.success) {
						jsonResponse.Regions.map((region) => {
							result.map((allowedRegion) => {
								if (allowedRegion.code === region.code) {
									this.setState({
										allowedRegions: [
											...this.state.allowedRegions,
											{ label: region.name, value: region.id },
										],
									});
								}
							});
						});
					}
				});
		}
	}

	componentDidMount() {
		Geocode.fromLatLng(
			this.state.mapPosition.lat,
			this.state.mapPosition.lng,
		).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);
				this.setState({
					address: address ? address : "",
					area: area ? area : "",
					city: city ? city : "",
					state: state ? state : "",
				});
			},
			(error) => {
				// console.error(error);
			},
		);

		// var user = JSON.parse(window.localStorage.getItem("user"));
		// var data = JSON.parse(user);
		// if (data.permissions !== null) {
		// 	const permissions = data.Permissions;
		// 	const getRoleId = (Permissions) => Permissions.regionCode;
		// 	const getRoleIdArray = (permissions) => permissions.map(getRoleId);
		// 	const regions = getRoleIdArray(permissions);

		// 	var regionCodes = regions.filter(function (el) {
		// 		return el !== undefined;
		// 	});

		// 	const getNameId = (Permissions) => Permissions.regionName;
		// 	const getNameIdArray = (permissions) => permissions.map(getNameId);
		// 	const regionsname = getNameIdArray(permissions);
		// 	var regionName = regionsname.filter(function (el) {
		// 		return el !== undefined;
		// 	});

		// 	var regionsJson = [];

		// 	for (var i in regionCodes) {
		// 		var code = regionCodes[i];
		// 		var label = regionName[i];

		// 		regionsJson.push({
		// 			code: code,
		// 			label: label,
		// 		});
		// 	}
		// 	var result = [
		// 		...new Map(regionsJson.map((o) => [JSON.stringify(o), o])).values(),
		// 	];

		var user = JSON.parse(window.localStorage.getItem("user"));
		var data = JSON.parse(user);
		var token = cookie.load("Token");
		// 	let details = {
		// 		userid: data.id,
		// 		token: token,
		// 		permissions: data.Permissions,
		// 	};

		// 	fetch(`${baseUrl}/auth/users/getRegion`, {
		// 		headers: {
		// 			"Content-Type": "application/json",
		// 			Accept: "application/json",
		// 			Authorization: "Bearer " + details.token,
		// 			id: details.userid,
		// 			reg: JSON.stringify(details.permissions),
		// 		},
		// 		method: "Post",
		// 	})
		// 		.then((response) => response.json())
		// 		.then((jsonResponse) => {
		// 			if (jsonResponse.success) {
		// 				jsonResponse.Regions.map((region) => {
		// 					result.map((allowedRegion) => {
		// 						if (allowedRegion.code === region.code) {
		// 							this.setState({
		// 								allowedRegions: [
		// 									...this.state.allowedRegions,
		// 									{ label: region.name, value: region.id },
		// 								],
		// 							});
		// 						}
		// 					});
		// 				});
		// 			}
		// 		});
		// }
		let details = {
			token: cookie.load("Token"),
			id: data.id,
			permissions: data.Permissions,
		};

		fetch(baseUrl + "/crm/customerDetail/" + this.props.match.params.id, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + details.token,
				id: details.id,
				reg: JSON.stringify(details.permissions),
			},
			method: "Post",
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					console.log("DB_LAT_LONG", jsonResponse);
					let date = jsonResponse.client.dob.split("T");
					jsonResponse.client.dob = date[0];
					console.log(
						"jsonResponse.client.salespointId",
						jsonResponse.client.salespointId,
					);
					this.setState({
						db_id: jsonResponse.client.id,
						name: jsonResponse.client.name,
						father_name: jsonResponse.client.father_name,
						cnic: jsonResponse.client.cnic,
						dob: jsonResponse.client.dob,
						phone: jsonResponse.client.phone,
						selectedRegion: this.state.allowedRegions.find(
							(r) => r.value === jsonResponse.client.regionId,
						),
						village: jsonResponse.client.village,
						tehsil: jsonResponse.client.tehsil,
						selectedCustomer_status: {
							label: jsonResponse.client.customer_status,
							value: jsonResponse.client.customer_status,
						},
						selectedCustomer_type: {
							label: jsonResponse.client.customer_type,
							value: jsonResponse.client.customer_type,
						},
						selectedSalesPoints: jsonResponse.client.salespointId,
						image: jsonResponse.client.image,
						city: jsonResponse.client.city,
						area: jsonResponse.client.area,
						state: jsonResponse.client.state,
						address: jsonResponse.client.address,
						newLat: jsonResponse.client.lat,
						newLng: jsonResponse.client.long,
						loader: null,
						markerPosition: {
							lat: parseFloat(jsonResponse.client.lat),
							lng: parseFloat(jsonResponse.client.long),
						},
					});

					fetch(baseUrl + "/routing/listSalePointsByRegion", {
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							Authorization: "Bearer " + details.token,
							id: details.id,
							reg: JSON.stringify(details.permissions),
						},
						method: "Post",
						body: JSON.stringify({
							regionId: this.state.selectedRegion.value,
						}),
					})
						.then((res) => res.json())
						.then((jsonResponse) => {
							if (jsonResponse.success === true) {
								this.setState({
									slaesPoints: jsonResponse.salepoints.map((props) => {
										return {
											value: props.id,
											label: props.name,
										};
									}),
								});
								console.log(
									"jsonResponse.salepoints",
									jsonResponse.salepoints,
									this.state.selectedSalesPoints,
								);
								this.setState({
									selectedSalesPoints: jsonResponse.salepoints.map((s) => {
										if (s.id === this.state.selectedSalesPoints) {
											return {
												value: s.id,
												label: s.name,
											};
										}
									}),
								});

								fetch(
									baseUrl +
										"/crm/representativeList/" +
										this.props.match.params.id,
									{
										headers: {
											"Content-Type": "application/json",
											Accept: "application/json",
											Authorization: "Bearer " + details.token,
											id: details.id,
											reg: JSON.stringify(details.permissions),
										},
										method: "Post",
									},
								)
									.then((res) => res.json())
									.then((jsonResponse) => {
										if (jsonResponse.success === true) {
											this.setState({
												fetchedReps: jsonResponse.data.map((prop) => {
													return {
														rep_name: prop.name,
														rep_relation: prop.relation,
													};
												}),
											});
										}
									})
									.catch((err) => {
										this.errorAlert();
									});
							}
						})
						.catch((err) => {
							this.errorAlert();
						});
				} else {
					// console.log('Blaa Blaa');
				}
			})
			.catch((err) => {
				// throw new Error(err);
			});
		//displaying memos
		let displayData = {
			token: cookie.load("Token"),
			id: data.id,
			permissions: data.Permissions,
		};
		fetch(baseUrl + "/crm/displayMemos/" + this.props.match.params.id, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + displayData.token,
				id: displayData.id,
				reg: JSON.stringify(displayData.permissions),
			},
			method: "Post",
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						Memo: jsonResponse.Memo.map((prop, key) => {
							return {
								mem: prop.Memos,
								id: prop.id,
							};
						}),
					});
				}
			})
			.catch((err) => {
				this.errorAlert();
			});
	}

	saveReps = () => {
		var user = JSON.parse(window.localStorage.getItem("user"));
		var data = JSON.parse(user);
		var token = cookie.load("Token");
		let inserData = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
			repName: this.state.repName,
			repRelation: this.state.repRelation,
		};
		fetch(baseUrl + "/crm/addRep/" + this.props.match.params.id, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + inserData.token,
				id: inserData.userid,
				reg: JSON.stringify(inserData.permissions),
			},
			method: "Post",
			body: JSON.stringify({
				name: inserData.repName,
				father_name: inserData.repRelation,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.successAlert();
				} else {
					this.errorAlert();
				}
			})
			.catch((err) => {
				this.errorAlert();
			});

		this.setState({ customerRep: this.state.customerRep.concat(inserData) });

		this.setState({ pressed: false });
		this.setState({ repName: "" });
		this.setState({ repRelation: "" });
		dropDown = null;
	};
	showInseminationEvents() {
		// let objj = this.state.data.find(o => o.id === id)
		// console.log(objj,"checking insem")
		phoneNo = this.state.phone;
		let path = "/admin/insemintaionList/" + phoneNo;
		this.props.history.push(path);
	}
	savePressed = () => {
		var reg_per = [];
		this.state.permissions.Permissions.map((props) => {
			this.state.dropDown1array.map((data) => {
				reg_per.push({
					id: data.id,
					Permissions: props,
					Region: data.Region,
				});
			});
		});

		this.setState({
			Roles_Permissions: this.state.Roles_Permissions.concat(reg_per),
		});
		this.setState({
			Roles: this.state.Roles.concat(this.state.dropDown1array),
		});

		dropDown = null;
		this.setState({ dropDown1array: [""] });
		this.setState({ pressed: false });
		this.setState({ regionDrop1: false });
		reg_per = [];
	};
	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	};
	onAddRolesPress = () => {
		this.setState({ pressed: true });
	};
	getCity = (addressArray) => {
		let city = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				"administrative_area_level_2" === addressArray[i].types[0]
			) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	getArea = (addressArray) => {
		let area = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if (
						"sublocality_level_1" === addressArray[i].types[j] ||
						"locality" === addressArray[i].types[j]
					) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};
	getState = (addressArray) => {
		let state = "";
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (
					addressArray[i].types[0] &&
					"administrative_area_level_1" === addressArray[i].types[0]
				) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};
	onInfoWindowClose = (event) => {};
	onMarkerDragEnd = (event) => {
		this.setState({ newLat: event.latLng.lat() });
		this.setState({ newLng: event.latLng.lng() });
		this.setState({
			markerPosition: { lat: event.latLng.lat(), lng: event.latLng.lng() },
		});
		let addressArray = [];
		Geocode.fromLatLng(this.state.newLat, this.state.newLng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);
				console.log(this.state.newLat, "latitude");
				console.log(this.state.newLng, "longitude");
				//    globalState=this.getCity(addressArray);
				//    console.log(globalState,"Checking Global state")
				this.setState({
					address: address ? address : "",
					area: area ? area : "",
					city: city ? city : "",
					state: state ? state : "",
				});
			},
			(error) => {
				console.error(error);
			},
		);
	};
	addMapData = () => {
		console.log("checking new one");
	};
	onPlaceSelected = (place) => {
		const address = place.formatted_address,
			addressArray = place.address_components,
			city = this.getCity(addressArray),
			area = this.getArea(addressArray),
			state = this.getState(addressArray),
			//    globalState=this.getCity(addressArray),
			//    console.log(globalState,"Checking Global state"),
			latValue = place.geometry.location.lat(),
			lngValue = place.geometry.location.lng();

		this.setState({
			address: address ? address : "",
			area: area ? area : "",
			city: city ? city : "",
			state: state ? state : "",
			markerPosition: {
				lat: latValue,
				lng: lngValue,
			},
			mapPosition: {
				lat: latValue,
				lng: lngValue,
			},
		});
		this.state.globalState = this.state.city;
	};

	updateUser() {
		this.setState({
			innerloader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: "250px",
							marginLeft: "-80px",
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			),
		});
		var user = JSON.parse(window.localStorage.getItem("user"));
		var data = JSON.parse(user);

		let details = {
			token: cookie.load("Token"),
			id: data.id,
			permissions: data.Permissions,
		};
		console.log(details, "details");
		const formData = new FormData();
		formData.append("myImage", this.state.files);

		if (this.state.files != null) {
			fetch(baseUrl + "/crm/uploadImg", {
				headers: {
					Authorization: "Bearer " + details.token,
					id: details.id,
					reg: JSON.stringify(details.permissions),
				},
				method: "Post",
				body: formData,
			})
				.then((res) => res.json())
				.then((jsonResponse) => {
					console.log(this.state.changedCustomer_status, "red");
					console.log(this.state.changedCustomer_type, "yellow");
					if (jsonResponse.success === true) {
						fetch(
							baseUrl +
								"/crm/customerModification/" +
								this.props.match.params.id,
							{
								headers: {
									"Content-Type": "application/json",
									Accept: "application/json",
									Authorization: "Bearer " + details.token,
									id: details.id,
									reg: JSON.stringify(details.permissions),
								},
								method: "Post",
								body: JSON.stringify({
									name: this.state.name,
									father_name: this.state.father_name,
									cnic: this.state.cnic,
									dob: this.state.dob,
									phone: this.state.phone,
									tehsil: this.state.tehsil,
									village: this.state.village,
									region: this.state.selectedRegion.value,
									salespoint: this.state.selectedSalesPoints.value,
									customer_status: this.state.selectedCustomer_status.value,
									customer_type: this.state.selectedCustomer_type.value,
									city: this.state.city,
									area: this.state.area,
									state: this.state.state,
									address: this.state.address,
									newLat: this.state.newLat,
									newLng: this.state.newLng,
									image: jsonResponse.data,
								}),
							},
						)
							.then((res) => res.json())
							.then(() => {
								this.setState({ innerloader: null });
								this.successAlert();
								// this.setState({ allowedRegions: [] });
								this.componentDidMount();
							});
					}
				})
				.catch((err) => {
					this.errorAlert();
					throw new Error(err);
				});
		} else {
			console.log(this.state.changedCustomer_status, "red");
			console.log(this.state.changedCustomer_type, "yellow");
			console.log(this.state.selectedSalesPoints, "blue");
			console.log(this.state.selectedRegion, "green");

			fetch(
				baseUrl + "/crm/customerModification/" + this.props.match.params.id,
				{
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: "Bearer " + details.token,
						id: details.id,
						reg: JSON.stringify(details.permissions),
					},
					method: "Post",
					body: JSON.stringify({
						name: this.state.name,
						father_name: this.state.father_name,
						cnic: this.state.cnic,
						dob: this.state.dob,
						phone: this.state.phone,
						tehsil: this.state.tehsil,
						village: this.state.village,
						region: this.state.selectedRegion.value,
						salespoint: this.state.selectedSalesPoints.value,
						customer_status: this.state.selectedCustomer_status.value,
						customer_type: this.state.selectedCustomer_type.value,
						city: this.state.city,
						area: this.state.area,
						state: this.state.state,
						address: this.state.address,
						newLat: this.state.newLat,
						newLng: this.state.newLng,
					}),
				},
			)
				.then((res) => res.json())
				.then((jsonResponse) => {
					this.setState({ innerloader: null });
					this.successAlert();
					// this.setState({ allowedRegions: [] });
					this.componentDidMount();
				})
				.catch((err) => {
					this.errorAlert();
					throw new Error(err);
				});
		}
	}
	onAddMemosPress() {
		this.setState({ pressed: true });
	}

	validateForm() {
		var permissions = window.localStorage.getItem("permissions");
		var Check = permissions.includes("modifiedUser");
		return Check === true;
	}
	regionDropDown1 = (selectedOption) => {
		var item = this.state.dropDown1array.find(
			(x) => x.Role == selectedOption.value,
		);
		if (item) {
			item.Region = selectedOption.label;

			this.setState({ dropDown1array: [item] });
		}
	};
	handleCancelButton = () => {
		this.props.history.push("/admin/customersList");
	};
	handleDeleteButton = () => {
		this.setState({
			innerloader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: "250px",
							marginLeft: "-80px",
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			),
		});

		var user = JSON.parse(window.localStorage.getItem("user"));
		var data = JSON.parse(user);

		let details = {
			token: cookie.load("Token"),
			id: data.id,
			permissions: data.Permissions,
		};

		fetch(baseUrl + "/crm/delete-customer/" + this.props.match.params.id, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + details.token,
				id: details.id,
				reg: JSON.stringify(details.permissions),
			},
			method: "Delete",
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						customerDeleted: true,
					});

					this.successAlert();
				}
			})
			.catch((err) => {
				console.log("Delete Error", err);
			});
	};
	ChangeSalesPoint = (event) => {
		this.setState({ selectedSalesPoints: event });
	};
	ChangeStatus = (event) => {
		this.setState({ selectedCustomer_status: event });
	};
	ChangeType = (event) => {
		this.setState({ selectedCustomer_type: event });
	};
	ChangeRegion = (event) => {
		this.setState({ selectedRegion: event });

		if (event.label != "") {
			var user = JSON.parse(window.localStorage.getItem("user"));
			var data = JSON.parse(user);
			var token = cookie.load("Token");

			let header = {
				userid: data.id,
				token: token,
				permissions: data.Permissions,
			};
			console.log(header);
			fetch(baseUrl + "/routing/listSalePointsByRegion", {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + header.token,
					id: header.userid,
					reg: JSON.stringify(header.permissions),
				},
				method: "Post",
				body: JSON.stringify({
					regionId: event.value,
				}),
			})
				.then((res) => res.json())
				.then((jsonResponse) => {
					this.setState({ loader: null });
					if (jsonResponse.success === true) {
						this.setState({
							slaesPoints: jsonResponse.salepoints.map((props) => {
								return {
									value: props.id,
									label: props.name,
								};
							}),
						});
					} else {
						this.setState({ loader: null });
					}
				})
				.catch((err) => {
					this.setState({ loader: null });
				});
		}
	};
	onAddRolesPress = () => {
		this.setState({ pressed: true });
	};

	SavePress = () => {
		var user = JSON.parse(window.localStorage.getItem("user"));
		var data = JSON.parse(user);
		var token = cookie.load("Token");
		console.log(data, "hello");
		let inserData = {
			memo: this.state.memo,
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};
		fetch(baseUrl + "/crm/addMemo/" + this.props.match.params.id, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + inserData.token,
				id: inserData.userid,
				reg: JSON.stringify(inserData.permissions),
			},
			method: "Post",
			body: JSON.stringify({
				memo: inserData.memo,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.successAlert();

					this.state.Memo.push({
						mem: inserData.memo,
					});
				} else {
					this.errorAlert();
				}
			})
			.catch((err) => {
				this.errorAlert();
			});

		this.setState({ pressed: false });
		this.setState({ memo: "" });
	};

	render() {
		let map;
		if (this.state.center.lat !== undefined) {
			map = (
				<div>
					<div>
						<div className='form-group'>
							<label htmlFor=''>City</label>
							<input
								type='text'
								name='city'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.city}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor=''>Area</label>
							<input
								type='text'
								name='area'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.area}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor=''>State</label>
							<input
								type='text'
								name='state'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.state}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor=''>Address</label>
							<input
								type='text'
								name='address'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.address}
							/>
						</div>
					</div>
					<AsyncMap
						googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${window._env_.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&v=3`}
						loadingElement={<div style={{ height: "100%" }} />}
						containerElement={<div style={{ height: "300px" }} />}
						mapElement={<div style={{ height: "100%" }} />}
					/>
				</div>
			);
		} else {
			map = <div style={{ height: "300px" }} />;
		}
		if (this.state.pressed == true) {
			dropDown = (
				<>
					<Row>
						<Col md={4}>
							<label md={3}>Name </label>
							<Input
								type='text'
								name='repName'
								onChange={this.handleChange}
								value={this.state.repName}
							/>
						</Col>
						<Col md={4}>
							<label md={3}>Relation </label>
							<Input
								type='text'
								name='repRelation'
								onChange={this.handleChange}
								value={this.state.repRelation}
							/>
						</Col>

						<Col md={4}>
							<Button
								style={{ background: "transparent", marginTop: 21 }}
								onClick={this.saveReps}
							>
								Save
							</Button>
						</Col>
					</Row>
				</>
			);
		}
		if (this.state.pressed == true) {
			memo = (
				<>
					<Row>
						<Col md={12}>
							<ul>
								<li className='timeline-inverted'>
									<div className='timeline-panel'>
										<div className='timeline-heading'>
											<Row>
												<Col xs={3}>
													<Badge color='info' style={{ marginTop: 14 }}>
														Memo Title
													</Badge>
												</Col>
												<Col xs={{ size: 1, offset: 2 }}>
													<Button
														style={{ background: "transparent" }}
														onClick={() => this.hide()}
													>
														✖
													</Button>
												</Col>
											</Row>
										</div>
										<div className='timeline-body'>
											<textarea
												style={{ background: "transparent", color: "white" }}
												name='memo'
												rows={3}
												onChange={this.handleChange}
												value={this.state.memo}
											></textarea>
										</div>
									</div>
									<Button
										style={{ background: "transparent", marginTop: 21 }}
										onClick={this.SavePress}
									>
										Save
									</Button>
								</li>
							</ul>
						</Col>
					</Row>
				</>
			);
		}

		if (this.state.loader !== null) {
			return (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: "250px",
							marginLeft: "50px",
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			);
		}
		if (this.state.loader === null) {
			return (
				<>
					<div className='content'>
						{this.state.alert}
						{this.state.loader}
						<Row>
							<Col md={{ size: 6, offset: 3 }}>
								<Card>
									<CardHeader>
										<CardTitle tag='h4'>Customer Detail</CardTitle>
									</CardHeader>
									<CardBody>
										<Form action='/' className='form-horizontal' method='get'>
											<Row>
												<Col xs={6}>
													<FormGroup>
														<label>Name </label>

														<Input
															type='text'
															name='name'
															onChange={this.handleChange}
															value={this.state.name}
														/>
													</FormGroup>
													<FormGroup>
														<label>Father Name </label>
														<Input
															type='text'
															name='father_name'
															onChange={this.handleChange}
															value={this.state.father_name}
														/>
													</FormGroup>
													<FormGroup>
														<label>CNIC </label>
														<Input
															type='tel'
															name='cnic'
															onChange={this.handleChange}
															value={this.state.cnic}
														/>
													</FormGroup>
												</Col>
												<Col md={6}>
													<div>
														<input
															type='file'
															style={{ marginTop: 30 }}
															name='myImage'
															onChange={this.onFilesAdded}
														/>
														<img src={this.state.image} />
													</div>
												</Col>
											</Row>
											<Row>
												<Col md={6}>
													<FormGroup>
														<label>Phone </label>
														<Input
															type='tel'
															name='phone'
															onChange={this.handleChange}
															value={this.state.phone}
														/>
													</FormGroup>

													<FormGroup>
														<Label>Customer Type</Label>
														<Select
															options={this.state.customer_type}
															onChange={this.ChangeType}
															value={this.state.selectedCustomer_type}
														></Select>
													</FormGroup>
												</Col>
												<Col md={6}>
													<FormGroup>
														<label>Date of Birth </label>
														<Input
															type='date'
															name='dob'
															onChange={this.handleChange}
															value={this.state.dob}
														/>
													</FormGroup>

													<FormGroup>
														<label>Customer Status </label>
														<Select
															options={this.state.customer_status}
															onChange={this.ChangeStatus}
															value={this.state.selectedCustomer_status}
														></Select>
													</FormGroup>
												</Col>
											</Row>
										</Form>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col md={{ size: 6, offset: 3 }}>
								<Card>
									<CardHeader>
										<CardTitle tag='h4'>Customer Representative</CardTitle>
									</CardHeader>
									<CardBody>
										<Row>
											<Col sm='10'>
												<FormGroup>
													<Button onClick={this.onAddRolesPress}>
														Add Representative
													</Button>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<FormGroup>
												{this.state.fetchedReps.map((Role, idx) => (
													<div key={idx}>
														<Col md={6}>
															<label>Representative Name</label>
															<input
																placeholder={Role.rep_name}
																value={Role.rep_name}
															/>
														</Col>

														<Col md={6}>
															<label>Representative Relation</label>
															<input
																placeholder={Role.rep_relation}
																value={Role.rep_relation}
															/>
														</Col>

														{/* <Row>
                                              <Col lg="2" xs={{offset:10}}>                                                  
                                              <Button 
                                              color="primary" onClick={this.handleRemoveShareholder(Role.repName)}>✖</Button>
                                              </Col>
                                          </Row> */}
													</div>
												))}
											</FormGroup>
										</Row>
										<FormGroup>{dropDown}</FormGroup>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col md={{ size: 6, offset: 3 }}>
								<Card>
									<CardHeader>
										<Row>
											<Col xs={5}>
												<CardTitle tag='h4'>Customer Location</CardTitle>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										<Row>
											<Col sm={12}>
												<div>
													{/* <Map
                                        google={this.props.google}
                                        center={{lat: 30.375320, lng: 69.345116}}
                                        height='300px'
                                        zoom={6}
                                        /> */}
												</div>
											</Col>
										</Row>
										<Row>
											<Col md={6}>
												<FormGroup>
													<label>Tehsil </label>
													<Input
														type='text'
														name='tehsil'
														onChange={this.handleChange}
														value={this.state.tehsil}
													/>
												</FormGroup>
												<FormGroup className={"has-label"}>
													<label>Region </label>

													<Select
														options={this.state.allowedRegions}
														onChange={this.ChangeRegion}
														value={this.state.selectedRegion}
													></Select>
												</FormGroup>
											</Col>
											<Col md={6}>
												<FormGroup>
													<label>Village </label>
													<Input
														type='text'
														name='village'
														onChange={this.handleChange}
														value={this.state.village}
													/>
												</FormGroup>
												<FormGroup className={"has-label"}>
													<label>Sales Point </label>

													<Select
														onChange={this.ChangeSalesPoint}
														value={this.state.selectedSalesPoints}
														options={this.state.slaesPoints}
													></Select>
												</FormGroup>
											</Col>
											<Col md={12}>
												<FormGroup style={{ width: "94%", marginLeft: "22px" }}>
													{/* google={this.props.google} */}
													{map}
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									<CardFooter className='text-right'>
										<Button
											className='btn-round'
											color='primary'
											onClick={this.updateUser}
											size='md'
										>
											Update
										</Button>
										<Button
											className='btn-round'
											color='info'
											onClick={this.handleCancelButton}
											size='md'
										>
											Cancel
										</Button>
										<Button
											className='btn-round'
											color='danger'
											onClick={this.handleDeleteButton}
											size='md'
										>
											Delete
										</Button>
									</CardFooter>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col md={{ size: 6, offset: 3 }}>
								<Card>
									<CardHeader>
										<Row>
											<Col xs={5}>
												<CardTitle tag='h4'>Customer Memos</CardTitle>
											</Col>
											<Col xs={{ size: 3, offset: 4 }}>
												<Button
													style={{ background: "transparent" }}
													onClick={this.onAddMemosPress}
												>
													<span className='tim-icons icon-simple-add'></span>
												</Button>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										{this.state.pressed && memo}
										<ul>
											<Label>
												<h3 style={{ marginLeft: 73 }}>
													Previously Stored Memos
												</h3>
											</Label>
											{this.state.Memo.map((mem) => (
												<li className='timeline-inverted'>
													<div className='timeline-panel'>
														<div className='timeline-heading'>
															<Badge color='info'>Memo # {mem.id}</Badge>
														</div>
														<div className='timeline-body'>
															<Card>{mem.mem}</Card>
														</div>
													</div>
												</li>
											))}
										</ul>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col md={{ size: 6, offset: 3 }}>
								<Card>
									<CardHeader>
										<Row>
											<Col xs={5}>
												<CardTitle tag='h4'>Insemination Events</CardTitle>
											</Col>
											<Col xs={{ size: 3, offset: 4 }}>
												<Button
													style={{ background: "transparent" }}
													onClick={this.AddInceminasionEvents}
												>
													<span className='tim-icons icon-simple-add'></span>
												</Button>
											</Col>
											<Col xs={{ size: 3, offset: 4 }}>
												<Button
													style={{ background: "transparent" }}
													onClick={this.showInseminationEvents}
												>
													View Events
												</Button>
											</Col>
										</Row>
									</CardHeader>
								</Card>
							</Col>
						</Row>
					</div>
				</>
			);
		}
	}
}

export default withRouter(EditCustomer);
