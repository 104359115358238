/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import { baseUrl } from '../../Shared/baseUrl';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Switch from 'react-bootstrap-switch';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Button,
	Badge,
} from 'reactstrap';
import Moment from 'moment';

var currentDate;

class incomingInventoryDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toWh: '',
			fromWh: '',
			fromWhName: '',
			parentCat: '',
			productCat: '',
			product: '',
			productName: '',
			sku: '',
			dispatchedQty: '',
			dispatchDate: '',
			id: '',
			okProd: '',
			dmgdProduct: '',
			prodNotReceived: '',
			alert: null,
			loader: null,
		};
	}

	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert(true)}
					onCancel={() => this.hideAlert(true)}
					btnSize=''
				>
					Warehouse Updated Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = (success) => {
		this.setState({
			alert: null,
		});

		if (success) {
			this.handleCancelButton();
		}
	};

	errorAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					Something went wrong!!
				</ReactBSAlert>
			),
		});
	};

	handleCancelButton = () => {
		this.props.history.push('/admin/transitInventory');
	};

	componentDidMount() {
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		let details = {
			token: cookie.load('Token'),
			id: data.id,
			permissions: data.Permissions,
		};

		fetch(
			baseUrl +
			'/inventory/getIncomingInventoryDetail/' +
			this.props.match.params.id,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + details.token,
					id: details.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: 'Post',
			},
		)
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					fetch(
						`${baseUrl}/pos/productsList/${jsonResponse.data[0].productId}`,
						{
							headers: {
								'Content-Type': 'application/json',
								Accept: 'application/json',
								Authorization: 'Bearer ' + details.token,
								id: details.id,
								reg: JSON.stringify(data.Permissions),
							},
							method: 'Post',
						},
					)
						.then((response) => response.json())
						.then((jsonResponse) => {
							if (jsonResponse.success) {
								this.setState({
									product: jsonResponse.product,
									productName: jsonResponse.product.productName,
								});
							}
						});

					this.setState({
						toWh: jsonResponse.data[0].toWh,
						fromWh: jsonResponse.data[0].fromWarehouse,
						fromWhName: jsonResponse.data[0].fromWarehouse.whName,
						sku: jsonResponse.data[0].sku,
						dispatchedQty: jsonResponse.data[0].qty,
						dispatchDate: jsonResponse.data[0].dispatchDate,
						id: jsonResponse.data[0].id,
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}

	handleSubmit = (event) => {
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		var token = cookie.load('Token');
		var d1 = new Date();
		currentDate = Moment(d1).format('YYYY-MM-DD');

		fetch(baseUrl + '/inventory/completeTransit', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				okProduct: this.state.okProd !== '' ? parseInt(this.state.okProd) : 0,
				damagedProduct: this.state.dmgdProduct !== '' ? parseInt(this.state.dmgdProduct) : 0,
				productNotReceived: this.state.prodNotReceived !== '' ? parseInt(this.state.prodNotReceived) : 0,
				dispatchDate: `${this.state.dispatchDate.split('T')[0]}`,
				date: currentDate,
				sku: this.state.sku,
				productId: this.state.product.id,
				originWarehouseId: this.state.fromWh.id,
				destinationWarehouseId: this.state.toWh,
				// dispatchedQty: this.state.dispatchedQty,
				// id: this.state.id,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					fetch(baseUrl + '/inventory/completeSenderTransit', {
						headers: {
							'Content-Type': 'application/json',
							Accept: 'application/json',
							Authorization: 'Bearer ' + token,
							id: data.id,
							reg: JSON.stringify(data.Permissions),
						},
						method: 'Post',
						body: JSON.stringify({
							fromWh: this.state.fromWh.id,
							sku: this.state.sku,
							okProd: this.state.okProd,
							date: currentDate,
						}),
					})
						.then((res) => res.json())
						.then((jsonResponse) => {
							if (jsonResponse.success === true) {
								this.successAlert();
							} else {
								this.errorAlert();
							}
						});
				} else {
					this.errorAlert();
				}
			});
	};

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col md={{ size: 7, offset: 2 }}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Warehouse Detail</CardTitle>
								</CardHeader>
								<CardBody>
									<Form action='/' className='form-horizontal' method='get'>
										<Row>
											<Col md={12}>
												<FormGroup>
													<Label>Dispatced from</Label>
													<Input
														className='text-white'
														type='text'
														name='fromWh'
														readOnly
														value={this.state.fromWhName}
													/>
												</FormGroup>
												<FormGroup>
													<Label>Dispatch Date</Label>
													<Input
														className='text-white'
														type='text'
														name='dispatchDate'
														readOnly
														value={this.state.dispatchDate.split('T')[0]}
													/>
												</FormGroup>
												<FormGroup>
													<Label>Product</Label>
													<Input
														className='text-white'
														type='text'
														name='product'
														readOnly
														value={this.state.productName}
													/>
												</FormGroup>
												<FormGroup>
													<Label>SKU</Label>
													<Input
														className='text-white'
														type='text'
														name='sku'
														readOnly
														value={this.state.sku}
													/>
												</FormGroup>
												<FormGroup>
													<Label>Dispatched Quantity</Label>
													<Input
														className='text-white'
														type='text'
														name='dispatchedQty'
														readOnly
														value={this.state.dispatchedQty}
													/>
												</FormGroup>
												<FormGroup>
													<Label>OK Product</Label>
													<Input
														type='text'
														name='okProd'
														onChange={this.handleChange}
													/>
												</FormGroup>
												<FormGroup>
													<Label>Product Not Received</Label>
													<Input
														type='text'
														name='prodNotReceived'
														onChange={this.handleChange}
													/>
												</FormGroup>
												<FormGroup>
													<Label>Damaged Product</Label>
													<Input
														type='text'
														name='dmgdProduct'
														onChange={this.handleChange}
													/>
												</FormGroup>
											</Col>
										</Row>
									</Form>
								</CardBody>
								<CardFooter className='text-right'>
									<Button
										// disabled={!this.validateForm()}
										className='btn-round'
										color='primary'
										onClick={this.handleSubmit}
										size='lg'
									>
										Update
									</Button>
									<Button
										className='btn-round'
										color='primary'
										onClick={this.handleCancelButton}
										size='lg'
									>
										Cancel
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default withRouter(incomingInventoryDetail);
