import React from 'react';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Row,
	Col,
} from 'reactstrap';
import { baseUrl } from '../../Shared/baseUrl';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import ReactTable from 'react-table';

const user = JSON.parse(window.localStorage.getItem('user'));
const data = JSON.parse(user);
const token = cookie.load('Token');

const initialState = {
	allowedRegions: [],
	selectedRegion: null,
	fieldSupervisors: [],
	selectedFieldSupervisor: null,
	subRoutes: [],
	selectedSubRoute: null,
	vehicles: [],
	selectedVehicle: null,
	selectedWeekDay: [],
	routeSchedule: true,
	table: [],
	tableData: [],
	type: 'new',
	scheduleRouteId: null,
};

const weekDays = [
	{ label: 'Sunday', value: 'Sunday' },
	{ label: 'Monday', value: 'Monday' },
	{ label: 'Tuesday', value: 'Tuesday' },
	{ label: 'Wednesday', value: 'Wednesday' },
	{ label: 'Thursday', value: 'Thursday' },
	{ label: 'Friday', value: 'Friday' },
	{ label: 'Saturday', value: 'Saturday' },
];

class RouteSchedule extends React.Component {
	constructor(props) {
		super(props);
		this.state = { alert: null, ...initialState };
	}

	componentWillMount() {
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer ' + token,
			id: data.id,
			reg: JSON.stringify(data.Permissions),
		};

		fetch(`${baseUrl}/routing/get-schedule-routes`, {
			headers: headers,
			method: 'Get',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						tableData: jsonResponse.scheduleRoutes.map(
							(scheduleRoute, index) => {
								fetch(
									`${baseUrl}/auth/users/getUserDetail/${scheduleRoute.fsId}`,
									{
										headers: headers,
										method: 'Post',
									},
								)
									.then((user) => user.json())
									.then((jsonUser) => {
										this.state.tableData[index].fieldSupervisorName =
											jsonUser.User.username;

										this.setState({ tableData: [...this.state.tableData] });
									});

								fetch(
									`${baseUrl}/fleet/getvehicleDetails/${scheduleRoute.vehicleId}`,
									{
										headers: headers,
										method: 'Post',
									},
								)
									.then((vehicle) => vehicle.json())
									.then((jsonVehicle) => {
										this.state.tableData[index].vehicleName =
											jsonVehicle.vehicle.make +
											' ' +
											jsonVehicle.vehicle.vehicleNumber;

										this.setState({ tableData: [...this.state.tableData] });
									});

								return {
									id: scheduleRoute.id,
									day: scheduleRoute.day,
									subRouteName: scheduleRoute.subRoute.name,
									regionName: scheduleRoute.region.name,
								};
							},
						),
					});
				}
			});
	}

	componentDidMount() {
		if (data.permissions !== null) {
			const permissions = data.Permissions;
			const getRoleId = (Permissions) => Permissions.regionCode;
			const getRoleIdArray = (permissions) => permissions.map(getRoleId);
			const regions = getRoleIdArray(permissions);

			var regionCodes = regions.filter(function (el) {
				return el !== undefined;
			});

			const getNameId = (Permissions) => Permissions.regionName;
			const getNameIdArray = (permissions) => permissions.map(getNameId);
			const regionsname = getNameIdArray(permissions);
			var regionName = regionsname.filter(function (el) {
				return el !== undefined;
			});

			var regionsJson = [];

			for (var i in regionCodes) {
				var code = regionCodes[i];
				var label = regionName[i];

				regionsJson.push({
					code: code,
					label: label,
				});
			}
			var result = [
				...new Map(regionsJson.map((o) => [JSON.stringify(o), o])).values(),
			];

			const headers = {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			};

			fetch(`${baseUrl}/auth/users/getRegion`, {
				headers: headers,
				method: 'Post',
			})
				.then((response) => response.json())
				.then((jsonResponse) => {
					if (jsonResponse.success) {
						jsonResponse.Regions.map((region) => {
							result.map((allowedRegion) => {
								if (allowedRegion.code === region.code) {
									this.setState({
										allowedRegions: [
											...this.state.allowedRegions,
											{ label: region.name, value: region.id },
										],
									});
								}
							});
						});
					}
				});
		}

		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer ' + token,
			id: data.id,
			reg: JSON.stringify(data.Permissions),
		};

		fetch(`${baseUrl}/auth/users/getFieldSupervisors`, {
			headers: headers,
			method: 'Post',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						fieldSupervisors: jsonResponse.data.map((fieldSupervisor) => {
							return {
								label: fieldSupervisor.username,
								value: fieldSupervisor.id,
							};
						}),
					});
				}
			});

		fetch(`${baseUrl}/routing/getRouteDetails`, {
			headers: headers,
			method: 'Post',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						subRoutes: jsonResponse.routes.map((subRoute) => {
							return {
								label: subRoute.name,
								value: subRoute.id,
							};
						}),
					});
				}
			});

		fetch(`${baseUrl}/fleet/getVehiclesList`, {
			headers: headers,
			method: 'Post',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						vehicles: jsonResponse.Vehicle.map((vehicle) => {
							return {
								label: vehicle.make + ' ' + vehicle.vehicleNumber,
								value: vehicle.id,
							};
						}),
					});
				}
			});
	}

	handleRegionChange = (event) => {
		this.setState({ selectedRegion: event, routeSchedule: true });
	};

	handleFieldSupervisorChange = (event) => {
		this.setState({ selectedFieldSupervisor: event });
	};

	handleSubRouteChange = (event) => {
		this.setState({ selectedSubRoute: event });
	};

	handleVehicleChange = (event) => {
		this.setState({ selectedVehicle: event });
	};

	handleWeekDayChange = (event) => {
		this.setState({ selectedWeekDay: event });
	};

	hideForm = () => {
		this.setState({
			selectedRegion: null,
			routeSchedule: false,
			selectedFieldSupervisor: null,
			selectedVehicle: null,
			selectedWeekDay: null,
			selectedSubRoute: null,
			type: 'new',
			scheduleRouteId: null,
		});
	};

	handleSubmit = () => {
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer ' + token,
			id: data.id,
			reg: JSON.stringify(data.Permissions),
		};

		fetch(`${baseUrl}/routing/add-schedule-route`, {
			headers: headers,
			method: 'Post',
			body: JSON.stringify({
				fsId: this.state.selectedFieldSupervisor
					? this.state.selectedFieldSupervisor.value
					: null,
				day: this.state.selectedWeekDay
					? this.state.selectedWeekDay.value
					: null,
				regionId: this.state.selectedRegion
					? this.state.selectedRegion.value
					: null,
				subRouteId: this.state.selectedSubRoute
					? this.state.selectedSubRoute.value
					: null,
				vehicleId: this.state.selectedVehicle
					? this.state.selectedVehicle.value
					: null,
			}),
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert(jsonResponse.message);
					this.resetForm();
					this.componentWillMount();
					this.componentDidMount();
				} else {
					this.errorAlert(jsonResponse.message);
				}
			});
	};

	successAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					{message}
				</ReactBSAlert>
			),
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message}
				</ReactBSAlert>
			),
		});
	};

	resetForm = () => {
		this.setState(initialState);
	};

	filterCaseInsensitive = (filter, row) => {
		const id = filter.pivotId || filter.id;
		const content = row[id];
		if (typeof content !== 'undefined') {
			// filter by text in the table or if it's a object, filter by key
			if (typeof content === 'object' && content !== null && content.key) {
				return String(content.key)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			} else {
				return String(content)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			}
		}

		return true;
	};

	rowClicked = (row) => {
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer ' + token,
			id: data.id,
			reg: JSON.stringify(data.Permissions),
		};

		fetch(`${baseUrl}/routing/get-schedule-route/${row.id}`, {
			headers: headers,
			method: 'Get',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						routeSchedule: true,
						selectedVehicle: {
							label: row.vehicleName,
							value: jsonResponse.scheduleRoute.vehicleId,
						},
						selectedWeekDay: { label: row.day, value: row.day },
						selectedSubRoute: {
							label: row.subRouteName,
							value: jsonResponse.scheduleRoute.subRouteId,
						},
						selectedRegion: {
							label: row.regionName,
							value: jsonResponse.scheduleRoute.regionId,
						},
						selectedFieldSupervisor: {
							label: row.fieldSupervisorName,
							value: jsonResponse.scheduleRoute.fsId,
						},
						type: 'edit',
						scheduleRouteId: row.id,
					});
				} else {
					this.errorAlert(jsonResponse.message);
				}
			});
	};

	handleUpdate = (event) => {
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer ' + token,
			id: data.id,
			reg: JSON.stringify(data.Permissions),
		};

		fetch(
			`${baseUrl}/routing/update-schedule-route/${this.state.scheduleRouteId}`,
			{
				headers: headers,
				method: 'Post',
				body: JSON.stringify({
					fsId: this.state.selectedFieldSupervisor
						? this.state.selectedFieldSupervisor.value
						: null,
					day: this.state.selectedWeekDay
						? this.state.selectedWeekDay.value
						: null,
					regionId: this.state.selectedRegion
						? this.state.selectedRegion.value
						: null,
					subRouteId: this.state.selectedSubRoute
						? this.state.selectedSubRoute.value
						: null,
					vehicleId: this.state.selectedVehicle
						? this.state.selectedVehicle.value
						: null,
				}),
			},
		)
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert(jsonResponse.message);
					this.hideForm();
					this.componentWillMount();
				} else {
					this.errorAlert(jsonResponse.message);
				}
			});
	};

	handleDelete = () => {
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer ' + token,
			id: data.id,
			reg: JSON.stringify(data.Permissions),
		};
		
		fetch(
			`${baseUrl}/routing/delete-schedule-route/${this.state.scheduleRouteId}`,
			{
				headers: headers,
				method: 'Delete',
			},
		)
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert(jsonResponse.message);
					this.hideForm();
					this.componentWillMount();
				} else {
					this.errorAlert(jsonResponse.message);
				}
			});
	};

	render() {
		return (
			<div className='content'>
				{this.state.alert}
				<Row>
					<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
						<Form>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Add Route Scheduling</CardTitle>
								</CardHeader>
								<CardBody>
									<FormGroup className={'has-label'}>
										<label>Select Region*</label>
										<Select
											options={this.state.allowedRegions}
											onChange={this.handleRegionChange}
											value={this.state.selectedRegion}
										/>
									</FormGroup>
									{this.state.routeSchedule && (
										<div>
											<FormGroup className={'has-label'}>
												<label>Select Field Supervisor*</label>
												<Select
													options={this.state.fieldSupervisors}
													onChange={this.handleFieldSupervisorChange}
													value={this.state.selectedFieldSupervisor}
												/>
											</FormGroup>

											<FormGroup className={'has-label'}>
												<label>Select Sub Route*</label>
												<Select
													options={this.state.subRoutes}
													onChange={this.handleSubRouteChange}
													value={this.state.selectedSubRoute}
												/>
											</FormGroup>

											<FormGroup className={'has-label'}>
												<label>Select Vehicle*</label>
												<Select
													options={this.state.vehicles}
													onChange={this.handleVehicleChange}
													value={this.state.selectedVehicle}
												/>
											</FormGroup>

											<FormGroup className={'has-label'}>
												<label>Select Week Day*</label>
												<Select
													options={weekDays}
													onChange={this.handleWeekDayChange}
													value={this.state.selectedWeekDay}
												/>
											</FormGroup>
										</div>
									)}
								</CardBody>
								<CardFooter className='text-right'>
									{this.state.routeSchedule && (
										<div>
											{this.state.type === 'new' && (
												<Button
													className='btn-round'
													size='md'
													color='primary'
													onClick={this.handleSubmit}
												>
													Add Schedule
												</Button>
											)}

											{this.state.type === 'edit' && (
												<Button
													className='btn-round'
													size='md'
													color='primary'
													onClick={this.handleUpdate}
												>
													Update Schedule
												</Button>
											)}

											<Button
												className='btn-round'
												color='info'
												size='md'
												onClick={this.hideForm}
											>
												Show Schedules
											</Button>

											{this.state.type === 'edit' && (
												<Button
													className='btn-round'
													size='md'
													color='danger'
													onClick={this.handleDelete}
												>
													Delete Schedule
												</Button>
											)}
										</div>
									)}
								</CardFooter>
							</Card>
						</Form>
					</Col>
				</Row>
				{!this.state.routeSchedule && (
					<Row className='mt-5'>
						<Col xs={12} md={12}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Routes Scheduling List</CardTitle>
								</CardHeader>
								<CardBody>
									{this.state.tableData.length > 0 && (
										<ReactTable
											data={this.state.tableData}
											filterable
											defaultFilterMethod={this.filterCaseInsensitive}
											resizable={false}
											columns={[
												{
													Header: 'Field Supervisor',
													accessor: 'fieldSupervisorName',
												},
												{
													Header: 'Sub Route Name',
													accessor: 'subRouteName',
												},
												{
													Header: 'Vehicle',
													accessor: 'vehicleName',
												},
												{
													Header: 'Day',
													accessor: 'day',
												},
												{
													Header: 'Region',
													accessor: 'regionName',
												},
											]}
											getTdProps={(state, rowInfo, column, instance) => {
												return {
													onClick: (e) => this.rowClicked(rowInfo.original),
												};
											}}
											defaultPageSize={
												this.state.tableData.length < 10
													? this.state.tableData.length
													: 10
											}
											showPaginationTop
											showPaginationBottom={false}
											className='-striped -highlight'
										/>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

export default RouteSchedule;
