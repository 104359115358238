import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { baseUrl } from '../../Shared/baseUrl';
import cookie from 'react-cookies';
import ReactTable from 'react-table';

const user = JSON.parse(window.localStorage.getItem('user'));
const data = JSON.parse(user);
const token = cookie.load('Token');

const initialState = {
	tableData: [],
};

class PreviousTrips extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentDidMount() {
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer ' + token,
			id: data.id,
			reg: JSON.stringify(data.Permissions),
		};
		
		fetch(`${baseUrl}/routing/get-previous-trips`, {
			headers: headers,
			method: 'Get',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						tableData: jsonResponse.previousTrips.map((previousTrip, index) => {
							fetch(
								`${baseUrl}/auth/users/getUserDetail/${previousTrip.fsId}`,
								{
									headers: headers,
									method: 'Post',
								},
							)
								.then((response) => response.json())
								.then((jsonResponse) => {
									if (jsonResponse.success) {
										this.state.tableData[index].fieldSupervisorName =
											jsonResponse.User.username;

										this.setState({ tableData: [...this.state.tableData] });
									}
								});

							return {
								id: previousTrip.id,
								subRouteName: previousTrip.subRoute.name,
								startTime: previousTrip.startTime,
								endTime: previousTrip.endTime,
							};
						}),
					});
				}
			});
	}

	filterCaseInsensitive = (filter, row) => {
		const id = filter.pivotId || filter.id;
		const content = row[id];
		if (typeof content !== 'undefined') {
			// filter by text in the table or if it's a object, filter by key
			if (typeof content === 'object' && content !== null && content.key) {
				return String(content.key)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			} else {
				return String(content)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			}
		}

		return true;
	};

	rowClicked = (row) => {
		if (row) {
			this.props.history.push(`/admin/previous-trip/${row.original.id}`);
		}
	};

	render() {
		return (
			<div className='content'>
				<Col md={8} className='ml-auto mr-auto'>
					<h2 className='text-center'>Previous Trips Info</h2>
					<p className='text-center'>
						This Table contain all the information about Routes{' '}
						<a href='' target='_blank' rel='noopener noreferrer'>
							Trips-table
						</a>
						. If need to modified Promotions please click on the buttons in the
						action column.{' '}
						<a href='' target='_blank' rel='noopener noreferrer'>
							full documentation.
						</a>
					</p>
				</Col>
				<Row className='mt-5'>
					<Col xs={12} md={12}>
						<Card>
							<CardHeader>
								<CardTitle tag='h4'>Trips Table</CardTitle>
							</CardHeader>
							<CardBody>
								<ReactTable
									data={this.state.tableData}
									filterable
									defaultFilterMethod={this.filterCaseInsensitive}
									resizable={false}
									columns={[
										{
											Header: 'ID',
											accessor: 'id',
										},
										{
											Header: 'Field Supervisor',
											accessor: 'fieldSupervisorName',
										},
										{
											Header: 'Sub Route',
											accessor: 'subRouteName',
										},
										{
											Header: 'Start Time',
											accessor: 'startTime',
										},
										{
											Header: 'End Time',
											accessor: 'endTime',
										},
									]}
									getTdProps={(state, rowInfo, column, instance) => {
										return {
											onClick: (e) => this.rowClicked(rowInfo),
										};
									}}
									defaultPageSize={10}
									showPaginationTop
									showPaginationBottom={false}
									className='-striped -highlight'
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

export default PreviousTrips;
