/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react"
import cookie from "react-cookies"
import ReactBSAlert from "react-bootstrap-sweetalert"
import Loader from "react-loader-spinner"
import LoadingOverlay from "react-loading-overlay"
import Select from "react-select"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Label,
    Form,
    Input,
    Row,
    Col,
    Checkbox
} from "reactstrap"
import { baseUrl } from "../../Shared/baseUrl"

var dropDown

class AddSms extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // register form
            message:"",
            trigger:[
            { label: "Route Scheduled", value: "t1" },
        ],
            emailVerified:false,
            alert: null,
            loader:null,
            Roles:[],
            Regions:[],
            fetchedRoles:[],
            pressed:false,
            roleSelected:[],
            selectedRegion:"",
            dropDown1array:[],
            regionDrop1:false,
            permissions:[],
            Roles_Permissions:[],
            checked:false,
            selectedTrigger_type:"",

     
        }
        this.successAlert = this.successAlert.bind(this)
        this.onAddRolesPress = this.onAddRolesPress.bind(this)

    }

  handleCheckboxChange = event =>{
      this.setState({ checked: event.target.checked })
  }

  componentWillUnmount() {
      var id = window.setTimeout(null, 0)
      while (id--) {
          window.clearTimeout(id)
      }
  }
  onAddRolesPress  (){
      this.setState({ pressed: true })
  }

  componentDidMount(){
      
      var user = JSON.parse(window.localStorage.getItem("user"))
      var data = JSON.parse(user)
      var token = cookie.load("Token")
      let details = {
          userid:data.id,
          token:token,
      }
      fetch(baseUrl + "/auth/roles/getRole/Roles",{
          headers : { 
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + details.token,
              "id": details.userid
          },
          method:"Post",
      
      }).then((res)=>res.json()).then((jsonResponse)=>{
          this.setState({loader:null})
          if(jsonResponse.success === true){
              var newArray = jsonResponse.Roles.filter(function (el) {
                  return el.Role.Role !== "Super-User" 
            
              })
              this.setState({fetchedRoles:newArray.map((prop,key)=>{
                  return{
                      value:prop.id,
                      label:prop.Role.Role,
                      Dep:prop.Role.Dep
                  }
              })})
     
          }
          else {
              throw new Error("Error from backend")
          }
     
      }).catch(err =>{
          throw new Error(err)
      })
 
  }

  
 
  handleRemoveShareholder = idx => () => {
      this.setState({
          roleSelected: this.state.roleSelected.filter((s) => idx !== s.id)
      })
      this.setState({Roles_Permissions:this.state.Roles_Permissions.filter((s)=> idx !== s.id)})
  };

  savePressed = () =>{
      var reg_per =[] 
      this.state.permissions.Permissions.map((props,key)=>{
          this.state.dropDown1array.map((data)=>{
              if(data.Dep == "reg"){
                  reg_per.push({
                      id:data.id,
                      Permissions:props,
                      regionCode:data.regionCode,
                      regionName:data.regionName
                  })
              }
              else{
                  reg_per.push({
                      id:data.id,
                      Permissions:props,
               
                  })
              }
              
          })    
      })
      this.setState({Roles_Permissions:this.state.Roles_Permissions.concat(reg_per)})
      this.setState({roleSelected:this.state.roleSelected.concat(this.state.dropDown1array)})
      dropDown = null
      this.setState({pressed:false})
      this.setState({regionDrop1:false})
      this.setState({dropDown1array:[""]})
      reg_per = []
  }
  
  successAlert()  {
      this.setState({
          alert: (
              <ReactBSAlert
                  success
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Success!"
                  confirmBtnBsStyle="success"
                  onConfirm={() => this.hideAlert()}
                  onCancel={() => this.hideAlert()}
                  btnSize="">
                Message Added Successfully !
              </ReactBSAlert>
          )
      })
  }
  hideAlert = () => {
      this.setState({
          alert: null
      })
  }
errorAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Error"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="danger"
                btnSize=""
            >
     Error!!!!!
       
            </ReactBSAlert>
        )
    })
  
};

 addingSmsFunction = (details) => {
     this.setState({loader:(
         <LoadingOverlay
             styles={{
                 overlay: (base) => ({
                     ...base,
                     marginTop: "250px",
                     marginLeft: "-80px",
                 })
             }}
             active={true}
             spinner={<Loader  type="CradleLoader"
  
                 height="100"	
                 width="100"/>}
 
         >
         </LoadingOverlay>
     )})
     console.log(this.state.selectedTrigger_type, "yellow")

     fetch(baseUrl + "/sms/addSms",{
      
         headers : { 
             "Content-Type": "application/json",
             "Accept": "application/json",
             "Authorization": "Bearer " + details.token,
             "id": details.id
         },
         method:"Post",
         body:JSON.stringify({
             message:details.message,
             trigger:details.trigger,
         })
     }).then((res)=>res.json()).then((jsonResponse)=>{
         console.log(jsonResponse)
         this.setState({loader:null})
         if(jsonResponse.success === true){
             this.successAlert()
         }
         else {
             this.errorAlert()
         }
       
     }).catch(err =>{
         this.errorAlert()
     })
 }

  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
      if (value.length >= length) {
          return true
      }
      return false
  };
  // function that verifies if two strings are equal
  compare = (string1, string2) => {
      if (string1 === string2) {
          return true
      }
      return false
  };

  resetForm(){
      this.setState({message:""})

  }
  ChangeType =(event) => {
    this.setState({selectedTrigger_type:event.label})
  
  }
  handleSubmit = event => {
      var user = JSON.parse(window.localStorage.getItem("user"))
      var data = JSON.parse(user)
      var token = cookie.load("Token")
    
      var result = [...new Map(this.state.roleSelected.map( o => [JSON.stringify(o), o])).values()]
  
      let details = {
          userid:data.id,
          message:this.state.message,
          trigger:this.state.selectedTrigger_type,
          token:token,
          Roles:result,
      
      }
      this.addingSmsFunction(details)
      this.resetForm()
  }
  handleChange =(event) => {
    
    const target = event.target
    const value =  target.value
    const name = target.name

    this.setState({
        [name]: value
    })
   
}

  render() {
      if(this.state.pressed == true){
          dropDown  = 
 <>

       </>  
      }
      let {
          usernameState,
    
      } = this.state
      return (
        
      <>
        <div className="content">
            {this.state.alert}
       
            {this.state.loader}
            <Row >
                <Col className="mr-auto" md={{size:7,offset:2}}>
          
                    <Form id="RegisterValidation">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h3">Add New Message</CardTitle>
                            </CardHeader>
                            <CardBody>
                                
                                <FormGroup>
                                    <label>Trigger *</label>
                                    <Select options={this.state.trigger} onChange={this.ChangeType} 
                                    placeholder={this.state.selectedTrigger_type}
                                    >
                                          </Select>
                                </FormGroup>
                                <FormGroup className={`has-label ${usernameState}`}>
                                    <label>Message *</label>
                                    <textarea rows='8' cols='50' style={{background:"transparent", color:"white"}} type="text" name="message" onChange={this.handleChange} value={this.state.message}/>

                   
                                    {this.state.usernameState === "has-danger" ? (
                                        <label className="error">
                          Please enter a valid message.
                                        </label>
                                    ) : null}
                                </FormGroup>
                            </CardBody>
                            <CardFooter className="text-right">
                                <Button color="primary"     
                                    onClick={this.handleSubmit}

                                >
                      Send Message
                                </Button>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
          
            </Row>
        </div>
      </>
      )
  }
}

export default AddSms
