import React, { Component } from "react";

// react component for creating dynamic tables
import ReactTable from "react-table";
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,

} from "reactstrap";
import { getUsers } from "../../Redux/ActionCreator";
import { baseUrl } from "../../Shared/baseUrl";
const mapStateToProps = (state) => {
  return{
    Users:state.Users
   }

}

const mapDispatchToProps = (dispatch) => {
  return {

    getUsers: (details) => dispatch(getUsers(details)),
  };
}



class ModifiedProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      table:null
    };
  }
 
  componentDidMount(){
    var user = JSON.parse(window.localStorage.getItem('user'))
    var data = JSON.parse(user)

    let details = {
      token:cookie.load('Token'),
      id:data.id,
      permissions:data.Permissions

      
    }

      fetch(baseUrl + '/hrm/getProfile',{
          headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + details.token,
              'id': details.id,
              "reg":JSON.stringify(details.permissions)

              
             },
          method:'Post',
      }).then((res)=>res.json()).then((jsonResponse)=>{
       
          if(jsonResponse.success === true){
            
        
             this.setState({data:jsonResponse.Employee.map((prop, key) => {      
              return {
                name: prop.username,
                email: prop.email
              };
             
            })
          })
          this.setState({table:
            <ReactTable
            data={this.state.data}
            filterable
            resizable={false}
            columns={[
              {
                Header: "Name",
                accessor: "name"
              },
              {
                Header:<span style={{marginRight:'100%'}}>Email</span>,
                accessor: "email"
              }
            ]}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: e =>
              
             this.rowClicked(rowInfo.original.email)
            
              };
            }}
            defaultPageSize={10}
            showPaginationTop
            showPaginationBottom={false}
            className="-striped -highlight"
          />
          })
          }

      }).catch(err =>{
         
      })
 
  }
  rowClicked(email){
    let obj = this.state.data.find(o => o.email === email)

    this.props.history.push(`/admin/Edit_Profile/${obj.email}`);
  }
  
  render() {


    return (
      <>
        <div className="content">
       {}
          <Col md={8} className="ml-auto mr-auto">
            <h2 className="text-center">Users Profile</h2>
            <p className="text-center">
              This Table contain all the information about Users{" "}
              <a
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                user-table
              </a>
              . If need to modified user please click on the buttons
              in the action column.{" "}
              <a
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                full documentation.
              </a>
            </p>
          </Col>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Users Table</CardTitle>
                </CardHeader>
                <CardBody>
              {this.state.table}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
   }

}

export default connect(mapStateToProps,mapDispatchToProps) (ModifiedProfile)