/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

// react component for creating dynamic tables
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { getUsers } from '../../Redux/ActionCreator';
import { baseUrl } from '../../Shared/baseUrl';
import { any } from 'prop-types';
const mapStateToProps = (state) => {
	return {
		Users: state.Users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (details) => dispatch(getUsers(details)),
	};
};

class listPromo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			table: null,
		};
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);

		let details = {
			token: cookie.load('Token'),
			id: data.id,
			Permissions: data.Permissions,
		};

		fetch(baseUrl + '/pos/getProductPromotionList', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(details.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						data: jsonResponse.pOffer.map((prop, key) => {
							fetch(`${baseUrl}/pos/productsList/${prop.productId}`, {
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
									Authorization: 'Bearer ' + details.token,
									id: details.id,
									reg: JSON.stringify(details.Permissions),
								},
								method: 'Post',
							})
								.then((response) => response.json())
								.then((jsonResponse) => {
									if (jsonResponse.success) {
										this.state.data[key].prod_name =
											jsonResponse.product.productName;

										this.setState({ data: [...this.state.data] });
									}
								});

							fetch(`${baseUrl}/auth/users/get-region/${prop.regionId}`, {
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
									Authorization: 'Bearer ' + details.token,
									id: details.id,
									reg: JSON.stringify(details.Permissions),
								},
								method: 'Post',
							})
								.then((response) => response.json())
								.then((jsonResponse) => {
									if (jsonResponse.success) {
										this.state.data[key].region = jsonResponse.region.name;

										this.setState({ data: [...this.state.data] });
									}
								});

							return {
								id: prop.id,
								offerType: prop.offerType,
								pOffer: prop.offer,
								sDate: this.formatDate(prop.startDate),
							};
						}),
					});
				}
			})
			.catch((err) => {});
	}

	formatDate(string) {
		var options = { year: 'numeric', month: 'long', day: 'numeric' };
		return new Date(string).toLocaleDateString([], options);
	}

	rowClicked(id) {
		let obj = this.state.data.find((o) => o.id === id);
		this.props.history.push(`/admin/Edit_Promo/${obj.id}`);
	}

	filterCaseInsensitive = (filter, row) => {
		const id = filter.pivotId || filter.id;
		const content = row[id];
		if (typeof content !== 'undefined') {
			// filter by text in the table or if it's a object, filter by key
			if (typeof content === 'object' && content !== null && content.key) {
				return String(content.key)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			} else {
				return String(content)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			}
		}

		return true;
	};

	render() {
		return (
			<>
				<div className='content'>
					{}
					<Col md={8} className='ml-auto mr-auto'>
						<h2 className='text-center'>Product Promotion Info</h2>
						<p className='text-center'>
							This Table contain all the information about Promotions{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								Promotions-table
							</a>
							. If need to modified Promotions please click on the buttons in
							the action column.{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								full documentation.
							</a>
						</p>
					</Col>
					<Row className='mt-5'>
						<Col xs={12} md={12}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Product Promotion Table</CardTitle>
								</CardHeader>
								<CardBody>
									<ReactTable
										data={this.state.data}
										filterable
										defaultFilterMethod={this.filterCaseInsensitive}
										resizable={false}
										columns={[
											{
												Header: 'Product Name',
												accessor: 'prod_name',
											},
											{
												Header: 'Region',
												accessor: 'region',
											},
											{
												Header: 'Offer Type',
												accessor: 'offerType',
											},
											{
												Header: 'Offer',
												accessor: 'pOffer',
											},
											{
												Header: 'Start Date',
												accessor: 'sDate',
											},
										]}
										getTdProps={(state, rowInfo, column, instance) => {
											return {
												onClick: (e) => this.rowClicked(rowInfo.original.id),
											};
										}}
										defaultPageSize={10}
										showPaginationTop
										showPaginationBottom={false}
										className='-striped -highlight'
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(listPromo);
