/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import { baseUrl } from '../../Shared/baseUrl';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Switch from 'react-bootstrap-switch';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Button,
	Badge,
} from 'reactstrap';

var counter = 0;
class EditProduct extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			product_name: '',
			sku: '',
			product_categories: [],
			selectedproduct_category: '',
			alert: null,
			loader: null,
			skuVerified: true,
		};

		this.updateProduct = this.updateProduct.bind(this);
		this.handleswitchChange = this.handleswitchChange.bind(this);
	}
	hide() {
		this.setState({
			isActive: false,
		});
	}
	handleCheckboxChange = (event) => {
		this.setState({ checked: event.target.checked });
	};

	successAlert(message) {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					onCancel={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					btnSize=''
				>
					{message ? message : `Product Updated Successfully !`}
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	hideDeleteAlert = () => {
		this.setState({
			alert: null,
		});

		this.handleCancelButton();
	};

	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : `Something went wrong!!`}
				</ReactBSAlert>
			),
		});
	};

	handleswitchChange(checked, event) {
		this.setState({ active: event });
	}
	handleCancelButton = () => {
		this.props.history.push('/admin/productsList');
	};
	ChangeProductCat = (event) => {
		this.setState({ selectedproduct_category: event });
	};

	componentDidMount() {
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		let details = {
			token: cookie.load('Token'),
			id: data.id,
			permissions: data.Permissions,
		};

		fetch(baseUrl + '/pos/productsList/' + this.props.match.params.id, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						product_name: jsonResponse.product.productName,
						sku: jsonResponse.product.sku,
						selectedproduct_category: jsonResponse.product.productCategoryId,
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});

		fetch(
			`${baseUrl}/pos/get-product-categories/${this.props.match.params.id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + details.token,
					id: details.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: 'Get',
			},
		)
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						product_categories: jsonResponse.categories.map((category) => {
							return {
								label: category.productCat,
								value: category.id,
							};
						}),
					});

					this.setState({
						selectedproduct_category: this.state.product_categories.find(
							(c) => c.value === this.state.selectedproduct_category,
						),
					});
				} else {
					// console.log('jsonResponse not success');
				}
			})
			.catch((err) => {
				// console.log('ProdCat', err);
			});
	}
	verifySKU = (event) => {
		var value = event.target.value;
		var skuRex = /^(([A-Z]+[A-Z]+[0-9]+[0-9]+[0-9]))$/;
		if (skuRex.test(value)) {
			this.setState({ skuVerified: true });
			return true;
		}
		return false;
	};
	SkuExist = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					sku already Exist!!!!
				</ReactBSAlert>
			),
		});
	};
	handleSubmit = (event) => {
		this.updateProduct();
	};
	resetForm() {
		this.setState({ product_name: '', sku: '', price: '' });
	}

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	};
	validateForm() {
		return this.state.skuVerified === true;
	}

	updateProduct() {
		this.setState({
			innerloader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: '250px',
							marginLeft: '-80px',
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			),
		});
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);

		let details = {
			token: cookie.load('Token'),
			id: data.id,
			permissions: data.Permissions,
		};
		fetch(baseUrl + '/pos/productModification/' + this.props.match.params.id, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(details.permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				productName: this.state.product_name,
				sku: this.state.sku,
				productCategoryId: this.state.selectedproduct_category.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({ innerloader: null });
					this.successAlert();
					// this.componentDidMount();
				} else {
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert();
				throw new Error(err);
			});
	}

	handleDeleteButton = () => {
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);

		let details = {
			token: cookie.load('Token'),
			id: data.id,
			permissions: data.Permissions,
		};

		fetch(`${baseUrl}/pos/delete-product/${this.props.match.params.id}`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(details.permissions),
			},
			method: 'Delete',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert(jsonResponse.message);
				} else {
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert();
			});
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col md={{ size: 7, offset: 2 }}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Product Detail</CardTitle>
								</CardHeader>
								<CardBody>
									<Form action='/' className='form-horizontal' method='get'>
										<Row>
											<Col md={12}>
												<FormGroup>
													<Label>Product Name</Label>
													<Input
														type='text'
														name='product_name'
														onChange={this.handleChange}
														value={this.state.product_name}
													/>
												</FormGroup>
												<FormGroup>
													<Label>SKU</Label>
													<Input
														type='text'
														name='sku'
														maxLength='5'
														placeholder='SJ098'
														onChange={(e) => {
															this.handleChange(e);
															this.verifySKU(e);
														}}
														value={this.state.sku}
													/>
												</FormGroup>
												<FormGroup>
													<Label>Product Category</Label>
													<Select
														name='product_category'
														onChange={this.ChangeProductCat}
														options={this.state.product_categories}
														value={this.state.selectedproduct_category}
													/>
												</FormGroup>
											</Col>
										</Row>
									</Form>
								</CardBody>
								<CardFooter className='text-right'>
									<Button
										// disabled={!this.validateForm()}
										className='btn-round'
										color='primary'
										onClick={this.handleSubmit}
										disabled={!this.validateForm()}
										size='md'
									>
										Update
									</Button>
									<Button
										className='btn-round'
										color='info'
										onClick={this.handleCancelButton}
										size='md'
									>
										Cancel
									</Button>
									<Button
										className='btn-round'
										color='danger'
										onClick={this.handleDeleteButton}
										size='md'
									>
										Delete
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default withRouter(EditProduct);
