/* eslint-disable no-unused-vars */
import React, { Component } from "react"

// react component for creating dynamic tables
import ReactTable from "react-table"
import { connect } from "react-redux"
import cookie from "react-cookies"
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,

} from "reactstrap";
import { getUsers } from "../../Redux/ActionCreator";
import { baseUrl } from "../../Shared/baseUrl";
const mapStateToProps = (state) => {
    return{
        Users:state.Users
    }

}

const mapDispatchToProps = (dispatch) => {
    return {

        getUsers: (details) => dispatch(getUsers(details)),
    }
}



class ModifiedSms extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: null,
            table:null
        }
    }
 
    componentDidMount(){
        var user = JSON.parse(window.localStorage.getItem('user'))
        var data = JSON.parse(user)
    
        let details = {
          token:cookie.load('Token'),
          id:data.id,
          permissions:data.Permissions
    
          
        }
    
          fetch(baseUrl + '/sms/getSmsDetail',{
              headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'Authorization': 'Bearer ' + details.token,
                  'id': details.id,
                  "reg":JSON.stringify(details.permissions)
    
                  
                 },
              method:'Post',
          }).then((res)=>res.json()).then((jsonResponse)=>{
           
              if(jsonResponse.success === true){
                
            
                 this.setState({data:jsonResponse.Sms.map((prop, key) => {      
                  return {
                      id:prop.id,
                    message: prop.message,
                    trigger: prop.trigger
                  };
                 
                })
              })
              this.setState({table:
                <ReactTable
                data={this.state.data}
                filterable
                resizable={false}
                columns={[
                  {
                    Header: "Message",
                    accessor: "message"
                  },
                  {
                    Header:<span style={{marginRight:'100%'}}>trigger</span>,
                    accessor: "trigger"
                  }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: e =>
                  
                 this.rowClicked(rowInfo.original.id)
                
                  };
                }}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
              })
              }
    
          }).catch(err =>{
             
          })
     
      }
    rowClicked(id){
        let obj = this.state.data.find(o => o.id === id)
        this.props.history.push(`/admin/edit_sms/${obj.id}`)
    }
  
    render() {


        return (
      <>
        <div className="content">
            {}
            <Col md={8} className="ml-auto mr-auto">
                <h2 className="text-center">Messages Info</h2>
                <p className="text-center">
              This Table contain all the information about Messages{" "}
                    <a
                        href=""
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                Message-table
                    </a>
              . If need to modified messages please click on the buttons
              in the action column.{" "}
                    <a
                        href=""
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                full documentation.
                    </a>
                </p>
            </Col>
            <Row className="mt-5">
                <Col xs={12} md={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Messages Table</CardTitle>
                        </CardHeader>
                        <CardBody>
                            {this.state.table}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
      </>
        )
    }

}

export default connect(mapStateToProps,mapDispatchToProps) (ModifiedSms)
