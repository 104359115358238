/* eslint-disable no-unused-vars */
import React, { Fragment, useMemo, useState } from 'react';
import { render } from 'react-dom';
import cookie from 'react-cookies';

import { GoogleMap, withScriptjs, withGoogleMap, InfoWindow, Marker, DirectionsRenderer } from 'react-google-maps';
import { baseUrl } from '../../Shared/baseUrl';

class Maps extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			lat: null,
			lng: null,
			customersLocations: []
		};
	}



	componentDidMount() {

		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			userid: data.id,
			token: token,
			permissions: data.Permissions
		};

		fetch(baseUrl + `/routing/getCurrentTrip/${details.userid}`, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer ' + token,
				'id': details.userid,
				'reg': JSON.stringify(details.permissions)
			},
			method: 'Get',
		})
			.then((res) => res.json()).then((jsonResponse) => {
				console.log(jsonResponse, 'getCurrentTrip');
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					const _data = jsonResponse.activeTrip[0];
					console.log(_data, 'getCurrentTrip');
					console.log(_data.subRoute.regionId, 'getCurrentTrip');
					fetch(baseUrl + `/crm/getCustomerByRegionId/${_data.subRoute.regionId}`, {
						headers: {
							'Content-Type': 'application/json',
							'Accept': 'application/json',
							'Authorization': 'Bearer ' + token,
							'id': details.userid,
							'reg': JSON.stringify(details.permissions)
						},
						method: 'Get',
					})
						.then((res) => res.json()).then((jsonResponse) => {
							console.log(jsonResponse, 'getCurrentTrip');
							this.setState({ loader: null });
							if (jsonResponse.success === true) {
								// const customerData = jsonResponse.data;
								this.setState({ customersLocations: jsonResponse.data });
								console.log(jsonResponse.data, 'getCurrentTrip');
							}
							else {
								throw new Error('Error from backend');
							}

						}).catch(err => {
							throw new Error(err);
						});

				}
				else {
					throw new Error('Error from backend');
				}

			}).catch(err => {
				throw new Error(err);
			});



		setInterval(() => {
			fetch(baseUrl + `/routing/getCurrentFsLocation/${this.props.userId}`, {
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + token,
					'id': details.userid,
					'reg': JSON.stringify(details.permissions)
				},
				method: 'Get',
			})
				.then((res) => res.json()).then((jsonResponse) => {
					this.setState({ loader: null });
					if (jsonResponse.success === true) {
						const location = jsonResponse.data;
						// console.log(parseFloat(location.substring(0, location.indexOf(','))), parseFloat(location.substring((location.indexOf(',') + 1), location.length)), 'location');

						if (
							this.state.lat !== parseFloat(location.substring(0, location.indexOf(','))) ||
							this.state.lng !== parseFloat(location.substring((location.indexOf(',') + 1), location.length))
						) {
							this.setState({
								lat: parseFloat(location.substring(0, location.indexOf(','))),
								lng: parseFloat(location.substring((location.indexOf(',') + 1), location.length))
							});
						}
					}
					else {
						throw new Error('Error from backend');
					}

				}).catch(err => {
					throw new Error(err);
				});

		}, 30000);

	}


	render() {


		const AsyncMap =
			withScriptjs(withGoogleMap((props) =>
				<GoogleMap
					defaultZoom={18}

					center={{
						lat: this.state.lat, lng: this.state.lng
					}}
				>
					<Marker position={{ lat: this.state.lat, lng: this.state.lng }}

					/>
					{
						this.state.customersLocations.length > 0 && this.state.customersLocations.map((customer, i) => {
							console.log('lat:', parseFloat(customer.lat), 'lng:', parseFloat(customer.long), 'customer');
							return (
								<Fragment key={i}>
									{
										(customer.lat && customer.long) &&

										<Marker position={{ lat: parseFloat(customer.lat), lng: parseFloat(customer.long) }}
											icon={
												{ url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' }
											}
										/>
									}
								</Fragment>

							);
						})
					}
				</GoogleMap>
			));

		return (

			<>
				{
					(this.state.lat !== null && this.state.lat !== null) &&
					<AsyncMap
						googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${window._env_.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&v=3`}
						loadingElement={
							<div style={{ height: '100%' }} />
						}
						containerElement={
							<div style={{ height: '300px' }} />
						}
						mapElement={
							<div style={{ height: '100%' }} />
						}
					/>
				}
			</>

		);
	}
}

export default Maps;