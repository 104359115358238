/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";


class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <div className="about">
          <a href="https://bullrun.bullrun.pk/privacy">Privacy Policy</a>
          </div>
          <ul className="nav">
          </ul>
          <div className="copyright">
          Built with{" "}<i className="tim-icons icon-heart-2" /> by <a href="https://imroz.io/">Imroz</a> for <a href="https://bullrun.pk">Prime Genetics</a>. Build #{process.env.REACT_APP_COMMIT_SHA}
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
