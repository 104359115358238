import * as ActionTypes from './ActionTypes'
import { baseUrl } from '../Shared/baseUrl'
import fetch from 'cross-fetch'

export const loginFunction = (details) => (dispatch) => {
    fetch(baseUrl + '/auth/users/login',{
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           },
        method:'Post',
        body:JSON.stringify({
            email:details.email,
            password:details.password
        })
    }).then((res)=>res.json()).then((jsonResponse)=>{
    
        if(jsonResponse.success === true){
            dispatch(login(jsonResponse))
        }
        else{
            dispatch(login(jsonResponse))
        }
       
    }).catch(err =>{
        dispatch(error(err))
    })
}
export const logOut = () =>({
    type:ActionTypes.LogOut,
    })

export const login = (user) =>({
type:ActionTypes.LOGGED_IN,
payload:user
})
export const error = (user) =>({
    type:ActionTypes.INVALID_DATA,
    payload:user
    })


    export const registerFunction = (details) => (dispatch) => {
        fetch(baseUrl + '/auth/users/signup',{
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
               },
            method:'Post',
            body:JSON.stringify({
                email:details.email,
                password:details.password,
                username:details.username
            })
        }).then((res)=>res.json()).then((jsonResponse)=>{
            if(jsonResponse.success === true){
                dispatch(register(jsonResponse))
            }
            if(jsonResponse.success === false){
                dispatch(registrationFailed(jsonResponse))
            }
           
        }).catch(err =>{
            console.log(err)
            dispatch( registrationFailed(err))
        })
    }
    
    export const register = (user) =>({
    type:ActionTypes.REGISTERD,
    payload:user
    })
    export const registrationFailed = (error) =>({
        type:ActionTypes.REGISTERING_FAILED,
        payload:error
        })
        export const registering = () =>({
            type:ActionTypes.REGISTERING,
         
            })

    export const unlockFunction = (details) => (dispatch) => {
        fetch(baseUrl + '/auth/users/lock-dashboard',{
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + details.token
               },
            method:'Post',
            body:JSON.stringify({
                password:details.password,
                userid:details.userid
            })
        }).then((res)=>res.json()).then((jsonResponse)=>{
            if(jsonResponse.success === true){

                dispatch(unlock())
            }
            if(jsonResponse.success === false){
                dispatch(unlock_Failed(jsonResponse))
            }
           
        }).catch(err =>{
            dispatch( unlock_Failed(err))
        })
    }
    export const unlock = () =>({
        type:ActionTypes.UNLOCKED
        })
        export const unlock_Failed= (error) =>({
            type:ActionTypes.UNLOCKED_FAILED,
            payload:error
            })
            export const lock= () =>({
                type:ActionTypes.LOCKED,
                })
                export const lockState = () =>({
                    type:ActionTypes.LOCKED_STATE
                })

                export const getUsers = (details) => (dispatch) => {
                    fetch(baseUrl + '/auth/users/getUser',{
                        headers : { 
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + details.token,
                            'id': details.id
                           },
                        method:'Post',
                        
                    }).then((res)=>res.json()).then((jsonResponse)=>{
                        if(jsonResponse.success === true){
                            dispatch(user(jsonResponse.Users))
                        }
  
                    }).catch(err =>{
                        dispatch( user_Failed(err))
                    })
                }
                export const user = (user) =>({
                    type:ActionTypes.get_Users,
                    payload:user
                    })
                    export const user_Failed = (error) =>({
                        type:ActionTypes.user_Failed,
                        payload:error
                        })
                        export const fetching_User = () =>({
                            type:ActionTypes.user_Loading,
                         
                            })
                          
              
              
    
    