import React, { Component } from 'react';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Label,
	Input,
	Row,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { baseUrl } from '../../Shared/baseUrl';

class addWarehouse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			regions: [],
			fa: [],
			selectedRegion: '',
			whName: '',
			selectedFa: '',
			alert: null,
			// loader:null,
			// pressed:false,
			// checked:false,
		};
		this.successAlert = this.successAlert.bind(this);
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/auth/users/getRegion', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						regions: jsonResponse.Regions.map((props) => {
							return {
								value: props.id,
								label: props.name,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});

		fetch(baseUrl + '/auth/users/getFa', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						fa: jsonResponse.data.map((props) => {
							return {
								value: props.id,
								label: props.username,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}
	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					Warehouse Added Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message || `Something Went Wrong !!!!!`}
				</ReactBSAlert>
			),
		});
	};

	changeRegion = (event) => {
		this.setState({ selectedRegion: event });
	};

	resetForm() {
		this.setState({ whName: '', selectedRegion: '', selectedFa: '' });
	}
	handleSubmit = (event) => {
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		var token = cookie.load('Token');
		fetch(baseUrl + '/inventory/addWarehouse', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				whName: this.state.whName,
				regionId: this.state.selectedRegion.value,
				faId: this.state.selectedFa.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.successAlert();
					this.resetForm();
				} else {
					this.errorAlert(jsonResponse.message);
				}
			});
	};
	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	changeFa = (event) => {
		this.setState({ selectedFa: event });
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col className='mr-auto' md={{ size: 6, offset: 3 }}>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h3'>Add New Warehouse </CardTitle>
									</CardHeader>
									<CardBody>
										<Col md={12}>
											<FormGroup>
												<label>Warehouse Name </label>
												<Input
													type='text'
													name='whName'
													value={this.state.whName}
													onChange={this.handleChange}
												/>
											</FormGroup>
											<FormGroup>
												<label>Region </label>
												<Select
													onChange={this.changeRegion}
													options={this.state.regions}
													value={this.state.selectedRegion}
												/>
											</FormGroup>
											<FormGroup>
												<label>Field Accountant </label>
												<Select
													onChange={this.changeFa}
													options={this.state.fa}
													value={this.state.selectedFa}
												/>
											</FormGroup>
										</Col>
									</CardBody>
									<CardFooter className='text-right'>
										<Button color='primary' onClick={this.handleSubmit}>
											Add Warehouse
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default addWarehouse;
