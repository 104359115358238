/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from "react"
import { withRouter } from "react-router-dom"
import ReactBSAlert from "react-bootstrap-sweetalert"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Input,
    FormGroup,

    Container,
    Col
} from "reactstrap"
import { baseUrl } from "../../Shared/baseUrl"
var id
class ForgetPassword extends React.Component {
    constructor(props){
        super (props)
        this.state = {
            email:"",
            emailVerified:"",
            alert: null,
        }
        this.routeChange = this.routeChange.bind(this)

    }
errorAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Error"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="danger"
                btnSize=""
            >
    Something Went Wrong!!!!!
       
            </ReactBSAlert>
        )
    })
  
};

successAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                confirmBtnBsStyle="success"
                onConfirm = {() => {
                    this.routeChange();
                }}
                // onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                btnSize="">
              Reset link has been sent to your email address !
            </ReactBSAlert>
        )
    })
}

compare = (string1, string2) => {
    if (string1 === string2) {
        return true
    }
    return false
};

verifyLength = (value, length) => {
    if (value.length >= length) {
        return true
    }
    return false
};

verifyEmail = (event) => {
    var value = event.target.value
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (emailRex.test(value)) {
        this.setState({emailVerified:true})
        return true
    }
    return false
};
validateForm() {
    return this.state.emailVerified
}
routeChange(){
    return this.props.history.push("/auth/login")
} 

hideAlert = () => {
    this.setState({
        alert: null
    })
}

handleChange =(event)=> {
    const target = event.target
    const value =  target.value
    const name = target.name

    this.setState({
        [name]: value
    })
    
}
  forgetPassword = (details) =>{
      fetch(baseUrl + "/auth/users/forgetpassword",{
          headers : { 
              "Content-Type": "application/json",
              "Accept": "application/json"
          },
          method:"Post",
          body:JSON.stringify({
              email:details.email,
        
          })
      }).then((res)=>res.json()).then((jsonResponse)=>{
          if(jsonResponse.success === true){
            //   this.routeChange()
            this.successAlert();
          }
          else {
              this.errorAlert()
              throw new Error("Success Failed ")
          }
   
      }).catch(err =>{
          this.errorAlert()
          throw new Error(err)
      })
  }
  handleSubmit = (event) => {

      event.preventDefault();
  
      let details = {
          email:this.state.email
      }
      this.forgetPassword(details)
  
  }
  componentDidMount() {
      document.body.classList.toggle("login-page")
  }
  componentWillUnmount() {
      document.body.classList.toggle("login-page")
  }
  render() {
      let {
          emailState,
      } = this.state
      return  (
      
      <>
     
        <div className="content">
            {this.state.alert}
            <Container>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                    <Form className="form" onSubmit={this.handleSubmit}>
                        <Card className="card-login card-white">
                            <CardHeader>
                                <img
                                    alt="..."
                                    src={require("assets/img/card-primary.png")}
                                />
                                <CardTitle tag="h2">Email</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <FormGroup className={`has-label ${ emailState}`}>
                                    <label>Email *</label>
                                    <Input placeholder="Email" type="text" name="email" onChange={e => {this.handleChange(e);this.verifyEmail(e)}} value={this.state.email}/>
                                </FormGroup>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    block
                                    className="mb-3"
                                    color="primary"
                                    size="lg"
                                    disabled={!this.validateForm()}
                                >
                      Send email
                                </Button>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
            </Container>
        </div>
      </>
      )
  }
}

export default withRouter(ForgetPassword)


