import * as ActionType from './ActionTypes'

export const Lock = (state = {
    UNLOCKED:true,
    UNLOCKED_FAILED:null,
    LOCKED:false,
    LOCKED_STATE:true
},action) =>{

switch(action.type){
    case ActionType.UNLOCKED:
    return {...state, UNLOCKED:true, UNLOCKED_FAILED:null,  LOCKED:false,LOCKED_STATE:false}

    case ActionType.LOCKED:
    return{ ...state,UNLOCKED:false, UNLOCKED_FAILED:null,  LOCKED:true,LOCKED_STATE:false}


    case ActionType.UNLOCKED_FAILED:
    return { ...state,UNLOCKED:false,UNLOCKED_FAILED:action.payload,LOCKED:true,LOCKED_STATE:false}

    case ActionType.LOCKED_STATE:
    return { ...state,UNLOCKED:false,UNLOCKED_FAILED:null,LOCKED:true,LOCKED_STATE:true}

    default:
    return state;

}
}