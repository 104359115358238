import React from 'react';
import { Route, Switch } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
// react plugin for creating notifications over the dashboard
import NotificationAlert from 'react-notification-alert';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { withRouter } from 'react-router-dom';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import FixedPlugin from 'components/FixedPlugin/FixedPlugin.jsx';

import routes from 'routes.js';

import logo from 'assets/img/react-logo.png';

import { baseUrl } from '../../Shared/baseUrl';

var ps;

const mapStateToProps = (state) => {
    return {
        Lock: state.Lock,
    };

};

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColor: 'blue',
            sidebarMini: true,
            opacity: 0,
            sidebarOpened: false,
            sidebar: null,
            setRoutes: null
        };
    }

    token_Verification(details) {
        fetch(baseUrl + '/auth/users/token_Verification', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + details.token
            },
            method: 'Post',

        }).then((res) => res.json()).then((jsonResponse) => {
            if (jsonResponse.success === true) {
            }
            else {
                return this.props.history.push('/auth/login');
            }

        }).catch(err => {
            console.log(err);
        });
    }
    check(details) {

        fetch(baseUrl + '/auth/users/userPermissions ', {
            method: 'Post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + details.token
            },
            body: JSON.stringify({
                id: details.id
            })

        }).then((res) => res.json()).then((jsonResponse) => {
            window.localStorage.setItem('permissions', JSON.stringify(jsonResponse.permissions));
            this.setState({
                sidebar: (
                    <Sidebar
                        {...this.props}
                        routes={routes}
                        activeColor={this.state.activeColor}
                        logo={{
                            outterLink: 'https://bullrun.pk',
                            text: 'Bullrun',
                            imgSrc: logo
                        }}
                        closeSidebar={this.closeSidebar}
                    />
                )
            });
            this.setState({
                setRoutes: (
                    <Switch>{this.getRoutes(routes)}</Switch>
                )
            });
        }).catch(err => {
            console.log(err);
        });

    }

    componentDidMount() {
        var user = JSON.parse(window.localStorage.getItem('user'));
        var data = JSON.parse(user);
        var token = cookie.load('Token');

        if (data != null) {
            const Roles = data.Roles;
            const getRoleId = role => role.id;
            const getRoleIdArray = Roles =>
                Roles.map(getRoleId);
            const RolesIDs = getRoleIdArray(Roles);
            let details = {
                token: cookie.load('Token'),
                id: RolesIDs
            };



            this.token_Verification(details);
            this.check(details);
            if (!cookie.load('Token')) {
                return this.props.history.push('/auth/login');
            }
            if (this.props.Lock.LOCKED) {
                return this.props.history.push('/auth/lock-screen');
            }
        }
        else {
            return this.props.history.push('/auth/login');
        }

        if (navigator.platform.indexOf('Win') > -1) {
            document.documentElement.className += ' perfect-scrollbar-on';
            document.documentElement.classList.remove('perfect-scrollbar-off');
            ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
            let tables = document.querySelectorAll('.table-responsive');
            for (let i = 0; i < tables.length; i++) {
                ps = new PerfectScrollbar(tables[i]);
            }
            console.log(ps, 'checking ps at pos 1');
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf('Win') > -1) {
            console.log(ps, 'checking ps');
            document.documentElement.className += ' perfect-scrollbar-off';
            document.documentElement.classList.remove('perfect-scrollbar-on');
        }
    }
    componentDidUpdate(e) {
        if (e.history.action === 'PUSH') {
            if (navigator.platform.indexOf('Win') > -1) {
                let tables = document.querySelectorAll('.table-responsive');
                for (let i = 0; i < tables.length; i++) {
                    ps = new PerfectScrollbar(tables[i]);
                }
            }
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainPanel.scrollTop = 0;
        }
    }
    showNavbarButton = () => {
        if (
            document.documentElement.scrollTop > 50 ||
            document.scrollingElement.scrollTop > 50 ||
            this.refs.mainPanel.scrollTop > 50
        ) {
            this.setState({ opacity: 1 });
        } else if (
            document.documentElement.scrollTop <= 50 ||
            document.scrollingElement.scrollTop <= 50 ||
            this.refs.mainPanel.scrollTop <= 50
        ) {
            this.setState({ opacity: 0 });
        }
    };
    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === '/admin') {
                var permissions = window.localStorage.getItem('permissions');
                var Check = permissions.includes(prop.permission);
                if (Check === true) {
                    return (
                        <Route
                            path={prop.layout + prop.path}
                            component={prop.component}
                            key={key}
                        />
                    );
                }

            } else {
                return null;
            }
        });
    };
    getActiveRoute = routes => {
        let activeRoute = 'Default Brand Text';
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.pathname.indexOf(
                        routes[i].layout + routes[i].path
                    ) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    handleActiveClick = color => {
        this.setState({ activeColor: color });
    };
    handleMiniClick = () => {
        let notifyMessage = 'Sidebar mini ';
        if (document.body.classList.contains('sidebar-mini')) {
            this.setState({ sidebarMini: false });
            notifyMessage += 'deactivated...';
        } else {
            this.setState({ sidebarMini: true });
            notifyMessage += 'activated...';
        }
        let options = {};
        options = {
            place: 'tr',
            message: notifyMessage,
            type: 'primary',
            icon: 'tim-icons icon-bell-55',
            autoDismiss: 7
        };
        //   this.refs.notificationAlert.notificationAlert(options)
        document.body.classList.toggle('sidebar-mini');
    };
    toggleSidebar = () => {
        this.setState({
            sidebarOpened: !this.state.sidebarOpened
        });
        document.documentElement.classList.toggle('nav-open');
    };
    closeSidebar = () => {
        this.setState({
            sidebarOpened: false
        });
        document.documentElement.classList.remove('nav-open');
    };
    render() {

        return (
            <>
                <div className="wrapper">
                    <div className="rna-container">
                        {/* <NotificationAlert ref="notificationAlert" /> */}
                    </div>
                    <div
                        className="navbar-minimize-fixed"
                        style={{ opacity: this.state.opacity }}
                    >
                        <button
                            className="minimize-sidebar btn btn-link btn-just-icon"
                            onClick={this.handleMiniClick}
                        >
                            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
                            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
                        </button>
                    </div>
                    {this.state.sidebar}

                    <div
                        className="main-panel"
                        ref="mainPanel"
                        data={this.state.activeColor}
                    >
                        <AdminNavbar
                            {...this.props}
                            handleMiniClick={this.handleMiniClick}
                            brandText={this.getActiveRoute(routes)}
                            sidebarOpened={this.state.sidebarOpened}
                            toggleSidebar={this.toggleSidebar}
                        />
                        {this.state.setRoutes}
                        {// we don't want the Footer to be rendered on full screen maps page
                            this.props.location.pathname.indexOf('full-screen-map') !==
                                -1 ? null : (
                                <Footer fluid />
                            )}
                    </div>
                    <FixedPlugin
                        activeColor={this.state.activeColor}
                        sidebarMini={this.state.sidebarMini}
                        handleActiveClick={this.handleActiveClick}
                        handleMiniClick={this.handleMiniClick}
                    />
                </div>
            </>
        );

    }
}

export default withRouter(connect(mapStateToProps)(Admin));
