/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import cookie from "react-cookies";
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Label,
	Form,
	Input,
	Row,
	Col,
} from "reactstrap";
import { baseUrl } from "../../Shared/baseUrl";

const initialState = {
	loader: null,
	driverName: "",
	phoneNumber: "",
	cnic: "",
	status: "Available",
	phoneVerified: false,
	cnicVerified: false,
	phoneVerifiedText: true,
	cnicVerifiedText: true,
	checked: false,
};
class AddDriver extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...initialState, alert: null };
		this.successAlert = this.successAlert.bind(this);
	}

	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}
	onAddRolesPress() {
		this.setState({ pressed: true });
	}

	componentDidMount() {}

	verifyPhone = (event) => {
		var value = event.target.value;
		var phoneRex = /^((03)([0-9]{9}))$/;
		if (phoneRex.test(value)) {
			this.setState({ phoneVerified: true, phoneVerifiedText: true });
			return true;
		}

		this.setState({ phoneVerifiedText: false });
		return false;
	};
	verifyCnic = (event) => {
		var value = event.target.value;
		var cnicRex = /^((3[0-9]{4}-)([0-9]{7}-)([0-9]{1}))$/;
		if (cnicRex.test(value)) {
			this.setState({ cnicVerified: true, cnicVerifiedText: true });
			return true;
		}

		this.setState({ cnicVerifiedText: false });
		return false;
	};
	validateForm() {
		return (
			this.state.phoneVerified &&
			this.state.cnicVerified &&
			this.state.phoneVerifiedText &&
			this.state.cnicVerifiedText
		);
	}
	cnicRegexAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: "block", marginTop: "-100px" }}
					title="Error"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle="danger"
					btnSize=""
				>
					Make sure the CNIC format is correct
				</ReactBSAlert>
			),
		});
	};
	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: "block", marginTop: "-100px" }}
					title="Success!"
					confirmBtnBsStyle="success"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=""
				>
					Driver Added Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: "block", marginTop: "-100px" }}
					title="Error"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle="danger"
					btnSize=""
				>
					{message ? message : `Something Went Wrong !!!!!`}
				</ReactBSAlert>
			),
		});
	};
	addingDriverFunction = () => {
		var product = JSON.parse(window.localStorage.getItem("user"));
		var data = JSON.parse(product);
		var token = cookie.load("Token");

		let details = {
			driverName: this.state.driverName,
			phoneNumber: this.state.phoneNumber,
			cnic: this.state.cnic,
			status: this.state.status,
		};
		if (this.state.cnicVerified === false && details.cnic !== "") {
			this.setState({ loader: null });
			this.cnicRegexAlert();
		} else {
			fetch(baseUrl + "/fleet/addDriver", {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
					id: data.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: "Post",
				body: JSON.stringify({
					driverName: details.driverName,
					phoneNumber: details.phoneNumber,
					cnic: details.cnic,
					status: details.status,
				}),
			})
				.then((res) => res.json())
				.then((jsonResponse) => {
					this.setState({ loader: null });
					if (jsonResponse.success) {
						this.successAlert();
						this.resetForm();
					} else {
						this.errorAlert(jsonResponse.message);
					}
				})
				.catch((err) => {
					this.errorAlert();
				});
		}
	};

	resetForm() {
		this.setState(initialState);
	}

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	render() {
		return (
			<>
				<div className="content">
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col className="mr-auto" md={{ size: 7, offset: 2 }}>
							<Form>
								<Card>
									<CardHeader>
										<CardTitle tag="h3">Add Driver</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup className={"has-label"}>
											<Label> Driver Name *</Label>

											<Input
												type="text"
												name="driverName"
												onChange={this.handleChange}
												value={this.state.driverName}
											/>
										</FormGroup>
										<FormGroup className={"has-label"}>
											<label> Phone Number *</label>

											<Input
												type="text"
												name="phoneNumber"
												maxLength="11"
												placeholder="xxxxxxxxxxx"
												onChange={(e) => {
													this.handleChange(e);
													this.verifyPhone(e);
												}}
												value={this.state.phoneNumber}
											/>
											{!this.state.phoneVerifiedText && (
												<span className="text-danger ml-2">Invalid format</span>
											)}
										</FormGroup>
										<FormGroup className={"has-label"}>
											<label> CNIC *</label>

											<Input
												type="text"
												name="cnic"
												maxLength="15"
												placeholder="xxxxx-xxxxxxx-x"
												onChange={(e) => {
													this.handleChange(e);
													this.verifyCnic(e);
												}}
												value={this.state.cnic}
											/>
											{!this.state.cnicVerifiedText && (
												<span className="text-danger ml-2">Invalid format</span>
											)}
										</FormGroup>
									</CardBody>
									<CardFooter className="text-right">
										<Button
											color="primary"
											onClick={this.addingDriverFunction}
											disabled={!this.validateForm()}
										>
											Add Driver
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default AddDriver;
