/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

// react component for creating dynamic tables
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	CardTitle,
	Row,
	Col,
	Form,
} from 'reactstrap';
import { getUsers } from '../../Redux/ActionCreator';
import { baseUrl } from '../../Shared/baseUrl';
var count = 0;
var uId;
const mapStateToProps = (state) => {
	return {
		Users: state.Users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (details) => dispatch(getUsers(details)),
	};
};

class CustomerDetail extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: null,
			table: null,
		};
		// this.showInseminationEvents = this.showInseminationEvents.bind(this)
	}

	componentDidMount() {
		count = 0;
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);

		// console.log(result[0].label, "yippy")
		let details = {
			token: cookie.load('Token'),
			id: data.id,
			Permissions: data.Permissions,
		};

		fetch(baseUrl + '/crm/customersList', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(details.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						data: jsonResponse.client.map((prop, key) => {
							return {
								id: prop.id ? prop.id : '',
								name: prop.name ? prop.name : '',
								father_name: prop.father_name ? prop.father_name : '',
								// cnic: prop.cnic ? prop.cnic : '',
								// dob: prop.dob ? prop.dob : '',
								phone: prop.phone ? prop.phone : '',
								landline: prop.landline ? prop.landline : '',
								tehsil: prop.tehsil ? prop.tehsil : '',
								village: prop.village ? prop.village : '',
								region: prop.region.name ? prop.region.name : '',
								// Status: prop.customer_status ? prop.customer_status : '',
								type: prop.customer_type ? prop.customer_type : '',
							};
						}),
					});

					this.setState({
						table: (
							<ReactTable
								data={this.state.data}
								filterable
								defaultFilterMethod={this.filterCaseInsensitive}
								resizable={false}
								columns={[
									{
										Header: 'Name',
										accessor: 'name',
									},
									{
										Header: 'Father Name',
										accessor: 'father_name',
									},
									{
										Header: 'Phone',
										accessor: 'phone',
									},
									{
										Header: 'Landline',
										accessor: 'landline',
									},
									{
										Header: 'Tehsil',
										accessor: 'tehsil',
									},
									{
										Header: 'Village',
										accessor: 'village',
									},
									{
										Header: 'Region',
										accessor: 'region',
									},
									{
										Header: 'Customer Type',
										accessor: 'type',
									},
								]}
								getTdProps={(state, rowInfo, column, instance) => {
									if (rowInfo && rowInfo.row) {
										return {
											onClick: (e) => this.rowClicked(rowInfo.original.id),
										};
									} else {
										return {};
									}
								}}
								defaultPageSize={10}
								showPaginationTop
								showPaginationBottom={false}
								className='-striped -highlight'
							/>
						),
					});
				}
			})
			.catch((err) => { });
	}
	rowClicked(id) {
		// if(count === 0){
		let obj = this.state.data.find((o) => o.id === id);
		this.props.history.push(`/admin/edit_customer/${obj.id}`);
		// }
	}
	// showInseminationEvents(){
	//     // let objj = this.state.data.find(o => o.id === id)
	//     // console.log(objj,"checking insem")
	//     let path =`/admin/insemintaionList/`
	//     this.props.history.push(path);
	//     count=count+1
	// }

	filterCaseInsensitive = (filter, row) => {
		const id = filter.pivotId || filter.id;
		const content = row[id];
		if (typeof content !== 'undefined') {
			// filter by text in the table or if it's a object, filter by key
			if (typeof content === 'object' && content !== null && content.key) {
				return String(content.key)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			} else {
				return String(content)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			}
		}

		return true;
	};

	render() {
		return (
			<>
				<div className='content'>
					{ }
					<Col md={8} className='ml-auto mr-auto'>
						<h2 className='text-center'>Customers Info</h2>
						<p className='text-center'>
							This Table contain all the information about Customers{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								Customer-table
							</a>
							. If need to modified customer please click on the buttons in the
							action column.{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								full documentation.
							</a>
						</p>
					</Col>
					<Row className='mt-5'>
						<Col xs={12} md={12}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Customers Table</CardTitle>
								</CardHeader>
								<CardBody>{this.state.table}</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
