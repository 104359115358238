import React from "react"
import cookie from "react-cookies"
import ReactBSAlert from "react-bootstrap-sweetalert"
import Loader from "react-loader-spinner"
import LoadingOverlay from "react-loading-overlay"
import Select from "react-select"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Label,
    Form,
    Input,
    Row,
    Col,
    Checkbox
} from "reactstrap"
import { baseUrl } from "../../Shared/baseUrl"

var dropDown

class AddProfile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // register form
            email:"",
            password:"",
            username:"",
            isChecked: false,
            confirmPassword: "",
            emailState: "",
            passwordState: "",
            confirmPasswordState: "",
            usernameState:"",
            emailVerified:false,
            alert: null,
            loader:null,
            Roles:[],
            Regions:[],
            fetchedRoles:[],
            pressed:false,
            roleSelected:[],
            selectedRegion:"",
            dropDown1array:[],
            regionDrop1:false,
            permissions:[],
            Roles_Permissions:[],
            checked:false,

     
        }
        this.successAlert = this.successAlert.bind(this)
        this.onAddRolesPress = this.onAddRolesPress.bind(this)
        this.selectChangenew1 = this.selectChangenew1.bind(this)

    }

  handleCheckboxChange = event =>{
      this.setState({ checked: event.target.checked })
  }

  componentWillUnmount() {
      var id = window.setTimeout(null, 0)
      while (id--) {
          window.clearTimeout(id)
      }
  }
  onAddRolesPress  (){
      this.setState({ pressed: true })   
  }

  componentDidMount(){
      var user = JSON.parse(window.localStorage.getItem("user"))
      var data = JSON.parse(user)
      var token = cookie.load("Token")
      let details = {
          userid:data.id,
          token:token,
      }
      fetch(baseUrl + "/auth/roles/getRole/Roles",{
          headers : { 
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + details.token,
              "id": details.userid
          },
          method:"Post",
      
      }).then((res)=>res.json()).then((jsonResponse)=>{
          this.setState({loader:null})
          if(jsonResponse.success === true){
              var newArray = jsonResponse.Roles.filter(function (el) {
                  return el.Role.Role !== "Super-User" 
            
              })
              this.setState({fetchedRoles:newArray.map((prop,key)=>{
                  return{
                      value:prop.id,
                      label:prop.Role.Role,
                      Dep:prop.Role.Dep
                  }
              })})
     
          }
          else {
              console.log("error")
          }
     
      }).catch(err =>{
          console.log(err)
      })
 
  }

  
  selectChangenew1(selectedOption){
      var sendRole
      var user = JSON.parse(window.localStorage.getItem("user"))
      var data = JSON.parse(user)
      var token = cookie.load("Token")
      let details = {
          userid:data.id,
          token:token,
      }

      fetch(baseUrl + "/auth/users/userSingle_permission",{
          headers : { 
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + details.token
          },
          method:"Post",
          body:JSON.stringify({
              id:selectedOption.value
          })
      }).then((res)=>res.json()).then((jsonResponse)=>{
          if(jsonResponse.success === true){
              this.setState({permissions:jsonResponse.permissions})
          }
          else {
              console.log("error")
          }
   
      }).catch(err =>{
          console.log(err)
      })
      if (selectedOption.Dep == "reg"){
          fetch(baseUrl + "/auth/users/getRegion",{
              headers : { 
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": "Bearer " + details.token,
                  "id": details.userid
              },
              method:"Post",
      
          }).then((res)=>res.json()).then((jsonResponse)=>{
    
              if(jsonResponse.success === true){
    
                  this.setState({Regions:jsonResponse.Regions.map((prop,key)=>{

                      return{
                          value:selectedOption.label,
                          label:prop.Region
                      }
                  })})
                  this.setState({regionDrop1:true})
              }
              else {
                  console.log("error")
              }
     
          }).catch(err =>{
              console.log(err)
          })
          let inserData = {
              id:selectedOption.value,
              Role:selectedOption.label,
              Region:""
          }
          sendRole = inserData
          this.setState({dropDown1array:[sendRole]})

      }

      else{
          this.setState({regionDrop1:false})
          let inserData2 = {
              id:selectedOption.value,
              Role:selectedOption.label,
              Region:"All"
          }
          sendRole = inserData2
          this.setState({dropDown1array:[sendRole]})
      }
  }
  handleRemoveShareholder = idx => () => {
      this.setState({
          roleSelected: this.state.roleSelected.filter((s) => idx !== s.id)
      })
      this.setState({Roles_Permissions:this.state.Roles_Permissions.filter((s)=> idx !== s.id)})
  };

  savePressed = () =>{
      var reg_per =[] 
      this.state.permissions.Permissions.map((props,key)=>{
          this.state.dropDown1array.map((data)=>{
              reg_per.push({
                  id:data.id,
                  Permissions:props,
                  Region:data.Region
              })
          })    
      })
      this.setState({Roles_Permissions:this.state.Roles_Permissions.concat(reg_per)})
      this.setState({roleSelected:this.state.roleSelected.concat(this.state.dropDown1array)})
      dropDown = null
      this.setState({pressed:false})
      this.setState({regionDrop1:false})
      this.setState({dropDown1array:[""]})
      reg_per = []
  }
  
  successAlert()  {
      this.setState({
          alert: (
              <ReactBSAlert
                  success
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Success!"
                  confirmBtnBsStyle="success"
                  onConfirm={() => this.hideAlert()}
                  onCancel={() => this.hideAlert()}
                  btnSize="">
                User Added Successfully !
              </ReactBSAlert>
          )
      })
  }
  hideAlert = () => {
      this.setState({
          alert: null
      })
  }
errorAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Error"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="danger"
                btnSize=""
            >
     User with this email Already Exsit !!!!!
       
            </ReactBSAlert>
        )
    })
  
};

 addingProfileFunction =()=>{
     //   this.setState({loader:(
     //     <LoadingOverlay
     //     styles={{
     //      overlay: (base) => ({
     //        ...base,
     //        marginTop: "250px",
     //        marginLeft: "-80px",
     //      })
     //    }}
     //    active={true}
     //    spinner={<Loader  type="CradleLoader"
  
     //    height="100"	
     //    width="100"/>}
 
     //    >
     //  </LoadingOverlay>
     //    )})
     var user = JSON.parse(window.localStorage.getItem("user"))
     var data = JSON.parse(user)
     var token = cookie.load("Token")
     let details = {

         token:token
     }
     if (this.checked !=false){
       
         fetch(baseUrl + "/hrm/addprofile",{
      
             headers : { 
                 "Content-Type": "application/json",
                 "Accept": "application/json",
                 "Authorization": "Bearer " + details.token,
        
                 "id": details.id,
                 "reg":details,
             },
             method:"Post",
             body:JSON.stringify({
                 email:this.state.email

             })
         }).then((res)=>res.json()).then((jsonResponse)=>{
             this.setState({loader:null})
             if(jsonResponse.success === true){
                 this.successAlert()
             }
             else {
                 this.errorAlert()
             }
  
         }).catch(err =>{
             this.errorAlert()
         })
     }

 };

 addingUserFunction = (details) => {
     this.setState({loader:(
         <LoadingOverlay
             styles={{
                 overlay: (base) => ({
                     ...base,
                     marginTop: "250px",
                     marginLeft: "-80px",
                 })
             }}
             active={true}
             spinner={<Loader  type="CradleLoader"
  
                 height="100"	
                 width="100"/>}
 
         >
         </LoadingOverlay>
     )})

     fetch(baseUrl + "/auth/users/addUser/addinguser",{
      
         headers : { 
             "Content-Type": "application/json",
             "Accept": "application/json",
             "Authorization": "Bearer " + details.token,
             "id": details.userid
         },
         method:"Post",
         body:JSON.stringify({
             email:details.email,
             username:details.username,
             Roles:details.Roles,
             Roles_Permissions:this.state.Roles_Permissions

         })
     }).then((res)=>res.json()).then((jsonResponse)=>{
         this.setState({loader:null})
         if(jsonResponse.success === true){
             this.successAlert()
         }
         else {
             this.errorAlert()
         }
       
     }).catch(err =>{
         this.errorAlert()
     })
 };
  // function that returns true if value is email, false otherwise
  verifyEmail = value => {
      var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (emailRex.test(value)) {
          this.setState({emailVerified:true})
          return true
      }
      return false
  }
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
      if (value.length >= length) {
          return true
      }
      return false
  };
  // function that verifies if two strings are equal
  compare = (string1, string2) => {
      if (string1 === string2) {
          return true
      }
      return false
  };
  validateForm() {
      return this.state.emailVerified === true
  }
  resetForm(){
      this.setState({email:""})
      this.setState({password:""})
      this.setState({username:""})
      this.setState({confirmPassword:""})
      this.setState({emailState: ""})
      this.setState({passwordState: ""})
      this.setState({confirmPasswordState: ""})
      this.setState({usernameState:""})
      this.setState({dropDown1array:[""]})
      this.setState({pressed:false})
      this.setState({regionDrop1:false})
      this.setState({roleSelected:[]})
      this.setState({Roles_Permissions:[]})
      this.setState({checked:false})
      dropDown = null

  }
  handleSubmit = event => {
      var user = JSON.parse(window.localStorage.getItem("user"))
      var data = JSON.parse(user)
      var token = cookie.load("Token")
    
      var result = [...new Map(this.state.roleSelected.map( o => [JSON.stringify(o), o])).values()]
  
      let details = {
          userid:data.id,
          email:this.state.email,
          username:this.state.username,
          token:token,
          Roles:result,
      
      }
      this.addingUserFunction(details)
      this.addingProfileFunction()
      this.resetForm()
  }
  
  handleChange =(event, stateName, type, stateNameEqualTo) => {
    
      switch (type) {
      case "email":
          if (this.verifyEmail(event.target.value)) {
              this.setState({ [stateName + "State"]: "has-success" })
          } else {
              this.setState({ [stateName + "State"]: "has-danger" })
          }
          break
      case "password":
          if (this.verifyLength(event.target.value, 5)) {
              this.setState({ [stateName + "State"]: "has-success" })
          } else {
              this.setState({ [stateName + "State"]: "has-danger" })
          }
          break
      case "username":
          if (this.verifyLength(event.target.value, 5)) {
              this.setState({ [stateName + "State"]: "has-success" })
          } else {
              this.setState({ [stateName + "State"]: "has-danger" })
          }
          break
      case "equalTo":
          if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
              this.setState({ [stateName + "State"]: "has-success" })
              this.setState({ [stateNameEqualTo + "State"]: "has-success" })
          } else {
              this.setState({ [stateName + "State"]: "has-danger" })
              this.setState({ [stateNameEqualTo + "State"]: "has-danger" })
          }
          break
      case "length":
          if (this.verifyLength(event.target.value, stateNameEqualTo)) {
              this.setState({ [stateName + "State"]: "has-success" })
          } else {
              this.setState({ [stateName + "State"]: "has-danger" })
          }
          break
      default:
          break
      }

      this.setState({
          [stateName]: event.target.value 
      })  
  }
  regionDropDown1 = (selectedOption) =>{
   
      var item = this.state.dropDown1array.find(x => x.Role == selectedOption.value)
      if (item) {
          item.Region = selectedOption.label

          this.setState({dropDown1array:[item]})
      }
  }

  render() {
      if(this.state.pressed == true){
          dropDown  = 
 <>
     <Row>
         <Col md={4}>
             <label md={2}>Select Role</label>
             <Select options={this.state.fetchedRoles}  onChange={this.selectChangenew1} ></Select>
         </Col>
         <Col md={4}>
             <label md={2}>Select Region</label>
             {this.state.regionDrop1 ? (
                 <Select options={this.state.Regions}  onChange={this.regionDropDown1} ></Select>
             ):null}
         </Col>
         <Col md={4}>
             <Button style={{background:"transparent",marginTop:21}} onClick={this.savePressed}>Save</Button>
         </Col>
     </Row>
       </>  
      }
      let {
          emailState,
          usernameState,
    
      } = this.state
      return (
        
      <>
        <div className="content">
            {this.state.alert}
       
            {this.state.loader}
            <Row >
                <Col className="ml-auto"  md ="5">
                    <div className="info-area info-horizontal mt-5">
                        <div className="icon icon-warning">
                            <i className="tim-icons icon-badge" />
                        </div>
                        <div className="description">
                            <h3 className="info-title">Required</h3>
                            <p className="description">
                      Labels Mark with * must be provided to add New Profile. If 
                      required fileds are missing, user can not be added.
                            </p>
                        </div>
                    </div>
                    <div className="info-area info-horizontal">
                        <div className="icon icon-primary">
                            <i className="tim-icons icon-book-bookmark" />
                        </div>
                        <div className="description">
                            <h3 className="info-title">Remember</h3>
                            <p className="description">
                    Each user must have unique email address. If email
                    already exist user can not be added.
                            </p>
                        </div>
                    </div>
                    <div className="info-area info-horizontal">
                        <div className="icon icon-info">
                            <i className="tim-icons icon-cloud-upload-94" />
                        </div>
                        <div className="description">
                            <h3 className="info-title">Add User</h3>
                            <p className="description">
                      Unitll all the requirments are fullfilled Add Profile Button
                      will be disabled.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col className="mr-auto" md="7">
          
                    <Form id="RegisterValidation">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Add New Profile Form</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <FormGroup className={`has-label ${usernameState}`}>
                                    <label>Username *</label>
                    
                                    <Input type="username" name="username" onChange={e => this.handleChange(e, "username", "username")} value={this.state.username}/>

                   
                                    {this.state.usernameState === "has-danger" ? (
                                        <label className="error">
                          Please enter a valid username.
                                        </label>
                                    ) : null}
                                </FormGroup>
                                <FormGroup className={`has-label ${ emailState}`}>
                                    <label>Email Address *</label>
                                    <Input 
                                        type="email" name="email"   onChange={e => this.handleChange(e, "email", "email")} value={this.state.email}/>

                   
                                    {this.state.emailState === "has-danger" ? (
                                        <label className="error">
                          Please enter a valid email address.
                                        </label>
                                    ) : null}
                                </FormGroup>
             
                            </CardBody>
                            <CardFooter className="text-right">
                                <Button color="primary"     
                                    onClick={this.handleSubmit}
                                    disabled={!this.validateForm()}>
                      Add User Profile
                                </Button>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
          
            </Row>
        </div>
      </>
      )
  }
}

export default AddProfile
