/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

// react component for creating dynamic tables
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { getUsers } from '../../Redux/ActionCreator';
import { baseUrl } from '../../Shared/baseUrl';
const mapStateToProps = (state) => {
	return {
		Users: state.Users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (details) => dispatch(getUsers(details)),
	};
};

class CustomerDetail extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: null,
			table: null,
		};
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);

		let details = {
			token: cookie.load('Token'),
			id: data.id,
			Permissions: data.Permissions,
		};

		fetch(baseUrl + '/pos/getSalesTargetList', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(details.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						data: jsonResponse.data.map((prop, key) => {
							fetch(`${baseUrl}/routing/getRouteInfo/${prop.subRouteId}`, {
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
									Authorization: 'Bearer ' + details.token,
									id: details.id,
									reg: JSON.stringify(details.Permissions),
								},
								method: 'Post',
							})
								.then((response) => response.json())
								.then((jsonResponse) => {
									if (jsonResponse.success) {
										this.state.data[key].subRouteName = jsonResponse.route.name;

										this.setState({ data: [...this.state.data] });
									}
								});

							fetch(`${baseUrl}/auth/users/getUserDetail/${prop.fsId}`, {
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
									Authorization: 'Bearer ' + details.token,
									id: details.id,
									reg: JSON.stringify(details.Permissions),
								},
								method: 'Post',
							})
								.then((response) => response.json())
								.then((jsonResponse) => {
									if (jsonResponse.success) {
										this.state.data[key].sp = jsonResponse.User.username;

										this.setState({ data: [...this.state.data] });
									}
								});

							return {
								id: prop.id,
								st: prop.target,
								importedDoseQty: prop.importedDoseQty,
								localDoseQty: prop.localDoseQty,
								date: prop.date,
							};
						}),
					});
				}
			})
			.catch((err) => {});
	}
	rowClicked(id) {
		let obj = this.state.data.find((o) => o.id === id);
		this.props.history.push(`/admin/edit_sales_target/${obj.id}`);
	}

	filterCaseInsensitive = (filter, row) => {
		const id = filter.pivotId || filter.id;
		const content = row[id];
		if (typeof content !== 'undefined') {
			// filter by text in the table or if it's a object, filter by key
			if (typeof content === 'object' && content !== null && content.key) {
				return String(content.key)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			} else {
				return String(content)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			}
		}

		return true;
	};

	render() {
		return (
			<>
				<div className='content'>
					{}
					<Col md={8} className='ml-auto mr-auto'>
						<h2 className='text-center'>Customers Info</h2>
						<p className='text-center'>
							This Table contain all the information about Customers{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								Customer-table
							</a>
							. If need to modified customer please click on the buttons in the
							action column.{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								full documentation.
							</a>
						</p>
					</Col>
					<Row className='mt-5'>
						<Col xs={12} md={12}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Customers Table</CardTitle>
								</CardHeader>
								<CardBody>
									{this.state.data && (
										<ReactTable
											data={this.state.data}
											filterable
											defaultFilterMethod={this.filterCaseInsensitive}
											resizable={false}
											columns={[
												{
													Header: 'Sales Person',
													accessor: 'sp',
												},
												{
													Header: 'Sub Route',
													accessor: 'subRouteName',
												},
												{
													Header: 'Sales Target',
													accessor: 'st',
												},
												{
													Header: 'Imported Dose Qty',
													accessor: 'importedDoseQty',
												},
												{
													Header: 'Local Dose Qty',
													accessor: 'localDoseQty',
												},
												{
													Header: 'Date',
													accessor: 'date',
												},
											]}
											getTdProps={(state, rowInfo, column, instance) => {
												return {
													onClick: (e) => this.rowClicked(rowInfo.original.id),
												};
											}}
											defaultPageSize={10}
											showPaginationTop
											showPaginationBottom={false}
											className='-striped -highlight'
										/>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
