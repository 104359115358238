/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

// react component for creating dynamic tables
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import {
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	FormGroup,
	Form,
} from 'reactstrap';
import Select from 'react-select';
import { getUsers } from '../../Redux/ActionCreator';
import { baseUrl } from '../../Shared/baseUrl';
const mapStateToProps = (state) => {
	return {
		Users: state.Users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (details) => dispatch(getUsers(details)),
	};
};

class whInventoryList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			table: null,
		};
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/inventory/getCurrentUserWarehouse', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				faId: data.id,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({
					assignedWarehouse: jsonResponse.data.map((prop, key) => {
						return {
							value: prop.id,
							label: prop.whName,
						};
					}),
				});
			})
			.catch((err) => {});
	}
	// rowClicked(id){
	//     console.log(id, "checking id")
	//     let obj = this.state.data.find(o => o.id === id)
	//     this.props.history.push(`/admin/fsInventoryReturn/${obj.id}`)
	// }

	changeWarehouse = (event) => {
		this.setState({
			selectedWarehouse: event,
		});

		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/inventory/getInventoryByWarehouse', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				warehouseId: event.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						data: jsonResponse.data.map((props, index) => {
							fetch(`${baseUrl}/auth/users/getUserDetail/${props.fsId}`, {
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
									Authorization: 'Bearer ' + token,
									id: data.id,
									reg: JSON.stringify(data.Permissions),
								},
								method: 'Post',
							})
								.then((response) => response.json())
								.then((jsonResponse) => {
									if (jsonResponse.success) {
										this.state.data[index].fsUsername =
											jsonResponse.User.username;

										this.setState({ data: [...this.state.data] });
									}
								});

							fetch(`${baseUrl}/pos/productsList/${props.productId}`, {
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
									Authorization: 'Bearer ' + token,
									id: data.id,
									reg: JSON.stringify(data.Permissions),
								},
								method: 'Post',
							})
								.then((response) => response.json())
								.then((jsonResponse) => {
									if (jsonResponse.success) {
										this.state.data[index].productName =
											jsonResponse.product.productName;

										this.setState({ data: [...this.state.data] });
									}
								});

							return {
								currentQty: props.qtyAssigned,
								sku: props.sku,
								itemsReturned: props.itemsReturned,
							};
						}),
					});
				}
			});
	};

	render() {
		return (
			<>
				<div className='content'>
					{}
					<Col md={8} className='ml-auto mr-auto'>
						<h2 className='text-center'>Current Inventory Info</h2>
						<p className='text-center'>
							This Table contain all the information about Inventory{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								Current Inventory-table
							</a>
							. If need to modified customer please click on the buttons in the
							action column.{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								full documentation.
							</a>
						</p>
					</Col>
					<Row>
						<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h4'>Select Warehouse</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup className={'has-label'}>
											<label> Warehouse *</label>
											<Select
												onChange={this.changeWarehouse}
												options={this.state.assignedWarehouse}
												value={this.state.selectedWarehouse}
											></Select>
										</FormGroup>
									</CardBody>
								</Card>
							</Form>
						</Col>
					</Row>
					<Row className='mt-5'>
						<Col xs={12} md={12}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Current Inventory Table</CardTitle>
								</CardHeader>
								<CardBody>
									<ReactTable
										data={this.state.data}
										filterable
										resizable={false}
										columns={[
											{
												Header: 'Field Supervisor',
												accessor: 'fsUsername',
											},
											{
												Header: 'Product',
												accessor: 'productName',
											},
											{
												Header: 'SKU',
												accessor: 'sku',
											},
											{
												Header: 'Quantity',
												accessor: 'currentQty',
											},
										]}
										defaultPageSize={10}
										showPaginationTop
										showPaginationBottom={false}
										className='-striped -highlight'
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(whInventoryList);
