/* eslint-disable no-cond-assign */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import {
	GoogleMap,
	withScriptjs,
	withGoogleMap,
	InfoWindow,
	Marker,
	Polyline,
} from 'react-google-maps';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	Label,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
} from 'reactstrap';
import { baseUrl } from '../../Shared/baseUrl';
Geocode.setApiKey(window._env_.REACT_APP_GOOGLE_MAP_API_KEY);
Geocode.enableDebug();
var dropDown;
var map;
var counter = 0;
let AsyncMap;
class EditRoute extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			subRouteName: '',
			region: '',
			allowedRegions: [],
			salesPoints: [],
			selectedSalesPoints: [],
			selectedSalesPointsValues: [],
			assignedSalesCode: [],
			unassingedsalesPoints: [],
			selectedLatLong: [],
			selectedAllowedRegion: '',
			multiValue: null,
			selectedMultiValues: [],
			allSalesPoints: [],
			center: {
				lat: 31.4805,
				lng: 74.3239,
			},
			mapPosition: {
				lat: 31.4805,
				lng: 74.3239,
			},
			markerPosition: {
				lat: 31.4805,
				lng: 74.3239,
			},
			markers: [],
			selectedMarker: false,
			parentRouteList: [],
			selectedParentRoute: null,
		};
		this.handleMultiChange = this.handleMultiChange.bind(this);
		this.setState = this.setState.bind(this);
	}
	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}

	componentWillMount() {
		AsyncMap = withScriptjs(
			withGoogleMap((props) => (
				<GoogleMap
					google={this.props.google}
					defaultZoom={13}
					defaultCenter={{
						lat: this.state.mapPosition.lat,
						lng: this.state.mapPosition.lng,
					}}
				>
					{this.state.markers.map((marker, key) => {
						return (
							<Marker
								google={this.props.google}
								key={key}
								position={{
									lat: parseFloat(marker.lat),
									lng: parseFloat(marker.lng),
								}}
							/>
						);
					})}

					<Autocomplete
						style={{
							width: '100%',
							height: '40px',
							paddingLeft: '16px',
							marginTop: '2px',
							marginBottom: '100px',
						}}
						onPlaceSelected={this.onPlaceSelected}
						types={['(regions)']}
					/>
				</GoogleMap>
			)),
		);

		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(`${baseUrl}/routing/get-parent-routes`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Get',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						parentRouteList: jsonResponse.response.map((parentRoute) => {
							return {
								label: parentRoute.name,
								value: parentRoute.id,
							};
						}),
					});
				} else {
					this.errorAlert(jsonResponse.message);
				}
			});
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		if (data.permissions !== null) {
			const permissions = data.Permissions;
			const getRoleId = (Permissions) => Permissions.regionCode;
			const getRoleIdArray = (permissions) => permissions.map(getRoleId);
			const regions = getRoleIdArray(permissions);

			var regionCodes = regions.filter(function (el) {
				return el !== undefined;
			});

			const getNameId = (Permissions) => Permissions.regionName;
			const getNameIdArray = (permissions) => permissions.map(getNameId);
			const regionsname = getNameIdArray(permissions);
			var regionName = regionsname.filter(function (el) {
				return el !== undefined;
			});
			var regionsJson = [];

			for (var i in regionCodes) {
				var code = regionCodes[i];
				var label = regionName[i];

				regionsJson.push({
					code: code,
					label: label,
				});
			}
			var result = [
				...new Map(regionsJson.map((o) => [JSON.stringify(o), o])).values(),
			];

			var user = JSON.parse(window.localStorage.getItem('user'));
			var data = JSON.parse(user);
			var token = cookie.load('Token');
			let details = {
				userid: data.id,
				token: token,
				permissions: data.Permissions,
			};

			fetch(`${baseUrl}/auth/users/getRegion`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + details.token,
					id: details.userid,
					reg: JSON.stringify(details.permissions),
				},
				method: 'Post',
			})
				.then((response) => response.json())
				.then((jsonResponse) => {
					if (jsonResponse.success) {
						jsonResponse.Regions.map((region) => {
							result.map((allowedRegion) => {
								if (allowedRegion.code === region.code) {
									this.setState({
										allowedRegions: [
											...this.state.allowedRegions,
											{ label: region.name, value: region.id },
										],
									});
								}
							});
						});
					}
				});
		}

		fetch(baseUrl + '/routing/getRouteInfo/' + this.props.match.params.id, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.setState({
						subRouteName: jsonResponse.route.name,
						selectedAllowedRegion: this.state.allowedRegions.find(
							(r) => r.value === jsonResponse.route.regionId,
						),
						selectedParentRoute: this.state.parentRouteList.find(
							(r) => r.value === jsonResponse.route.parentRouteId,
						),
					});
					this.setState({
						selectedSalesPoints: JSON.parse(jsonResponse.route.salesPoints).map(
							(prop) => {
								return {
									value: {
										lng: prop.lng,
										lat: prop.lat,
										address: prop.address,
									},
									label: prop.name,
								};
							},
						),
						selectedSalesPointsValues: JSON.parse(
							jsonResponse.route.salesPoints,
						).map((prop) => {
							return {
								value: {
									lng: prop.lng,
									lat: prop.lat,
									address: prop.address,
								},
								label: prop.name,
							};
						}),
						markers: JSON.parse(jsonResponse.route.salesPoints).map((prop) => {
							return {
								lat: prop.lat,
								lng: prop.lng,
							};
						}),
					});
					this.setState({
						allSalesPoints: JSON.parse(jsonResponse.route.salesPoints).map(
							(prop, key) => {
								return {
									lat: prop.lat,
									lng: prop.lng,
									address: prop.address,
									name: prop.name,
									code: prop.code,
								};
							},
						),
					});
					console.log(
						JSON.parse(jsonResponse.route.salesPoints),
						'Selected sales points',
					);
				} else {
					this.setState({ loader: null });
					this.errorAlert();
				}
			})
			.then(() => {
				fetch(baseUrl + '/routing/getAssignedSalesPointsByRegion', {
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: 'Bearer ' + token,
						id: data.id,
						reg: JSON.stringify(data.Permissions),
					},
					method: 'Post',
					body: JSON.stringify({
						regionId: this.state.selectedAllowedRegion.value,
					}),
				})
					.then((res) => res.json())
					.then((jsonResponse) => {
						if (jsonResponse.success === true) {
							jsonResponse.salepoints.map((prop) => {
								JSON.parse(prop.salesPoints).map((props, key) => {
									this.state.assignedSalesCode.push({
										code: props.code,
									});
								});
							});
						}
					})
					.then(() => {
						fetch(baseUrl + '/routing/getSalesPointsByRegion', {
							headers: {
								'Content-Type': 'application/json',
								Accept: 'application/json',
								Authorization: 'Bearer ' + token,
								id: data.id,
								reg: JSON.stringify(data.Permissions),
							},
							method: 'Post',
							body: JSON.stringify({
								regionId: this.state.selectedAllowedRegion.value,
							}),
						})
							.then((res) => res.json())
							.then((jsonResponse) => {
								if (jsonResponse.success === true) {
									jsonResponse.salepoints.map((props) => {
										let i = 0;
										this.state.assignedSalesCode.map((prop) => {
											if (prop.code === props.code) {
												i = i + 1;
											}
										});
										if (i === 0) {
											this.state.unassingedsalesPoints.push({
												long: props.long,
												lat: props.lat,
												address: props.address,
												name: props.name,
												code: props.code,
											});
										}
									});
									this.setState({
										salesPoints: this.state.unassingedsalesPoints.map(
											(prop) => {
												return {
													value: {
														lng: prop.long,
														lat: prop.lat,
														address: prop.address,
														name: prop.name,
														code: prop.code,
													},
													label: prop.name,
												};
											},
										),
									});
								}
							});
					});
			})
			.catch((err) => {
				this.errorAlert('Something went wrong please refresh page');
			});
	}

	successAlert(message) {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					onCancel={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					btnSize=''
				>
					{message ? message : `Route updated Successfully !`}
				</ReactBSAlert>
			),
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	hideDeleteAlert = () => {
		this.setState({
			alert: null,
		});

		this.props.history.push('/admin/routeDetails');
	};

	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : `Route Could not be updated !!!!`}
				</ReactBSAlert>
			),
		});
	};

	updateRoute() {
		this.setState({
			loader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: '250px',
							marginLeft: '-80px',
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			),
		});

		this.state.multiValue &&
			this.state.multiValue.map((selectedMultiValue) => {
				this.state.allSalesPoints.push({
					lat: selectedMultiValue.value.lat,
					lng: selectedMultiValue.value.lng,
					address: selectedMultiValue.value.address,
					name: selectedMultiValue.value.name,
					code: selectedMultiValue.value.code,
				});
			});

		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};

		fetch(
			baseUrl + '/routing/modifyRouteDetails/' + this.props.match.params.id,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + details.token,
					id: details.userid,
					reg: JSON.stringify(details.permissions),
				},
				method: 'Post',
				body: JSON.stringify({
					name: this.state.subRouteName,
					regionId: this.state.selectedAllowedRegion.value,
					salePoints: this.state.allSalesPoints,
					parentRouteId: this.state.selectedParentRoute.value,
				}),
			},
		)
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert();
				} else {
					this.errorAlert(jsonResponse.message);
				}

				this.setState({ loader: null });
			})
			.catch((err) => {
				this.errorAlert();
			});
	}

	resetForm() {
		this.setState({ name: '', selectedAllowedRegion: '' });
	}
	handleSubmit = (event) => {
		this.updateRoute();
	};
	handleCancelButton = () => {
		this.props.history.push('/admin/routeDetails');
	};

	handleDeleteButton = () => {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};

		fetch(`${baseUrl}/routing/delete-sub-route/${this.props.match.params.id}`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.userid,
				reg: JSON.stringify(details.permissions),
			},
			method: 'Delete',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert(jsonResponse.message);
				} else {
					this.errorAlert(jsonResponse.message);
				}
			});
	};

	ChangeStatus = (event) => {
		this.setState({ selectedAllowedRegion: event });
	};

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	handleMultiChange(options) {
		this.state.markers = [];

		this.state.selectedSalesPoints.length > 0 &&
			this.state.selectedSalesPoints.map((selectedSalePoint) => {
				this.state.markers.push({
					lat: selectedSalePoint.value.lat,
					lng: selectedSalePoint.value.lng,
				});
			});

		if (options.length > 0) {
			options.map((option) => {
				this.state.markers.push({
					lat: option.value.lat,
					lng: option.value.lng,
				});
			});
		}

		this.setState((state) => {
			return {
				multiValue: options,
			};
		});
	}

	handleParentRouteChange = (event) => {
		this.setState({ selectedParentRoute: event });
	};

	render() {
		let map;
		if (this.state.center.lat !== undefined) {
			map = (
				<div>
					<AsyncMap
						googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${window._env_.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&v=3`}
						loadingElement={<div style={{ height: `100%` }} />}
						containerElement={<div style={{ height: '300px' }} />}
						mapElement={<div style={{ height: `100%` }} />}
					/>
				</div>
			);
		} else {
			map = <div style={{ height: '300px' }} />;
		}

		return (
			<>
				<div className='content'>
					{this.state.alert}

					{this.state.loader}
					<Row>
						<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h4'>Edit Sub Route</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup className={'has-label'}>
											<Label> Route Name *</Label>
											<Select
												type='text'
												options={this.state.parentRouteList}
												value={this.state.selectedParentRoute}
												onChange={this.handleParentRouteChange}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<Label> Sub-Route Name *</Label>
											<Input
												type='text'
												name='subRouteName'
												onChange={this.handleChange}
												value={this.state.subRouteName}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<Label> Region Name *</Label>
											<Select
												onChange={this.ChangeStatus}
												options={this.state.allowedRegions}
												value={this.state.selectedAllowedRegion}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<Label>Assigned Sales Points</Label>
											<Select
												name='AssignedSalespointlist'
												isMulti
												onChange={this.handleMultiChange}
												value={this.state.selectedSalesPoints}
												isDisabled={true}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<Label> Add Sales Points </Label>
											<Select
												name='salespointlist'
												isMulti
												onChange={this.handleMultiChange}
												options={this.state.salesPoints}
												placeholder='Select Sales points for adding to this Route'
											/>
										</FormGroup>
										<FormGroup
											className={'has-label'}
											style={{ width: '94%', marginLeft: '22px' }}
										>
											{map}
										</FormGroup>
									</CardBody>
									<CardFooter className='text-right'>
										<Button
											className='btn-round'
											color='primary'
											onClick={this.handleSubmit}
											size='md'
										>
											Update
										</Button>
										<Button
											className='btn-round'
											color='info'
											onClick={this.handleCancelButton}
											size='md'
										>
											Cancel
										</Button>
										<Button
											className='btn-round'
											color='danger'
											onClick={this.handleDeleteButton}
											size='md'
										>
											Delete
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default EditRoute;
