/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import { baseUrl } from '../../Shared/baseUrl';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Button,
} from 'reactstrap';
import Moment from 'moment';

var currentDate;

class fsInventoryReturn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fsUsername: '',
			fromWh: '',
			whName: '',
			qtyAssigned: '',
			sku: '',
			id: '',
			itemsDamaged: 0,
			itemsReturned: 0,
			alert: null,
			loader: null,
			warehouseId: '',
		};
	}

	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert(true)}
					onCancel={() => this.hideAlert(true)}
					btnSize=''
				>
					Warehouse Updated Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = (success) => {
		this.setState({
			alert: null,
		});

		if (success) {
			this.handleCancelButton();
		}
	};

	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : 'Something went wrong!!'}
				</ReactBSAlert>
			),
		});
	};

	handleCancelButton = () => {
		this.props.history.push('/admin/fsInventoryList');
	};

	componentDidMount() {
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		let details = {
			token: cookie.load('Token'),
			id: data.id,
			permissions: data.Permissions,
		};

		fetch(
			baseUrl + '/inventory/getFsInventoryDetail/' + this.props.match.params.id,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + details.token,
					id: details.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: 'Post',
			},
		)
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					fetch(
						`${baseUrl}/auth/users/getUserDetail/${jsonResponse.data.fsId}`,
						{
							headers: {
								'Content-Type': 'application/json',
								Accept: 'application/json',
								Authorization: 'Bearer ' + details.token,
								id: data.id,
								reg: JSON.stringify(details.Permissions),
							},
							method: 'Post',
						},
					)
						.then((response) => response.json())
						.then((jsonResponse) => {
							if (jsonResponse.success) {
								this.setState({ fsUsername: jsonResponse.User.username });
							}
						});

					this.setState({
						whName: jsonResponse.data.warehouse.whName,
						qtyAssigned: jsonResponse.data.qtyAssigned,
						sku: jsonResponse.data.sku,
						id: jsonResponse.data.id,
						warehouseId: jsonResponse.data.warehouse.id,
						itemsDamaged: parseInt(jsonResponse.data.itemsDamaged),
						itemsReturned: parseInt(jsonResponse.data.itemsReturned),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}

	handleSubmit = (event) => {
		if (parseInt(this.state.itemsReturned) > parseInt(this.state.qtyAssigned)) {
			this.errorAlert(
				`Items returned must be less than or equal ${this.state.qtyAssigned}`,
			);
		} else {
			var product = JSON.parse(window.localStorage.getItem('user'));
			var data = JSON.parse(product);
			var token = cookie.load('Token');
			var d1 = new Date();
			currentDate = Moment(d1).format('YYYY-MM-DD');
			fetch(baseUrl + '/inventory/updateFsInventory', {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
					id: data.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: 'Post',
				body: JSON.stringify({
					id: this.state.id,
					itemsDamaged: this.state.itemsDamaged,
					itemsReturned: this.state.itemsReturned,
					sku: this.state.sku,
					warehouseId: this.state.warehouseId,
					date: currentDate,
				}),
			})
				.then((res) => res.json())
				.then((jsonResponse) => {
					if (jsonResponse.success === true) {
						this.successAlert();
					} else {
						this.errorAlert();
					}
				});
		}
	};

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col md={{ size: 7, offset: 2 }}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>FS Inventory Detail</CardTitle>
								</CardHeader>
								<CardBody>
									<Form action='/' className='form-horizontal' method='get'>
										<Row>
											<Col md={12}>
												<FormGroup>
													<Label>Field Supervisor</Label>
													<Input
														className='text-white'
														type='text'
														name='fsUsername'
														readOnly
														value={this.state.fsUsername}
													/>
												</FormGroup>
												<FormGroup>
													<Label>Warehouse</Label>
													<Input
														className='text-white'
														type='text'
														name='whName'
														readOnly
														value={this.state.whName}
													/>
												</FormGroup>
												<FormGroup>
													<Label>Product SKU</Label>
													<Input
														className='text-white'
														type='text'
														name='sku'
														readOnly
														value={this.state.sku}
													/>
												</FormGroup>
												<FormGroup>
													<Label>Assigned Quantity</Label>
													<Input
														className='text-white'
														type='text'
														name='qtyAssigned'
														readOnly
														value={this.state.qtyAssigned}
													/>
												</FormGroup>
												<FormGroup>
													<Label>Items Damaged</Label>
													<Input
														type='text'
														name='itemsDamaged'
														onChange={this.handleChange}
														value={this.state.itemsDamaged}
													/>
												</FormGroup>
												<FormGroup>
													<Label>OK Item Returned</Label>
													<Input
														type='text'
														name='itemsReturned'
														onChange={this.handleChange}
														value={this.state.itemsReturned}
													/>
												</FormGroup>
											</Col>
										</Row>
									</Form>
								</CardBody>
								<CardFooter className='text-right'>
									<Button
										className='btn-round'
										color='primary'
										onClick={this.handleSubmit}
										size='lg'
									>
										Update
									</Button>
									<Button
										className='btn-round'
										color='primary'
										onClick={this.handleCancelButton}
										size='lg'
									>
										Cancel
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default withRouter(fsInventoryReturn);
