/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import { baseUrl } from '../../Shared/baseUrl';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Button,
} from 'reactstrap';
class editPromo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			productCategory: [],
			selectedProductCategory: '',
			prod_Name: [],
			selectedProdName: '',
			regions: [],
			selectedRegion: '',
			offerType: [
				{ label: 'Volume', value: 'Volume' },
				{ label: 'Percentage', value: 'Percentage' },
			],
			selectedOfferType: '',
			selectedOfferType1: '',
			offer: '',
			startDate: '',
			endDate: '',
		};
		this.updateProductPromotions = this.updateProductPromotions.bind(this);

		this.successAlert = this.successAlert.bind(this);
	}
	hide() {
		this.setState({
			isActive: false,
		});
	}

	handleCheckboxChange = (event) => {
		this.setState({ checked: event.target.checked });
	};
	successAlert(message) {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					onCancel={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					btnSize=''
				>
					{message ? message : `Product Promotions Updated Successfully !`}
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	hideDeleteAlert = () => {
		this.hideAlert();

		this.props.history.push('/admin/listPromotions');
	};

	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : `Something gets wrong !!!!!`}
				</ReactBSAlert>
			),
		});
	};

	ChangeProductCat = (event) => {
		this.setState({ selectedProductCategory: event, selectedProdName: '' });

		if (event.label !== '') {
			var user = JSON.parse(window.localStorage.getItem('user'));
			var data = JSON.parse(user);
			var token = cookie.load('Token');

			let header = {
				userid: data.id,
				token: token,
				permissions: data.Permissions,
			};

			fetch(baseUrl + '/pos/listProdNameByCatg', {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + header.token,
					id: header.userid,
					reg: JSON.stringify(header.permissions),
				},
				method: 'Post',
				body: JSON.stringify({
					prodCat: event.value,
				}),
			})
				.then((res) => res.json())
				.then((jsonResponse) => {
					this.setState({ loader: null });
					if (jsonResponse.success === true) {
						this.setState({
							prod_Name: jsonResponse.prodName.map((props) => {
								return {
									value: props.id,
									label: props.productName,
								};
							}),
						});
					} else {
						this.setState({ loader: null });
					}
				})
				.catch((err) => {
					this.setState({ loader: null });
				});
		}
	};

	ChangeProductName = (event) => {
		this.setState({ selectedProdName: event });
	};

	//changing the region
	ChangeRegion = (event) => {
		this.setState({ selectedRegion: event });
	};

	ChangeOfferType = (event) => {
		this.setState({ selectedOfferType: event });
	};

	componentWillMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/auth/users/getRegion', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						regions: jsonResponse.Regions.map((props) => {
							return {
								value: props.id,
								label: props.name,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let header = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};

		fetch(baseUrl + '/pos/Edit_Promo/' + this.props.match.params.id, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					let startDate = jsonResponse.pPromo.startDate.split('T');
					let endDate = jsonResponse.pPromo.endDate.split('T');

					this.setState({
						selectedProdName: jsonResponse.pPromo.productId,
						selectedRegion: this.state.regions.find(
							(r) => r.value === jsonResponse.pPromo.regionId,
						),
						selectedOfferType: {
							label: jsonResponse.pPromo.offerType,
							value: jsonResponse.pPromo.offerType,
						},
						offer: jsonResponse.pPromo.offer,
						startDate: startDate[0],
						endDate: endDate[0],
						loader: null,
					});

					fetch(
						baseUrl +
							'/pos/get-product-categories/' +
							this.state.selectedProdName,
						{
							headers: {
								'Content-Type': 'application/json',
								Accept: 'application/json',
								Authorization: 'Bearer ' + token,
								id: data.id,
								reg: JSON.stringify(data.Permissions),
							},
							method: 'Get',
						},
					)
						.then((res) => res.json())
						.then((jsonResponse) => {
							if (jsonResponse.success === true) {
								this.setState({
									productCategory: jsonResponse.categories.map((props) => {
										return {
											value: props.id,
											label: props.productCat,
										};
									}),
								});

								fetch(
									`${baseUrl}/pos/productsList/${this.state.selectedProdName}`,
									{
										headers: {
											'Content-Type': 'application/json',
											Accept: 'application/json',
											Authorization: 'Bearer ' + header.token,
											id: header.userid,
											reg: JSON.stringify(header.permissions),
										},
										method: 'Post',
									},
								)
									.then((res) => res.json())
									.then((jsonResponse) => {
										if (jsonResponse.success) {
											this.setState({
												selectedProductCategory: this.state.productCategory.find(
													(c) =>
														c.value === jsonResponse.product.productCategoryId,
												),
											});

											fetch(baseUrl + '/pos/listProdNameByCatg', {
												headers: {
													'Content-Type': 'application/json',
													Accept: 'application/json',
													Authorization: 'Bearer ' + header.token,
													id: header.userid,
													reg: JSON.stringify(header.permissions),
												},
												method: 'Post',
												body: JSON.stringify({
													prodCat: jsonResponse.product.productCategoryId,
												}),
											})
												.then((res) => res.json())
												.then((jsonResponse) => {
													this.setState({ loader: null });
													if (jsonResponse.success === true) {
														this.setState({
															prod_Name: jsonResponse.prodName.map((props) => {
																return {
																	value: props.id,
																	label: props.productName,
																};
															}),
														});

														this.setState({
															selectedProdName: this.state.prod_Name.find(
																(p) => p.value === this.state.selectedProdName,
															),
														});
													} else {
														this.setState({ loader: null });
													}
												})
												.catch((err) => {
													this.setState({ loader: null });
												});
										}
									});
							}
						})
						.catch((err) => {
							throw new Error(err);
						});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}
	formatDate(string) {
		var options = { month: 'numeric', day: 'numeric', year: 'numeric' };
		return new Date(string).toLocaleDateString([], options);
	}
	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	};

	updateProductPromotions() {
		this.setState({
			loader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: '250px',
							marginLeft: '-80px',
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			),
		});
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			productId: this.state.selectedProdName.value,
			regionId: this.state.selectedRegion.value,
			offerType: this.state.selectedOfferType.value,
			pOffer: this.state.offer,
			sDate: this.state.startDate,
			eDate: this.state.endDate,
		};

		fetch(
			baseUrl +
				'/pos/productPromotionModification/' +
				this.props.match.params.id,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
					id: data.userid,
					reg: JSON.stringify(data.permissions),
				},
				method: 'Post',
				body: JSON.stringify({
					productId: details.productId,
					regionId: details.regionId,
					offerType: details.offerType,
					pOffer: details.pOffer,
					sDate: details.sDate,
					eDate: details.eDate,
				}),
			},
		)
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.successAlert();
				} else {
					this.setState({ loader: null });
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert();
			});
	}

	handleCancelButton = () => {
		this.props.history.push('/admin/listPromotions');
	};

	handleDeleteButton = () => {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(
			`${baseUrl}/pos/delete-promotion-list/${this.props.match.params.id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
					id: data.userid,
					reg: JSON.stringify(data.permissions),
				},
				method: 'Delete',
			},
		)
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert(jsonResponse.message);
				} else {
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert('Error while deleting promotion');
			});
	};

	ChangeStatus = (event) => {
		this.setState({ selectedAllowedRegion: event.label });
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}

					{this.state.loader}
					<Row>
						<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h4'>Edit Promotion</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup className={'has-label'}>
											<label> Product Category *</label>
											<Select
												onChange={this.ChangeProductCat}
												options={this.state.productCategory}
												value={this.state.selectedProductCategory}
											></Select>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Product *</label>

											<Select
												onChange={this.ChangeProductName}
												options={this.state.prod_Name}
												value={this.state.selectedProdName}
											></Select>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Region *</label>

											<Select
												onChange={this.ChangeRegion}
												options={this.state.regions}
												value={this.state.selectedRegion}
											></Select>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Offer Type *</label>

											<Select
												onChange={this.ChangeOfferType}
												options={this.state.offerType}
												value={this.state.selectedOfferType}
											></Select>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Offer *</label>

											<Input
												type='text'
												name='offer'
												onChange={this.handleChange}
												value={this.state.offer}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Starting Date *</label>
											<Input
												type='date'
												name='startDate'
												onChange={this.handleChange}
												value={this.state.startDate}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Ending Date *</label>
											<Input
												type='date'
												name='endDate'
												onChange={this.handleChange}
												value={this.state.endDate}
											/>
										</FormGroup>
									</CardBody>
									<CardFooter className='text-right'>
										<Button
											className='btn-round'
											color='primary'
											onClick={this.updateProductPromotions}
											size='md'
										>
											Update
										</Button>
										<Button
											className='btn-round'
											color='info'
											onClick={this.handleCancelButton}
											size='md'
										>
											Cancel
										</Button>
										{/* <Button
											className='btn-round'
											color='danger'
											onClick={this.handleDeleteButton}
											size='md'
										>
											Delete
										</Button> */}
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default withRouter(editPromo);
