/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

// react component for creating dynamic tables
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { getUsers } from '../../Redux/ActionCreator';
import { baseUrl } from '../../Shared/baseUrl';
const mapStateToProps = (state) => {
	return {
		Users: state.Users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (details) => dispatch(getUsers(details)),
	};
};

class fsInventoryList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			table: null,
		};
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);

		let details = {
			token: cookie.load('Token'),
			id: data.id,
			Permissions: data.Permissions,
		};

		fetch(baseUrl + '/inventory/getCurrentUserWarehouse', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(details.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				faId: data.id,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						yourWarehouse: jsonResponse.data.map((props) => {
							return props.id;
						}),
					});

					fetch(baseUrl + '/inventory/getFSInventoryByWarehouse', {
						headers: {
							'Content-Type': 'application/json',
							Accept: 'application/json',
							Authorization: 'Bearer ' + details.token,
							id: data.id,
							reg: JSON.stringify(details.Permissions),
						},
						method: 'Post',
						body: JSON.stringify({
							warehouseIds: this.state.yourWarehouse,
						}),
					})
						.then((res) => res.json())
						.then((jsonResponse) => {
							if (jsonResponse.success === true) {
								this.setState({
									data: jsonResponse.data.map((props, index) => {
										fetch(`${baseUrl}/auth/users/getUserDetail/${props.fsId}`, {
											headers: {
												'Content-Type': 'application/json',
												Accept: 'application/json',
												Authorization: 'Bearer ' + details.token,
												id: data.id,
												reg: JSON.stringify(details.Permissions),
											},
											method: 'Post',
										})
											.then((response) => response.json())
											.then((jsonResponse) => {
												if (jsonResponse.success) {
													this.state.data[index].fsUsername =
														jsonResponse.User.username;

													this.setState({ data: [...this.state.data] });
												}
											});

										return {
											whName: props.warehouse.whName,
											sku: props.sku,
											qtyAssigned: props.qtyAssigned,
											id: props.id,
										};
									}),
								});
							}
						});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}
	rowClicked(id) {
		let obj = this.state.data.find((o) => o.id === id);
		this.props.history.push(`/admin/fsInventoryReturn/${obj.id}`);
	}

	render() {
		return (
			<>
				<div className='content'>
					{}
					<Col md={8} className='ml-auto mr-auto'>
						<h2 className='text-center'>FS Info</h2>
						<p className='text-center'>
							This Table contain all the information about FS{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								FS-table
							</a>
							. If need to modified customer please click on the buttons in the
							action column.{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								full documentation.
							</a>
						</p>
					</Col>
					<Row className='mt-5'>
						<Col xs={12} md={12}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>FS Table</CardTitle>
								</CardHeader>
								<CardBody>
									<ReactTable
										data={this.state.data}
										filterable
										resizable={false}
										columns={[
											{
												Header: 'Warehouse',
												accessor: 'whName',
											},
											{
												Header: 'FS Username',
												accessor: 'fsUsername',
											},
											{
												Header: 'SKU',
												accessor: 'sku',
											},
											{
												Header: 'Qty',
												accessor: 'qtyAssigned',
											},
										]}
										getTdProps={(state, rowInfo, column, instance) => {
											return {
												onClick: (e) => this.rowClicked(rowInfo.original.id),
											};
										}}
										defaultPageSize={10}
										showPaginationTop
										showPaginationBottom={false}
										className='-striped -highlight'
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(fsInventoryList);
