/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Label,
	Input,
	Row,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { baseUrl } from '../../Shared/baseUrl';

var dropDown;
let initialState = {
	name: '',
	farmer: '',
	phone: '',
	product_nameOptions: [],
	productCategory: [],
	selectedProduct: '',
	tehsil: '',
	address: '',
	bull_no: '',
	bullNo: '',
	company: '',
	insemTime: '',
	inseminationTime: '',
	bookNo: '',
	date: '1990-12-31',
	serialNumber: '',
	alert: null,
	loader: null,
	files: null,
	pressed: false,
};
class AddInceminasionEvents extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.successAlert = this.successAlert.bind(this);
	}

	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}
	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		fetch(baseUrl + '/crm/customerDetail/' + this.props.match.params.id, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				console.log(jsonResponse.client.name, 'Check Name');

				if (jsonResponse.success === true) {
					this.setState({
						name: jsonResponse.client.name,
						phone: jsonResponse.client.phone,
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});

		fetch(baseUrl + '/pos/productsList', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						product_nameOptions: jsonResponse.product.map((prop, key) => {
							console.log(jsonResponse, 'newcheck');

							return {
								value: prop.productName,
								label: prop.productName,
							};
						}),
					});
				}
			});
	}
	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''>
					Insemination Event Added Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''>
					{message ? message : `Something Went Wrong !!!!!`}
				</ReactBSAlert>
			),
		});
	};
	addingInseminationTimeFunction = (details) => {
		this.setState({
			loader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: '250px',
							marginLeft: '-80px',
						}),
					}}
					active={true}
					spinner={
						<Loader type='CradleLoader' height='100' width='100' />
					}></LoadingOverlay>
			),
		});
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		let header = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};
		console.log(details.inseminationTime, 'Time');
		fetch(baseUrl + '/crm/addInseminationEvent', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + header.token,
				id: header.userid,
				reg: JSON.stringify(header.permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				name: details.name,
				farmer: details.farmer,
				phone: details.phone,
				tehsil: details.tehsil,
				address: details.address,
				product: details.product,
				bullNo: details.bullNo,
				company: details.company,
				inseminationTime: details.inseminationTime,
				bookNo: details.bookNo,
				serialNumber: details.serialNumber,
				date: details.date,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.successAlert();
					this.resetForm();
				} else {
					console.log('else');
					this.setState({ loader: null });
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.setState({ loader: null });
				this.errorAlert();
			});
	};
	resetForm() {
		this.state = initialState;
	}
	handleSubmit = (event) => {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		let details = {
			name: this.state.name,
			farmer: this.state.farmer,
			phone: this.state.phone,
			tehsil: this.state.tehsil,
			address: this.state.address,
			product: this.state.selectedProduct,
			bullNo: this.state.bull_no,
			company: this.state.company,
			inseminationTime: this.state.insemTime,
			bookNo: this.state.bookNo,
			serialNumber: this.state.serialNumber,
			date: this.state.date,
		};

		console.log(details);

		this.addingInseminationTimeFunction(details);
	};

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};
	Change = (event) => {
		this.setState({ selectedProduct: event.label });
	};

	handleCancelButton = () => {
		this.props.history.push(
			`/admin/edit_customer/${this.props.match.params.id}`,
		);
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}

					{this.state.loader}
					<Row>
						<Col className='mr-auto' md={{ size: 6, offset: 3 }}>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h3'>Add Insemination Events</CardTitle>
									</CardHeader>
									<CardBody>
										<Row>
											<Col md={6}>
												<FormGroup>
													<label>Name </label>

													<Input
														readOnly
														type='text'
														name='name'
														onChange={this.handleChange}
														value={this.state.name}
													/>
												</FormGroup>
												<FormGroup>
													<label>Phone Number</label>

													<Input
														readOnly
														type='text'
														name='phone'
														onChange={this.handleChange}
														value={this.state.phone}
													/>
												</FormGroup>
												<FormGroup>
													<label>Farmer </label>
													<Input
														type='text'
														name='farmer'
														onChange={this.handleChange}
														value={this.state.farmer}
													/>
												</FormGroup>
											</Col>
											<Col>
												<FormGroup>
													<label>Product </label>
													<Select
														placeholder='Select Product'
														onChange={this.Change}
														options={this.state.product_nameOptions}
														value={this.state.value}
													/>
												</FormGroup>
												<FormGroup>
													<label>Book Number </label>
													<Input
														type='text'
														name='bookNo'
														onChange={this.handleChange}
														value={this.state.bookNo}
													/>
												</FormGroup>
												<FormGroup>
													<label>Serial Number </label>
													<Input
														type='text'
														name='serialNumber'
														onChange={this.handleChange}
														value={this.state.serialNumber}
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md={6}>
												<FormGroup>
													<label>Address </label>
													<Input
														type='text'
														name='address'
														onChange={this.handleChange}
														value={this.state.address}
													/>
												</FormGroup>
												<FormGroup>
													<label>Tehsil </label>
													<Input
														type='text'
														name='tehsil'
														onChange={this.handleChange}
														value={this.state.tehsil}
													/>
												</FormGroup>
												<FormGroup>
													<label>Date </label>
													<Input
														type='date'
														name='date'
														onChange={this.handleChange}
														value={this.state.date}
													/>
												</FormGroup>
											</Col>
											<Col md={6}>
												<FormGroup>
													<label>Time of Insemination </label>
													<Input
														type='text'
														name='insemTime'
														onChange={this.handleChange}
														value={this.state.insemTime}
													/>
												</FormGroup>
												<FormGroup>
													<label>Bull Number </label>
													<Input
														type='text'
														name='bull_no'
														onChange={this.handleChange}
														value={this.state.bull_no}
													/>
												</FormGroup>
												<FormGroup>
													<label>Company </label>
													<Input
														type='text'
														name='company'
														onChange={this.handleChange}
														value={this.state.company}
													/>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									<CardFooter className='text-right'>
										<Button color='info' onClick={this.handleCancelButton}>
											Cancel
										</Button>
										<Button color='primary' onClick={this.handleSubmit}>
											Add Event
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default AddInceminasionEvents;
