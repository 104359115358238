/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react"
import cookie from "react-cookies"
import ReactBSAlert from "react-bootstrap-sweetalert"
import Loader from "react-loader-spinner"
import LoadingOverlay from "react-loading-overlay"
import Select from "react-select"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Label,
    Form,
    Input,
    Row,
    Col,
    Checkbox
} from "reactstrap"
import { baseUrl } from "../../Shared/baseUrl"

var dropDown

class AddProductPricing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // Add Product Price Form 
            alert: null,
            loader:null,
            selectedproduct_category:"",
            prod_Name: [],
            selectedproduct_name:"",
            regions: [],
            selectedregion:"",
            price: "",
            dropDown1array:[],
            regionDrop1:false,
            permissions:[],
            Roles_Permissions:[],
            productCategory:[],
            checked:false,

     
        }
        this.successAlert = this.successAlert.bind(this)
        this.onAddRolesPress = this.onAddRolesPress.bind(this)
    }

  handleCheckboxChange = event =>{
      this.setState({ checked: event.target.checked })
  }

  componentWillUnmount() {
      var id = window.setTimeout(null, 0)
      while (id--) {
          window.clearTimeout(id)
      }
  }
  onAddRolesPress  (){
      this.setState({ pressed: true })
  }

  componentDidMount(){
      
      var user = JSON.parse(window.localStorage.getItem("user"))
      var data = JSON.parse(user)
      var token = cookie.load("Token")

      fetch(baseUrl + "/pos/getProductCategory" ,{

        headers : { 
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
            "id": data.id
        },
        method:"Post",
          
    }).then((res)=>res.json()).then((jsonResponse)=>{
        console.log(jsonResponse, "Check Json")
        if(jsonResponse.success === true){
            this.setState({productCategory:jsonResponse.productCatg.map((props)=>{
                return{
                    value:props.productCat,
                    label:props.productCat
                }
            })})
        }
      
    }).catch(err =>{
        throw new Error(err)
    })

    fetch(baseUrl + "/auth/users/getRegion" ,{

        headers : { 
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
            "id": data.id
        },
        method:"Post",
          
    }).then((res)=>res.json()).then((jsonResponse)=>{
        console.log(jsonResponse, "Check Json")
        if(jsonResponse.success === true){
            this.setState({regions:jsonResponse.Regions.map((props)=>{
                return{
                    value:props.name,
                    label:props.name
                }
            })})
        }
      
    }).catch(err =>{
        throw new Error(err)
    })

  }
  successAlert()  {
      this.setState({
          alert: (
              <ReactBSAlert
                  success
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Success!"
                  confirmBtnBsStyle="success"
                  onConfirm={() => this.hideAlert()}
                  onCancel={() => this.hideAlert()}
                  btnSize="">
                Procuct Price Added Successfully !
              </ReactBSAlert>
          )
      })
  }
  hideAlert = () => {
      this.setState({
          alert: null
      })
  }
errorAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Error"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="danger"
                btnSize=""
            >
     Product's Price already exist !!!!!
       
            </ReactBSAlert>
        )
        
    })
    this.resetForm()
  
};
//changing product category
 ChangeProductCat =(event) => {
    this.setState({selectedproduct_category:event.label})
    if(event.label!=''){
        console.log(this.state.selectedproduct_category)
        var user = JSON.parse(window.localStorage.getItem("user"))
        var data = JSON.parse(user)
        var token = cookie.load("Token")

      let header = {
          userid:data.id,
          token:token,
          permissions:data.Permissions
      }

      fetch(baseUrl + "/pos/listProdNameByCatg",{
          
          headers : { 
              "Content-Type": "application/json",
              "Accept": "application/json",         
              "Authorization": "Bearer " + header.token,
              "id": header.userid,
              "reg": JSON.stringify(header.permissions)
          },
          method:"Post",
          body:JSON.stringify({
              prodCat:event.label
          })

         
        
      }).then((res)=>res.json()).then((jsonResponse)=>{
          this.setState({loader:null})
          if(jsonResponse.success === true){
              this.setState({prod_Name:jsonResponse.prodName.map((props)=>{
                  return{
                      value:props.productName,
                      label:props.productName
                  }
              })})
          }
          else {
              this.setState({loader:null})
             
          }
        
      }).catch(err =>{
          this.setState({loader:null})
     
      })
    }
  
  };

  //changing product name
  ChangeProductName =(event) => {
    this.setState({selectedproduct_name:event.label})
  
  };

  //changing the region
  ChangeRegion =(event) => {
    this.setState({selectedregion:event.label})
  
  }
  //Add Product Pricing Function
 addingProductPriceFunction = () => {
    var product = JSON.parse(window.localStorage.getItem("user"))
    var data = JSON.parse(product)
    // console.log(data)
    console.log(data.Permissions, "check")
    var token = cookie.load("Token")
    let details = {
          product_category:this.state.selectedproduct_category,
          product_name: this.state.selectedproduct_name,
          region: this.state.selectedregion,
          price: this.state.price
                  }
                  console.log(details)

     fetch(baseUrl + "/pos/AddProductPrice",{
      
         headers : { 
             "Content-Type": "application/json",
             "Accept": "application/json",
             "Authorization": "Bearer " + token,
             "id": data.id,
             "reg": JSON.stringify(data.Permissions)
         },
         method:"Post",
         body:JSON.stringify({
             product_category:details.product_category,
             product_name:details.product_name,
             region:details.region,
             price:details.price

         })
     }).then((res)=>res.json()).then((jsonResponse)=>{
         console.log(jsonResponse)
         this.setState({loader:null})
         if(jsonResponse.success === true){
             this.successAlert()
         }
         else {
             this.errorAlert()
         }
       
     }).catch(err =>{
         this.errorAlert()
     })
     this.resetForm()
 }
 
  resetForm(){
      this.setState({product_category:[]})
      this.setState({product_name:[]})
      this.setState({region:[]})
      this.setState({price:[""]})
      this.setState({pressed:false})

  }
  handleChange =(event) => {
    
    const target = event.target
    const value =  target.value
    const name = target.name

    this.setState({
        [name]: value
    })
   
}
  render() {
      return (
        
      <>
        <div className="content">
            {this.state.alert}
       
            {this.state.loader}
            <Row >
                <Col className="mr-auto" md={{size:7,offset:2}}>
          
                    <Form>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h3">Add Product Pricing Form</CardTitle>
                            </CardHeader>
                            <CardBody>
                            <FormGroup>
                                        <div>
                                            <Row>
                                                <Col xs={2}>
                                                    <Label>Product Category</Label>
                                                    </Col>
                                                    <Col xs={3}>
                                                    <Select
                                                         onChange={this.ChangeProductCat}
                                                         options={this.state.productCategory}
                                                         value={this.state.value}
                                                    />
                                                </Col>  
                                                <Col xs={2}>
                                                    <Label>Product Name</Label>
                                                    </Col>
                                                    <Col xs={3}>
                                                    <Select
                                                    onChange={this.ChangeProductName}
                                                    options={this.state.prod_Name}
                                                    value={this.state.value}
                                                    />
                                                </Col>  
                                                </Row><br></br>
                                                <Row>
                                                <Col xs={2}>
                                                    <Label>Region</Label>
                                                    </Col>
                                                    <Col xs={8}>
                                                   <Select
                                                    onChange={this.ChangeRegion}
                                                    options={this.state.regions}
                                                    value={this.state.value}
                                                   />                           
                                                </Col>    
                                                </Row><br></br>
                                                <Row>
                                                <Col xs={2}>
                                                    <Label>Product Price</Label>
                                                    </Col>
                                                    <Col xs={2}>
                                                    <Input    style={{width: 395, height: 40}} type="text" name="price" onChange={this.handleChange}  value={this.state.price}/>
                                                    {/* <Select></Select> */}
                                                </Col>    
                                                </Row>
                                        </div>
                                </FormGroup>
                            </CardBody>
                            <CardFooter className="text-right">
                                <Button color="primary"     
                                    onClick={this.addingProductPriceFunction}>
                                    {/* // disabled={!this.validateForm()}> */}
                                 Add Price
                                </Button>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
          
            </Row>
        </div>
      </>
      )
  }
}

export default AddProductPricing
