/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Dropzone from './Dropzone';
import {
	GoogleMap,
	withScriptjs,
	withGoogleMap,
	InfoWindow,
	Marker,
} from 'react-google-maps';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
import { connect } from 'tls';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Label,
	Input,
	Row,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { baseUrl } from '../../Shared/baseUrl';

//OLD Geocode KEY
//AIzaSyDwAK-sOdqlntKEtQfGcyPRCjaK1mH3yPE
Geocode.setApiKey(window._env_.REACT_APP_GOOGLE_MAP_API_KEY);
Geocode.enableDebug();
var dropDown;
var globalState;
let AsyncMap;

const initialState = {
	name: '',
	father_name: '',
	cnic: '',
	dob: '1990-12-31',
	phone: '',
	landline: '',
	slaesPoints: [],
	customer_type: [
		{ label: 'AIT', value: 'ait' },
		{ label: 'FARM', value: 'farm' },
		{ label: 'DIST', value: 'dist' },
	],
	customer_status: [
		{ label: 'Lead', value: 'lead' },
		{ label: 'Prospect', value: 'prospect' },
		{ label: 'Onboard', value: 'onboard' },
	],
	tehsil: '',
	village: '',
	region: '',
	alert: null,
	loader: null,
	files: null,
	uploading: false,
	uploadProgress: {},
	successfullUploaded: false,
	selectedCustomer_type: '',
	selectedCustomer_status: '',
	selectedRegion: '',
	allowedRegions: [],
	pressed: false,
	repName: '',
	image: '',
	repRelation: '',
	customerRep: [],
	selectedSalesPoints: '',
	address: '',
	city: '',
	area: '',
	state: '',
	newLat: '31.484269618543166',
	newLng: '74.3263890899658',
	phoneVerified: false,
	phoneVerifiedText: true,
	cnicVerified: true,
	cnicVerifiedText: true,
	addressArray: [],
	center: {
		lat: 31.484269618543166,
		lng: 74.3263890899658,
	},
	mapPosition: {
		lat: 31.484269618543166,
		lng: 74.3263890899658,
	},
	markerPosition: {
		lat: 31.484269618543166,
		lng: 74.3263890899658,
	},
};

class AddCustomer extends React.Component {
	constructor(props) {
		super(props);

		this.state = initialState;

		this.onAddRolesPress = this.onAddRolesPress.bind(this);
		this.successAlert = this.successAlert.bind(this);
		this.onFilesAdded = this.onFilesAdded.bind(this);
	}

	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}

	componentWillMount() {
		AsyncMap = withScriptjs(
			withGoogleMap((props) => (
				<GoogleMap
					google={this.props.google}
					defaultZoom={15}
					defaultCenter={{
						lat: this.state.mapPosition.lat,
						lng: this.state.mapPosition.lng,
					}}>
					<InfoWindow
						onClose={this.onInfoWindowClose}
						position={{
							lat: this.state.markerPosition.lat + 0.001,
							lng: this.state.markerPosition.lng,
						}}>
						<div>
							<span style={{ padding: 0, margin: 0 }}>
								{this.state.address}
							</span>
						</div>
					</InfoWindow>
					<Marker
						google={this.props.google}
						draggable={true}
						onDragEnd={this.onMarkerDragEnd}
						position={{
							lat: this.state.markerPosition.lat,
							lng: this.state.markerPosition.lng,
						}}
					/>
					<Marker />

					<Autocomplete
						style={{
							width: '100%',
							height: '40px',
							paddingLeft: '16px',
							marginTop: '2px',
							marginBottom: '100px',
						}}
						onPlaceSelected={this.onPlaceSelected}
						types={['(regions)']}
					/>
				</GoogleMap>
			)),
		);
	}

	componentDidMount() {
		Geocode.fromLatLng(
			this.state.mapPosition.lat,
			this.state.mapPosition.lng,
		).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);
				this.setState({
					address: address ? address : '',
					area: area ? area : '',
					city: city ? city : '',
					state: state ? state : '',
				});
			},
			(error) => {
				console.error(error);
			},
		);
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		if (data.permissions !== null) {
			const permissions = data.Permissions;
			const getRoleId = (Permissions) => Permissions.regionCode;
			const getRoleIdArray = (permissions) => permissions.map(getRoleId);
			const regions = getRoleIdArray(permissions);

			var regionCodes = regions.filter(function (el) {
				return el !== undefined;
			});

			const getNameId = (Permissions) => Permissions.regionName;
			const getNameIdArray = (permissions) => permissions.map(getNameId);
			const regionsname = getNameIdArray(permissions);
			var regionName = regionsname.filter(function (el) {
				return el !== undefined;
			});

			var regionsJson = [];

			for (var i in regionCodes) {
				var code = regionCodes[i];
				var label = regionName[i];

				regionsJson.push({
					code: code,
					label: label,
				});
			}
			var result = [
				...new Map(regionsJson.map((o) => [JSON.stringify(o), o])).values(),
			];

			var user = JSON.parse(window.localStorage.getItem('user'));
			var data = JSON.parse(user);
			var token = cookie.load('Token');
			let details = {
				userid: data.id,
				token: token,
				permissions: data.Permissions,
			};

			fetch(`${baseUrl}/auth/users/getRegion`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + details.token,
					id: details.userid,
					reg: JSON.stringify(details.permissions),
				},
				method: 'Post',
			})
				.then((response) => response.json())
				.then((jsonResponse) => {
					if (jsonResponse.success) {
						jsonResponse.Regions.map((region) => {
							result.map((allowedRegion) => {
								if (allowedRegion.code === region.code) {
									this.setState({
										allowedRegions: [
											...this.state.allowedRegions,
											{ label: region.name, value: region.id },
										],
									});
								}
							});
						});
					}
				});
		}
	}
	dataAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''>
					Make sure you filled name, phone number, customer status and customer
					type
				</ReactBSAlert>
			),
		});
	};
	cnicRegexAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''>
					Make sure the CNIC format is correct
				</ReactBSAlert>
			),
		});
	};
	settingStates = () => {
		this.setState({
			center: { lat: 30.37532, lng: 69.345116 },
			mapPosition: {
				lat: this.state.center.lat,
				lng: this.state.center.lng,
			},
			markerPosition: {
				lat: this.state.center.lat,
				lng: this.state.center.lng,
			},
			height: '300px',
		});
	};
	onAddRolesPress() {
		this.setState({ pressed: true });
	}
	validateForm() {
		// return this.state.name.length > 0 && this.state.address.length > 5
	}
	successAlert() {
		this.resetForm();

		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''>
					Customer Added Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''>
					{message ? message : 'Something Went Wrong !!!!!'}
				</ReactBSAlert>
			),
		});
	};
	handlesalesCahnge = (selectedOptions) => {
		this.setState({ selectedSalesPoints: selectedOptions });
	};
	// shouldComponentUpdate( nextProps, nextState ){
	//     if (
	//      this.state.markerPosition.lat !== this.state.center.lat ||
	//      this.state.address !== nextState.address ||
	//      this.state.city !== nextState.city ||
	//      this.state.area !== nextState.area ||
	//      this.state.state !== nextState.state

	//     ) {
	//      return true
	//     } else if ( this.state.center.lat === nextProps.center.lat ){
	//      return false
	//     }
	//    }
	getCity = (addressArray) => {
		let city = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				'administrative_area_level_2' === addressArray[i].types[0]
			) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	getArea = (addressArray) => {
		let area = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if (
						'sublocality_level_1' === addressArray[i].types[j] ||
						'locality' === addressArray[i].types[j]
					) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};
	getState = (addressArray) => {
		let state = '';
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (
					addressArray[i].types[0] &&
					'administrative_area_level_1' === addressArray[i].types[0]
				) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};
	onInfoWindowClose = (event) => { };
	onMarkerDragEnd = (event) => {
		this.setState({ newLat: event.latLng.lat() });
		this.setState({ newLng: event.latLng.lng() });
		this.setState({
			markerPosition: { lat: event.latLng.lat(), lng: event.latLng.lng() },
		});
		Geocode.fromLatLng(this.state.newLat, this.state.newLng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);
				this.setState({
					address: address ? address : '',
					area: area ? area : '',
					city: city ? city : '',
					state: state ? state : '',
				});
			},
			(error) => {
				console.error(error);
			},
		);
	};
	addMapData = () => {
		console.log('checking new one');
	};
	verifyPhone = (event) => {
		var value = event.target.value;
		var phoneRex = /^((03)([0-9]{9}))$/;
		if (phoneRex.test(value)) {
			this.setState({ phoneVerified: true, phoneVerifiedText: true });
			return true;
		}

		this.setState({ phoneVerifiedText: false });
		return false;
	};
	verifyCnic = (event) => {
		var value = event.target.value;
		var cnicRex = /^((3[0-9]{4}-)([0-9]{7}-)([0-9]{1}))$/;
		if (cnicRex.test(value)) {
			this.setState({ cnicVerified: true, cnicVerifiedText: true });
			return true;
		}

		this.setState({ cnicVerifiedText: false });
		return false;
	};
	validateForm() {
		return (
			this.state.phoneVerified &&
			this.state.phoneVerifiedText &&
			this.state.cnicVerifiedText
		);
	}
	onPlaceSelected = (place) => {
		const address = place.formatted_address,
			addressArray = place.address_components,
			city = this.getCity(addressArray),
			area = this.getArea(addressArray),
			state = this.getState(addressArray),
			//    globalState=this.getCity(addressArray),
			//    console.log(globalState,"Checking Global state"),
			latValue = place.geometry.location.lat(),
			lngValue = place.geometry.location.lng();

		this.setState({
			address: address ? address : '',
			area: area ? area : '',
			city: city ? city : '',
			state: state ? state : '',
			markerPosition: {
				lat: latValue,
				lng: lngValue,
			},
			mapPosition: {
				lat: latValue,
				lng: lngValue,
			},
		});
		this.state.globalState = this.state.city;
	};
	addingCustomerFunction = (details) => {
		this.setState({
			loader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: '250px',
							marginLeft: '-80px',
						}),
					}}
					active={true}
					spinner={
						<Loader type='CradleLoader' height='100' width='100' />
					}></LoadingOverlay>
			),
		});
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		let header = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};
		if (this.checked != false) {
			//  console.log(details, "Yellow")
			//  console.log(this.state.files, "hello")
			//  const file = new FormData();
			//  file.append('file', this.state.files, this.state.files.name);
			//  data.append('filename', this.fileName.value);
			const formData = new FormData();
			formData.append('myImage', this.state.files);

			if (this.state.files != null) {
				if (
					details.name === '' ||
					details.phone === '' ||
					details.customer_status === '' ||
					details.customer_type === ''
				) {
					this.setState({ loader: null });
					this.dataAlert();
				} else if (this.state.cnicVerified === false && details.cnic != '') {
					this.setState({ loader: null });
					this.cnicRegexAlert();
				} else {
					fetch(baseUrl + '/crm/uploadImg', {
						headers: {
							Authorization: 'Bearer ' + header.token,
							id: header.userid,
							reg: JSON.stringify(header.permissions),
						},
						method: 'Post',
						body: formData,
					})
						.then((res) => res.json())
						.then((jsonResponse) => {
							if (jsonResponse.success === true) {
								console.log(details, 'check1');
								fetch(baseUrl + '/crm/addCustomer', {
									headers: {
										'Content-Type': 'application/json',
										Accept: 'application/json',
										Authorization: 'Bearer ' + header.token,
										id: header.userid,
										reg: JSON.stringify(header.permissions),
									},
									method: 'Post',

									body: JSON.stringify({
										name: details.name,
										father_name: details.father_name,
										cnic: details.cnic,
										dob: details.dob,
										phone: details.phone,
										landline: details.landline,
										tehsil: details.tehsil,
										village: details.village,
										regionId: details.region,
										customer_status: details.customer_status,
										customer_type: details.customer_type,
										customerRep: details.customerRep,
										salespoint: details.salespoint,
										image: jsonResponse.data,
										newLat: this.state.newLat,
										newLng: this.state.newLng,
									}),
								})
									.then((res) => res.json())
									.then((jsonResponse) => {
										this.setState({ loader: null });
										if (jsonResponse.success === true) {
											this.successAlert();
											this.componentDidMount();
										} else {
											this.setState({ loader: null });
											this.errorAlert(jsonResponse.message);
										}
									})
									.catch((err) => {
										this.setState({ loader: null });
										this.errorAlert();
									});
							}
						})
						.catch((error) => { });
				}
			} else {
				if (
					details.name === '' ||
					details.phone === '' ||
					details.customer_status === '' ||
					details.customer_type === ''
				) {
					this.setState({ loader: null });
					this.dataAlert();
				} else if (this.state.cnicVerified === false && details.cnic != '') {
					this.setState({ loader: null });
					this.cnicRegexAlert();
				} else {
					fetch(baseUrl + '/crm/addCustomer', {
						headers: {
							'Content-Type': 'application/json',
							Accept: 'application/json',
							Authorization: 'Bearer ' + header.token,
							id: header.userid,
							reg: JSON.stringify(header.permissions),
						},
						method: 'Post',

						body: JSON.stringify({
							name: details.name,
							father_name: details.father_name,
							cnic: details.cnic,
							dob: details.dob,
							phone: details.phone,
							landline: details.landline,
							tehsil: details.tehsil,
							village: details.village,
							regionId: details.region,
							customer_status: details.customer_status,
							customer_type: details.customer_type,
							customerRep: details.customerRep,
							salespoint: details.salespoint,
							city: details.city,
							area: details.area,
							state: details.state,
							address: details.address,
							newLat: this.state.newLat,
							newLng: this.state.newLng,
						}),
					})
						.then((res) => res.json())
						.then((jsonResponse) => {
							this.setState({ loader: null });
							if (jsonResponse.success === true) {
								this.successAlert();
								this.componentDidMount();
							} else {
								this.setState({ loader: null });
								this.errorAlert(jsonResponse.message);
							}
						})
						.catch((err) => {
							this.setState({ loader: null });
							this.errorAlert();
						});
				}
			}
		}
	};
	resetForm() {
		this.setState(initialState);
	}

	handleSubmit = (event) => {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		console.log(this.state, 'check1');
		let details = {
			userid: data.id,
			name: this.state.name,
			father_name: this.state.father_name,
			cnic: this.state.cnic,
			dob: this.state.dob,
			phone: this.state.phone,
			landline: this.state.landline,
			tehsil: this.state.tehsil,
			village: this.state.village,
			region: this.state.selectedRegion.value,
			customer_status: this.state.selectedCustomer_status.label,
			customer_type: this.state.selectedCustomer_type.label,
			token: token,
			Permissions: data.Permissions,
			customerRep: this.state.customerRep,
			salespoint: this.state.selectedSalesPoints.value,
			city: this.state.city,
			area: this.state.area,
			state: this.state.state,
			address: this.state.address,
			newLat: this.state.newLat,
			newLng: this.state.newLng,
		};

		this.addingCustomerFunction(details);
	};

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};
	Change = (event) => {
		this.setState({ selectedCustomer_type: event });
	};
	handleRemoveShareholder = (idx) => () => {
		this.setState({
			customerRep: this.state.customerRep.filter((s) => idx !== s.repName),
		});
	};
	ChangeStatus = (event) => {
		this.setState({ selectedCustomer_status: event });
	};
	ChangeRegion = (event) => {
		this.setState({ selectedRegion: event });

		if (event.label != '') {
			var user = JSON.parse(window.localStorage.getItem('user'));
			var data = JSON.parse(user);
			var token = cookie.load('Token');

			let header = {
				userid: data.id,
				token: token,
				permissions: data.Permissions,
			};

			fetch(baseUrl + '/routing/listSalePointsByRegion', {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + header.token,
					id: header.userid,
					reg: JSON.stringify(header.permissions),
				},
				method: 'Post',
				body: JSON.stringify({
					regionId: event.value,
				}),
			})
				.then((res) => res.json())
				.then((jsonResponse) => {
					this.setState({ loader: null });
					if (jsonResponse.success === true) {
						this.setState({
							slaesPoints: jsonResponse.salepoints.map((props) => {
								return {
									value: props.id,
									label: props.name,
								};
							}),
						});
					} else {
						this.setState({ loader: null });
					}
				})
				.catch((err) => {
					this.setState({ loader: null });
				});
		}
	};
	onFilesAdded(event) {
		this.setState({
			files: event.target.files[0],
		});
	}
	savePressed = () => {
		let inserData = {
			repName: this.state.repName,
			repRelation: this.state.repRelation,
		};
		this.setState({ customerRep: this.state.customerRep.concat(inserData) });

		this.setState({ pressed: false });
		this.setState({ repName: '' });
		this.setState({ repRelation: '' });
		dropDown = null;
	};

	render() {
		let map;
		if (this.state.center.lat !== undefined) {
			map = (
				<div>
					<div>
						<div className='form-group'>
							<label htmlFor=''>City</label>
							<input
								type='text'
								name='city'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.city}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor=''>Area</label>
							<input
								type='text'
								name='area'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.area}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor=''>State</label>
							<input
								type='text'
								name='state'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.state}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor=''>Address</label>
							<input
								type='text'
								name='address'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.address}
							/>
						</div>
					</div>
					<AsyncMap
						googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${window._env_.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&v=3`}
						loadingElement={<div style={{ height: '100%' }} />}
						containerElement={<div style={{ height: '300px' }} />}
						mapElement={<div style={{ height: '100%' }} />}
					/>
				</div>
			);
		} else {
			map = <div style={{ height: '300px' }} />;
		}
		if (this.state.pressed == true) {
			dropDown = (
				<>
					<Row>
						<Col md={4}>
							<label md={2}>Name </label>
							<Input
								type='text'
								name='repName'
								onChange={this.handleChange}
								value={this.state.repName}
							/>
						</Col>
						<Col md={4}>
							<label md={2}>Relation </label>
							<Input
								type='text'
								name='repRelation'
								onChange={this.handleChange}
								value={this.state.repRelation}
							/>
						</Col>

						<Col md={4}>
							<Button
								style={{ background: 'transparent', marginTop: 21 }}
								onClick={this.savePressed}>
								Save
							</Button>
						</Col>
					</Row>
				</>
			);
		}

		return (
			<>
				<div className='content'>
					{this.state.alert}

					{this.state.loader}
					<Row>
						<Col className='mr-auto' md={{ size: 6, offset: 3 }}>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h3'>Add New Customer Form</CardTitle>
									</CardHeader>
									<CardBody>
										<Row>
											<Col md={6}>
												<FormGroup>
													<label>Name </label>
													<Input
														type='text'
														name='name'
														onChange={this.handleChange}
														value={this.state.name}
													/>
												</FormGroup>
												<FormGroup>
													<label>Father Name </label>
													<Input
														type='text'
														name='father_name'
														onChange={this.handleChange}
														value={this.state.father_name}
													/>
												</FormGroup>
												<FormGroup>
													<label>CNIC / NTN</label>
													<Input
														type='number'
														name='cnic'
														placeholder='xxxxxxxxxxxxx'
														onChange={(e) => {
															this.handleChange(e);
															// this.verifyCnic(e);
														}}
														value={this.state.cnic}
													/>
													{!this.state.cnicVerifiedText && (
														<span className='text-danger ml-2'>
															Invalid CNIC format
														</span>
													)}
												</FormGroup>
												<FormGroup>
													<label>Landline</label>
													<Input
														type='number'
														name='landline'
														onChange={(e) => {
															this.handleChange(e);
															// this.verifyCnic(e);
														}}
														value={this.state.landline}
													/>
												</FormGroup>
											</Col>
											<Col md={6}>
												<div>
													<input
														type='file'
														style={{ marginTop: 30 }}
														name='myImage'
														onChange={this.onFilesAdded}
													/>
													{/* <input type="file" name="myImage" onChange={this.onFilesAdded}/>
                                        {/* <input ref={(ref) => { this.uploadInput = ref; }} type="file" />
                                        <input ref={(ref) => { this.fileName = ref; }} type="text" placeholder="Enter the desired name of file" /> */}
													{/* <img src={this.state.files}/> */}
												</div>
											</Col>
										</Row>

										<Row>
											<Col md={6}>
												<FormGroup>
													<label>Phone </label>
													<Input
														type='tel'
														name='phone'
														maxLength='11'
														onChange={(e) => {
															this.handleChange(e);
															this.verifyPhone(e);
														}}
														value={this.state.phone}
													/>
													{!this.state.phoneVerifiedText && (
														<span className='text-danger ml-2'>
															Invalid Phone No. format
														</span>
													)}
												</FormGroup>
												<FormGroup>
													<label>Village </label>
													<Input
														type='text'
														name='village'
														onChange={this.handleChange}
														value={this.state.village}
													/>
												</FormGroup>
												<FormGroup className={'has-label'}>
													<label>Region </label>

													<Select
														onChange={this.ChangeRegion}
														options={this.state.allowedRegions}
														value={this.state.selectedRegion}></Select>
												</FormGroup>
												<FormGroup>
													<label>Customer Type</label>
													<Select
														onChange={this.Change}
														options={this.state.customer_type}
														value={this.state.selectedCustomer_type}
													/>
												</FormGroup>
											</Col>
											<Col md={6}>
												<FormGroup>
													<label>Date of Birth </label>
													<Input
														type='date'
														name='dob'
														onChange={this.handleChange}
														value={this.state.dob}
													/>
												</FormGroup>
												<FormGroup>
													<label>Tehsil </label>
													<Input
														type='text'
														name='tehsil'
														onChange={this.handleChange}
														value={this.state.tehsil}
													/>
												</FormGroup>
												<FormGroup>
													<label>Select Sale Point</label>
													<Select
														options={this.state.slaesPoints}
														onChange={this.handlesalesCahnge}
														value={this.state.selectedSalesPoints}></Select>
												</FormGroup>
												<FormGroup>
													<label>Customer Status </label>
													<Select
														onChange={this.ChangeStatus}
														options={this.state.customer_status}
														value={this.state.selectedCustomer_status}
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md={6}>
												<FormGroup>
													<Row>
														<Col xs={5}>
															<label>
																<h5>Add Representatives</h5>
															</label>
														</Col>
														<Col xs={{ size: 3, offset: 4 }}>
															<Button
																style={{ background: 'transparent' }}
																onClick={this.onAddRolesPress}>
																<span className='tim-icons icon-simple-add'></span>
															</Button>
														</Col>
													</Row>
												</FormGroup>

												<FormGroup>{dropDown}</FormGroup>
												<FormGroup>
													{this.state.customerRep.map((Role, idx) => (
														<div>
															<Row>
																<Col md='3' xs={{ size: 1, offset: 1 }}>
																	<label>Representative Name</label>
																	<input
																		placeholder={Role.repName}
																		value={Role.repName}
																	/>
																</Col>
															</Row>
															<Row>
																<Col md='3' xs={{ size: 1, offset: 1 }}>
																	<label>Representative Relation</label>
																	<input
																		placeholder={Role.repRelation}
																		value={Role.repRelation}
																	/>
																</Col>
															</Row>
															<Row>
																<Col md='3' xs={{ size: 1, offset: 1 }}>
																	<Button
																		style={{
																			marginTop: 24,
																			background: 'transparent',
																		}}
																		onClick={this.handleRemoveShareholder(
																			Role.repName,
																		)}>
																		✖
																	</Button>
																</Col>
															</Row>
														</div>
													))}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<FormGroup style={{ width: '94%', marginLeft: '22px' }}>
												{/* google={this.props.google} */}
												{map}
											</FormGroup>
											{/* <Col sm={12}>
                                        <div>

                                    <Map                                    
                                        google={this.props.google}
                                        center={{lat: 30.375320, lng: 69.345116}}
                                        height='300px'
                                        zoom={6}
                                        />
                                        </div>
                                    </Col> */}
										</Row>
									</CardBody>

									<CardFooter className='text-right'>
										<Button
											color='primary'
											onClick={this.handleSubmit}
											disabled={!this.validateForm()}>
											Add Customer
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default AddCustomer;
