import React, { useRef } from 'react';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import {
	GoogleMap,
	withScriptjs,
	withGoogleMap,
	InfoWindow,
	Marker,
	Polyline,
} from 'react-google-maps';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';

import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
} from 'reactstrap';
import { baseUrl } from '../../Shared/baseUrl';
Geocode.setApiKey(window._env_.REACT_APP_GOOGLE_MAP_API_KEY);
Geocode.enableDebug();
var dropDown;
var map;
var counter = 0;
let AsyncMap;

const initialState = {
	name: '',
	subRouteName: '',
	region: '',
	allowedRegions: [],
	salesPoints: [],
	selectedLatLong: [],
	selectedAllowedRegion: '',
	multiValue: null,
	selectedMultiValues: [],
	center: {
		lat: 31.484269618543166,
		lng: 74.3263890899658,
	},
	mapPosition: {
		lat: 31.484269618543166,
		lng: 74.3263890899658,
	},
	markerPosition: {
		lat: 31.484269618543166,
		lng: 74.3263890899658,
	},
	markers: [],
	selectedMarker: false,
	dbSalespoints: [],
	assignedSalespoints: [],
	assignedSalesCode: [],
	unassingedsalesPoints: [],
	parentRouteList: [],
	selectedParentRoute: null,
	selectedSalePoints: [],
};

class AddRoute extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.handleMultiChange = this.handleMultiChange.bind(this);
		this.successAlert = this.successAlert.bind(this);
		this.setState = this.setState.bind(this);
	}
	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}

	componentWillMount() {
		AsyncMap = withScriptjs(
			withGoogleMap((props) => (
				<GoogleMap
					google={this.props.google}
					defaultZoom={13}
					defaultCenter={{
						lat: this.state.mapPosition.lat,
						lng: this.state.mapPosition.lng,
					}}
				>
					{this.state.markers.map((marker, key) => {
						console.log('marker', marker);
						return (
							<Marker
								google={this.props.google}
								key={key}
								position={{
									lat: parseFloat(marker.lat),
									lng: parseFloat(marker.lng),
								}}
							/>
						);
					})}

					<Autocomplete
						style={{
							width: '100%',
							height: '40px',
							paddingLeft: '16px',
							marginTop: '2px',
							marginBottom: '100px',
						}}
						onPlaceSelected={this.onPlaceSelected}
						types={['(regions)']}
					/>
				</GoogleMap>
			)),
		);
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};

		if (data.permissions !== null) {
			const permissions = data.Permissions;
			const getRoleId = (Permissions) => Permissions.regionCode;
			const getRoleIdArray = (permissions) => permissions.map(getRoleId);
			const regions = getRoleIdArray(permissions);

			var regionCodes = regions.filter(function (el) {
				return el !== undefined;
			});

			const getNameId = (Permissions) => Permissions.regionName;
			const getNameIdArray = (permissions) => permissions.map(getNameId);
			const regionsname = getNameIdArray(permissions);
			var regionName = regionsname.filter(function (el) {
				return el !== undefined;
			});
			var regionsJson = [];

			for (var i in regionCodes) {
				var code = regionCodes[i];
				var label = regionName[i];

				regionsJson.push({
					code: code,
					label: label,
				});
			}
			var result = [
				...new Map(regionsJson.map((o) => [JSON.stringify(o), o])).values(),
			];

			var user = JSON.parse(window.localStorage.getItem('user'));
			var data = JSON.parse(user);
			var token = cookie.load('Token');
			let details = {
				userid: data.id,
				token: token,
				permissions: data.Permissions,
			};

			fetch(`${baseUrl}/auth/users/getRegion`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + details.token,
					id: details.userid,
					reg: JSON.stringify(details.permissions),
				},
				method: 'Post',
			})
				.then((response) => response.json())
				.then((jsonResponse) => {
					if (jsonResponse.success) {
						jsonResponse.Regions.map((region) => {
							result.map((allowedRegion) => {
								if (allowedRegion.code === region.code) {
									this.setState({
										allowedRegions: [
											...this.state.allowedRegions,
											{ label: region.name, value: region.id },
										],
									});
								}
							});
						});
					}
				});
		}

		fetch(`${baseUrl}/routing/get-parent-routes`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.userid,
				reg: JSON.stringify(details.permissions),
			},
			method: 'Get',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						parentRouteList: jsonResponse.response.map((parentRoute) => {
							return {
								label: parentRoute.name,
								value: parentRoute.id,
							};
						}),
					});
				} else {
					this.errorAlert(jsonResponse.message);
				}
			});
	}

	addingRoute() {
		this.setState({
			loader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: '250px',
							marginLeft: '-80px',
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			),
		});

		if (!this.state.selectedParentRoute) {
			this.setState({ loader: null });
			return this.errorAlert('Please select Route name');
		}

		this.state.multiValue &&
			this.state.multiValue.map((selectedMultiValue) => {
				this.state.selectedMultiValues.push({
					lat: selectedMultiValue.value.lat,
					lng: selectedMultiValue.value.lng,
					address: selectedMultiValue.value.address,
					name: selectedMultiValue.value.name,
					code: selectedMultiValue.value.code,
				});
			});

		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};
		fetch(baseUrl + '/routing/addRoute', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.userid,
				reg: JSON.stringify(details.permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				name: this.state.name,
				regionId: this.state.selectedAllowedRegion.value,
				salePoints: this.state.selectedMultiValues,
				parentRouteId: this.state.selectedParentRoute.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success) {
					this.successAlert();
					this.resetForm();
					this.componentWillMount();
					this.componentDidMount();
				} else {
					this.setState({ loader: null });
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert();
			});
	}

	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					Route Added Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : `Route Could not be Added !!!!`}
				</ReactBSAlert>
			),
		});
	};

	drawPolyline() {
		console.log('Poly Line function is on call');
	}

	resetForm() {
		this.setState(initialState);
	}

	handleSubmit = (event) => {
		this.addingRoute();
	};

	ChangeStatus = (event) => {
		this.setState({
			selectedAllowedRegion: event,
			assignedSalesCode: [],
			salesPoints: [],
			markers: [],
			unassingedsalesPoints: [],
		});
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};
		fetch(baseUrl + '/routing/getAssignedSalesPointsByRegion', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.userid,
				reg: JSON.stringify(details.permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				regionId: event.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					jsonResponse.salepoints.map((prop) => {
						JSON.parse(prop.salesPoints).map((props) => {
							this.state.assignedSalesCode.push({
								code: props.code,
							});
						});
					});
				}
			})
			.catch((err) => {
				this.errorAlert();
			});
		fetch(baseUrl + '/routing/getSalesPointsByRegion', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.userid,
				reg: JSON.stringify(details.permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				regionId: event.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					jsonResponse.salepoints.map((props) => {
						let i = 0;
						this.state.assignedSalesCode.map((prop) => {
							// console.log(props.code,"All codes")
							if (prop.code === props.code) {
								i = i + 1;
							}
						});
						if (i === 0) {
							this.state.unassingedsalesPoints.push({
								long: props.long,
								lat: props.lat,
								address: props.address,
								name: props.name,
								code: props.code,
							});
							console.log(
								this.state.unassingedsalesPoints,
								'sales point not assigned',
							);
						}
					});
					this.setState({
						salesPoints: this.state.unassingedsalesPoints.map((prop) => {
							return {
								value: {
									lng: prop.long,
									lat: prop.lat,
									address: prop.address,
									name: prop.name,
									code: prop.code,
								},
								label: prop.name,
							};
						}),
					});
					this.setState({
						selectedLatLong: this.state.unassingedsalesPoints.map(
							(prop, key) => {
								return {
									lat: prop.lat,
									long: prop.long,
								};
							},
						),
					});
					// this.setState({
					// 	markers: this.state.unassingedsalesPoints.map((prop, key) => {
					// 		return {
					// 			lat: prop.lat,
					// 			lng: prop.long,
					// 		};
					// 	}),
					// });
				}
			})
			.catch((err) => {
				this.errorAlert();
			});
	};

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	handleMultiChange(options) {
		this.setState({
			markers: options.map((option) => {
				return {
					lat: option.value.lat,
					lng: option.value.lng,
				};
			}),
		});

		this.setState({
			selectedSalePoints: options,
		});

		this.setState((state) => {
			return {
				multiValue: options,
			};
		});
	}

	handleParentRouteChange = (event) => {
		this.setState({ selectedParentRoute: event });
	};

	render() {
		let map;
		if (this.state.center.lat !== undefined) {
			map = (
				<div>
					<AsyncMap
						googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${window._env_.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&v=3`}
						loadingElement={<div style={{ height: `100%` }} />}
						containerElement={<div style={{ height: '300px' }} />}
						mapElement={<div style={{ height: `100%` }} />}
					/>
				</div>
			);
		} else {
			map = <div style={{ height: '300px' }} />;
		}

		return (
			<>
				<div className='content'>
					{this.state.alert}

					{this.state.loader}
					<Row>
						<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h4'>Add Sub Route</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup className={'has-label'}>
											<label> Route Name *</label>
											<Select
												type='text'
												options={this.state.parentRouteList}
												onChange={this.handleParentRouteChange}
												value={this.state.selectedParentRoute}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Sub-Route Name *</label>
											<Input
												type='text'
												name='name'
												onChange={this.handleChange}
												value={this.state.name}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Region Name *</label>
											<Select
												onChange={this.ChangeStatus}
												options={this.state.allowedRegions}
												value={this.state.selectedAllowedRegion}
												placeholder='Select Region'
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Add Sales Points *</label>
											<Select
												name='salespointlist'
												isMulti
												onChange={this.handleMultiChange}
												options={this.state.salesPoints}
												value={this.state.selectedSalePoints}
												placeholder='Select the sales points'
											/>
										</FormGroup>
										<FormGroup
											className={'has-label'}
											style={{ width: '94%', marginLeft: '22px' }}
										>
											{map}
										</FormGroup>
									</CardBody>
									<CardFooter className='text-right'>
										<Button color='primary' onClick={this.handleSubmit}>
											Add Sub Route
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default AddRoute;
