import React from 'react';
import {
	Input,
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Row,
	Col,
} from 'reactstrap';
import { baseUrl } from '../../Shared/baseUrl';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import ReactTable from 'react-table';
import {
	GoogleMap,
	withScriptjs,
	withGoogleMap,
	InfoWindow,
	Marker,
} from 'react-google-maps';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';

let AsyncMap;

const initialState = {
	startTime: '',
	endTime: '',
	fieldSupervisor: '',
	subRoute: '',
	center: {
		lat: 31.484269618543166,
		lng: 74.3263890899658,
	},
	mapPosition: {
		lat: 31.484269618543166,
		lng: 74.3263890899658,
	},
	markerPosition: {
		lat: 31.484269618543166,
		lng: 74.3263890899658,
	},
};

const user = JSON.parse(window.localStorage.getItem('user'));
const data = JSON.parse(user);
const token = cookie.load('Token');

class ViewPreviousTrip extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentWillMount() {
		AsyncMap = withScriptjs(
			withGoogleMap((props) => (
				<GoogleMap
					google={this.props.google}
					defaultZoom={15}
					defaultCenter={{
						lat: this.state.mapPosition.lat,
						lng: this.state.mapPosition.lng,
					}}
				>
					<InfoWindow
						onClose={this.onInfoWindowClose}
						position={{
							lat: this.state.markerPosition.lat + 0.001,
							lng: this.state.markerPosition.lng,
						}}
					>
						<div>
							<span style={{ padding: 0, margin: 0 }}>
								{this.state.address}
							</span>
						</div>
					</InfoWindow>
					<Marker
						google={this.props.google}
						draggable={true}
						onDragEnd={this.onMarkerDragEnd}
						position={{
							lat: this.state.markerPosition.lat,
							lng: this.state.markerPosition.lng,
						}}
					/>
					<Marker />

					<Autocomplete
						style={{
							width: '100%',
							height: '40px',
							paddingLeft: '16px',
							marginTop: '2px',
							marginBottom: '100px',
						}}
						onPlaceSelected={this.onPlaceSelected}
						types={['(regions)']}
					/>
				</GoogleMap>
			)),
		);
	}

	componentDidMount() {
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer ' + token,
			id: data.id,
			reg: JSON.stringify(data.Permissions),
		};
		
		fetch(
			`${baseUrl}/routing/get-previous-trip/${this.props.match.params.id}`,
			{
				headers: headers,
				method: 'Get',
			},
		)
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					fetch(
						`${baseUrl}/auth/users/getUserDetail/${jsonResponse.previousTrip.fsId}`,
						{
							headers: headers,
							method: 'Post',
						},
					)
						.then((response) => response.json())
						.then((jsonResponse) => {
							if (jsonResponse.success) {
								this.setState({ fieldSupervisor: jsonResponse.User.username });
							}
						});

					this.setState({
						subRoute: jsonResponse.previousTrip.subRoute.name,
						startTime: jsonResponse.previousTrip.startTime,
						endTime: jsonResponse.previousTrip.endTime,
					});
				}
			});
	}

	render() {
		return (
			<div className='content'>
				<h1>Previous Trip</h1>
				<Row>
					<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
						<Card>
							<CardHeader>
								<CardTitle tag='h4'>Trip Details</CardTitle>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md={6}>
										<label>
											Field Supervisor
											<Input
												className='form-control text-white'
												type='text'
												value={this.state.fieldSupervisor}
												readOnly
											/>
										</label>

										<label>
											Start Time
											<Input
												className='form-control text-white'
												type='text'
												value={this.state.startTime}
												readOnly
											/>
										</label>
									</Col>

									<Col md={6}>
										<label>
											Sub Route
											<Input
												className='form-control text-white'
												type='text'
												value={this.state.subRoute}
												readOnly
											/>
										</label>

										<label>
											End Time
											<Input
												className='form-control text-white'
												type='text'
												value={this.state.endTime}
												readOnly
											/>
										</label>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<AsyncMap
									googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${window._env_.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&v=3`}
									loadingElement={<div style={{ height: '100%' }} />}
									containerElement={<div style={{ height: '300px' }} />}
									mapElement={<div style={{ height: '100%' }} />}
								/>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

export default ViewPreviousTrip;
