/* eslint-disable no-cond-assign */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
} from 'reactstrap';
import { baseUrl } from '../../Shared/baseUrl';

var dropDown;

const initialState = {
	productCategory: [],
	selectedProductCategory: '',
	prod_Name: [],
	selectedProdName: '',
	regions: [],
	selectedRegion: '',
	parentCategory: [
		{ label: 'Imported', value: 'Imported' },
		{ label: 'Local', value: 'Local' },
	],
	offerType: [
		{ label: 'Volume', value: 'Volume' },
		{ label: 'Percentage', value: 'Percentage' },
	],
	selectedOfferType: '',
	selectedparentCategory: '',
	offer: '',
	startDate: '',
	endDate: '',
	data: null,
};
class proPromo extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.successAlert = this.successAlert.bind(this);
	}

	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/auth/users/getRegion', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				console.log(jsonResponse, 'Check Json');
				if (jsonResponse.success === true) {
					this.setState({
						regions: jsonResponse.Regions.map((props) => {
							return {
								value: props.id,
								label: props.name,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}

	addingProductPromotion() {
		this.setState({
			loader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: '250px',
							marginLeft: '-80px',
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			),
		});
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			productId: this.state.selectedProdName.value,
			regionId: this.state.selectedRegion.value,
			offerType: this.state.selectedOfferType.value,
			pOffer: this.state.offer,
			sDate: this.state.startDate,
			eDate: this.state.endDate,
		};

		fetch(baseUrl + '/pos/productPromotions', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				productId: details.productId,
				regionId: details.regionId,
				offerType: details.offerType,
				pOffer: details.pOffer,
				sDate: details.sDate,
				eDate: details.eDate,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.successAlert();
					this.resetForm();
					this.componentDidMount();
				} else {
					this.setState({ loader: null });
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert();
			});
	}

	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					Product Offer Added Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : `Product Offer Could not be Added !!!!`}
				</ReactBSAlert>
			),
		});
	};
	offerExistAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					Product Offer already Exist!!!!
				</ReactBSAlert>
			),
		});
	};

	resetForm() {
		this.setState(initialState);
	}

	handleSubmit = (event) => {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		fetch(baseUrl + '/pos/getProductPromotionList', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						data: jsonResponse.pOffer.map((prop, key) => {
							//   console.log(this.formatDate(prop.startDate))
							return {
								db_prod_name: prop.productId,
								db_region: prop.regionId,
								db_eDate: prop.endDate,
							};
						}),
					});

					let counter = 0;

					for (let i = 0; i < this.state.data.length; i++) {
						if (
							this.state.data[i].db_prod_name ===
								this.state.selectedProdName.value &&
							this.state.data[i].db_region ===
								this.state.selectedRegion.value &&
							this.state.data[i].db_eDate !== ''
						) {
							counter = counter + 1;
						}
					}
					if (counter !== 0) {
						this.offerExistAlert();
					} else {
						this.addingProductPromotion();
					}
				}
			});
	};
	ChangeProductCat = (event) => {
		this.setState({ selectedProductCategory: event, selectedProdName: '' });

		if (event.label != '') {
			var user = JSON.parse(window.localStorage.getItem('user'));
			var data = JSON.parse(user);
			var token = cookie.load('Token');

			let header = {
				userid: data.id,
				token: token,
				permissions: data.Permissions,
			};

			fetch(baseUrl + '/pos/listProdNameByCatg', {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + header.token,
					id: header.userid,
					reg: JSON.stringify(header.permissions),
				},
				method: 'Post',
				body: JSON.stringify({
					prodCat: event.value,
				}),
			})
				.then((res) => res.json())
				.then((jsonResponse) => {
					this.setState({ loader: null });
					if (jsonResponse.success === true) {
						this.setState({
							prod_Name: jsonResponse.prodName.map((props) => {
								return {
									value: props.id,
									label: props.productName,
								};
							}),
						});
					} else {
						this.setState({ loader: null });
					}
				})
				.catch((err) => {
					this.setState({ loader: null });
				});
		}
	};

	ChangeParentCat = (event) => {
		this.setState({
			selectedparentCategory: event,
			selectedProductCategory: '',
			selectedProdName: '',
		});

		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/pos/getProductCategory', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				parentCat: event.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						productCategory: jsonResponse.productCatg.map((props) => {
							return {
								value: props.id,
								label: props.productCat,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	};

	ChangeProductName = (event) => {
		this.setState({ selectedProdName: event });
	};

	//changing the region
	ChangeRegion = (event) => {
		this.setState({ selectedRegion: event });
	};

	ChangeOfferType = (event) => {
		this.setState({ selectedOfferType: event });
	};
	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}

					{this.state.loader}
					<Row>
						<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h4'>Add Promotions</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup>
											<label>Main Category *</label>
											<Select
												onChange={this.ChangeParentCat}
												options={this.state.parentCategory}
												value={this.state.selectedparentCategory}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Product Category *</label>
											<Select
												onChange={this.ChangeProductCat}
												options={this.state.productCategory}
												value={this.state.selectedProductCategory}
											></Select>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Product *</label>

											<Select
												onChange={this.ChangeProductName}
												options={this.state.prod_Name}
												value={this.state.selectedProdName}
											></Select>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Region *</label>

											<Select
												onChange={this.ChangeRegion}
												options={this.state.regions}
												value={this.state.selectedRegion}
											></Select>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Offer Type *</label>

											<Select
												onChange={this.ChangeOfferType}
												options={this.state.offerType}
												value={this.state.selectedOfferType}
											></Select>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Offer *</label>

											<Input
												type='text'
												name='offer'
												onChange={this.handleChange}
												value={this.state.offer}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Starting Date *</label>
											<Input
												type='date'
												name='startDate'
												onChange={this.handleChange}
												value={this.state.startDate}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Ending Date *</label>
											<Input
												type='date'
												name='endDate'
												onChange={this.handleChange}
												value={this.state.endDate}
											/>
										</FormGroup>
									</CardBody>
									<CardFooter className='text-right'>
										<Button color='primary' onClick={this.handleSubmit}>
											Add Promotion
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default proPromo;
