/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import { baseUrl } from '../../Shared/baseUrl';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Switch from 'react-bootstrap-switch';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
	GoogleMap,
	withScriptjs,
	withGoogleMap,
	InfoWindow,
	Marker,
} from 'react-google-maps';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Button,
	Badge,
} from 'reactstrap';
Geocode.setApiKey(window._env_.REACT_APP_GOOGLE_MAP_API_KEY);
Geocode.enableDebug();
var counter = 0;
let AsyncMap;
class editRoute extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			code: '',
			region: '',
			allowedRegions: [],
			selectedAllowedRegion: '',
			loader: null,
			setLat: '',
			setLng: '',
			address: '',
			city: '',
			area: '',
			state: '',
			newLat: '',
			newLng: '',
			addressArray: [],
			data: null,
			center: {
				lat: 31.484269618543166,
				lng: 74.3263890899658,
			},
			mapPosition: {
				lat: 31.484269618543166,
				lng: 74.3263890899658,
			},
			markerPosition: {
				lat: 31.484269618543166,
				lng: 74.3263890899658,
			},
		};
		this.updateUser = this.updateUser.bind(this);

		this.successAlert = this.successAlert.bind(this);
	}
	hide() {
		this.setState({
			isActive: false,
		});
	}

	handleCheckboxChange = (event) => {
		this.setState({ checked: event.target.checked });
	};
	successAlert(message) {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					onCancel={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					btnSize=''
				>
					{message ? message : `Sales point Updated Successfully !`}
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	hideDeleteAlert = () => {
		this.setState({
			alert: null,
		});

		this.props.history.push('/admin/listSalePoints');
	};

	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : `Something went wrong`}
				</ReactBSAlert>
			),
		});
	};

	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}

	componentWillMount() {
		AsyncMap = withScriptjs(
			withGoogleMap((props) => (
				<GoogleMap
					google={this.props.google}
					defaultZoom={15}
					defaultCenter={{
						lat: this.state.mapPosition.lat,
						lng: this.state.mapPosition.lng,
					}}
				>
					<InfoWindow
						onClose={this.onInfoWindowClose}
						position={{
							lat: this.state.markerPosition.lat + 0.001,
							lng: this.state.markerPosition.lng,
						}}
					>
						<div>
							<span style={{ padding: 0, margin: 0 }}>
								{this.state.address}
							</span>
						</div>
					</InfoWindow>
					<Marker
						google={this.props.google}
						draggable={true}
						onDragEnd={this.onMarkerDragEnd}
						position={{
							lat: this.state.markerPosition.lat,
							lng: this.state.markerPosition.lng,
						}}
					/>
					<Marker />

					<Autocomplete
						style={{
							width: '100%',
							height: '40px',
							paddingLeft: '16px',
							marginTop: '2px',
							marginBottom: '100px',
						}}
						onPlaceSelected={this.onPlaceSelected}
						types={['(regions)']}
					/>
				</GoogleMap>
			)),
		);
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		if (data.permissions !== null) {
			const permissions = data.Permissions;
			const getRoleId = (Permissions) => Permissions.regionCode;
			const getRoleIdArray = (permissions) => permissions.map(getRoleId);
			const regions = getRoleIdArray(permissions);

			var regionCodes = regions.filter(function (el) {
				return el !== undefined;
			});

			const getNameId = (Permissions) => Permissions.regionName;
			const getNameIdArray = (permissions) => permissions.map(getNameId);
			const regionsname = getNameIdArray(permissions);
			var regionName = regionsname.filter(function (el) {
				return el !== undefined;
			});

			var regionsJson = [];

			for (var i in regionCodes) {
				var code = regionCodes[i];
				var label = regionName[i];

				regionsJson.push({
					code: code,
					label: label,
				});
			}
			var result = [
				...new Map(regionsJson.map((o) => [JSON.stringify(o), o])).values(),
			];

			var user = JSON.parse(window.localStorage.getItem('user'));
			var data = JSON.parse(user);
			var token = cookie.load('Token');
			let details = {
				userid: data.id,
				token: token,
				permissions: data.Permissions,
			};

			fetch(`${baseUrl}/auth/users/getRegion`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + details.token,
					id: details.userid,
					reg: JSON.stringify(details.permissions),
				},
				method: 'Post',
			})
				.then((response) => response.json())
				.then((jsonResponse) => {
					if (jsonResponse.success) {
						jsonResponse.Regions.map((region) => {
							result.map((allowedRegion) => {
								if (allowedRegion.code === region.code) {
									this.setState({
										allowedRegions: [
											...this.state.allowedRegions,
											{ label: region.name, value: region.id },
										],
									});
								}
							});
						});
					}
				});
		}

		let details = {
			token: cookie.load('Token'),
			id: data.id,
			permissions: data.Permissions,
		};

		fetch(baseUrl + '/routing/routeDetail/' + this.props.match.params.id, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(details.permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						name: jsonResponse.salepoints.name,
						code: jsonResponse.salepoints.code,
						selectedAllowedRegion: this.state.allowedRegions.find(
							(r) => r.value === jsonResponse.salepoints.regionId,
						),
						city: jsonResponse.salepoints.city,
						area: jsonResponse.salepoints.area,
						state: jsonResponse.salepoints.state,
						address: jsonResponse.salepoints.address,
						newLat: jsonResponse.salepoints.lat,
						newLng: jsonResponse.salepoints.long,
						markerPosition: {
							lat: parseFloat(jsonResponse.salepoints.lat),
							lng: parseFloat(jsonResponse.salepoints.long),
						},
					});
				}
			})
			.catch((err) => {
				// throw new Error(err)
			});
	}

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	};

	changeLatLong = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	};
	setMarker = (event) => {
		//  this.setState({newLat : this.state.setLat});
		//  this.setState({newLng : this.state.setLng});
		let addressArray = [];
		Geocode.fromLatLng(this.state.newLat, this.state.newLng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);

				this.setState({
					address: address ? address : '',
					area: area ? area : '',
					city: city ? city : '',
					state: state ? state : '',
					markerPosition: {
						lat: parseFloat(this.state.newLat),
						lng: parseFloat(this.state.newLng),
					},
					mapPosition: {
						lat: parseFloat(this.state.newLat),
						lng: parseFloat(this.state.newLng),
					},
					center: {
						lat: parseFloat(this.state.newLat),
						lng: parseFloat(this.state.newLng),
					},
				});
			},
			(error) => {
				console.error(error);
			},
		);
	};

	getCity = (addressArray) => {
		let city = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				'administrative_area_level_2' === addressArray[i].types[0]
			) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	getArea = (addressArray) => {
		let area = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if (
						'sublocality_level_1' === addressArray[i].types[j] ||
						'locality' === addressArray[i].types[j]
					) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};
	getState = (addressArray) => {
		let state = '';
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (
					addressArray[i].types[0] &&
					'administrative_area_level_1' === addressArray[i].types[0]
				) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};
	onInfoWindowClose = (event) => {};
	onMarkerDragEnd = (event) => {
		this.setState({ newLat: event.latLng.lat() });
		this.setState({ newLng: event.latLng.lng() });
		this.setState({
			markerPosition: { lat: event.latLng.lat(), lng: event.latLng.lng() },
		});
		let addressArray = [];
		Geocode.fromLatLng(this.state.newLat, this.state.newLng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);
				//    globalState=this.getCity(addressArray);
				//    console.log(globalState,"Checking Global state")
				this.setState({
					address: address ? address : '',
					area: area ? area : '',
					city: city ? city : '',
					state: state ? state : '',
					markerPosition: {
						lat: this.state.newLat,
						lng: this.state.newLng,
					},
					mapPosition: {
						lat: this.state.newLat,
						lng: this.state.newLng,
					},
					center: {
						lat: this.state.newLat,
						lng: this.state.newLng,
					},
				});
			},
			(error) => {
				console.error(error);
			},
		);
	};

	onPlaceSelected = (place) => {
		const address = place.formatted_address,
			addressArray = place.address_components,
			city = this.getCity(addressArray),
			area = this.getArea(addressArray),
			state = this.getState(addressArray),
			//    globalState=this.getCity(addressArray),
			//    console.log(globalState,"Checking Global state"),
			latValue = place.geometry.location.lat(),
			lngValue = place.geometry.location.lng();
		this.state.newLat = latValue;
		this.state.newLng = lngValue;
		this.setState({
			address: address ? address : '',
			area: area ? area : '',
			city: city ? city : '',
			state: state ? state : '',
			markerPosition: {
				lat: this.state.newLat,
				lng: this.state.newLng,
			},
			mapPosition: {
				lat: this.state.newLat,
				lng: this.state.newLng,
			},
			center: {
				lat: this.state.newLat,
				lng: this.state.newLng,
			},
		});
		this.state.globalState = this.state.city;
	};

	updateUser() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};
		fetch(baseUrl + '/routing/modifySalePoints/' + this.props.match.params.id, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.userid,
				reg: JSON.stringify(details.permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				name: this.state.name,
				code: this.state.code,
				city: this.state.city,
				area: this.state.area,
				state: this.state.state,
				address: this.state.address,
				newLat: this.state.newLat,
				newLng: this.state.newLng,
				regionId: this.state.selectedAllowedRegion.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.successAlert();
				} else {
					this.setState({ loader: null });
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert();
			});
	}

	handleCancelButton = () => {
		this.props.history.push('/admin/listSalePoints');
	};

	handleDeleteButton = () => {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};

		fetch(
			`${baseUrl}/routing/delete-sales-point/${this.props.match.params.id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + details.token,
					id: details.userid,
					reg: JSON.stringify(details.permissions),
				},
				method: 'Delete',
			},
		)
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert(jsonResponse.message);
				} else {
					this.errorAlert(jsonResponse.message);
				}
			});
	};

	handleSubmit = (event) => {
		counter = 0;
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		fetch(
			baseUrl +
				'/routing/listSalePointsWhereIdNot/' +
				this.props.match.params.id,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
					id: data.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: 'Post',
			},
		)
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						data: jsonResponse.salepoints.map((prop, key) => {
							return {
								db_code: prop.code,
							};
						}),
					});
					for (let i = 0; i < this.state.data.length; i++) {
						if (this.state.data[i].db_code === this.state.code) {
							counter = counter + 1;
						}
					}
					if (counter !== 0) {
						this.salePointCode();
					} else {
						this.updateUser();
					}
				}
			});
	};

	salePointRegex = (event) => {
		var value = event.target.value;
		var spRex = /^(([A-Z]{3,4})([0-9]{1,4}))$/;
		if (spRex.test(value)) {
			this.setState({ spVerified: true });
			return true;
		}
		return false;
	};
	validateForm() {
		return this.state.code;
	}
	salePointCode = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Erorr'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					Salepoint Code already Exists !
				</ReactBSAlert>
			),
		});
	};

	ChangeRegion = (event) => {
		this.setState({ selectedAllowedRegion: event });
	};

	render() {
		let map;
		if (this.state.center.lat !== undefined) {
			map = (
				<div>
					<div>
						<div className='form-group'>
							<label htmlFor=''>City</label>
							<input
								type='text'
								name='city'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.city}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor=''>Area</label>
							<input
								type='text'
								name='area'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.area}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor=''>State</label>
							<input
								type='text'
								name='state'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.state}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor=''>Address</label>
							<input
								type='text'
								name='address'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.address}
							/>
						</div>
					</div>
					<AsyncMap
						googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${window._env_.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&v=3`}
						loadingElement={<div style={{ height: `100%` }} />}
						containerElement={<div style={{ height: '300px' }} />}
						mapElement={<div style={{ height: `100%` }} />}
					/>
				</div>
			);
		} else {
			map = <div style={{ height: '300px' }} />;
		}
		return (
			<>
				<div className='content'>
					{this.state.alert}

					{this.state.loader}
					<Row>
						<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h4'>Edit salepoint</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup className={'has-label'}>
											<label> Salepoint Name *</label>
											<Input
												type='name'
												name='name'
												onChange={this.handleChange}
												value={this.state.name}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Salepoint Code *</label>

											<Input
												type='code'
												name='code'
												onChange={(e) => {
													this.handleChange(e);
													this.salePointRegex(e);
												}}
												value={this.state.code}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Region Name *</label>

											<Select
												onChange={this.ChangeRegion}
												options={this.state.allowedRegions}
												value={this.state.selectedAllowedRegion}
											></Select>
										</FormGroup>

										<FormGroup style={{ width: '94%', marginLeft: '22px' }}>
											{map}
										</FormGroup>
									</CardBody>
									<CardFooter className='text-right'>
										<Button
											className='btn-round'
											color='primary'
											onClick={this.handleSubmit}
											size='md'
										>
											Update
										</Button>
										<Button
											className='btn-round'
											color='info'
											onClick={this.handleCancelButton}
											size='md'
										>
											Cancel
										</Button>
										<Button
											className='btn-round'
											color='danger'
											onClick={this.handleDeleteButton}
											size='md'
										>
											Delete
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default withRouter(editRoute);
