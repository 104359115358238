/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

// react component for creating dynamic tables
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import {
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	FormGroup,
	Form,
} from 'reactstrap';

import Select from 'react-select';
import { getUsers } from '../../Redux/ActionCreator';
import { baseUrl } from '../../Shared/baseUrl';
import Moment from 'moment';

var currentDate;
const mapStateToProps = (state) => {
	return {
		Users: state.Users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (details) => dispatch(getUsers(details)),
	};
};

class transitInventory extends Component {
	constructor(props) {
		super(props);

		this.state = {
			assignedWarehouse: [],
			selectedWarehouse: '',
			data: [],
			indata: [],
			table: null,
			intable: null,
		};
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/inventory/getCurrentUserWarehouse', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				faId: data.id,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({
					assignedWarehouse: jsonResponse.data.map((prop, key) => {
						return {
							value: prop.id,
							label: prop.whName,
						};
					}),
				});
			})
			.catch((err) => {});
	}

	changeWarehouse = (event) => {
		this.setState({
			selectedWarehouse: event,
		});

		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/inventory/getFromWhTransit', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				fromWh: event.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						data: jsonResponse.data.map((props, index) => {
							fetch(`${baseUrl}/pos/productsList/${props.productId}`, {
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
									Authorization: 'Bearer ' + token,
									id: data.id,
									reg: JSON.stringify(data.Permissions),
								},
								method: 'Post',
							})
								.then((response) => response.json())
								.then((jsonResponse) => {
									if (jsonResponse.success) {
										this.state.data[index].productName =
											jsonResponse.product.productName;

										this.setState({ indata: [...this.state.indata] });
									}
								});

							let splitDate = props.dispatchDate.split('T')[0];
							return {
								toWh: props.toWarehouse.whName,
								sku: props.sku,
								qty: props.qty,
								date: splitDate,
							};
						}),
					});

					this.setState({
						table: (
							<ReactTable
								data={this.state.data}
								filterable
								resizable={false}
								columns={[
									{
										Header: 'Dispatched To',
										accessor: 'toWh',
									},
									{
										Header: 'Product',
										accessor: 'productName',
									},
									{
										Header: 'SKU',
										accessor: 'sku',
									},
									{
										Header: 'Quantity Dispatched',
										accessor: 'qty',
									},
									{
										Header: 'Dispatched Date',
										accessor: 'date',
									},
								]}
								// getTdProps={(state, rowInfo, column, instance) => {
								//     console.log(rowInfo, "checking earlier")
								//     return {
								//         onClick: e =>

								//             this.rowClicked(rowInfo.original.id)

								//     }
								// }}
								defaultPageSize={this.state.data.length}
								showPaginationTop
								showPaginationBottom={false}
								className='-striped -highlight'
							/>
						),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});

		fetch(baseUrl + '/inventory/getToWhTransit', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				toWh: event.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						indata: jsonResponse.data.map((props, index) => {
							fetch(`${baseUrl}/pos/productsList/${props.productId}`, {
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
									Authorization: 'Bearer ' + token,
									id: data.id,
									reg: JSON.stringify(data.Permissions),
								},
								method: 'Post',
							})
								.then((response) => response.json())
								.then((jsonResponse) => {
									if (jsonResponse.success) {
										this.state.indata[index].productName =
											jsonResponse.product.productName;

										this.setState({ indata: [...this.state.indata] });
									}
								});

							let splitDate = props.dispatchDate.split('T')[0];
							return {
								fromWh: props.fromWarehouse.whName,
								sku: props.sku,
								qty: props.qty,
								date: splitDate,
								id: props.id,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	};

	rowClicked(id) {
		let obj = this.state.indata.find((o) => o.id === id);
		this.props.history.push(`/admin/inventory_detail/${obj.id}`);
	}

	render() {
		return (
			<>
				<div className='content'>
					{}
					<Col md={8} className='ml-auto mr-auto'>
						<h2 className='text-center'>Transit Inventory Info</h2>
						<p className='text-center'>
							This Table contain all the information about Warehouses{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								Transit-table
							</a>
							. If need to modified customer please click on the buttons in the
							action column.{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								full documentation.
							</a>
						</p>
					</Col>
					<Row>
						<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h4'>Select Warehouse</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup className={'has-label'}>
											<label> Warehouse *</label>
											<Select
												onChange={this.changeWarehouse}
												options={this.state.assignedWarehouse}
												value={this.state.selectedWarehouse}
											></Select>
										</FormGroup>
									</CardBody>
								</Card>
							</Form>
						</Col>
					</Row>
					<Row className='mt-5'>
						<Col xs={12} md={12}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Outgoing Inventory Table</CardTitle>
								</CardHeader>
								<CardBody>
									{this.state.data.length > 0 && (
										<ReactTable
											data={this.state.data}
											filterable
											resizable={false}
											columns={[
												{
													Header: 'Dispatched To',
													accessor: 'toWh',
												},
												{
													Header: 'Product',
													accessor: 'productName',
												},
												{
													Header: 'SKU',
													accessor: 'sku',
												},
												{
													Header: 'Quantity Dispatched',
													accessor: 'qty',
												},
												{
													Header: 'Dispatched Date',
													accessor: 'date',
												},
											]}
											defaultPageSize={this.state.data.length}
											showPaginationTop
											showPaginationBottom={false}
											className='-striped -highlight'
										/>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row className='mt-5'>
						<Col xs={12} md={12}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Incoming Inventory Table</CardTitle>
								</CardHeader>
								<CardBody>
									{this.state.indata.length > 0 && (
										<ReactTable
											data={this.state.indata}
											filterable
											resizable={false}
											columns={[
												{
													Header: 'Sender',
													accessor: 'fromWh',
												},
												{
													Header: 'Product',
													accessor: 'productName',
												},
												{
													Header: 'SKU',
													accessor: 'sku',
												},
												{
													Header: 'Quantity Dispatched',
													accessor: 'qty',
												},
												{
													Header: 'Dispatched Date',
													accessor: 'date',
												},
											]}
											getTdProps={(state, rowInfo, column, instance) => {
												return {
													onClick: (e) => this.rowClicked(rowInfo.original.id),
												};
											}}
											defaultPageSize={this.state.indata.length}
											showPaginationTop
											showPaginationBottom={false}
											className='-striped -highlight'
										/>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(transitInventory);
