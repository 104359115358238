import Login from "views/pages/Login.jsx"
// import Pricing from "views/pages/Pricing.jsx"
// import Register from "views/pages/Register.jsx"
import Lock from "views/pages/Lock.jsx"
import SetPassword from "views/pages/SetPassword.jsx"
import ForgetPassword from "views/pages/ForgetPassword.jsx"
const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        layout: "/auth"
    },
    // {
    //     path: "/pricing",
    //     component: Pricing,
    //     layout: "/auth"
    //   },
    //   {
    //     path: "/register",
    //     name: "Register",
    //     component: Register,
    //     layout: "/auth"
    //   },
    {
        path: "/lock-screen",
        name: "Lock-Screen",
        component: Lock,
        layout: "/auth"
    },
    {
        path: "/setpassword/:id",
        name: "SetPassword",
        component:SetPassword,
        layout: "/auth"
    },     
    {
        path: "/forgetpassword",
        name: "ForgetPassword",
        component: ForgetPassword,
        layout: "/auth"
    }

]
export default routes