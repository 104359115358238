import React from 'react';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
} from 'reactstrap';
import { baseUrl } from '../../Shared/baseUrl';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';

const initialState = {
	name: '',
};

class AddParentRoute extends React.Component {
	constructor(props) {
		super(props);
		this.state = { alert: null, ...initialState };
	}

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	handleSubmit = () => {
		let user = JSON.parse(window.localStorage.getItem('user'));
		let userData = JSON.parse(user);
		let token = cookie.load('Token');

		fetch(`${baseUrl}/routing/add-parent-route`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: userData.id,
				reg: JSON.stringify(userData.permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				name: this.state.name,
			}),
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.resetForm();
					this.successAlert(jsonResponse.message);
				} else {
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert('Fetch Api not found');
			});
	};

	resetForm = () => {
		this.setState(initialState);
	};

	successAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					{message}
				</ReactBSAlert>
			),
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message}
				</ReactBSAlert>
			),
		});
	};

	render() {
		return (
			<div className='content'>
				{this.state.alert}
				<Row>
					<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
						<Form>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Add Route</CardTitle>
								</CardHeader>
								<CardBody>
									<FormGroup className={'has-label'}>
										<label> Route Name *</label>
										<Input
											type='text'
											name='name'
											onChange={this.handleChange}
											value={this.state.name}
										/>
									</FormGroup>
								</CardBody>
								<CardFooter className='text-right'>
									<Button color='primary' onClick={this.handleSubmit}>
										ADD
									</Button>
								</CardFooter>
							</Card>
						</Form>
					</Col>
				</Row>
			</div>
		);
	}
}

export default AddParentRoute;
