import React, { Component } from 'react';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Label,
	Input,
	Row,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { baseUrl } from '../../Shared/baseUrl';
import Moment from 'moment';

var dropDown;
var currentDate;
class addInventory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			whName: '',
			selectedparentCategory: '',
			productCategory: [],
			productName: [],
			productToInventory: [],
			productToInventoryForAPICallOnly: [],
			parentCategory: [
				{ label: 'Imported', value: 'Imported' },
				{ label: 'Local', value: 'Local' },
			],
			selectedProductCategory: '',
			sku: '',
			prodQty: '',
			alert: null,
			// loader:null,
			// pressed:false,
			// checked:false,
		};
		this.successAlert = this.successAlert.bind(this);
		this.onAddInventoryPress = this.onAddInventoryPress.bind(this);
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
	}
	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					Inventory Added Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	errorAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					Something Went Wrong !!!!!
				</ReactBSAlert>
			),
		});
	};

	onAddInventoryPress() {
		this.setState({ pressed: true });
	}

	resetForm() {
		this.setState({
			whName: '',
			selectedparentCategory: '',
			selectedProductCategory: '',
			sku: '',
			prodQty: '',
			pressed: true,
			productToInventory: [],
		});
	}
	handleSubmit = (event) => {
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		var token = cookie.load('Token');
		var d1 = new Date();
		currentDate = Moment(d1).format('YYYY-MM-DD');
		fetch(baseUrl + '/inventory/addInventory', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				inventory: this.state.productToInventoryForAPICallOnly,
				date: currentDate,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.resetForm();
					this.successAlert();
				} else {
					this.errorAlert();
				}
			});
	};
	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	changeProductCategory = (event) => {
		this.setState({ selectedProductCategory: event, selectedProduct: '' });

		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/pos/listProdNameByCatg', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				prodCat: event.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						productName: jsonResponse.prodName.map((props) => {
							return {
								value: props.id,
								label: props.productName,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	};

	changeProduct = (event) => {
		this.setState({
			selectedProduct: event,
		});
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/pos/productsList/' + event.value, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({ sku: jsonResponse.product.sku });
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	};

	savePressed = () => {
		let inserData = {
			selectedparentCategory: this.state.selectedparentCategory,
			selectedProductCategory: this.state.selectedProductCategory,
			selectedProduct: this.state.selectedProduct,
			sku: this.state.sku,
			prodQty: this.state.prodQty,
		};
		this.setState({
			productToInventory: this.state.productToInventory.concat(inserData),
			productToInventoryForAPICallOnly: this.state.productToInventoryForAPICallOnly.concat({
				productId: this.state.selectedProduct.value,
				sku: this.state.sku,
				quantity: this.state.prodQty
			})
		});

		this.setState({ pressed: false });
		this.setState({ selectedProductCategory: '' });
		this.setState({ selectedProduct: '' });
		this.setState({ sku: '' });
		this.setState({ prodQty: '' });
		dropDown = null;
	};

	ChangeParentCat = (event) => {
		this.setState({
			selectedparentCategory: event,
			selectedProductCategory: '',
			selectedProduct: '',
		});

		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/pos/getProductCategory', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				parentCat: event.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						productCategory: jsonResponse.productCatg.map((props) => {
							return {
								value: props.id,
								label: props.productCat,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	};

	render() {
		if (this.state.pressed == true) {
			dropDown = (
				<>
					<Row>
						<Col md={8}>
							<label>Main Category </label>
							<Select
								onChange={this.ChangeParentCat}
								options={this.state.parentCategory}
								value={this.state.selectedparentCategory}
							/>
							<label>Product Category</label>
							<Select
								onChange={this.changeProductCategory}
								options={this.state.productCategory}
								value={this.state.selectedProductCategory}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<label>Product</label>
							<Select
								onChange={this.changeProduct}
								options={this.state.productName}
								value={this.state.selectedProduct}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<label>SKU</label>
							<Input
								className='text-white'
								type='text'
								name='sku'
								value={this.state.sku}
								readOnly
							/>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<label>Quantity</label>
							<Input
								type='text'
								name='prodQty'
								onChange={this.handleChange}
								value={this.state.prodQty}
							/>
						</Col>
					</Row>

					<Col md={4}>
						<Button
							style={{ background: 'transparent', marginTop: 21 }}
							onClick={this.savePressed}
						>
							Save
						</Button>
					</Col>
				</>
			);
		}
		return (
			<>
				<div className='content'>
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col className='mr-auto' md={{ size: 6, offset: 3 }}>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h3'>Add New Inventory</CardTitle>
									</CardHeader>
									<CardBody>
										<Col md={12}>
											<FormGroup>
												<Row>
													<Col xs={4}>
														<Label>
															<h3>Add Product to Inventory</h3>
														</Label>
													</Col>
													<Col xs={{ size: 3, offset: 5 }}>
														<Button
															style={{ background: 'transparent' }}
															onClick={this.onAddInventoryPress}
														>
															<span className='tim-icons icon-simple-add'></span>
														</Button>
													</Col>
												</Row>
											</FormGroup>
											<FormGroup>{dropDown}</FormGroup>
											<FormGroup>
												{this.state.productToInventory.map((prod, idx) => (
													<div key={idx}>
														<Row>
															<Col md='8'>
																<Label>Main Category</Label>
																<Input
																	className='text-white'
																	readOnly
																	value={prod.selectedparentCategory.label}
																/>
															</Col>
														</Row>
														<Row>
															<Col md='8'>
																<Label>Product Category</Label>
																<Input
																	className='text-white'
																	readOnly
																	value={prod.selectedProductCategory.label}
																/>
															</Col>
														</Row>
														<Row>
															<Col md='8'>
																<Label>Product Name</Label>
																<Input
																	className='text-white'
																	readOnly
																	value={prod.selectedProduct.label}
																/>
															</Col>
														</Row>
														<Row>
															<Col md='8'>
																<Label>SKU</Label>
																<Input
																	className='text-white'
																	readOnly
																	value={prod.sku}
																/>
															</Col>
														</Row>
														<Row>
															<Col md='8'>
																<Label>Quantity</Label>
																<Input
																	className='text-white'
																	readOnly
																	value={prod.prodQty}
																/>
															</Col>
														</Row>
													</div>
												))}
											</FormGroup>
										</Col>
									</CardBody>
									<CardFooter className='text-right'>
										<Button color='primary' onClick={this.handleSubmit}>
											Add Inventory
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default addInventory;
