import * as ActionType from './ActionTypes'

export const Register= (state = {
    REGISTERING:true,
    REGISTERING_FAILED:null,
    REGISTERD:'',
},action) =>{

switch(action.type){
    case ActionType.REGISTERING:
    return {...state, REGISTERING:true, REGISTERING_FAILED:null,  REGISTERD:''}

    case ActionType.REGISTERD:
    return{ ...state,REGISTERING:false, REGISTERING_FAILED:null,  REGISTERD:action.payload}


    case ActionType.REGISTERING_FAILED:
    return { ...state,REGISTERING:false,REGISTERING_FAILED:action.payload, REGISTERD:''}

    default:
    return state;

}
}