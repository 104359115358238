/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import { baseUrl } from '../../Shared/baseUrl';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Switch from 'react-bootstrap-switch';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Button,
} from 'reactstrap';
import { cpus } from 'os';
var dropDown;
class EditPrice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			loader: null,
			selectedproduct_category: '',
			prod_Name: '',
			prod_NameOptions: [],
			regions: '',
			regionsOptions: [],
			selectedregion: '',
			price: '',
			dropDown1array: [],
			regionDrop1: false,
			permissions: [],
			Roles_Permissions: [],
			productCategory: '',
			productCategoryOptions: [],
			checked: false,
		};

		this.updateProductPrice = this.updateProductPrice.bind(this);
		// this.handleswitchChange = this.handleswitchChange.bind(this)
		// this.selectChangenew1 = this.selectChangenew1.bind(this)
	}

	ChangeProductCat = (event) => {
		this.setState({ productCategory: event.label });
		if (event.label != '') {
			console.log(this.state.selectedproduct_category);
			var user = JSON.parse(window.localStorage.getItem('user'));
			var data = JSON.parse(user);
			var token = cookie.load('Token');

			let header = {
				userid: data.id,
				token: token,
				permissions: data.Permissions,
			};

			fetch(baseUrl + '/pos/listProdNameByCatg', {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + header.token,
					id: header.userid,
					reg: JSON.stringify(header.permissions),
				},
				method: 'Post',
				body: JSON.stringify({
					prodCat: event.label,
				}),
			})
				.then((res) => res.json())
				.then((jsonResponse) => {
					this.setState({ loader: null });
					if (jsonResponse.success === true) {
						this.setState({
							prod_NameOptions: jsonResponse.prodName.map((props) => {
								return {
									value: props.productName,
									label: props.productName,
								};
							}),
						});
					} else {
						this.setState({ loader: null });
					}
				})
				.catch((err) => {
					this.setState({ loader: null });
				});
		}
	};

	//changing product name
	ChangeProductName = (event) => {
		this.setState({ prod_Name: event.label });
	};

	//changing the region
	ChangeRegion = (event) => {
		this.setState({ regions: event.label });
	};
	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					Price Updated Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	errorAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					Something gets wrong !!!!!
				</ReactBSAlert>
			),
		});
	};

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/pos/edit_price/' + this.props.match.params.id, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					fetch(
						`${baseUrl}/pos/productsList/${jsonResponse.pPrice.productId}`,
						{
							headers: {
								'Content-Type': 'application/json',
								Accept: 'application/json',
								Authorization: 'Bearer ' + token,
								id: data.id,
								reg: JSON.stringify(data.Permissions),
							},
							method: 'Post',
						},
					)
						.then((response) => response.json())
						.then((jsonResponse) => {
							if (jsonResponse.success) {
								this.setState({ prod_Name: jsonResponse.product.productName });

								fetch(
									`${baseUrl}/pos/get-product-category/${jsonResponse.product.productCategoryId}`,
									{
										headers: {
											'Content-Type': 'application/json',
											Accept: 'application/json',
											Authorization: 'Bearer ' + token,
											id: data.id,
											reg: JSON.stringify(data.Permissions),
										},
										method: 'Get',
									},
								)
									.then((response) => response.json())
									.then((jsonResponse) => {
										if (jsonResponse.success) {
											this.setState({
												productCategory:
													jsonResponse.productCategory.productCat,
											});
										}
									});
							}
						});

					fetch(
						`${baseUrl}/auth/users//get-region/${jsonResponse.pPrice.regionId}`,
						{
							headers: {
								'Content-Type': 'application/json',
								Accept: 'application/json',
								Authorization: 'Bearer ' + token,
								id: data.id,
								reg: JSON.stringify(data.Permissions),
							},
							method: 'Post',
						},
					)
						.then((response) => response.json())
						.then((jsonResponse) => {
							if (jsonResponse.success) {
								this.setState({ regions: jsonResponse.region.name });
							}
						});

					this.setState({
						price: jsonResponse.pPrice.productPrice,
						id: jsonResponse.pPrice.id,
						loader: null,
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}
	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	};
	updateProductPrice() {
		this.setState({
			innerloader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: '250px',
							marginLeft: '-80px',
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			),
		});
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);

		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		var token = cookie.load('Token');

		let details = {
			product_category: this.state.productCategory,
			product_name: this.state.prod_Name,
			region: this.state.regions,
			price: this.state.price,
		};

		fetch(
			baseUrl + '/pos/productPriceModification/' + this.props.match.params.id,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
					id: data.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: 'Post',
				body: JSON.stringify({
					price: details.price,
				}),
			},
		)
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ innerloader: null });
				this.successAlert();
				//   this.componentDidMount()
			})
			.catch((err) => {
				this.errorAlert();
				throw new Error(err);
			});
	}

	handleCancelButton = () => {
		this.props.history.push('/admin/getProductPriceList');
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col md={{ size: 7, offset: 2 }}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Price Detail</CardTitle>
								</CardHeader>
								<CardBody>
									<Form action='/' className='form-horizontal' method='get'>
										<FormGroup>
											<div>
												<Row>
													<Col xs={2}>
														<Label>Product Category</Label>
													</Col>
													<Col xs={3}>
														<Input
															className='text-white'
															readOnly
															style={{ width: 395, height: 40 }}
															type='text'
															name='productCategory'
															onChange={this.handleChange}
															value={this.state.productCategory}
														/>
													</Col>
												</Row>
												<br></br>
												<Row>
													<Col xs={2}>
														<Label>Product Name</Label>
													</Col>
													<Col xs={3}>
														<Input
															className='text-white'
															readOnly
															style={{ width: 395, height: 40 }}
															type='text'
															name='prod_Name'
															onChange={this.handleChange}
															value={this.state.prod_Name}
														/>
													</Col>
												</Row>
												<br></br>
												<Row>
													<Col xs={2}>
														<Label>Region</Label>
													</Col>
													<Col xs={8}>
														<Input
															className='text-white'
															readOnly
															style={{ width: 395, height: 40 }}
															type='text'
															name='regions'
															onChange={this.handleChange}
															value={this.state.regions}
														/>
													</Col>
												</Row>
												<br></br>
												<Row>
													<Col xs={2}>
														<Label>Product Price</Label>
													</Col>
													<Col xs={2}>
														<Input
															style={{ width: 395, height: 40 }}
															type='text'
															name='price'
															onChange={this.handleChange}
															value={this.state.price}
														/>
													</Col>
												</Row>
											</div>
										</FormGroup>
									</Form>
								</CardBody>
								<CardFooter className='text-right'>
									<Button
										className='btn-round'
										color='primary'
										onClick={this.updateProductPrice}
										size='md'
									>
										Update
									</Button>
									<Button
										className='btn-round'
										color='info'
										onClick={this.handleCancelButton}
										size='md'
									>
										Cancel
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}
export default withRouter(EditPrice);
