/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import { baseUrl } from '../../Shared/baseUrl';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Switch from 'react-bootstrap-switch';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
// reactstrap components
import {

	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Button
} from 'reactstrap';
import { cpus } from 'os';
var dropDown;
class EditUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username:'',
			email:'',
			active:Boolean,
			Roles:[],
			loader:'',
			alert: null,
			switchCheck:false,
			fetchedRoles:[],
			Regions:[],
			defaultRegion:null,
			roleSelected:[],
			selectedRegion:'',
			dropDown1array:[],
			regionDrop1:false,
			pressed:false,
			permissions:[],
			Roles_Permissions:[],
			checked:false,
		};

		this.updateUser = this.updateUser.bind(this);
		this.handleswitchChange = this.handleswitchChange.bind(this);
		this.selectChangenew1 = this.selectChangenew1.bind(this);
	}

handleCheckboxChange = event =>{
	this.setState({ checked: event.target.checked });
}
successAlert()  {
	this.setState({
		alert: (
			<ReactBSAlert
				success
				style={{ display: 'block', marginTop: '-100px' }}
				title="Success!"
				confirmBtnBsStyle="success"
				onConfirm={() => this.hideAlert()}
				onCancel={() => this.hideAlert()}
				btnSize="">
            User Added Successfully !
			</ReactBSAlert>
		)
	});
}
hideAlert = () => {
	this.setState({
		alert: null
	});
}

errorAlert = () => {
	this.setState({
		alert: (
			<ReactBSAlert
				danger
				style={{ display: 'block', marginTop: '-100px' }}
				title="Error"
				onConfirm={() => this.hideAlert()}
				onCancel={() => this.hideAlert()}
				confirmBtnBsStyle="danger"
				btnSize=""
			>
     User with this email Already Exsit !!!!!
       
			</ReactBSAlert>
		)
	});
  
};


handleswitchChange(checked,event,id) {
	this.setState({ active:event });
}
handleRemoveShareholder = idx => () => {
	this.state.Roles.map((s) => {
		if(idx === s.id) {
			this.state.fetchedRoles.push({
				label: s.Role,
				value: s.id,
				Dep: 'reg'
			});
		}
	});
	this.setState({
		fetchedRoles: this.state.fetchedRoles
	});

	this.setState({
		Roles: this.state.Roles.filter((s) => idx !== s.id)
	});
	this.setState({Roles_Permissions:this.state.Roles_Permissions.filter((s)=> idx !== s.id)});
};
componentDidMount(){
	var user = JSON.parse(window.localStorage.getItem('user'));
	var data = JSON.parse(user);
    
	let details = {
		token:cookie.load('Token'),
		id:data.id
	};

  
	fetch(baseUrl + '/auth/users/getUserDetail/'+this.props.match.params.id ,{

		headers : { 
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + details.token,
			'id': details.id
		},
		method:'Post',
          
	}).then((res)=>res.json()).then((jsonResponse)=>{
		if(jsonResponse.success === true){
			this.setState({Roles:jsonResponse.User.Roles.map((prop,key)=>{
        
				return {
					id:prop.id,
					Role:prop.Role,
					regionName:prop.regionName,
					regionCode:prop.regionCode,
					...prop
				};
			})});
			this.setState({username:jsonResponse.User.username,email:jsonResponse.User.email,active:jsonResponse.User.active,
				loader:null});
			fetch(baseUrl + '/auth/roles/getRole/Roles',{
				headers : { 
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': 'Bearer ' + details.token,
					'id': details.id
				},
				method:'Post',
      
			}).then((res)=>res.json()).then((jsonResponse)=>{
				this.setState({loader:null});
				if(jsonResponse.success === true){
					var newArray = jsonResponse.Roles.filter(function (el) {
						return el.Role.Role !== 'Super-User'; 
            
					});

					newArray.map((prop,key)=>{
						if(!this.state.Roles.find(o => o.id === prop.id)) {
							this.state.fetchedRoles.push({
								value:prop.id,
								label:prop.Role.Role,
								Dep:prop.Role.Dep
							});
						}
					});

					this.setState({fetchedRoles: this.state.fetchedRoles});

				}
				else {
					throw new Error('Success Failed');
				}
     
			}).catch(err =>{
				throw new Error(err);
			});
		}
      
	}).catch(err =>{
		throw new Error(err);
	});
}
selectChangenew1(selectedOption){
	var sendRole;
	var user = JSON.parse(window.localStorage.getItem('user'));
	var data = JSON.parse(user);
	var token = cookie.load('Token');
	let details = {
		userid:data.id,
		token:token,
	};

	fetch(baseUrl + '/auth/users/userSingle_permission',{
		headers : { 
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + details.token
		},
		method:'Post',
		body:JSON.stringify({
			id:selectedOption.value
		})
	}).then((res)=>res.json()).then((jsonResponse)=>{
		if(jsonResponse.success === true){
			this.setState({permissions:jsonResponse.permissions});
          
		}
  
		else {
			throw new Error('Success Failed');
		}
   
	}).catch(err =>{
		throw new Error(err);
	});
	if (selectedOption.Dep == 'reg'){
		fetch(baseUrl + '/auth/users/getRegion',{
			headers : { 
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer ' + details.token,
				'id': details.userid
			},
			method:'Post',
      
		}).then((res)=>res.json()).then((jsonResponse)=>{
    
			if(jsonResponse.success === true){
    
				this.setState({Regions:jsonResponse.Regions.map((prop,key)=>{

					return{
						value:selectedOption.label,
						label:prop.name,
						code:prop.code
					};
				})});
				this.setState({regionDrop1:true});
			}
			else {
				throw new Error('Success Failed');
			}
     
		}).catch(err =>{
			throw new Error(err);
		});
		let inserData = {
			id:selectedOption.value,
			Role:selectedOption.label,
			Region:''
		};
		sendRole = inserData;
		this.setState({dropDown1array:[sendRole]});

	}

	else{
		this.setState({regionDrop1:false});
		let inserData2 = {
			id:selectedOption.value,
			Role:selectedOption.label,
         
		};
		sendRole = inserData2;
		this.setState({dropDown1array:[sendRole]});
	}
}
savePressed = () =>{
	var reg_per =[]; 
	this.state.permissions.Permissions.map((props,key)=>{
		this.state.dropDown1array.map((data)=>{
			if(data.Dep == 'reg'){
				reg_per.push({
					id:data.id,
					Permissions:props,
					regionCode:data.regionCode,
					regionName:data.regionName
				});
			}
			else{
				reg_per.push({
					id:data.id,
					Permissions:props,
             
				});
			}
            
		});    
	});
	this.setState({Roles_Permissions:this.state.Roles_Permissions.concat(reg_per)});
	this.setState({Roles:this.state.Roles.concat(this.state.dropDown1array)});
	dropDown = null;
	this.setState({pressed:false});
	this.setState({regionDrop1:false});
	this.setState({dropDown1array:['']});
	this.setState({
		fetchedRoles: this.state.fetchedRoles.filter(obj => {
			if(this.state.dropDown1array.find(o => o.id === obj.value)) {
				return;
			} else {
				return obj;
			}
		})
	});
	reg_per = [];
}
  handleChange =(event)=> {
  	const target = event.target;
  	const value =  target.value;
  	const name = target.name;
  	this.setState({
  		[name]: value
  	});
    
  }
  onAddRolesPress  = () =>{
  	this.setState({ pressed: true });   
  }

  addingProfileFunction =()=>{
  	var user = JSON.parse(window.localStorage.getItem('user'));
  	var data = JSON.parse(user);
  	var token = cookie.load('Token');
  	let details = {
  		userid:data.id,
  		token:token,
  		permissions:data.Permissions
  	};
  	if (this.checked !=false){
          
  		fetch(baseUrl + '/hrm/addprofile',{
          
  			headers : { 
  				'Content-Type': 'application/json',
  				'Accept': 'application/json',
  				'Authorization': 'Bearer ' + details.token,
            
  				'id': details.id,
  				'reg':JSON.stringify(details.permissions)
  			},
  			method:'Post',
  			body:JSON.stringify({
  				email:this.state.email,
  				username:this.state.username,
    
  			})
  		}).then((res)=>res.json()).then((jsonResponse)=>{
  			this.setState({loader:null});
  			if(jsonResponse.success === true){
  				this.successAlert();
  			}
  			else {
  				this.errorAlert();
  			}
      
  		}).catch(err =>{
  			this.errorAlert();
  		});
  	}
  };

  updateUser(){
  	this.setState({innerloader:(
  		<LoadingOverlay
  			styles={{
  				overlay: (base) => ({
  					...base,
  					marginTop: '250px',
  					marginLeft: '-80px',
  				})
  			}}
  			active={true}
  			spinner={<Loader  type="CradleLoader"
    
  				height="100"	
  				width="100"/>}
   
  		>
  		</LoadingOverlay>
  	)});
  	var user = JSON.parse(window.localStorage.getItem('user'));
  	var data = JSON.parse(user);

  	let details = {
  		token:cookie.load('Token'),
  		id:data.id
  	};
  
  	fetch(baseUrl + '/auth/users/modifiedUser/'+this.props.match.params.id,{

  		headers : { 
  			'Content-Type': 'application/json',
  			'Accept': 'application/json',
  			'Authorization': 'Bearer ' + details.token,
  			'id': details.id
  		},
  		method:'Post',
  		body:JSON.stringify({
  			Role: this.state.Roles,
  			Region_Permissions:this.state.Roles_Permissions,
  			username:this.state.username,
  			email:this.state.email,
  			active:this.state.active,
          
  		})
          
  	}).then((res)=>res.json()).then((jsonResponse)=>{
  		this.setState({innerloader:null});
  		this.successAlert();
  		this.componentDidMount();

  	}).catch(err =>{
  		this.errorAlert();    
  		throw new Error(err);
  	});
  }



  validateForm() {
  	var permissions = window.localStorage.getItem('permissions');
  	var Check = permissions.includes('modifiedUser');
  	return Check === true;
  }
  regionDropDown1 = (selectedOption) =>{
   
  	var item = this.state.dropDown1array.find(x => x.Role == selectedOption.value);
  	if (item) {
  		item.regionCode = selectedOption.code;
  		item.regionName = selectedOption.label;  
  		this.setState({dropDown1array:[item]});
  	}
  }

handleCancelButton = () =>{
	this.props.history.push('/admin/Modified_User');
}


render() {
	if(this.state.pressed == true){
		dropDown  = 
             <Row>
             	<Label sm="2">Role</Label>
             	<Col sm={{size:3}}>
             		<Select options={this.state.fetchedRoles} onChange={this.selectChangenew1} ></Select>
             	</Col>
             	<Col sm={{size:3}}>
             		{this.state.regionDrop1 ? (
             			<Select options={this.state.Regions}  onChange={this.regionDropDown1} ></Select>
             		):null}
             	</Col>
             	<Col sm={2}>
             		<Button style={{background:'transparent'}} onClick={this.savePressed}>Save</Button>
             	</Col>
            
             </Row>;
	}
	if(this.state.loader !== null){
		return(
			<LoadingOverlay
				styles={{
					overlay: (base) => ({
						...base,
						marginTop: '250px',
						marginLeft: '50px',
					})
				}}
				active={true}
				spinner={<Loader  
					type="CradleLoader"
					height="100"	
					width="100"/>}
 
			>
			</LoadingOverlay>
		);

	}
	if(this.state.loader === null){
		return (
    <>
      <div className="content">
      	{this.state.alert}
      	{this.state.loader}
      	<Row>
      
      		<Col md={{size:7,offset:2}}>
      			<Card>
      				<CardHeader>
      					<CardTitle tag="h4">User Detail</CardTitle>
      				</CardHeader>
      				<CardBody>
      					<Form action="/" className="form-horizontal" method="get">
      						<Row>
      							<Label sm="2">Username</Label>
      							<Col sm="10">
      								<FormGroup>
      									<Input type="text" disabled={!this.validateForm()} name="username" onChange={this.handleChange} value = {this.state.username}/>
                      
      								</FormGroup>
      							</Col>
      						</Row>
      						<Row>
      							<Label sm="2">Email</Label>
      							<Col sm="10">
      								<FormGroup >
      									<Input disabled={!this.validateForm()} value={this.state.email} name="email" onChange={this.handleChange}  type="email" />
      								</FormGroup>
      							</Col>
      						</Row>
               
      						<Row>
      							<Label sm="2">Account-Status</Label>
      							<Col sm="10">
      								<FormGroup>
      									<Switch
      										defaultValue={this.state.active}
      										checked={this.state.active}
      										onChange={this.handleswitchChange}
      										offColor=""
      										offText={
      											<i className="tim-icons icon-simple-remove" />
      										}
      										onColor=""
      										onText={<i className="tim-icons icon-check-2" />}
      									/>
      								</FormGroup>
      							</Col>
      						</Row>
      						<FormGroup >
      							{this.state.Roles.map((Role, idx) => (
                                       
      								<div>
      									<Row>
      										<Label sm="2">Selected Role</Label>
      										<Col sm={{size:3}}>
      											<Select placeholder={Role.Role} ></Select>
      										</Col>
      										<Label sm="2">Selected Region</Label>
      										<Col sm={{size:3}}>
      											{Role.regionName !== undefined ?(
      												<Select placeholder={Role.regionName} ></Select>
      											):null}
      										</Col>
      										<Col sm={2}>
      											<Button style={{background:'transparent'}} onClick={this.handleRemoveShareholder(Role.id)}>✖</Button>
      										</Col>
           
      									</Row>
      								</div>
      							))}
      							<Row>        
      							</Row>
      							<Row>
      								<Label sm="2">Add User Roles</Label>
      								<Col sm="10">
      									<FormGroup>
      										<Button onClick={this.onAddRolesPress} >Add Roles</Button>       
      									</FormGroup>
      								</Col>
      								<Col>
      									{dropDown}
      								</Col>  
      							</Row>
      						</FormGroup>
              
       
      					</Form>
      				</CardBody>
      				<CardFooter className="text-right" >
      					<Button
      						disabled={!this.validateForm()}
      						className="btn-round"
      						color="primary"
      						onClick={this.updateUser}
      						size="lg"
      					>
                     Update
      					</Button>
      					<Button
      						className="btn-round"
      						color="primary"
      						onClick={this.handleCancelButton}
      						size="lg"
      					>
                  Cancel
      					</Button>
      				</CardFooter>
      			</Card>
      		</Col>
         
      	</Row>
      </div>
    </>
		);
	}

}
}

export default withRouter(EditUser);
