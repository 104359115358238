/* eslint-disable no-unused-vars */
import Dashboard from 'views/Dashboard.jsx';
import AddUser from './views/User-Management/Add-User';
import ModifiedUsers from './views/User-Management/Modified-Users';
import EditUser from './views/User-Management/Edit-Users';
import AddCustomer from './views/Customer-Management/Add-Customer';
import AddProductPrice from './views/Product-Management/Add-ProductPricing';
import ViewProductPrice from './views/Product-Management/View-Product-Price';
import EditPrice from './views/Product-Management/Edit-Price';
import AddProfile from './views/User-Profile/Add-Profile';
import ModifiedProfile from './views/User-Profile/Modified-Profile';
import EditProfile from './views/User-Profile/Edit-Profile';
import Routing from './views/Routing/Routing';
import Schedule from './views/Routing/scheduleRoute';
import AddParentRoute from './views/Routing/AddParentRoute';
import AddRoute from './views/Routing/Add-Route';
import PrivacyPolicy from './views/About/PrivacyPolicy';
import CustomerDetail from './views/Customer-Management/Customer-Detail';
import EditCustomer from './views/Customer-Management/Edit-Customer';
import AddProduct from './views/Product-Management/Add-Product';
import ProductDetail from './views/Product-Management/Product-Detail';
import editRoute from './views/Routing/editRoute';
import listRoute from './views/Routing/listRoute';
import proPromo from './views/Product-Management/productPromotion';
import listPromotions from './views/Product-Management/listPromotions';
import editPromotion from './views/Product-Management/editPromotion';
import EditProduct from './views/Product-Management/Edit-Product';
import SetSales from './views/Product-Management/Set-Sales-Target';
import listSalesTarget from './views/Product-Management/listSalesTarget';
import editSalesTarget from './views/Product-Management/editSalesTarget';
import AddInseminationEvent from './views/Customer-Management/Add-Inceminasion-Events';
import InseminationEventsDetails from './views/Customer-Management/InseminationEvents-Details';
import EditInsemEvents from './views/Customer-Management/Edit-InseminationEvents';
import AddVehicle from './views/Fleets-Management/Add-Vehicles';
import ViewVehicle from './views/Fleets-Management/Vehicles-Details';
import EditVehicleDetails from './views/Fleets-Management/Edit-VehicleDetails';
import AddDriver from './views/Fleets-Management/Add-Driver';
import ViewDriver from './views/Fleets-Management/Drivers-Details';
import EditDriverDetails from './views/Fleets-Management/Edit-DriverDetails';
import addSms from './views/sms/addSms';
import listSms from './views/sms/listSms';
import editSms from './views/sms/editSms';
import TripTracking from './views/Trip-Tracking/trip-tracking';
import RouteDetails from './views/Routing/Route-Details';
import EditRouteDetails from './views/Routing/Edit-Route-Details';
import addWarehouse from './views/Inventory-Management/addWarehouse';
import listWarehouse from './views/Inventory-Management/listWarehouse';
import editWarehouse from './views/Inventory-Management/editWarehouse';
import addInventory from './views/Inventory-Management/addInventory';
import moveInventory from './views/Inventory-Management/moveInventory';
import transitInventory from './views/Inventory-Management/transitInventory';
import incomingInventoryDetail from './views/Inventory-Management/incomingInventoryDetail';
import assignInventoryToFs from './views/Inventory-Management/assignInventoryToFs';
import fsInventoryList from './views/Inventory-Management/fsInventoryList';
import fsInventoryReturn from './views/Inventory-Management/fsInventoryReturn';
import whInventoryList from './views/Inventory-Management/whInventoryList';
import ParentRouteList from './views/Routing/ParentRouteList';
import EditParentRoute from './views/Routing/EditParentRoute';
import RouteSchedule from './views/Routing/RouteSchedule';
import PreviousTrips from './views/Routing/PreviousTrips';
import ViewPreviousTrip from './views/Routing/ViewPreviousTrip';

const routes = [
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: 'tim-icons icon-chart-pie-36',
		component: Dashboard,
		layout: '/admin',
		permission: 'No-Permissions',
	},
	{
		collapse: true,
		name: 'User-Management',
		icon: 'tim-icons icon-single-02',
		state: 'userManagmentCollapse',
		permission: 'addUser',
		views: [
			{
				path: '/Adduser',
				name: 'Add User',
				mini: 'AU',
				component: AddUser,
				layout: '/admin',
				permission: 'addUser',
			},
			{
				path: '/Modified_User',
				name: 'Modify Users',
				mini: 'MU',
				component: ModifiedUsers,
				layout: '/admin',
				permission: 'modifiedUser',
			},
			{
				path: '/edit_user/:id',
				layout: '/admin',
				component: EditUser,
				permission: 'edit_user',
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: 'Customer-Management',
		icon: 'tim-icons icon-single-02',
		state: 'customerManagmentCollapse',
		permission: 'addCustomer',
		views: [
			{
				path: '/addCustomer',
				name: 'Add Customer',
				mini: 'AC',
				component: AddCustomer,
				layout: '/admin',
				permission: 'addCustomer',
			},
			{
				path: '/customersList',
				name: 'Customer Detail',
				mini: 'CD',
				component: CustomerDetail,
				layout: '/admin',
				permission: 'customersList',
			},
			{
				path: '/edit_customer/:id',
				layout: '/admin',
				component: EditCustomer,
				permission: 'edit_customer',
				invisible: true,
			},
			{
				path: '/Add_InceminasionEvents/:id',
				layout: '/admin',
				component: AddInseminationEvent,
				permission: 'Add_InceminasionEvents',
				invisible: true,
			},
			{
				path: '/insemintaionList/:phone',
				layout: '/admin',
				name: 'Insemination Events Detail',
				mini: 'IED',
				component: InseminationEventsDetails,
				permission: 'insemintaionList',
				invisible: true,
			},
			{
				path: '/edit_InseminationEvents/:id',
				layout: '/admin',
				component: EditInsemEvents,
				permission: 'edit_InseminationEvents',
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: 'Fleet-Management',
		icon: 'tim-icons icon-single-02',
		state: 'fleetManagmentCollapse',
		permission: 'fleetManagement',
		views: [
			{
				path: '/addVehicle',
				name: 'Add Vehicle',
				mini: 'AV',
				component: AddVehicle,
				layout: '/admin',
				permission: 'addVehicle',
			},
			{
				path: '/vehicleList',
				name: 'Vehicle Listing',
				mini: 'VL',
				component: ViewVehicle,
				layout: '/admin',
				permission: 'vehicleList',
			},
			{
				path: '/edit_vehicleDetails/:id',
				layout: '/admin',
				component: EditVehicleDetails,
				permission: 'edit_vehicleDetails',
				invisible: true,
			},
			{
				path: '/addDriver',
				name: 'Add Driver',
				mini: 'AD',
				component: AddDriver,
				layout: '/admin',
				permission: 'addDriver',
			},
			{
				path: '/driverList',
				name: 'Driver Listing',
				mini: 'DL',
				component: ViewDriver,
				layout: '/admin',
				permission: 'driverList',
			},
			{
				path: '/edit_driverDetails/:id',
				layout: '/admin',
				component: EditDriverDetails,
				permission: 'edit_driverDetails',
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: 'Product-Management',
		icon: 'tim-icons icon-pencil',
		state: 'productManagmentCollapse',
		permission: 'addProduct',
		views: [
			{
				path: '/addProduct',
				name: 'Add Product Details',
				mini: 'AP',
				component: AddProduct,
				layout: '/admin',
				permission: 'addProduct',
			},
			{
				path: '/productsList',
				name: 'Product Listing',
				mini: 'PL',
				component: ProductDetail,
				layout: '/admin',
				permission: 'productsList',
			},
			// {
			//     path: "/AddProductPrice",
			//     name: "Add Product Price",
			//     mini: "APP",
			//     component: AddProductPrice,
			//     layout: "/admin",
			//     permission: "AddProductPrice",
			// },
			{
				path: '/getProductPriceList',
				name: 'View Product Price',
				mini: 'VPP',
				component: ViewProductPrice,
				layout: '/admin',
				permission: 'getProductPriceList',
			},
			{
				path: '/edit_product/:id',
				layout: '/admin',
				component: EditProduct,
				permission: 'edit_product',
				invisible: true,
			},
			{
				path: '/edit_price/:id',
				layout: '/admin',
				component: EditPrice,
				permission: 'edit_price',
				invisible: true,
			},
			{
				path: '/productPromotions',
				name: 'Product Promotions',
				mini: 'PP',
				component: proPromo,
				layout: '/admin',
				permission: 'productPromotions',
			},
			{
				path: '/listPromotions',
				layout: '/admin',
				component: listPromotions,
				mini: 'LP',
				name: 'list Promotions',
				permission: 'listPromotions',
			},
			{
				path: '/Edit_Promo/:id',
				component: editPromotion,
				layout: '/admin',
				permission: 'Edit_Promo',
				invisible: true,
			},
			{
				path: '/setSales',
				name: 'Set Sales Target',
				mini: 'SS',
				component: SetSales,
				layout: '/admin',
				permission: 'setSalesTarget',
			},
			{
				path: '/listSalesTarget',
				layout: '/admin',
				component: listSalesTarget,
				mini: 'LST',
				name: 'Sales Targets',
				permission: 'listSalesTarget',
			},
			{
				path: '/edit_sales_target/:id',
				layout: '/admin',
				component: editSalesTarget,
				permission: 'edit_sales_target',
				invisible: true,
			},
			//         {
			//             path: "/product_customer/:id",
			//             layout: "/admin",
			//             component: ProductCustomer,
			//             permission: "No-Permissions",
			//         },
		],
	},
	{
		collapse: true,
		name: 'Employee Profile',
		icon: 'tim-icons icon-badge',
		state: 'UserProfileCollapse',
		permission: 'addProfile',
		views: [
			{
				path: '/Modified_Profile',
				name: 'Modify Profile',
				mini: 'MP',
				component: ModifiedProfile,
				layout: '/admin',
				permission: 'getProfileDetail',
			},

			{
				path: '/Edit_Profile/:id',
				component: EditProfile,
				layout: '/admin',
				permission: 'getProfileDetail',
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: 'Route-Management',
		icon: 'tim-icons icon-pin',
		state: 'RoutingCollapse',
		permission: 'addSalePoints',
		views: [
			{
				path: '/add-parent-route',
				name: 'Add Route',
				mini: 'AR',
				component: AddParentRoute,
				layout: '/admin',
				permission: 'addRoute',
			},
			{
				path: '/parent-route-list',
				name: 'Route List',
				mini: 'RL',
				component: ParentRouteList,
				layout: '/admin',
				permission: 'routeDetails',
			},
			{
				path: '/edit-parent-route/:id',
				component: EditParentRoute,
				layout: '/admin',
				permission: 'routeDetails',
				invisible: true,
			},
			{
				path: '/addRoute',
				name: 'Add Sub Route',
				mini: 'ASR',
				component: AddRoute,
				layout: '/admin',
				permission: 'addRoute',
			},
			{
				path: '/routeDetails',
				name: 'Sub Routes Details',
				mini: 'RD',
				component: RouteDetails,
				layout: '/admin',
				permission: 'routeDetails',
			},
			{
				path: '/editRoute/:id',
				layout: '/admin',
				component: EditRouteDetails,
				permission: 'editRoute',
				invisible: true,
			},
			{
				path: '/Routing',
				name: 'Add Salespoint',
				mini: 'R',
				component: Routing,
				layout: '/admin',
				permission: 'addSalePoints',
			},

			{
				path: '/listSalePoints',
				name: 'Salespoint list',
				mini: 'SL',
				component: listRoute,
				layout: '/admin',
				permission: 'listSalePoints',
			},
			{
				path: '/edit_Route/:id',
				layout: '/admin',
				component: editRoute,
				permission: 'edit_Route',
				invisible: true,
			},
			{
				path: '/schedule_Route',
				name: 'Route Scheduling',
				mini: 'RS',
				component: Schedule,
				layout: '/admin',
				permission: 'scheduleRoute',
				invisible: true,
			},
			{
				path: '/route-schedule',
				name: 'Route Scheduling',
				mini: 'RS',
				component: RouteSchedule,
				layout: '/admin',
				permission: 'scheduleRoute',
			},
			{
				path: '/Trip-Tracking',
				name: 'Trip Tracking',
				mini: 'R',
				component: TripTracking,
				layout: '/admin',
				permission: 'Trip-Tracking',
			},
			{
				path: '/previous-trips',
				name: 'Previous Trips',
				mini: 'R',
				component: PreviousTrips,
				layout: '/admin',
				permission: 'Previous-Trips',
			},
			{
				path: '/previous-trip/:id',
				name: 'Previous Trips',
				mini: 'R',
				component: ViewPreviousTrip,
				layout: '/admin',
				permission: 'Previous-Trips',
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: 'sms',
		icon: 'tim-icons icon-bell-55',
		state: 'smsCollapse',
		permission: 'No-Permissions',
		views: [
			{
				path: '/addSms',
				name: 'Add Sms',
				mini: 'AS',
				component: addSms,
				layout: '/admin',
				permission: 'addSms',
			},
			{
				path: '/listSms',
				name: 'List Sms',
				mini: 'LS',
				component: listSms,
				layout: '/admin',
				permission: 'listSms',
			},
			{
				path: '/edit_sms/:id',
				layout: '/admin',
				component: editSms,
				permission: 'edit_sms',
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: 'Inventory',
		icon: 'tim-icons icon-bell-55',
		state: 'inventoryCollapse',
		permission: 'inventoryManagment',
		views: [
			{
				path: '/addWarehouse',
				name: 'Add Warehouse',
				mini: 'AW',
				component: addWarehouse,
				layout: '/admin',
				permission: 'addWarehouse',
			},
			{
				path: '/listWarehouse',
				name: 'List Warehouse',
				mini: 'LW',
				component: listWarehouse,
				layout: '/admin',
				permission: 'listWarehouse',
			},
			{
				path: '/getWarehouseById/:id',
				layout: '/admin',
				component: editWarehouse,
				permission: 'getWarehouseById',
				invisible: true,
			},
			{
				path: '/addInventory',
				name: 'Add Inventory',
				mini: 'AI',
				component: addInventory,
				layout: '/admin',
				permission: 'addInventory',
			},
			{
				path: '/moveInventory',
				name: 'Move Inventory',
				mini: 'MI',
				component: moveInventory,
				layout: '/admin',
				permission: 'moveInventory',
			},
			{
				path: '/transitInventory',
				name: 'Transit Inventory',
				mini: 'TI',
				component: transitInventory,
				layout: '/admin',
				permission: 'transitInventory',
			},

			{
				path: '/assignInventoryToFs',
				name: 'Assign Inventory FS',
				mini: 'AIF',
				component: assignInventoryToFs,
				layout: '/admin',
				permission: 'assignInventoryToFs',
			},
			{
				path: '/inventory_detail/:id',
				name: '',
				mini: '',
				component: incomingInventoryDetail,
				layout: '/admin',
				permission: 'inventory_detail',
				invisible: true,
			},
			{
				path: '/fsInventoryList',
				name: 'FS Inventory List',
				mini: 'FIL',
				component: fsInventoryList,
				layout: '/admin',
				permission: 'fsInventoryList',
			},
			{
				path: '/fsInventoryReturn/:id',
				name: 'FS Inventory Return',
				mini: 'FIR',
				component: fsInventoryReturn,
				layout: '/admin',
				permission: 'fsInventoryReturn',
				invisible: true,
			},
			{
				path: '/whInventoryList/',
				name: 'Current Inventory',
				mini: 'CI',
				component: whInventoryList,
				layout: '/admin',
				permission: 'whInventoryList',
			},
		],
	},
];
export default routes;
