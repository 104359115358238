/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from "react"
import { withRouter } from "react-router-dom"
import ReactBSAlert from "react-bootstrap-sweetalert"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Input,
    FormGroup,

    Container,
    Col
} from "reactstrap"
import { baseUrl } from "../../Shared/baseUrl"
var id
class SetPassword extends React.Component {
    constructor(props){
        super (props)
        this.state = {
            password:"",
            confirmPassword: "",
            passwordState: "",
            confirmPasswordState: "",
            alert: null,
        }
        this.routeChange = this.routeChange.bind(this)

    }
errorAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Error"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="danger"
                btnSize=""
            >
    Something Went Wrong!!!!!
       
            </ReactBSAlert>
        )
    })
  
};
compare = (string1, string2) => {
    if (string1 === string2) {
        return true
    }
    return false
};

verifyLength = (value, length) => {
    if (value.length >= length) {
        return true
    }
    return false
};

validateForm() {
    return this.state.password.length > 0
}
routeChange(){
    return this.props.history.push("/auth/login")
} 
   hideAlert = () => {
       this.setState({
           alert: null
       })
   }
  handleChange =(event, stateName, type, stateNameEqualTo) => {
    
      switch (type) {
      case "password":
          if (this.verifyLength(event.target.value, 5)) {
              this.setState({ [stateName + "State"]: "has-success" })
          } else {
              this.setState({ [stateName + "State"]: "has-danger" })
          }
          break
      case "equalTo":
          if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
              this.setState({ [stateName + "State"]: "has-success" })
              this.setState({ [stateNameEqualTo + "State"]: "has-success" })
          } else {
              this.setState({ [stateName + "State"]: "has-danger" })
              this.setState({ [stateNameEqualTo + "State"]: "has-danger" })
          }
          break
      case "length":
          if (this.verifyLength(event.target.value, stateNameEqualTo)) {
              this.setState({ [stateName + "State"]: "has-success" })
          } else {
              this.setState({ [stateName + "State"]: "has-danger" })
          }
          break
      default:
          break
      }

      this.setState({
          [stateName]: event.target.value 
      })  
  }
setPassword = (details) =>{

    console.log(this.props.match.params.id, "Flag");

    fetch(baseUrl + "/auth/users/setPassword",{
        headers : { 
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        method:"Post",
        body:JSON.stringify({
            password:details.password,
            id:this.props.match.params.id
        })
    }).then((res)=>res.json()).then((jsonResponse)=>{
        if(jsonResponse.success === true){
            this.routeChange()
        }
        else {
            this.errorAlert()
            throw new Error("Success Failed ")
        }
   
    }).catch(err =>{
        this.errorAlert()
        throw new Error(err)
    })
}
  handleSubmit = event => {
  
      let details = {
          password:this.state.password
      }
      this.setPassword(details)
  
  }
  componentDidMount() {
      id = parseInt(this.props.match.params)
      document.body.classList.toggle("login-page")
  }
  componentWillUnmount() {
      document.body.classList.toggle("login-page")
  }
  render() {
      let {
          passwordState,
          confirmPasswordState,
      } = this.state
      return  (
      
      <>
     
        <div className="content">
            {this.state.alert}
            <Container>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                    <Form className="form">
                        <Card className="card-login card-white">
                            <CardHeader>
                                <img
                                    alt="..."
                                    src={require("assets/img/card-primary.png")}
                                />
                                <CardTitle tag="h2">SetPass</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <FormGroup className={`has-label ${ passwordState}`}>
                                    <label>Password *</label>
                                    <Input  autoComplete="off"  id="password" type="password" name="password" onChange={e => this.handleChange(e, "password", "password")} value={this.state.password}
                                    />
                                    {this.state.passwordState=== "has-danger" ? (
                                        <label className="error">This field is required.</label>
                                    ) : null}
                                </FormGroup>
                                <FormGroup
                                    className={`has-label ${confirmPasswordState}`}
                                >
                                    <label>Confirm Password *</label>
                                    <Input equalto="#password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type="password"
                                        autoComplete="off" onChange={e => this.handleChange(e,"confirmPassword",
                                            "equalTo",
                                            "password")} value={this.state.confirmPassword}
                                    />
                                    {this.state.confirmPasswordState ===
                      "has-danger" ? (
                                            <label className="error">This field is required.</label>
                                        ) : null}
                                </FormGroup>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    block
                                    className="mb-3"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    size="lg"
                                    disabled={!this.validateForm()}
                                >
                      Get Started
                                </Button>
                                <div className="pull-left">
                                    <h6>
                                        <a
                                            className="link footer-link"
               
                                            onClick={this.registrationPage}
                                        >
                          Create Account
                                        </a>
                                    </h6>
                                </div>
                                <div className="pull-right">
                                    <h6>
                                        <a
                                            className="link footer-link"
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                          Need Help?
                                        </a>
                                    </h6>
                                </div>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
            </Container>
        </div>
      </>
      )
  }
}

export default withRouter( SetPassword)