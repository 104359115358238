export const LOGGED_IN="LOGGED_IN";
export const LogOut="LogOut";
export const INVALID_DATA="INVALID_DATA";
export const REGISTERD="REGISTERD";
export const REGISTERING="REGISTERING";
export const REGISTERING_FAILED="REGISTERING_FAILED";
export const UNLOCKED_FAILED = " UNLOCKED_FAILED";
export const UNLOCKED = "UNLOCKED";
export const LOCKED = "LOCKED";
export const LOCKED_STATE="LOCKED_STATE";
export const get_Users ="get_Users";
export const user_Loading = "user_Loading";
export const user_Failed = "user_Failed "


