import React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { baseUrl } from '../../Shared/baseUrl';
import cookie from 'react-cookies';
import ReactTable from 'react-table';

const initialState = {
	table: null,
	data: null,
};

class ParentRouteList extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);

		let details = {
			token: cookie.load('Token'),
			id: data.id,
			Permissions: data.Permissions,
		};

		fetch(baseUrl + '/routing/get-parent-routes', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(details.Permissions),
			},
			method: 'Get',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						data: jsonResponse.response.map((prop) => {
							return {
								id: prop.id,
								name: prop.name,
							};
						}),
					});

					this.setState({
						table: (
							<ReactTable
								data={this.state.data}
								filterable
								defaultFilterMethod={this.filterCaseInsensitive}
								resizable={false}
								columns={[
									{
										Header: 'ID',
										accessor: 'id',
									},
									{
										Header: 'Route Name',
										accessor: 'name',
									},
								]}
								getTdProps={(state, rowInfo, column, instance) => {
									return {
										onClick: (e) => this.rowClicked(rowInfo.original.id),
									};
								}}
								defaultPageSize={10}
								showPaginationTop
								showPaginationBottom={false}
								className='-striped -highlight'
							/>
						),
					});
				}
			})
			.catch((err) => {});
	}

	rowClicked(id) {
		let obj = this.state.data.find((o) => o.id === id);
		this.props.history.push(`/admin/edit-parent-route/${obj.id}`);
	}

	filterCaseInsensitive = (filter, row) => {
		const id = filter.pivotId || filter.id;
		const content = row[id];
		if (typeof content !== 'undefined') {
			// filter by text in the table or if it's a object, filter by key
			if (typeof content === 'object' && content !== null && content.key) {
				return String(content.key)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			} else {
				return String(content)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			}
		}

		return true;
	};

	render() {
		return (
			<div className='content'>
				{}
				<Col md={8} className='ml-auto mr-auto'>
					<h2 className='text-center'>Routes Info</h2>
					<p className='text-center'>
						This Table contain all the information about Routes{' '}
						<a href='' target='_blank' rel='noopener noreferrer'>
							Routes-table
						</a>
						. If need to modified Promotions please click on the buttons in the
						action column.{' '}
						<a href='' target='_blank' rel='noopener noreferrer'>
							full documentation.
						</a>
					</p>
				</Col>
				<Row className='mt-5'>
					<Col xs={12} md={12}>
						<Card>
							<CardHeader>
								<CardTitle tag='h4'>Routes Table</CardTitle>
							</CardHeader>
							<CardBody>{this.state.table}</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

export default ParentRouteList;
