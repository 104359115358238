/* eslint-disable no-cond-assign */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react"
import cookie from "react-cookies"
import ReactBSAlert from "react-bootstrap-sweetalert"
import Loader from "react-loader-spinner"
import LoadingOverlay from "react-loading-overlay"
import Select from "react-select"
import Geocode from 'react-geocode'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Label
} from "reactstrap"
import { baseUrl } from "../../Shared/baseUrl"

var dropDownSunday
var dropDownMonday
var dropDownTuesday
var dropDownWednesday
var dropDownThursday
var dropDownFriday
var dropDownSaturday


var showSundaySchedule
var showMondaySchedule
var showTuesdaySchedule
var showWednesdaySchedule
var showThursdaySchedule
var showFridaySchedule
var showSaturdaySchedule

var counter=0 

class Schedule extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            region:"",
            allowedRegions:[],
            selectedAllowedRegion:"",
            region:"",
            fs:"",
            route:"",
            pressedSunday:false,
            pressedMonday:false,
            pressedTuesday:false,
            pressedWednesday:false,
            pressedThursday:false,
            pressedFriday:false,
            pressedSaturday:false,
            sundayRoutes:[],
            mondayRoutes:[],
            tuesdayRoutes:[],
            wednesdayRoutes:[],
            thursdayRoutes:[],
            fridayRoutes:[],
            saturdayRoutes:[],
            sunday:"Sunday",
            monday:"Monday",
            tuesday:"Tuesday",
            wednesday:"Wednesday",
            thursday:"Thursday",
            friday:"Friday",
            saturday:"Saturday",
            sundayFs: "",
            sundayRoute: "",
            
           
     
        }
        this.successAlert = this.successAlert.bind(this)
        this.onPressSunday = this.onPressSunday.bind(this)
        this.onPressMonday = this.onPressMonday.bind(this)
        this.onPressTuesday = this.onPressTuesday.bind(this)
        this.onPressWednesday = this.onPressWednesday.bind(this)
        this.onPressThursday = this.onPressThursday.bind(this)
        this.onPressFriday = this.onPressFriday.bind(this)
        this.onPressSaturday = this.onPressSaturday.bind(this)
        this.addingSundaySchedule = this.addingSundaySchedule.bind(this)
        this.addingMondaySchedule = this.addingMondaySchedule.bind(this)
        this.addingTuesdaySchedule = this.addingTuesdaySchedule.bind(this)
        this.addingWednesdaySchedule = this.addingWednesdaySchedule.bind(this)
        this.addingThursdaySchedule = this.addingThursdaySchedule.bind(this)
        this.addingFridaySchedule = this.addingFridaySchedule.bind(this)
        this.addingSaturdaySchedule = this.addingSaturdaySchedule.bind(this)
       
        



    }


    componentWillUnmount() {
        var id = window.setTimeout(null, 0)
        while (id--) {
            window.clearTimeout(id)

        }

    }
    onPressSunday (){
        this.setState({ pressedSunday: true })
    }

    onPressMonday (){
        this.setState({ pressedMonday: true })
    }

    onPressTuesday (){
        this.setState({ pressedTuesday: true })
    }

    onPressWednesday (){
        this.setState({ pressedWednesday: true })
    }

    onPressThursday (){
        this.setState({ pressedThursday: true })
    }

    onPressFriday (){
        this.setState({ pressedFriday: true })
    }

    onPressSaturday (){
        this.setState({ pressedSaturday: true })
    }

 

    componentDidMount(){
    
        var user = JSON.parse(window.localStorage.getItem("user"))
        var data = JSON.parse(user)
       
        if(data.permissions !== null){
            const permissions = data.Permissions
            const getRoleId= Permissions => Permissions.regionCode
            const getRoleIdArray = permissions =>
                permissions.map(getRoleId)
            const regions = getRoleIdArray(permissions)
     
            var regionCodes = regions.filter(function (el){
                return el !== undefined
        
            })
          
            const getNameId= Permissions => Permissions.regionName
            const getNameIdArray = permissions =>
                permissions.map(getNameId)
            const regionsname = getNameIdArray(permissions)
            var regionName = regionsname.filter(function (el){
                return el !== undefined
        
            })
            
    
            var regionsJson = []
    
            for(var i in regionCodes) {
    
                var code = regionCodes[i]
                var label = regionName[i]
    
                regionsJson.push({
                    "code": code,
                    "label": label
                })
            }
            var result = [...new Map(regionsJson.map( o => [JSON.stringify(o), o])).values()]
    
            this.setState({allowedRegions
            :result})
        }





        
    
    ////////////////////////    Getting Field Supervisors    \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\


    var user = JSON.parse(window.localStorage.getItem("user"))
    var data = JSON.parse(user)
    var token = cookie.load("Token")

    fetch(baseUrl + "/auth/users/getFieldSupervisors" ,{

      headers : { 
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
          "id": data.id,
          "reg": JSON.stringify(data.Permissions)
      },
      method:"Post",
        
  }).then((res)=>res.json()).then((jsonResponse)=>{
      console.log(jsonResponse, "Check Json")
      if(jsonResponse.success === true){
          this.setState({fs:jsonResponse.data.map((props)=>{
              return{
                  value:props.username,
                  label:props.username
              }
          })
        })
      }
    
  }).catch(err =>{
      throw new Error(err)
  })
    //\\\\\\\\\\\\\\\\\\\\\\    Ending get field supervisors   /////////////////////////////////



        ////////////////////////    Getting Routes    \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\


    
        fetch(baseUrl + "/routing/getRouteDetails" ,{
    
          headers : { 
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + token,
              "id": data.id,
              "reg": JSON.stringify(data.Permissions)
          },
          method:"Post",
            
      }).then((res)=>res.json()).then((jsonResponse)=>{
          console.log(jsonResponse, "Check route")
          if(jsonResponse.success === true){
              this.setState({route:jsonResponse.routes.map((props)=>{
                  return{
                      value:props.subRoute,
                      label:props.subRoute
                  }
              })
            })
          }
        
      }).catch(err =>{
          throw new Error(err)
      })



        //\\\\\\\\\\\\\\\\\\\\\\    Ending get routes   /////////////////////////////////
    }   


    
     
                addingSundaySchedule () {    
                    
                        let inserData = {
                            fs:this.state.sundayFs,
                            day:this.state.sunday,
                            route:this.state.sundayRoute,
                            region:this.state.selectedAllowedRegion
                        }
                        this.setState({sundayRoutes:this.state.sundayRoutes.concat(inserData)})
                    
                        this.setState({pressedSunday:false})
                        this.setState({sundayFs:""})
                        this.setState({sundayRoute:""})
                        dropDownSunday=null
                }

                addingMondaySchedule () {    
                    
                    let inserData = {
                        fs:this.state.mondayFs,
                        day:this.state.monday,
                        route:this.state.mondayRoute,
                        region:this.state.selectedAllowedRegion
                    }
                    this.setState({mondayRoutes:this.state.mondayRoutes.concat(inserData)})
                
                    this.setState({pressedMonday:false})
                    this.setState({mondayFs:""})
                    this.setState({mondayRoute:""})
                    dropDownMonday=null
            }


                addingTuesdaySchedule () {    
                        
                    let inserData = {
                        fs:this.state.tuesdayFs,
                        day:this.state.tuesday,
                        route:this.state.tuesdayRoute,
                        region:this.state.selectedAllowedRegion
                    }
                    this.setState({tuesdayRoutes:this.state.tuesdayRoutes.concat(inserData)})
                
                    this.setState({pressedTuesday:false})
                    this.setState({tuesdayFs:""})
                    this.setState({tuesdayRoute:""})
                    dropDownTuesday=null
                }



                addingWednesdaySchedule () {    
                        
                    let inserData = {
                        fs:this.state.wednesdayFs,
                        day:this.state.wednesday,
                        route:this.state.wednesdayRoute,
                        region:this.state.selectedAllowedRegion
                    }
                    this.setState({wednesdayRoutes:this.state.wednesdayRoutes.concat(inserData)})
                
                    this.setState({pressedWednesday:false})
                    this.setState({wednesdayFs:""})
                    this.setState({wednesdayRoute:""})
                    dropDownWednesday=null
                    }

                addingThursdaySchedule () {    
        
                    let inserData = {
                        fs:this.state.thursdayFs,
                        day:this.state.thursday,
                        route:this.state.thursdayRoute,
                        region:this.state.selectedAllowedRegion
                    }
                    this.setState({thursdayRoutes:this.state.thursdayRoutes.concat(inserData)})
                  
                     this.setState({pressedThursday:false})
                     this.setState({thursdayFs:""})
                     this.setState({thursdayRoute:""})
                     dropDownThursday=null
                    }


                    addingFridaySchedule () {    
                
                        let inserData = {
                            fs:this.state.fridayFs,
                            day:this.state.friday,
                            route:this.state.fridayRoute,
                            region:this.state.selectedAllowedRegion
                        }
                        this.setState({fridayRoutes:this.state.fridayRoutes.concat(inserData)})
                    
                        this.setState({pressedFriday:false})
                        this.setState({fridayFs:""})
                        this.setState({fridayRoute:""})
                        dropDownFriday=null
                        }

                    addingSaturdaySchedule () {    
            
                        let inserData = {
                            fs:this.state.saturdayFs,
                            day:this.state.saturday,
                            route:this.state.saturdayRoute,
                            region:this.state.selectedAllowedRegion
                        }
                        this.setState({saturdayRoutes:this.state.saturdayRoutes.concat(inserData)})
                    
                        this.setState({pressedSaturday:false})
                        this.setState({saturdayFs:""})
                        this.setState({saturdayRoute:""})
                        dropDownSaturday=null
                        }
 
    
    
     
    successAlert()  {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Success!"
                    confirmBtnBsStyle="success"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    btnSize="">
              Route Schedule Added Successfully !
                </ReactBSAlert>
            )
        })
    }
hideAlert = () => {
    this.setState({
        alert: null
    })
}
errorAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Error"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="danger"
                btnSize=""
            >
  Route Schedule Could not be Added !!!!
     
            </ReactBSAlert>
        )
    })

};
resetForm(){
    this.setState({name:"",
    code:"",
    region:""})
}


  ChangeStatus =(event) => {
      this.setState({selectedAllowedRegion:event.label})
      


    var user = JSON.parse(window.localStorage.getItem("user"))
    var data = JSON.parse(user)

      let details = {
        token:cookie.load("Token"),
        id:data.id,
        permissions:data.Permissions
    }


    fetch(baseUrl + "/routing/getScheduledRoutesbyRegionSunday/"+event.label ,{

        headers : { 
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + details.token,
            "id": details.id,
            "reg": JSON.stringify(details.permissions)
        },
        method:"Post",
          
    }).then((res)=>res.json()).then((jsonResponse)=>{
        console.log(jsonResponse, "hello")
        if(jsonResponse.success === true){
            
                this.setState({dataSunday:jsonResponse.data.map((prop, key) => {
                    
                    return {
                        day:prop.day,
                        fs:prop.fs,
                        id:prop.id,
                        region:prop.region,
                        route:prop.subRoute
                    }
             
                })
 
            })
            
           
        }
        
      
    }).catch(err =>{
        // throw new Error(err)
    }) 


    fetch(baseUrl + "/routing/getScheduledRoutesbyRegionMonday/"+event.label ,{

        headers : { 
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + details.token,
            "id": details.id,
            "reg": JSON.stringify(details.permissions)
        },
        method:"Post",
          
    }).then((res)=>res.json()).then((jsonResponse)=>{
        console.log(jsonResponse, "hello")
        if(jsonResponse.success === true){
            
                this.setState({dataMonday:jsonResponse.data.map((prop, key) => {
                    
                    return {
                        day:prop.day,
                        fs:prop.fs,
                        id:prop.id,
                        region:prop.region,
                        route:prop.subRoute
                    }
             
                })
 
            })
            
           
        }
        
      
    }).catch(err =>{
        // throw new Error(err)
    })


    fetch(baseUrl + "/routing/getScheduledRoutesbyRegionTuesday/"+event.label ,{

        headers : { 
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + details.token,
            "id": details.id,
            "reg": JSON.stringify(details.permissions)
        },
        method:"Post",
          
    }).then((res)=>res.json()).then((jsonResponse)=>{
        console.log(jsonResponse, "hello")
        if(jsonResponse.success === true){
            
                this.setState({dataTuesday:jsonResponse.data.map((prop, key) => {
                    
                    return {
                        day:prop.day,
                        fs:prop.fs,
                        id:prop.id,
                        region:prop.region,
                        route:prop.subRoute
                    }
             
                })
 
            })
            
           
        }
        
      
    }).catch(err =>{
        // throw new Error(err)
    })


    fetch(baseUrl + "/routing/getScheduledRoutesbyRegionWednesday/"+event.label ,{

        headers : { 
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + details.token,
            "id": details.id,
            "reg": JSON.stringify(details.permissions)
        },
        method:"Post",
          
    }).then((res)=>res.json()).then((jsonResponse)=>{
        console.log(jsonResponse, "hello")
        if(jsonResponse.success === true){
            
                this.setState({dataWednesday:jsonResponse.data.map((prop, key) => {
                    
                    return {
                        day:prop.day,
                        fs:prop.fs,
                        id:prop.id,
                        region:prop.region,
                        route:prop.subRoute
                    }
             
                })
 
            })
            
           
        }
        
      
    }).catch(err =>{
        // throw new Error(err)
    })


    fetch(baseUrl + "/routing/getScheduledRoutesbyRegionThursday/"+event.label ,{

        headers : { 
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + details.token,
            "id": details.id,
            "reg": JSON.stringify(details.permissions)
        },
        method:"Post",
          
    }).then((res)=>res.json()).then((jsonResponse)=>{
        console.log(jsonResponse, "hello")
        if(jsonResponse.success === true){
            
                this.setState({dataThursday:jsonResponse.data.map((prop, key) => {
                    
                    return {
                        day:prop.day,
                        fs:prop.fs,
                        id:prop.id,
                        region:prop.region,
                        route:prop.subRoute
                    }
             
                })
 
            })
            
           
        }
        
      
    }).catch(err =>{
        // throw new Error(err)
    })


    fetch(baseUrl + "/routing/getScheduledRoutesbyRegionFriday/"+event.label ,{

        headers : { 
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + details.token,
            "id": details.id,
            "reg": JSON.stringify(details.permissions)
        },
        method:"Post",
          
    }).then((res)=>res.json()).then((jsonResponse)=>{
        console.log(jsonResponse, "hello")
        if(jsonResponse.success === true){
            
                this.setState({dataFriday:jsonResponse.data.map((prop, key) => {
                    
                    return {
                        day:prop.day,
                        fs:prop.fs,
                        id:prop.id,
                        region:prop.region,
                        route:prop.subRoute
                    }
             
                })
 
            })
            
           
        }
        
      
    }).catch(err =>{
        // throw new Error(err)
    })


    fetch(baseUrl + "/routing/getScheduledRoutesbyRegionSaturday/"+event.label ,{

        headers : { 
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + details.token,
            "id": details.id,
            "reg": JSON.stringify(details.permissions)
        },
        method:"Post",
          
    }).then((res)=>res.json()).then((jsonResponse)=>{
        console.log(jsonResponse, "hello")
        if(jsonResponse.success === true){
            
                this.setState({dataSaturday:jsonResponse.data.map((prop, key) => {
                    
                    return {
                        day:prop.day,
                        fs:prop.fs,
                        id:prop.id,
                        region:prop.region,
                        route:prop.subRoute
                    }
             
                })
 
            })
            
           
        }
        
      
    }).catch(err =>{
        // throw new Error(err)
    })
 
  }


  handleRouteUsers = idx => () => {
    this.setState({
        sundayRoutes: this.state.sundayRoutes.filter((s) => idx !== s.fs)
    })
 
};
  
addingRouteSchuduleFunction = (details) => {
    this.setState({loader:(
        <LoadingOverlay
            styles={{
                overlay: (base) => ({
                    ...base,
                    marginTop: "250px",
                    marginLeft: "-80px",
                })
            }}
            active={true}
            spinner={<Loader  type="CradleLoader"
 
                height="100"	
                width="100"/>}

        >
        </LoadingOverlay>

    )})
    var user = JSON.parse(window.localStorage.getItem("user"))
    var data = JSON.parse(user)
    var token = cookie.load("Token")
   
   
    let header = {
        userid:data.id,
        token:token,
        permissions:data.Permissions
    }
          var weekRoutesArray = details.sundayRoutes.concat(details.mondayRoutes, details.tuesdayRoutes, details.wednesdayRoutes, details.thursdayRoutes, details.fridayRoutes, details.saturdayRoutes)
                                           
                   fetch(baseUrl + "/routing/addRouteSchedule",{
                       headers : { 
                           "Content-Type": "application/json",
                           "Accept": "application/json",         
                           "Authorization": "Bearer " + header.token,
                           "id": header.userid,
                           "reg": JSON.stringify(header.permissions)
                       },
                       method:"Post",
                      
                       body:JSON.stringify({
                        weekArray:weekRoutesArray
                           
                       })
                   }).then((res)=>res.json()).then((jsonResponse)=>{
                       this.setState({loader:null})
                       if(jsonResponse.success === true){
                           this.successAlert()
                       }
                       else {
                           this.setState({loader:null})
                           this.errorAlert()
                       }
                     
                   }).catch(err =>{
                       this.setState({loader:null})
                       this.errorAlert()
                   })   

}





  handleSubmit = event => {
    var user = JSON.parse(window.localStorage.getItem("user"))
    var data = JSON.parse(user)
    var token = cookie.load("Token")
    let details = {
        sundayRoutes:this.state.sundayRoutes,
        mondayRoutes:this.state.mondayRoutes,
        tuesdayRoutes:this.state.tuesdayRoutes,
        wednesdayRoutes:this.state.wednesdayRoutes,
        thursdayRoutes:this.state.thursdayRoutes,
        fridayRoutes:this.state.fridayRoutes,
        saturdayRoutes:this.state.saturdayRoutes
             }
   
    this.addingRouteSchuduleFunction(details)

    this.resetForm()
}

handleSundayFsChange = (event) => {
    console.log(event, "checking fs change")
    this.setState({sundayFs:event.label})
}

handleSundayRouteChange = (event) => {
    console.log(event, "checking route change")
    this.setState({sundayRoute:event.label})
}

handleMondayFsChange = (event) => {
    console.log(event, "checking fs change")
    this.setState({mondayFs:event.label})
}

handleMondayRouteChange = (event) => {
    console.log(event, "checking route change")
    this.setState({mondayRoute:event.label})
}

handleTuesdayFsChange = (event) => {
    console.log(event, "checking fs change")
    this.setState({tuesdayFs:event.label})
}

handleTuesdayRouteChange = (event) => {
    console.log(event, "checking route change")
    this.setState({tuesdayRoute:event.label})
}

handleWednesdayFsChange = (event) => {
    console.log(event, "checking fs change")
    this.setState({wednesdayFs:event.label})
}

handleWednesdayRouteChange = (event) => {
    console.log(event, "checking route change")
    this.setState({wednesdayRoute:event.label})
}

handleThursdayFsChange = (event) => {
    console.log(event, "checking fs change")
    this.setState({thursdayFs:event.label})
}

handleThursdayRouteChange = (event) => {
    console.log(event, "checking route change")
    this.setState({thursdayRoute:event.label})
}

handleFridayFsChange = (event) => {
    console.log(event, "checking fs change")
    this.setState({fridayFs:event.label})
}

handleFridayRouteChange = (event) => {
    console.log(event, "checking route change")
    this.setState({fridayRoute:event.label})
}

handleSaturdayFsChange = (event) => {
    console.log(event, "checking fs change")
    this.setState({saturdayFs:event.label})
}

handleSatrudayRouteChange = (event) => {
    console.log(event, "checking route change")
    this.setState({saturdayRoute:event.label})
}

  render() {


    if(this.state.dataSunday){
        showSundaySchedule  = 
<>
{this.state.dataSunday.map((sunday, idx) => (
   <Row>
       <Col md={4}>
           <Label>FS Name</Label>
           <Input type="text" name="fs"  value={sunday.fs} />

           {/* <Input type="hidden" name="day" value="Sunday" ref={(input) => { this.day = input }} /> */}

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>
           <Input type="text" name="route" value={sunday.route} />
       </Col>
       
   </Row>
))}
     </> 
    }


    if(this.state.dataMonday){
        showMondaySchedule  = 
<>
{this.state.dataMonday.map((monday, idx) => (
   <Row>
       <Col md={4}>
           <Label>FS Name</Label>
           <Input type="text" name="fs"  value={monday.fs} />
           
           {/* <Input type="hidden" name="day" value="Sunday" ref={(input) => { this.day = input }} /> */}

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>
           <Input type="text" name="route" value={monday.route} />
       </Col>
       
   </Row>
))}
     </> 
    }


    if(this.state.dataTuesday){
        showTuesdaySchedule  = 
<>
{this.state.dataTuesday.map((tuesday, idx) => (
   <Row>
       <Col md={4}>
           <Label>FS Name</Label>
           <Input type="text" name="fs"  value={tuesday.fs} />
           {/* <Input type="hidden" name="day" value="Sunday" ref={(input) => { this.day = input }} /> */}

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>
           <Input type="text" name="route" value={tuesday.route} />
       </Col>
       
   </Row>
))}
     </> 
    }

    if(this.state.dataWednesday){
        showWednesdaySchedule  = 
<>
{this.state.dataWednesday.map((wednesday, idx) => (
   <Row>
       <Col md={4}>
           <Label>FS Name</Label>
           <Input type="text" name="fs"  value={wednesday.fs} />
           {/* <Input type="hidden" name="day" value="Sunday" ref={(input) => { this.day = input }} /> */}

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>
           <Input type="text" name="route" value={wednesday.route} />
       </Col>
       
   </Row>
))}
     </> 
    }


    if(this.state.dataThursday){
        showThursdaySchedule  = 
<>
{this.state.dataThursday.map((thursday, idx) => (
   <Row>
       <Col md={4}>
           <Label>FS Name</Label>
           <Input type="text" name="fs"  value={thursday.fs} />
           {/* <Input type="hidden" name="day" value="Sunday" ref={(input) => { this.day = input }} /> */}

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>
           <Input type="text" name="route" value={thursday.route} />
       </Col>
       
   </Row>
))}
     </> 
    }

    if(this.state.dataFriday){
        showFridaySchedule  = 
<>
{this.state.dataFriday.map((friday, idx) => (
   <Row>
       <Col md={4}>
           <Label>FS Name</Label>
           <Input type="text" name="fs"  value={friday.fs} />
           {/* <Input type="hidden" name="day" value="Sunday" ref={(input) => { this.day = input }} /> */}

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>
           <Input type="text" name="route" value={friday.route} />
       </Col>
       
   </Row>
))}
     </> 
    }

    if(this.state.dataSaturday){
        showSaturdaySchedule  = 
<>
{this.state.dataSaturday.map((saturday, idx) => (
   <Row>
       <Col md={4}>
           <Label>FS Name</Label>
           <Input type="text" name="fs"  value={saturday.fs} />
           {/* <Input type="hidden" name="day" value="Sunday" ref={(input) => { this.day = input }} /> */}

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>
           <Input type="text" name="route" value={saturday.route} />
       </Col>
       
   </Row>
))}
     </> 
    }















    if(this.state.pressedSunday == true){
        dropDownSunday  = 
<>
    
   <Row>
       <Col md={4}>
           <Label>FS Name</Label>
          
            <Select  
            onChange={this.handleSundayFsChange}
            options={this.state.fs} 
            placeholder='Select FS' ></Select>

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>

            <Select  
             onChange={this.handleSundayRouteChange}
             options={this.state.route} 
             placeholder='Select Route' ></Select>
                
       </Col>
       <Col md={4}>
           <Button style={{background:"transparent",marginTop:21}} onClick={this.addingSundaySchedule}>Save</Button>
       </Col>
       
   </Row>
     </>  
    }

    else if(this.state.pressedMonday == true){
        dropDownMonday  = 
<>
    
<Row>
<Col md={4}>
           <Label>FS Name</Label>
          
            <Select  
            onChange={this.handleMondayFsChange}
            options={this.state.fs} 
            placeholder='Select FS' ></Select>

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>

            <Select  
             onChange={this.handleMondayRouteChange}
             options={this.state.route} 
             placeholder='Select Route' ></Select>
                
       </Col>
       <Col md={4}>
           <Button style={{background:"transparent",marginTop:21}} onClick={this.addingMondaySchedule}>Save</Button>
       </Col>
       
   </Row>
     </>  
    }

    else if(this.state.pressedTuesday == true){
        dropDownTuesday  = 
<>
    
<Row>
<Col md={4}>
           <Label>FS Name</Label>
          
            <Select  
            onChange={this.handleTuesdayFsChange}
            options={this.state.fs} 
            placeholder='Select FS' ></Select>

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>

            <Select  
             onChange={this.handleTuesdayRouteChange}
             options={this.state.route} 
             placeholder='Select Route' ></Select>
                
       </Col>
       <Col md={4}>
           <Button style={{background:"transparent",marginTop:21}} onClick={this.addingTuesdaySchedule}>Save</Button>
       </Col>
       
   </Row>
     </>  
    }

    else if(this.state.pressedWednesday == true){
        dropDownWednesday  = 
<>
    
<Row>
<Col md={4}>
           <Label>FS Name</Label>
          
            <Select  
            onChange={this.handleWednesdayFsChange}
            options={this.state.fs} 
            placeholder='Select FS' ></Select>

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>

            <Select  
             onChange={this.handleWednesdayRouteChange}
             options={this.state.route} 
             placeholder='Select Route' ></Select>
                
       </Col>
       <Col md={4}>
           <Button style={{background:"transparent",marginTop:21}} onClick={this.addingWednesdaySchedule}>Save</Button>
       </Col>
       
   </Row>
     </>  
    }

    else if(this.state.pressedThursday == true){
        dropDownThursday  = 
<>
    
   <Row>
   <Col md={4}>
           <Label>FS Name</Label>
          
            <Select  
            onChange={this.handleThursdayFsChange}
            options={this.state.fs} 
            placeholder='Select FS' ></Select>

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>

            <Select  
             onChange={this.handleThursdayRouteChange}
             options={this.state.route} 
             placeholder='Select Route' ></Select>
                
       </Col>
       <Col md={4}>
           <Button style={{background:"transparent",marginTop:21}} onClick={this.addingThursdaySchedule}>Save</Button>
       </Col>
       
   </Row>
     </>  
    }

    else if(this.state.pressedFriday == true){
        dropDownFriday  = 
<>
    
<Row>
<Col md={4}>
           <Label>FS Name</Label>
          
            <Select  
            onChange={this.handleFridayFsChange}
            options={this.state.fs} 
            placeholder='Select FS' ></Select>

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>

            <Select  
             onChange={this.handleFridayRouteChange}
             options={this.state.route} 
             placeholder='Select Route' ></Select>
                
       </Col>
       <Col md={4}>
           <Button style={{background:"transparent",marginTop:21}} onClick={this.addingFridaySchedule}>Save</Button>
       </Col>
       
   </Row>
     </>  
    }

    else if(this.state.pressedSaturday == true){
        dropDownSaturday  = 
<>
    
<Row>
<Col md={4}>
           <Label>FS Name</Label>
          
            <Select  
            onChange={this.handleSaturdayFsChange}
            options={this.state.fs} 
            placeholder='Select FS' ></Select>

       </Col>
       <Col md={4}>
           <Label>Route Name</Label>

            <Select  
             onChange={this.handleSatrudayRouteChange}
             options={this.state.route} 
             placeholder='Select Route' ></Select>
                
       </Col>
       <Col md={4}>
           <Button style={{background:"transparent",marginTop:21}} onClick={this.addingSaturdaySchedule}>Save</Button>
       </Col>
       
   </Row>
     </>  
    }
   
      return (
        
      <>
        <div className="content">
            {this.state.alert}
       
            {this.state.loader}
            <Row >
                <Col className="mr-auto" xs={{size:3,offset:3}}  md="6">
          
                    <Form id="RegisterValidation">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4"> Route Scheduling </CardTitle>
                            </CardHeader>
                            <CardBody>
                                
                                <FormGroup className={"has-label"}>
                                    <label> Region Name *</label>
                                    <Select  onChange={this.ChangeStatus} name="region"
                                    options={this.state.allowedRegions} 
                                    // value={this.state.selectedAllowedRegion}
                                     placeholder='Select Region' ></Select>
                                </FormGroup>
                                {this.state.region!==this.state.selectedAllowedRegion ?
                                (
                                   
                                    <FormGroup>
                                    <FormGroup>
                                        <Row>
                                        <Col xs={5}>
                                            <label><h5>Sunday</h5></label>
                                        </Col>
                                        <Col xs={{size:3,offset:4}}>
                                            <Button style={{background:"transparent"}} onClick={this.onPressSunday}>
                                                <span className="tim-icons icon-simple-add"></span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    {showSundaySchedule}
                                    {dropDownSunday} 
                                    <FormGroup>
                                    {this.state.sundayRoutes.map((Role, idx) => (
                                        <div>
                                            <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Field Supervisor</label>
                                                    <input placeholder ={Role.fs} value={Role.fs} />                                 
                                                </Col> 
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Route</label>
                                                <input placeholder ={Role.route} value={Role.route} />
                                                </Col>
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                    <Button style={{marginTop:24,background:"transparent"}} onClick={this.handleRouteUsers(Role.fs)}>✖</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </FormGroup> 
                                    <Row>
                                        <Col xs={5}>
                                            <label><h5>Monday</h5></label>
                                        </Col>
                                        <Col xs={{size:3,offset:4}}>
                                            <Button style={{background:"transparent"}} onClick={this.onPressMonday}>
                                                <span className="tim-icons icon-simple-add"></span>
                                            </Button>
                                        </Col>
                                    </Row> 
                                    {showMondaySchedule}
                                    {dropDownMonday}
                                    <FormGroup>
                                    {this.state.mondayRoutes.map((Role, idx) => (
                                        <div>
                                            <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Field Supervisor</label>
                                                    <input placeholder ={Role.fs} value={Role.fs} />                                 
                                                </Col> 
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Route</label>
                                                <input placeholder ={Role.route} value={Role.route} />
                                                </Col>
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                    <Button style={{marginTop:24,background:"transparent"}} onClick={this.handleRouteUsers(Role.fs)}>✖</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </FormGroup> 
                                    <Row>
                                        <Col xs={5}>
                                            <label><h5>Tuesday</h5></label>
                                        </Col>
                                        <Col xs={{size:3,offset:4}}>
                                            <Button style={{background:"transparent"}} onClick={this.onPressTuesday}>
                                                <span className="tim-icons icon-simple-add"></span>
                                            </Button>
                                        </Col>
                                    </Row> 
                                    {showTuesdaySchedule}
                                    {dropDownTuesday}
                                    <FormGroup>
                                    {this.state.tuesdayRoutes.map((Role, idx) => (
                                        <div>
                                            <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Field Supervisor</label>
                                                    <input placeholder ={Role.fs} value={Role.fs} />                                 
                                                </Col> 
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Route</label>
                                                <input placeholder ={Role.route} value={Role.route} />
                                                </Col>
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                    <Button style={{marginTop:24,background:"transparent"}} onClick={this.handleRouteUsers(Role.fs)}>✖</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </FormGroup> 
                                    <Row>
                                        <Col xs={5}>
                                            <label><h5>Wednesday</h5></label>
                                        </Col>
                                        <Col xs={{size:3,offset:4}}>
                                            <Button style={{background:"transparent"}} onClick={this.onPressWednesday}>
                                                <span className="tim-icons icon-simple-add"></span>
                                            </Button>
                                        </Col>
                                    </Row> 
                                    {showWednesdaySchedule}
                                    {dropDownWednesday}
                                    <FormGroup>
                                    {this.state.wednesdayRoutes.map((Role, idx) => (
                                        <div>
                                            <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Field Supervisor</label>
                                                    <input placeholder ={Role.fs} value={Role.fs} />                                 
                                                </Col> 
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Route</label>
                                                <input placeholder ={Role.route} value={Role.route} />
                                                </Col>
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                    <Button style={{marginTop:24,background:"transparent"}} onClick={this.handleRouteUsers(Role.fs)}>✖</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </FormGroup> 
                                    <Row>
                                        <Col xs={5}>
                                            <label><h5>Thursday</h5></label>
                                        </Col>
                                        <Col xs={{size:3,offset:4}}>
                                            <Button style={{background:"transparent"}} onClick={this.onPressThursday}>
                                                <span className="tim-icons icon-simple-add"></span>
                                            </Button>
                                        </Col>
                                    </Row> 
                                    {showThursdaySchedule}
                                    {dropDownThursday}
                                    <FormGroup>
                                    {this.state.thursdayRoutes.map((Role, idx) => (
                                        <div>
                                            <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Field Supervisor</label>
                                                    <input placeholder ={Role.fs} value={Role.fs} />                                 
                                                </Col> 
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Route</label>
                                                <input placeholder ={Role.route} value={Role.route} />
                                                </Col>
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                    <Button style={{marginTop:24,background:"transparent"}} onClick={this.handleRouteUsers(Role.fs)}>✖</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </FormGroup> 
                                    <Row>
                                        <Col xs={5}>
                                            <label><h5>Friday</h5></label>
                                        </Col>
                                        <Col xs={{size:3,offset:4}}>
                                            <Button style={{background:"transparent"}} onClick={this.onPressFriday}>
                                                <span className="tim-icons icon-simple-add"></span>
                                            </Button>
                                        </Col>
                                    </Row> 
                                    {showFridaySchedule}
                                    {dropDownFriday}
                                    <FormGroup>
                                    {this.state.fridayRoutes.map((Role, idx) => (
                                        <div>
                                            <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Field Supervisor</label>
                                                    <input placeholder ={Role.fs} value={Role.fs} />                                 
                                                </Col> 
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Route</label>
                                                <input placeholder ={Role.route} value={Role.route} />
                                                </Col>
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                    <Button style={{marginTop:24,background:"transparent"}} onClick={this.handleRouteUsers(Role.fs)}>✖</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </FormGroup> 
                                    <Row>
                                        <Col xs={5}>
                                            <label><h5>Saturday</h5></label>
                                        </Col>
                                        <Col xs={{size:3,offset:4}}>
                                            <Button style={{background:"transparent"}} onClick={this.onPressSaturday}>
                                                <span className="tim-icons icon-simple-add"></span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    {showSaturdaySchedule}
                                    {dropDownSaturday}
                                    <FormGroup>
                                    {this.state.saturdayRoutes.map((Role, idx) => (
                                        <div>
                                            <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Field Supervisor</label>
                                                    <input placeholder ={Role.fs} value={Role.fs} />                                 
                                                </Col> 
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                <label>Route</label>
                                                <input placeholder ={Role.route} value={Role.route} />
                                                </Col>
                                                </Row>
                                                <Row>
                                                <Col md="3" xs={{size:1,offset:1}}>
                                                    <Button style={{marginTop:24,background:"transparent"}} onClick={this.handleRouteUsers(Role.fs)}>✖</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </FormGroup> 
                                    </FormGroup>
                                   
                                      </FormGroup>
                                )
                                :null
                                }

                                

                               

                            </CardBody>
                            <CardFooter className="text-right">
                                <Button color="primary"     
                                    onClick={this.handleSubmit}
                                >
                      Schedule
                                </Button>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
          
            </Row>
        </div>
      </>
      )
  }
}

export default Schedule