import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { configStore } from './Redux/ConfigStore';
import { Provider } from 'react-redux';
import AuthLayout from 'layouts/Auth/Auth.jsx';
import AdminLayout from 'layouts/Admin/Admin.jsx';
import PrivacyPolicy from 'views/About/PrivacyPolicy.jsx';
import RTLLayout from 'layouts/RTL/RTL.jsx';
import 'assets/css/nucleo-icons.css';
import 'assets/scss/black-dashboard-pro-react.scss?v=1.0.0';
import 'assets/demo/demo.css';
import 'react-notification-alert/dist/animate.css';

const hist = createBrowserHistory();
const store = configStore();

Sentry.init({
	dsn: window._env_.REACT_APP_ERROR_REPORTING_KEY,
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 1.0,
});

ReactDOM.render(
	<Provider store={store}>
		<Router history={hist}>
			<Switch>
				<Route path='/auth' render={(props) => <AuthLayout {...props} />} />
				<Route path='/admin' render={(props) => <AdminLayout {...props} />} />
				<Route path='/rtl' render={(props) => <RTLLayout {...props} />} />
				<Route
					path='/privacy'
					render={(props) => <PrivacyPolicy {...props} />}
				/>
				<Redirect from='/' to='/admin/dashboard' />
			</Switch>
		</Router>
	</Provider>,
	document.getElementById('root'),
);
