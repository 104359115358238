import React from 'react';
import { baseUrl } from '../../Shared/baseUrl';
import cookie from 'react-cookies';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
} from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';

const user = JSON.parse(window.localStorage.getItem('user'));
const userData = JSON.parse(user);
const token = cookie.load('Token');
const initialState = {
	name: '',
	isDeleted: false,
};

class EditParentRoute extends React.Component {
	constructor(props) {
		super(props);
		this.state = { alert: null, ...initialState };
	}

	componentDidMount() {
		fetch(`${baseUrl}/routing/get-parent-route/${this.props.match.params.id}`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: userData.id,
				reg: JSON.stringify(userData.permissions),
			},
			method: 'Get',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				this.setState({ name: jsonResponse.response.name });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	handleSubmit = () => {
		fetch(
			`${baseUrl}/routing/update-parent-route/${this.props.match.params.id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
					id: userData.id,
					reg: JSON.stringify(userData.permissions),
				},
				method: 'post',
				body: JSON.stringify({
					name: this.state.name,
				}),
			},
		)
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert(jsonResponse.message);
				} else {
					this.errorAlert(jsonResponse.message);
				}
			});
	};

	successAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					{message}
				</ReactBSAlert>
			),
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null,
		});

		if (this.state.isDeleted) {
			this.props.history.push('/admin/parent-route-list');
		}
	};

	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message}
				</ReactBSAlert>
			),
		});
	};

	handleCancelButton = () => {
		this.props.history.push('/admin/parent-route-list');
	};

	handleDeleteButton = () => {
		fetch(
			`${baseUrl}/routing/delete-parent-route/${this.props.match.params.id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
					id: userData.id,
					reg: JSON.stringify(userData.permissions),
				},
				method: 'Delete',
			},
		)
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({ isDeleted: true });
					this.successAlert(jsonResponse.message);
				} else {
					this.errorAlert(jsonResponse.message);
				}
			});
	};

	render() {
		return (
			<div className='content'>
				{this.state.alert}
				<Row>
					<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
						<Form id='RegisterValidation'>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Add Route</CardTitle>
								</CardHeader>
								<CardBody>
									<FormGroup className={'has-label'}>
										<label> Route Name *</label>
										<Input
											type='text'
											name='name'
											onChange={this.handleChange}
											value={this.state.name}
										/>
									</FormGroup>
								</CardBody>
								<CardFooter className='text-right'>
									<Button
										className='btn-round'
										color='primary'
										size='md'
										onClick={this.handleSubmit}
									>
										Update
									</Button>
									<Button
										className='btn-round'
										color='info'
										size='md'
										onClick={this.handleCancelButton}
									>
										Cancel
									</Button>
									<Button
										className='btn-round'
										color='danger'
										size='md'
										onClick={this.handleDeleteButton}
									>
										Delete
									</Button>
								</CardFooter>
							</Card>
						</Form>
					</Col>
				</Row>
			</div>
		);
	}
}

export default EditParentRoute;
