/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import { baseUrl } from '../../Shared/baseUrl';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Switch from 'react-bootstrap-switch';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Button,
	Badge,
} from 'reactstrap';
var dropDown;
var memo;
var uId;
var uName;
class EditVehicleDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			loader: null,
			db_id: '',
			vehicleNumber: '',
			vehicleType: [
				{ label: 'Rented', value: 'Rented' },
				{ label: 'Owned', value: 'Owned' },
			],
			selectedVehicleType: '',
			make: '',
			managers: [],
			assignTo: [],
			selectedAssignee: '',
			selectedManager: '',
			dropDown1array: [],
			regionDrop1: false,
			permissions: [],
			Roles_Permissions: [],
			productCategory: [],
			checked: false,
		};
		this.successAlert = this.successAlert.bind(this);
		this.updateVehicleDetails = this.updateVehicleDetails.bind(this);
	}
	hide() {
		this.setState({
			isActive: false,
		});
	}
	successAlert(message) {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					onCancel={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					btnSize=''
				>
					{message ? message : 'Vehicle Details updated Successfully !'}
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	hideDeleteAlert = () => {
		this.setState({
			alert: null,
		});

		this.props.history.push('/admin/vehicleList');
	};

	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : 'Something Went Wrong!!!!!'}
				</ReactBSAlert>
			),
		});
	};

	ChangeVehicleType = (event) => {
		this.setState({ selectedVehicleType: event });
	};

	ChangeManager = (event) => {
		this.setState({ selectedManager: event });
	};

	changeAssignee = (event) => {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		this.state.selectedAssignee && this.state.selectedAssignee.value &&
			fetch(baseUrl + '/fleet/updateDriverStatusOnChange', {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
					id: data.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: 'Post',

				body: JSON.stringify({
					previousDriverId: this.state.selectedAssignee.value,
				}),
			})
				.then((res) => res.json())
				.then((jsonResponse) => {
					this.setState({ loader: null });
					if (jsonResponse.success === true) {
						fetch(baseUrl + '/fleet/getAvailableDrivers', {
							headers: {
								'Content-Type': 'application/json',
								Accept: 'application/json',
								Authorization: 'Bearer ' + token,
								id: data.id,
								reg: JSON.stringify(data.Permissions),
							},
							method: 'Post',
						})
							.then((res) => res.json())
							.then((jsonResponse) => {
								if (jsonResponse.success === true) {
									this.setState({
										assignTo: jsonResponse.Availabledriver.map((props) => {
											return {
												value: props.id,
												label: props.name,
											};
										}),
									});

									this.updateVehicleDetails();
								}
							})
							.catch((err) => {
								throw new Error(err);
							});
					} else {
						console.log(jsonResponse);
					}
				})
				.catch((err) => {
					this.errorAlert();
				});

		this.setState({ selectedAssignee: event });
	};

	componentWillMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/auth/users/getRSM', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.userid,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.setState({
						managers: jsonResponse.data.map((props) => {
							return {
								value: props.id,
								label: props.username,
							};
						}),
					});
				} else {
					this.setState({ loader: null });
				}
			})
			.catch((err) => {
				this.setState({ loader: null });
			});
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/fleet/getvehicleDetails/' + this.props.match.params.id, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						db_id: jsonResponse.vehicle.id,
						vehicleNumber: jsonResponse.vehicle.vehicleNumber,
						selectedVehicleType: {
							value: jsonResponse.vehicle.vehicleType,
							label: jsonResponse.vehicle.vehicleType,
						},
						make: jsonResponse.vehicle.make,
						selectedManager: this.state.managers.find(
							(m) => m.value === jsonResponse.vehicle.saleManagerId,
						),
						selectedAssignee: jsonResponse.driver
							? {
								value: jsonResponse.driver.id,
								label: jsonResponse.driver.name,
							  }
							: '',
						loader: null,
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
		fetch(baseUrl + '/fleet/getAvailableDrivers', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						assignTo: jsonResponse.Availabledriver.map((props) => {
							return {
								value: props.id,
								label: props.name,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	};

	updateVehicleDetails() {
		this.setState({
			innerloader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: '250px',
							marginLeft: '-80px',
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			),
		});

		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		let details = {
			vehicleNumber: this.state.vehicleNumber,
			vehicleType: this.state.selectedVehicleType.label,
			make: this.state.make,
			selectedManager: this.state.selectedManager.value,
			selectedAssignee: this.state.selectedAssignee.value,
		};
		fetch(
			baseUrl + '/fleet/updateVehicleDetails/' + this.props.match.params.id,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
					id: data.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: 'Post',
				body: JSON.stringify({
					vehicleNumber: details.vehicleNumber,
					vehicleType: details.vehicleType,
					make: details.make,
					selectedManager: details.selectedManager,
					selectedAssignee: details.selectedAssignee,
				}),
			},
		)
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({ innerloader: null });
					this.successAlert();
					this.state.selectedAssignee &&
						fetch(
							baseUrl +
								'/fleet/updateDriverStatus/' +
								this.state.selectedAssignee.value,
							{
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
									Authorization: 'Bearer ' + token,
									id: data.id,
									reg: JSON.stringify(data.Permissions),
								},
								method: 'Post',
							},
						)
							.then((res) => res.json())
							.then((jsonResponse) => {
								this.setState({ loader: null });
								if (jsonResponse.success === true) {
									// this.successAlert()
								} else {
									this.errorAlert();
								}
							})
							.catch((err) => {
								this.errorAlert();
							});
				} else {
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert();
				throw new Error(err);
			});
	}
	handleCancelButton = () => {
		this.props.history.push('/admin/vehicleList');
	};

	handleDeleteButton = () => {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(`${baseUrl}/fleet/delete-vehicle/${this.props.match.params.id}`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Delete',
			body: JSON.stringify({
				driverId: this.state.selectedAssignee.value,
			}),
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert(jsonResponse.message);
				} else {
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert();
			});
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col className='mr-auto' md={{ size: 7, offset: 2 }}>
							<Form>
								<Card>
									<CardHeader>
										<CardTitle tag='h3'>Edit Vehicle Details</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup className={'has-label'}>
											<label> Vehicle Number *</label>

											<Input
												type='text'
												name='vehicleNumber'
												onChange={this.handleChange}
												value={this.state.vehicleNumber}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Vehicle Type *</label>

											<Select
												onChange={this.ChangeVehicleType}
												options={this.state.vehicleType}
												value={this.state.selectedVehicleType}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Make *</label>

											<Input
												type='text'
												name='make'
												onChange={this.handleChange}
												value={this.state.make}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Manager *</label>

											<Select
												onChange={this.ChangeManager}
												options={this.state.managers}
												value={this.state.selectedManager}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Assign To</label>

											<Select
												onChange={this.changeAssignee}
												options={this.state.assignTo}
												value={this.state.selectedAssignee}
												noOptionsMessage={() => 'Other Drivers Not Available'}
											/>
										</FormGroup>
									</CardBody>
									<CardFooter className='text-right'>
										<Button
											className='btn-round'
											color='primary'
											onClick={this.updateVehicleDetails}
											size='md'
										>
											Update
										</Button>
										<Button
											className='btn-round'
											color='info'
											onClick={this.handleCancelButton}
											size='md'
										>
											Cancel
										</Button>
										<Button
											className='btn-round'
											color='danger'
											onClick={this.handleDeleteButton}
											size='md'
										>
											Delete
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default withRouter(EditVehicleDetails);
