/* eslint-disable no-cond-assign */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* global google */
import React from 'react';
import cookie from 'react-cookies';
import { Polyline, Circle } from 'react-google-maps';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import { GoogleMap, withScriptjs, withGoogleMap, InfoWindow, Marker, DirectionsRenderer } from 'react-google-maps';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
import vector from '../../assets/img/googlemapicon.png';

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
} from 'reactstrap';
import { baseUrl } from '../../Shared/baseUrl';
import Maps from '../components/Maps';
Geocode.setApiKey(window._env_.REACT_APP_GOOGLE_MAP_API_KEY);
Geocode.enableDebug();
var dropDown;
var counter = 0;

class TripTracking extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			places: [],
			fieldSupervisors: [],
			salesPoints: [],
			salePoints: [],
			selectedFS: null,
			latestLat: '',
			latestLng: '',
			coordinates: [],
			directions: null,

			center: {
				lat: 31.4805,
				lng: 74.3239,

			},
			mapPosition: {
				lat: 31.4805,
				lng: 74.3239

			},
			markerPosition: {
				lat: 31.4805,
				lng: 74.3239
			},


		};

	}


	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);

		}

	}


	componentDidMount() {
		// Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
		// 	response => {
		// 		console.log('res', response);

		// 		const address = response.results[0].formatted_address,
		// 			addressArray = response.results[0].address_components,
		// 			city = this.getCity(addressArray),
		// 			area = this.getArea(addressArray),
		// 			state = this.getState(addressArray);
		// 		this.setState({
		// 			address: (address) ? address : '',
		// 			area: (area) ? area : '',
		// 			city: (city) ? city : '',
		// 			state: (state) ? state : '',

		// 		});

		// 	},

		// 	error => {
		// 		console.error(error);
		// 	}
		// );
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			userid: data.id,
			token: token,
			permissions: data.Permissions

		};
		fetch(baseUrl + '/auth/users/getFieldSupervisors', {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer ' + details.token,
				'id': details.userid,
				'reg': JSON.stringify(details.permissions)
			},
			method: 'Post',

		}).then((res) => res.json()).then((jsonResponse) => {
			this.setState({ loader: null });
			if (jsonResponse.success === true) {
				console.log(jsonResponse, 'FS');
				this.setState({
					fieldSupervisors: jsonResponse.data.map((fs, key) => {
						return {
							value: fs.id,
							label: fs.username,
						};
					})
				});

			}
			else {
				throw new Error('Error from backend');
			}

		}).catch(err => {
			throw new Error(err);
		});

		// this.interval = setInterval(() => {
		// 	this.getLocation();
		// }, 30000);

	}

	// getLocation() {

	// 	var user = JSON.parse(window.localStorage.getItem('user'));
	// 	var data = JSON.parse(user);
	// 	var token = cookie.load('Token');
	// 	let details = {
	// 		userid: data.id,
	// 		token: token,
	// 		permissions: data.Permissions

	// 	};
	// 	fetch(baseUrl + '/routing/getLatestLocation', {
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			'Accept': 'application/json',
	// 			'Authorization': 'Bearer ' + details.token,
	// 			'id': details.userid,
	// 			'reg': JSON.stringify(details.permissions)
	// 		},
	// 		method: 'Post',
	// 		body: JSON.stringify({
	// 			fs: this.state.selectedFS
	// 		})

	// 	}).then((res) => res.json()).then((jsonResponse) => {
	// 		console.log(jsonResponse, 'check this out');
	// 		if (jsonResponse.success === true) {
	// 			this.setState({
	// 				latestLat: parseFloat(jsonResponse.data[0].lat),
	// 				lastestLng: parseFloat(jsonResponse.data[0].long),
	// 			});
	// 		}
	// 		else {
	// 			throw new Error('Error from backend');
	// 		}

	// 	}).catch(err => {
	// 		console.log(err);
	// 	});

	// }

	validateForm() {
		console.log(this.state.spVerified, 'verify');
		return this.state.code;
	}
	getCity = (addressArray) => {
		let city = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	getArea = (addressArray) => {
		let area = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};
	getState = (addressArray) => {
		let state = '';
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};

	//    setDirections = () =>{
	//      console.log(this.state.mapPosition,"length")
	//      const directionsService = new window.google.maps.DirectionsService();
	//      const waypoints = this.state.places.map(p =>({
	//        location: {lat: p.lat, lng:p.lng},
	//        stopover: true
	//    }))
	//    const origin = waypoints.shift().location;
	//    const destination = waypoints.pop().location;

	//                        console.log(this.state.coordinates,"Coordinates in then")

	//    //  const n=(this.state.waypoints.length) - 1
	//      // console.log(n,"length")
	//      //  var waypoints =[]
	//  //       {


	//  //         location: new window.google.maps.LatLng(31.504168, 74.331234),
	//  //         stopover:false
	//  //     },
	//  //     {

	//  //         location: new window.google.maps.LatLng(31.477025,74.369134),
	//  //         stopover:false
	//  //     },
	//  //     {


	//  //       location: new window.google.maps.LatLng(31.477025,74.274992),
	//  //       stopover:false
	//  //   }
	//  // ]
	//      // const origin = { lat: 31.504168,  lng: 74.331234};
	//      // const destination = { lat: 31.477025, lng: 74.369134};
	//      //  console.log(waypoints, "My waypoints")
	//      directionsService.route(
	//        {
	//          origin: origin,
	//          destination: destination,
	//          // origin: new window.google.maps.LatLng(this.state.waypoints[0].lat,this.state.waypoints[0].lng),
	//          // destination: new window.google.maps.LatLng(this.state.waypoints[2].lat,this.state.waypoints[2].lng),
	//          travelMode: window.google.maps.TravelMode.DRIVING,
	//          // waypoints:this.state.waypoints
	//          waypoints: waypoints,
	//          optimizeWaypoints: true,
	//        },
	//        (result, status) => {
	//          if (status === window.google.maps.DirectionsStatus.OK) {
	//            this.setState({
	//              directions: result
	//            });
	//          } else {
	//            console.error(`error fetching directions ${result}`);
	//          }
	//        }
	//      );
	//    }
	ChangeFS = (event) => {
		this.setState({ selectedFS: event.value, selectedFSId: event.id });
		// var user = JSON.parse(window.localStorage.getItem('user'));
		// var data = JSON.parse(user);
		// var token = cookie.load('Token');
		// let details = {
		// 	userid: data.id,
		// 	token: token,
		// 	permissions: data.Permissions
		// };

		// var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		// var d = new Date();
		// var dayname = days[d.getDay()];
		// fetch(baseUrl + '/routing/getRouteScheduale', {
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 		'Accept': 'application/json',
		// 		'Authorization': 'Bearer ' + details.token,
		// 		'id': details.userid,
		// 		'reg': JSON.stringify(details.permissions)
		// 	},
		// 	method: 'Post',
		// 	body: JSON.stringify({
		// 		username: event.value,
		// 		day: dayname,

		// 	}),
		// }).then((res) => res.json()).then((jsonResponse) => {
		// 	return jsonResponse;
		// }).then(function (data) {
		// 	console.log(data, 'Data');
		// 	return fetch(baseUrl + '/routing/getSalesPointsByRoutes', {
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 			'Accept': 'application/json',
		// 			'Authorization': 'Bearer ' + details.token,
		// 			'id': details.userid,
		// 			'reg': JSON.stringify(details.permissions)
		// 		},
		// 		method: 'Post',
		// 		body: JSON.stringify({
		// 			routeName: data.data.route,
		// 		})
		// 	});
		// }).then((res) => res.json()).then((jsonResponse) => {
		// 	console.log(jsonResponse, 'yellow');
		// 	if (jsonResponse.success === true) {

		// 		//  this.setState({
		// 		//    dbRoute: jsonResponse.salepoints.name,
		// 		//    dbRegion: jsonResponse.salepoints.region
		// 		//          })  

		// 		this.setState({
		// 			salePoints: JSON.parse(jsonResponse.salepoints.salesPoints)
		// 		});

		// 	}
		// 	else {
		// 		this.setState({ loader: null });
		// 	}

		// }).then(() => {
		// 	console.log(this.state.salePoints, 'shit');
		// 	this.setState({
		// 		places: this.state.salePoints.map((props) => {
		// 			return {
		// 				lat: parseFloat(props.lat),
		// 				lng: parseFloat(props.lng)
		// 			};
		// 		})
		// 	});
		// })
		// 	.catch(err => {
		// 		this.setState({ loader: null });
		// 	});
		//  this.setDirections()



	}

	render() {

		return (

			<>
				<div className="content">
					{this.state.alert}

					{this.state.loader}
					<Row >
						<Col className="mr-auto" xs={{ size: 3, offset: 3 }} md="6">

							<Form id="RegisterValidation">
								<Card>
									<CardHeader>
										<CardTitle tag="h4">Trip Tracking</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup className={'has-label'}>
											<label> FS Name *</label>
											<Select
												onChange={(value) => { this.setState({ selectedFS: value }); }}
												options={this.state.fieldSupervisors}
												value={this.state.selectedFS || null}
												placeholder='Select FS' >
											</Select>

										</FormGroup>
										<FormGroup style={{ width: '94%', marginLeft: '22px' }}>
											{
												this.state.selectedFS &&
												<Maps userId={this.state.selectedFS.value} />
											}
										</FormGroup>
										{/* <Button color="primary"     
                                     onClick={this.setDirections}
                                 >
                             Get directions!
                                 </Button> */}
									</CardBody>
								</Card>
							</Form>
						</Col>

					</Row>
				</div>
			</>
		);
	}
}
export default TripTracking;