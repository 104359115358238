import * as ActionType from './ActionTypes';

export const Login = (state = {
    LogOut: true,
    INVALID_DATA: null,
    LOGGED_IN: '',
}, action) => {

    switch (action.type) {
        case ActionType.LogOut:
            return { ...state, LogOut: true, INVALID_DATA: null, LOGGED_IN: '' };

        case ActionType.LOGGED_IN:
            return { ...state, LogOut: false, INVALID_DATA: null, LOGGED_IN: action.payload };


        case ActionType.INVALID_DATA:
            return { ...state, LogOut: false, INVALID_DATA: action.payload };

        default:
            return state;

    }
};