/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

// react component for creating dynamic tables
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { getUsers } from '../../Redux/ActionCreator';
import { baseUrl } from '../../Shared/baseUrl';
import { any } from 'prop-types';
const mapStateToProps = (state) => {
	return {
		Users: state.Users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (details) => dispatch(getUsers(details)),
	};
};

class listPromo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: null,
			table: null,
		};
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);

		let details = {
			token: cookie.load('Token'),
			id: data.id,
			Permissions: data.Permissions,
		};

		fetch(baseUrl + '/routing/getRouteDetails', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(details.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						data: jsonResponse.routes.map((prop, key) => {
							return {
								id: prop.id,
								subRouteName: prop.name,
								regionName: prop.region.name,
								routeName: prop.parentRoute.name,
							};
						}),
					});

					this.setState({
						table: (
							<ReactTable
								data={this.state.data}
								filterable
								defaultFilterMethod={this.filterCaseInsensitive}
								resizable={false}
								columns={[
									{
										Header: 'Route Name',
										accessor: 'routeName',
									},
									{
										Header: 'Sub Route Name',
										accessor: 'subRouteName',
									},
									{
										Header: 'Region',
										accessor: 'regionName',
									},
								]}
								getTdProps={(state, rowInfo, column, instance) => {
									return {
										onClick: (e) => this.rowClicked(rowInfo.original.id),
									};
								}}
								defaultPageSize={10}
								showPaginationTop
								showPaginationBottom={false}
								className='-striped -highlight'
							/>
						),
					});
				}
			})
			.catch((err) => {});
	}

	formatDate(string) {
		var options = { year: 'numeric', month: 'long', day: 'numeric' };
		return new Date(string).toLocaleDateString([], options);
	}

	rowClicked(id) {
		let obj = this.state.data.find((o) => o.id === id);
		this.props.history.push(`/admin/editRoute/${obj.id}`);
	}

	filterCaseInsensitive = (filter, row) => {
		const id = filter.pivotId || filter.id;
		const content = row[id];
		if (typeof content !== 'undefined') {
			// filter by text in the table or if it's a object, filter by key
			if (typeof content === 'object' && content !== null && content.key) {
				return String(content.key)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			} else {
				return String(content)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			}
		}

		return true;
	};

	render() {
		return (
			<>
				<div className='content'>
					{}
					<Col md={8} className='ml-auto mr-auto'>
						<h2 className='text-center'>Sub Routes Info</h2>
						<p className='text-center'>
							This Table contain all the information about Routes{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								Routes-table
							</a>
							. If need to modified Promotions please click on the buttons in
							the action column.{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								full documentation.
							</a>
						</p>
					</Col>
					<Row className='mt-5'>
						<Col xs={12} md={12}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Routes Table</CardTitle>
								</CardHeader>
								<CardBody>{this.state.table}</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(listPromo);
