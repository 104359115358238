/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Label,
	Form,
	Input,
	Row,
	Col,
} from 'reactstrap';
import { baseUrl } from '../../Shared/baseUrl';

const initialState = {
	loader: null,
	vehicleNumber: '',
	vehicleType: [
		{ label: 'Rented', value: 'Rented' },
		{ label: 'Owned', value: 'Owned' },
	],
	selectedVehicleType: '',
	make: '',
	managers: [],
	assignTo: [],
	selectedAssignee: '',
	selectedManager: '',
	dropDown1array: [],
	regionDrop1: false,
	permissions: [],
	Roles_Permissions: [],
	productCategory: [],
	checked: false,
};
class AddVehicle extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...initialState, alert: null };

		this.successAlert = this.successAlert.bind(this);
		this.onAddRolesPress = this.onAddRolesPress.bind(this);
	}

	handleCheckboxChange = (event) => {
		this.setState({ checked: event.target.checked });
	};

	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}
	onAddRolesPress() {
		this.setState({ pressed: true });
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/fleet/getAvailableDrivers', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						assignTo: jsonResponse.Availabledriver.map((props) => {
							return {
								value: props.id,
								label: props.name,
							};
						}),
					});

					this.setState({
						assignTo: [{value: null, label: ''}, ...this.state.assignTo]
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});

		fetch(baseUrl + '/auth/users/getRSM', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.userid,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.setState({
						managers: jsonResponse.data.map((props) => {
							return {
								value: props.id,
								label: props.username,
							};
						}),
					});
				} else {
					this.setState({ loader: null });
				}
			})
			.catch((err) => {
				this.setState({ loader: null });
			});
	}

	ChangeVehicleType = (event) => {
		this.setState({ selectedVehicleType: event });
	};
	ChangeManager = (event) => {
		this.setState({ selectedManager: event });
	};
	changeAssignee = (event) => {
		this.setState({ selectedAssignee: event });
	};
	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					Vehicle Added Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : 'Something Went Wrong !!!!!'}
				</ReactBSAlert>
			),
		});
	};
	addingVehicleFunction = () => {
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		var token = cookie.load('Token');

		let details = {
			vehicleNumber: this.state.vehicleNumber,
			vehicleType: this.state.selectedVehicleType.label,
			make: this.state.make,
			selectedManager: this.state.selectedManager.value,
			selectedAssignee: this.state.selectedAssignee.value,
		};
		fetch(baseUrl + '/fleet/addVehicle', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				vehicleNumber: details.vehicleNumber,
				vehicleType: details.vehicleType,
				make: details.make,
				selectedManager: details.selectedManager,
				selectedAssignee: details.selectedAssignee,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success) {
					this.successAlert();
					this.componentDidMount();
					this.resetForm();
				} else {
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert();
			});

		if (this.state.selectedAssignee.value) {
			fetch(
				baseUrl +
					'/fleet/updateDriverStatus/' +
					this.state.selectedAssignee.value,
				{
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: 'Bearer ' + token,
						id: data.id,
						reg: JSON.stringify(data.Permissions),
					},
					method: 'Post',
				},
			)
				.then((res) => res.json())
				.then((jsonResponse) => {
					this.setState({ loader: null });
					if (jsonResponse.success === true) {
						// this.successAlert()
					} else {
						this.errorAlert();
					}
				})
				.catch((err) => {
					this.errorAlert();
				});
		}
	};

	resetForm() {
		this.setState(initialState);
	}

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};
	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col className='mr-auto' md={{ size: 7, offset: 2 }}>
							<Form>
								<Card>
									<CardHeader>
										<CardTitle tag='h3'>Add Vehicle</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup className={'has-label'}>
											<Label> Vehicle Number *</Label>

											<Input
												type='text'
												name='vehicleNumber'
												onChange={this.handleChange}
												value={this.state.vehicleNumber}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<Label> Vehicle Type *</Label>

											<Select
												onChange={this.ChangeVehicleType}
												options={this.state.vehicleType}
												value={this.state.selectedVehicleType}
											></Select>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<Label> Make *</Label>

											<Input
												type='text'
												name='make'
												onChange={this.handleChange}
												value={this.state.make}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<Label> Manager *</Label>

											<Select
												onChange={this.ChangeManager}
												options={this.state.managers}
												value={this.state.selectedManager}
											></Select>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<Label> Assign To</Label>

											<Select
												onChange={this.changeAssignee}
												options={this.state.assignTo}
												value={this.state.selectedAssignee}
												noOptionsMessage={() => 'Drivers Not Available'}
											></Select>
										</FormGroup>
									</CardBody>
									<CardFooter className='text-right'>
										<Button
											color='primary'
											onClick={this.addingVehicleFunction}
										>
											Add Vehicle
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default AddVehicle;
