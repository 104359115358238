/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import { baseUrl } from '../../Shared/baseUrl';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Switch from 'react-bootstrap-switch';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Button,
	Badge,
} from 'reactstrap';

class editWarehouse extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			region: [],
			fa: [],
			whName: '',
			selectedRegion: '',
			selectedFa: '',
			alert: null,
			loader: null,
		};
	}

	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					Warehouse Updated Successfully !
				</ReactBSAlert>
			),
		});
	}

	deleteSuccessAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.props.history.push('/admin/listWarehouse')}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					Warehouse Deleted Successfully !
				</ReactBSAlert>
			),
		});
	}

	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	errorAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					Something went wrong!!
				</ReactBSAlert>
			),
		});
	};

	//Function to delete warehouse
	handleDeleteButton = () => {
		fetch(baseUrl + '/inventory/deleteWarehouse', {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify({ id: this.props.match.params.id }),
		})
			.then((res) => res.json())
			.then((res) => {
				this.deleteSuccessAlert();
				console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	handleCancelButton = () => {
		this.props.history.push('/admin/listWarehouse');
	};

	changeRegion = (event) => {
		this.setState({ selectedRegion: event });
	};

	changeFa = (event) => {
		this.setState({ selectedFa: event });
	};

	componentWillMount() {
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		let details = {
			token: cookie.load('Token'),
			id: data.id,
			permissions: data.Permissions,
		};

		fetch(baseUrl + '/auth/users/getRegion', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						region: jsonResponse.Regions.map((props) => {
							return {
								value: props.id,
								label: props.name,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});

		fetch(baseUrl + '/auth/users/getFa', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						fa: jsonResponse.data.map((props) => {
							return {
								value: props.id,
								label: props.username,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}

	componentDidMount() {
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		let details = {
			token: cookie.load('Token'),
			id: data.id,
			permissions: data.Permissions,
		};

		fetch(
			baseUrl + '/inventory/getWarehouseById/' + this.props.match.params.id,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + details.token,
					id: details.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: 'Post',
			},
		)
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						whName: jsonResponse.data.whName,
						selectedRegion: this.state.region.find(
							(r) => r.value === jsonResponse.data.regionId,
						),
						selectedFa: this.state.fa.find(
							(f) => f.value === jsonResponse.data.faId,
						),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}

	handleSubmit = (event) => {
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		var token = cookie.load('Token');
		fetch(
			baseUrl + '/inventory/modifyWarehouse/' + this.props.match.params.id,
			{
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
					id: data.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: 'Post',
				body: JSON.stringify({
					whName: this.state.whName,
					regionId: this.state.selectedRegion.value,
					faId: this.state.selectedFa.value,
				}),
			},
		)
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.successAlert();
				} else {
					this.errorAlert();
				}
			});
	};

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col md={{ size: 7, offset: 2 }}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Warehouse Detail</CardTitle>
								</CardHeader>
								<CardBody>
									<Form action='/' className='form-horizontal' method='get'>
										<Row>
											<Col md={12}>
												<FormGroup>
													<Label>Warehouse Name</Label>
													<Input
														type='text'
														name='whName'
														onChange={this.handleChange}
														value={this.state.whName}
													/>
												</FormGroup>
												<FormGroup>
													<Label>Region</Label>
													<Select
														name='region'
														onChange={this.changeRegion}
														options={this.state.region}
														value={this.state.selectedRegion}
													/>
												</FormGroup>
												<FormGroup>
													<Label>FA</Label>
													<Select
														name='region'
														onChange={this.changeFa}
														options={this.state.fa}
														value={this.state.selectedFa}
													/>
												</FormGroup>
											</Col>
										</Row>
									</Form>
								</CardBody>
								<CardFooter className='text-right'>
									<Button
										className='btn-round'
										color='primary'
										onClick={this.handleSubmit}
										size='md'
									>
										Update
									</Button>
									<Button
										className='btn-round'
										color='info'
										onClick={this.handleCancelButton}
										size='md'
									>
										Cancel
									</Button>
									<Button
										className='btn-round'
										color='danger'
										onClick={this.handleDeleteButton}
										size='md'
									>
										Delete
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default withRouter(editWarehouse);
