/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from "react"
import { connect } from "react-redux"
import cookie from "react-cookies"
import { withRouter } from "react-router-dom"
import ReactBSAlert from "react-bootstrap-sweetalert"
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col
} from "reactstrap"
import { unlockFunction,  lockState } from "../../Redux/ActionCreator"
const mapStateToProps = (state) => {
    return{
        Lock:state.Lock,
        Login:state.Login,
      
    }

}
var username
const mapDispatchToProps = (dispatch) => {
    return {
        unlock:(details) => dispatch(unlockFunction(details)),
        unlockedFailed:() => dispatch(lockState())
    }
}
class Lock extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            password : "",
            alert:null,
            userInfo:""
        }
    }

    componentWillReceiveProps(nextProps){

        if(nextProps.Lock.UNLOCKED_FAILED){
            this.errorAlert()
        }
   
    } 
    shouldComponentUpdate() {
  
        return this.state.alert === null
    }
  errorAlert = () => {
      this.setState({
          alert: (
              <ReactBSAlert
                  danger
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Wrong Details !!!"
                  onConfirm={() => this.hideAlert()}
                  onCancel={() => this.hideAlert()}
                  confirmBtnBsStyle="danger"
                  btnSize=""
              >
         Wrong Password !!!!!!
         
              </ReactBSAlert>
          )
      })
    
  };
  hideAlert = () => {
      this.setState({
          alert: null
      },()=>{this.props.unlockedFailed()})
  };
  componentDidMount() {
      var user = JSON.parse(window.localStorage.getItem("user"))
      var data = JSON.parse(user)
      this.setState({userInfo:data})
      username = data.username
      document.body.classList.toggle("lock-page")

  }
  componentWillUnmount() {
      document.body.classList.toggle("lock-page")
  }
unlock =() =>{
    var token = cookie.load("Token")
    let details = {
        userid:this.state.userInfo.id,
        password:this.state.password,
        token: token
    }
    this.props.unlock(details)
    window.localStorage.setItem("lockState",this.props.Login.LOGGED_IN.success)
}
handleChange =(event)=> {
    const target = event.target
    const value =  target.value
    const name = target.name

    this.setState({
        [name]: value
    })
  
}
routeChange(){
    return this.props.history.push("/admin/dashboard")
}

render() {
    var lockState = window.localStorage.getItem("lockState")
    if(this.props.Lock.UNLOCKED  && lockState !== null){
        window.localStorage.removeItem("lockState")
        this.routeChange()
    }
    return (
      <>
        <div className="content">
            {this.state.alert}
            <Container>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                    <Card className="card-lock card-white text-center">
                        <CardHeader>
                            <img alt="..."src={require("assets/img/placeholder.png")} />
                        </CardHeader>
                        <CardBody>
                            <CardTitle tag="h4">{username}</CardTitle>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="tim-icons icon-key-25" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Password" type="password" name="password" onChange={this.handleChange} value={this.state.password}
                                />
                            </InputGroup>
                        </CardBody>
                        <CardFooter>
                            <Button
                                className="btn-round"
                                color="primary"
                                size="lg"
                                onClick={this.unlock}
                            >
                    Unlock
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Container>
        </div>
      </>
    )
}
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)( Lock))
