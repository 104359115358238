/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import { baseUrl } from '../../Shared/baseUrl';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Switch from 'react-bootstrap-switch';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Button,
	Badge,
} from 'reactstrap';
var dropDown;
var memo;

class editSalesTarget extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fs: [],
			salesTarget: '',
			targetDate: '',
			allowedRegions: [],
			selectedAllowedRegion: '',
			selectedFs: '',
			selectedSt: '',
			date: '',
			lclDose: '',
			impDose: '',
			salesTarget: '',
			loader: null,
			subRoutes: [],
			selectedSubRoute: null,
		};
		this.updateUser = this.updateUser.bind(this);

		this.successAlert = this.successAlert.bind(this);
	}
	hide() {
		this.setState({
			isActive: false,
		});
	}

	handleCheckboxChange = (event) => {
		this.setState({ checked: event.target.checked });
	};
	successAlert(message) {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					onCancel={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					btnSize=''
				>
					{message ? message : `Sales target Updated Successfully !`}
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	hideDeleteAlert = () => {
		this.setState({
			alert: null,
		});

		this.props.history.push('/admin/listSalesTarget');
	};

	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : `Could not update sales target !!!!!`}
				</ReactBSAlert>
			),
		});
	};

	componentWillMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		let header = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};

		fetch(`${baseUrl}/routing/getRouteDetails`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + header.token,
				id: header.userid,
				reg: JSON.stringify(header.permissions),
			},
			method: 'Post',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						subRoutes: jsonResponse.routes.map((route) => {
							return {
								label: route.name,
								value: route.id,
							};
						}),
					});
				}
			});

		fetch(baseUrl + '/auth/users/getFieldSupervisors', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + header.token,
				id: header.userid,
				reg: JSON.stringify(header.permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.setState({
						fs: jsonResponse.data.map((props) => {
							return {
								value: props.id,
								label: props.username,
							};
						}),
					});
				} else {
					this.setState({ loader: null });
				}
			})
			.catch((err) => {
				this.setState({ loader: null });
			});
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		let header = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};

		fetch(baseUrl + '/pos/getSalesTargetbyId/' + this.props.match.params.id, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + header.token,
				id: header.userid,
				reg: JSON.stringify(header.permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.setState({
						selectedFs: this.state.fs.find(
							(fs) => fs.value === jsonResponse.data.fsId,
						),
						salesTarget: jsonResponse.data.target,
						targetDate: jsonResponse.data.date,
						impDose: jsonResponse.data.importedDoseQty,
						lclDose: jsonResponse.data.localDoseQty,
						loader: null,
						selectedSubRoute: this.state.subRoutes.find(
							(r) => r.value === jsonResponse.data.subRouteId,
						),
					});
				} else {
					this.setState({ loader: null });
				}
			})
			.catch((err) => {
				this.setState({ loader: null });
			});
	}

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	updateUser() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};

		fetch(baseUrl + '/pos/editSalesTarget/' + this.props.match.params.id, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.userid,
				reg: JSON.stringify(details.permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				fsId: this.state.selectedFs.value,
				salesTarget: this.state.salesTarget,
				date: this.state.targetDate,
				lclDose: this.state.lclDose,
				impDose: this.state.impDose,
				userId: details.userid,
				subRouteId: this.state.selectedSubRoute.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.successAlert();
				} else {
					this.setState({ loader: null });
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				console.log(err);
				this.errorAlert();
			});
	}

	handleCancelButton = () => {
		this.props.history.push('/admin/listSalesTarget');
	};

	fsChange = (event) => {
		this.setState({ selectedFs: event });
	};

	stChange = (event) => {
		this.setState({ selectedSt: event.label });
	};

	ChangeStatus = (event) => {
		this.setState({ selectedAllowedRegion: event.label });
	};

	handleDeleteButton = () => {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			userid: data.id,
			userName: data.username,
			token: token,
			permissions: data.Permissions,
		};

		fetch(`${baseUrl}/pos/delete-sale-target/${this.props.match.params.id}`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.userid,
				reg: JSON.stringify(details.permissions),
			},
			method: 'Delete',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert(jsonResponse.message);
				} else {
					this.errorAlert(jsonResponse.message);
				}
			});
	};

	handleSubRouteChange = (event) => {
		this.setState({ selectedSubRoute: event });
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}

					{this.state.loader}
					<Row>
						<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h4'>Sales Target</CardTitle>
									</CardHeader>
									<CardBody>
										<Row>
											<Col md={6}>
												<FormGroup>
													<Label>Field Supervisor </Label>
													<Select
														options={this.state.fs}
														value={this.state.selectedFs}
														onChange={this.fsChange}
													></Select>
												</FormGroup>

												<FormGroup>
													<Label>Select Sub Route</Label>
													<Select
														options={this.state.subRoutes}
														value={this.state.selectedSubRoute}
														onChange={this.handleSubRouteChange}
													></Select>
												</FormGroup>

												<FormGroup>
													<Label>Imported Doses (qty) </Label>
													<Input
														type='text'
														name='impDose'
														value={this.state.impDose}
														onChange={this.handleChange}
													/>
												</FormGroup>

												<FormGroup>
													<Label>Local Doses (qty) </Label>
													<Input
														type='text'
														name='lclDose'
														value={this.state.lclDose}
														onChange={this.handleChange}
													/>
												</FormGroup>

												<FormGroup>
													<Label>Sales Target </Label>
													<Input
														type='text'
														name='salesTarget'
														value={this.state.salesTarget}
														onChange={this.handleChange}
													/>
												</FormGroup>

												<FormGroup>
													<Label>Target for the Month </Label>
													<Input
														type='month'
														name='targetDate'
														value={this.state.targetDate}
														onChange={this.handleChange}
													/>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									<CardFooter className='text-right'>
										<Button
											className='btn-round'
											color='primary'
											onClick={this.updateUser}
											size='md'
										>
											Update
										</Button>
										<Button
											className='btn-round'
											color='info'
											onClick={this.handleCancelButton}
											size='md'
										>
											Cancel
										</Button>
										<Button
											className='btn-round'
											color='danger'
											onClick={this.handleDeleteButton}
											size='md'
										>
											Delete
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default withRouter(editSalesTarget);
