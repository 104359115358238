/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from "react"
import cookie from "react-cookies"
import { baseUrl } from "../../Shared/baseUrl"
import ReactBSAlert from "react-bootstrap-sweetalert"
import Loader from "react-loader-spinner"
import LoadingOverlay from "react-loading-overlay"
import Switch from "react-bootstrap-switch"
import { withRouter } from "react-router-dom"
import Select from "react-select"
// reactstrap components
import {

    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Button
} from "reactstrap"
import { cpus } from "os"
var dropDown
class EditSms extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message:"",
            trigger:[
                { label: "Route Scheduled", value: "t1" },
            ],
            active:Boolean,
            Roles:[],
            loader:"",
            alert: null,
            switchCheck:false,
            fetchedRoles:[],
            Regions:[],
            defaultRegion:null,
            roleSelected:[],
            selectedRegion:"",
            dropDown1array:[],
            regionDrop1:false,
            pressed:false,
            permissions:[],
            Roles_Permissions:[],
            checked:false,
            selectedTrigger_type:"",

        }

        this.updateUser = this.updateUser.bind(this)
        this.handleswitchChange = this.handleswitchChange.bind(this)
    }

handleCheckboxChange = event =>{
    this.setState({ checked: event.target.checked })
}
successAlert()  {
    this.setState({
        alert: (
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                confirmBtnBsStyle="success"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                btnSize="">
            Message Updated Successfully !
            </ReactBSAlert>
        )
    })
}
hideAlert = () => {
    this.setState({
        alert: null
    })
}

errorAlert = () => {
    this.setState({
        alert: (
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title="Error"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="danger"
                btnSize=""
            >
     Error !!!!!
       
            </ReactBSAlert>
        )
    })
  
};


handleswitchChange(checked,event,id) {
    this.setState({ active:event })
}
handleRemoveShareholder = idx => () => {
    this.setState({
        Roles: this.state.Roles.filter((s) => idx !== s.id)
    })
    this.setState({Roles_Permissions:this.state.Roles_Permissions.filter((s)=> idx !== s.id)})
};
componentDidMount(){
    var user = JSON.parse(window.localStorage.getItem("user"))
    var data = JSON.parse(user)
    
    let details = {
        token:cookie.load("Token"),
        id:data.id
    }

  console.log(this.props,"props")
    fetch(baseUrl + "/sms/getSms/"+this.props.match.params.id ,{

        headers : { 
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + details.token,
            "id": details.id
        },
        method:"Post",
          
    }).then((res)=>res.json()).then((jsonResponse)=>{
        if(jsonResponse.success === true){
            this.setState({
                message:jsonResponse.Sms.message,
                selectedTrigger_type:jsonResponse.Sms.trigger,
                loader:null})
        }
      
    }).catch(err =>{
        throw new Error(err)
    })
}

  handleChange =(event)=> {
      const target = event.target
      const value =  target.value
      const name = target.name
      this.setState({
          [name]: value
      })
    
  }
  onAddRolesPress  = () =>{
      this.setState({ pressed: true })   
  }
  updateUser(){
      this.setState({innerloader:(
          <LoadingOverlay
              styles={{
                  overlay: (base) => ({
                      ...base,
                      marginTop: "250px",
                      marginLeft: "-80px",
                  })
              }}
              active={true}
              spinner={<Loader  type="CradleLoader"
    
                  height="100"	
                  width="100"/>}
   
          >
          </LoadingOverlay>
      )})
      var user = JSON.parse(window.localStorage.getItem("user"))
      var data = JSON.parse(user)

      let details = {
          token:cookie.load("Token"),
          id:data.id
      }
  
      fetch(baseUrl + "/sms/modifiedSms/"+this.props.match.params.id,{

          headers : { 
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + details.token,
              "id": details.id
          },
          method:"Post",
          body:JSON.stringify({
              message: this.state.message,
              trigger:this.state.selectedTrigger_type,

          
          })
          
      }).then((res)=>res.json()).then((jsonResponse)=>{
          this.setState({innerloader:null})
          this.successAlert()
          this.componentDidMount()

      }).catch(err =>{
          this.errorAlert()    
          throw new Error(err)
      })
  }

  ChangeType =(event) => {
    this.setState({selectedTrigger_type:event.label})
  
  }

  validateForm() {
      var permissions = window.localStorage.getItem("permissions")
      var Check = permissions.includes("modifiedUser")
      return Check === true
  }
handleCancelButton = () =>{
    this.props.history.push("/admin/listSms")
}


render() {
    if(this.state.pressed == true){
        dropDown  = 
<>

     </>  
    }
    let {
        emailState,
        usernameState,
  
    } = this.state
    if(this.state.loader !== null){
        return(
            <LoadingOverlay
                styles={{
                    overlay: (base) => ({
                        ...base,
                        marginTop: "250px",
                        marginLeft: "50px",
                    })
                }}
                active={true}
                spinner={<Loader  
                    type="CradleLoader"
                    height="100"	
                    width="100"/>}
 
            >
            </LoadingOverlay>
        )

    }
    if(this.state.loader === null){
        return (
    <>
      <div className="content">
          {this.state.alert}
          {this.state.loader}
          <Row>
      
              <Col md={{size:7,offset:2}}>
                  <Card>
                      <CardHeader>
                          <CardTitle tag="h4">Message Detail</CardTitle>
                      </CardHeader>
                      <CardBody>
                                
                                <FormGroup className={`has-label ${ emailState}`}>
                                    <label>Trigger *</label>
                                    <Select options={this.state.trigger} onChange={this.ChangeType} placeholder={this.state.selectedTrigger_type}>
                                          </Select>
                                </FormGroup>
                                <FormGroup className={`has-label ${usernameState}`}>
                                    <label>Message *</label>
                    
                                    <textarea rows='8' cols='50' style={{background:"transparent", color:"white"}} type="text" name="message" onChange={this.handleChange} value={this.state.message}/>

                   
                                    {this.state.usernameState === "has-danger" ? (
                                        <label className="error">
                          Please enter a valid message.
                                        </label>
                                    ) : null}
                                </FormGroup>
                            </CardBody>
                      <CardFooter className="text-right" >
                          <Button
                              className="btn-round"
                              color="primary"
                              onClick={this.updateUser}
                              size="lg"
                          >
                     Update
                          </Button>
                          <Button
                              className="btn-round"
                              color="primary"
                              onClick={this.handleCancelButton}
                              size="lg"
                          >
                  Cancel
                          </Button>
                      </CardFooter>
                  </Card>
              </Col>
         
          </Row>
      </div>
    </>
        )
    }

}
}

export default withRouter(EditSms)
