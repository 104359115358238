import React, { Component } from 'react';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Label,
	Input,
	Row,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { baseUrl } from '../../Shared/baseUrl';

var counter = 0;
const initialState = {
	product_name: '',
	sku: '',
	productCategory: [],
	regions: [],
	region: [],
	parentCategory: [
		{ label: 'Imported', value: 'Imported' },
		{ label: 'Local', value: 'Local' },
	],
	selectedproduct_category: '',
	price: '',
	skuState: '',
	selectedparentCategory: '',
	skuVerified: false,
	skuVerifiedText: true,
};
class AddProduct extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.successAlert = this.successAlert.bind(this);
	}
	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}
	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/auth/users/getRegion', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				console.log(jsonResponse, 'Check Json');
				if (jsonResponse.success === true) {
					this.setState({
						regions: jsonResponse.Regions.map((props) => {
							return {
								value: props.id,
								label: props.name,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}
	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					Product Added Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : `Something Went Wrong !!!!!`}
				</ReactBSAlert>
			),
		});
	};
	verifySKU = (event) => {
		var value = event.target.value;
		var skuRex = /^(([A-Z]+[A-Z]+[0-9]+[0-9]+[0-9]))$/;
		if (skuRex.test(value)) {
			this.setState({ skuVerified: true, skuVerifiedText: true });
			return true;
		}

		this.setState({ skuVerified: false, skuVerifiedText: false });
		return false;
	};
	SkuExist = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					sku already Exist!!!!
				</ReactBSAlert>
			),
		});
	};
	validateForm() {
		return this.state.skuVerified === true;
	}

	ChangeProductCat = (event) => {
		this.setState({ selectedproduct_category: event });
	};
	addingProductFunction = () => {
		this.setState({
			loader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: '250px',
							marginLeft: '-80px',
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			),
		});

		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		console.log(data.Permissions, 'check');
		var token = cookie.load('Token');
		let details = {
			product_name: this.state.product_name,
			sku: this.state.sku,
			product_category: this.state.selectedproduct_category.value,
			price: this.state.price,
			regions: this.state.regions,
		};

		fetch(baseUrl + '/pos/addProduct', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				productCatId: details.product_category,
				product_name: details.product_name,
				sku: details.sku,
				price: details.price,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					fetch(baseUrl + '/pos/AddProductPrice', {
						headers: {
							'Content-Type': 'application/json',
							Accept: 'application/json',
							Authorization: 'Bearer ' + token,
							id: data.id,
							reg: JSON.stringify(data.Permissions),
						},
						method: 'Post',
						body: JSON.stringify({
							region: details.regions,
							price: details.price,
							productId: jsonResponse.data.id,
						}),
					})
						.then((res) => res.json())
						.then((jsonResponse) => {
							this.setState({ loader: null });
							if (jsonResponse.success === true) {
								this.resetForm();
								this.successAlert();
								this.componentDidMount();
							} else {
								// this.resetForm();
								this.errorAlert();
							}
						})
						.catch((err) => {
							// this.resetForm();
							this.errorAlert();
						});
				} else {
					// this.resetForm();
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				// this.resetForm();
				this.errorAlert();
			});
	};

	resetForm() {
		this.setState(initialState);
	}
	handleSubmit = (event) => {
		counter = 0;
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		var token = cookie.load('Token');
		fetch(baseUrl + '/pos/productsList', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						data: jsonResponse.product.map((prop, key) => {
							return {
								db_sku: prop.sku,
							};
						}),
					});
					console.log(this.state.sku, 'sku');
					for (let i = 0; i < this.state.data.length; i++) {
						if (this.state.data[i].db_sku === this.state.sku) {
							counter = counter + 1;
						}
					}
					if (counter !== 0) {
						this.SkuExist();
					} else {
						this.addingProductFunction();
					}
				}
			});
	};
	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	onFilesAdded(event) {
		this.setState({
			files: event.target.files[0],
		});
	}

	ChangeParentCat = (event) => {
		this.setState({ selectedparentCategory: event });

		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/pos/getProductCategory', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				parentCat: event.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					console.log('ProductCatg', jsonResponse);
					this.setState({
						productCategory: jsonResponse.productCatg.map((props) => {
							return {
								value: props.id,
								label: props.productCat,
							};
						}),
					});

					this.setState({ selectedproduct_category: '' });
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col className='mr-auto' md={{ size: 6, offset: 3 }}>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h3'>Add New Product</CardTitle>
									</CardHeader>
									<CardBody>
										<Col md={12}>
											<FormGroup>
												<label>Main Category </label>
												<Select
													onChange={this.ChangeParentCat}
													options={this.state.parentCategory}
													value={this.state.selectedparentCategory}
												/>
											</FormGroup>
											<FormGroup>
												<label>Product Category </label>
												<Select
													onChange={this.ChangeProductCat}
													options={this.state.productCategory}
													value={this.state.selectedproduct_category}
												/>
											</FormGroup>
											<FormGroup>
												<label>Product Name </label>
												<Input
													type='text'
													name='product_name'
													onChange={this.handleChange}
													value={this.state.product_name}
												/>
											</FormGroup>
											<FormGroup>
												<label> SKU </label>
												<Input
													type='tel'
													name='sku'
													maxLength='5'
													placeholder='SJ098'
													onChange={(e) => {
														this.handleChange(e);
														this.verifySKU(e);
													}}
													value={this.state.sku}
												/>
												{!this.state.skuVerifiedText && (
													<span className='text-danger ml-2'>
														Invalid Format
													</span>
												)}
											</FormGroup>
											<FormGroup>
												<label> Product Price </label>
												<Input
													type='tel'
													name='price'
													onChange={this.handleChange}
													value={this.state.price}
												/>
											</FormGroup>
										</Col>
									</CardBody>
									<CardFooter className='text-right'>
										<Button
											color='primary'
											onClick={this.handleSubmit}
											disabled={!this.validateForm()}
										>
											Add Product
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default AddProduct;
