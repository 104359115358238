/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

// react component for creating dynamic tables
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import { getUsers } from '../../Redux/ActionCreator';
import { baseUrl } from '../../Shared/baseUrl';
const mapStateToProps = (state) => {
	return {
		Users: state.Users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (details) => dispatch(getUsers(details)),
	};
};

class ViewProdPrice extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			table: null,
		};
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);

		let details = {
			token: cookie.load('Token'),
			id: data.id,
		};

		fetch(baseUrl + '/pos/getProductPriceList', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						data: jsonResponse.pPrice.map((prop, key) => {
							fetch(`${baseUrl}/pos/productsList/${prop.productId}`, {
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
									Authorization: 'Bearer ' + details.token,
									id: details.id,
									reg: JSON.stringify(data.Permissions),
								},
								method: 'Post',
							})
								.then((response) => response.json())
								.then((jsonResponse) => {
									if (jsonResponse.success) {
										this.state.data[key].prod_name =
											jsonResponse.product.productName;

										this.setState({ data: [...this.state.data] });

										fetch(
											`${baseUrl}/pos/get-product-category/${jsonResponse.product.productCategoryId}`,
											{
												headers: {
													'Content-Type': 'application/json',
													Accept: 'application/json',
													Authorization: 'Bearer ' + details.token,
													id: details.id,
													reg: JSON.stringify(data.Permissions),
												},
												method: 'Get',
											},
										)
											.then((response) => response.json())
											.then((jsonResponse) => {
												if (jsonResponse.success) {
													this.state.data[key].prod_catg =
														jsonResponse.productCategory.productCat;

													this.setState({ data: [...this.state.data] });
												}
											});
									}
								});

							fetch(`${baseUrl}/auth/users//get-region/${prop.regionId}`, {
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
									Authorization: 'Bearer ' + details.token,
									id: details.id,
									reg: JSON.stringify(data.Permissions),
								},
								method: 'Post',
							})
								.then((response) => response.json())
								.then((jsonResponse) => {
									if (jsonResponse.success) {
										this.state.data[key].Region = jsonResponse.region.name;

										this.setState({ data: [...this.state.data] });
									}
								});

							return {
								id: prop.id,
								price: prop.productPrice,
							};
						}),
					});
				}
			})
			.catch((err) => {});
	}
	rowClicked(id) {
		let obj = this.state.data.find((o) => o.id === id);
		this.props.history.push(`/admin/edit_price/${obj.id}`);
	}

	filterCaseInsensitive = (filter, row) => {
		const id = filter.pivotId || filter.id;
		const content = row[id];
		if (typeof content !== 'undefined') {
			// filter by text in the table or if it's a object, filter by key
			if (typeof content === 'object' && content !== null && content.key) {
				return String(content.key)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			} else {
				return String(content)
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			}
		}

		return true;
	};

	render() {
		return (
			<>
				<div className='content'>
					{}
					<Col md={8} className='ml-auto mr-auto'>
						<h2 className='text-center'>Product Price Info</h2>
						<p className='text-center'>
							This Table contain all the information about Product Price{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								product-price-table
							</a>
							. If need to modified price please click on the buttons in the
							action column.{' '}
							<a href='' target='_blank' rel='noopener noreferrer'>
								full documentation.
							</a>
						</p>
					</Col>
					<Row className='mt-5'>
						<Col xs={12} md={12}>
							<Card>
								<CardHeader>
									<CardTitle tag='h4'>Price Table</CardTitle>
								</CardHeader>
								<CardBody>
									<ReactTable
										data={this.state.data}
										filterable
										defaultFilterMethod={this.filterCaseInsensitive}
										resizable={false}
										columns={[
											{
												Header: 'Product Category',
												accessor: 'prod_catg',
											},
											{
												Header: 'Product Name',
												accessor: 'prod_name',
											},
											{
												Header: 'Region',
												accessor: 'Region',
											},
											{
												Header: 'Price',
												accessor: 'price',
											},
										]}
										getTdProps={(state, rowInfo, column, instance) => {
											return {
												onClick: (e) => this.rowClicked(rowInfo.original.id),
											};
										}}
										defaultPageSize={10}
										showPaginationTop
										showPaginationBottom={false}
										className='-striped -highlight'
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewProdPrice);
