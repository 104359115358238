/* eslint-disable no-unused-vars */
import React, { Component } from "react"

// react component for creating dynamic tables
import ReactTable from "react-table"
import { connect } from "react-redux"
import cookie from "react-cookies"
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,

} from "reactstrap";
import { getUsers } from "../../Redux/ActionCreator";
import { baseUrl } from "../../Shared/baseUrl";
const mapStateToProps = (state) => {
    return {
        Users: state.Users
    }

}

const mapDispatchToProps = (dispatch) => {
    return {

        getUsers: (details) => dispatch(getUsers(details)),
    }
}



class ModifiedUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: null,
            table: null
        }
    }

    componentDidMount() {
        var user = JSON.parse(window.localStorage.getItem("user"))
        var data = JSON.parse(user)

        let details = {
            token: cookie.load("Token"),
            id: data.id,

        }


        fetch(baseUrl + '/auth/users/getUser', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + details.token,
                'id': details.id,

            },
            method: "Post",
            body: JSON.stringify({
                region: data.Regions,
                roles: data.Roles,
            })


        }).then((res) => res.json()).then((jsonResponse) => {

            if (jsonResponse.success === true) {


                this.setState({
                    data: jsonResponse.Users.map((prop, key) => {

                        let roles
                        let regions
                        for (let i = 0; i < prop.Roles.length; i++) {
                            roles = prop.Roles[i].Role
                            regions = prop.Roles[i].Region
                        }

                        return {
                            id: prop.id,
                            name: prop.username,
                            email: prop.email,
                            active: JSON.stringify(prop.active),
                            roles: roles,
                            Region: regions
                        }

                    })
                })
                this.setState({
                    table:
                        <ReactTable
                            data={this.state.data}
                            filterable
                            resizable={false}
                            columns={[
                                {
                                    Header: "Name",
                                    accessor: "name"
                                },
                                {
                                    Header: "Email",
                                    accessor: "email"
                                },
                                {
                                    Header: "Region",
                                    accessor: "Region"
                                },
                                {
                                    Header: "Roles",
                                    accessor: "roles"
                                },
                                {
                                    Header: <span style={{ marginRight: "100%" }}>Active</span>,
                                    accessor: "active"
                                },


                            ]}
                            getTdProps={(state, rowInfo, column, instance) => {

                                if (rowInfo && rowInfo.row) {

                                    return {
                                        onClick: e =>

                                            this.rowClicked(rowInfo.original.id)


                                    }
                                } else {return{}}
                            }}
                            defaultPageSize={10}
                            showPaginationTop
                            showPaginationBottom={false}
                            className="-striped -highlight"
                        />
                })
            }

        }).catch(err => {

        })

    }
    rowClicked(id) {
        let obj = this.state.data.find(o => o.id === id)
        this.props.history.push(`/admin/edit_user/${obj.id}`)
    }

    render() {


        return (
            <>
                <div className="content">
                    {}
                    <Col md={8} className="ml-auto mr-auto">
                        <h2 className="text-center">Users Info</h2>
                        <p className="text-center">
                            This Table contain all the information about Users{" "}
                            <a
                                href=""
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                user-table
                    </a>
              . If need to modified user please click on the buttons
              in the action column.{" "}
                            <a
                                href=""
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                full documentation.
                    </a>
                        </p>
                    </Col>
                    <Row className="mt-5">
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Users Table</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {this.state.table}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ModifiedUser)
