/* eslint-disable no-cond-assign */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import cookie from "react-cookies";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Loader from "react-loader-spinner";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Label,
	Form,
	Input,
	Row,
	Col,
	Checkbox,
} from "reactstrap";
import { baseUrl } from "../../Shared/baseUrl";

var dropDown;
const axios = require("axios");

class EditProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			lastname: "",
			title: "",
			CNIC: "",
			dob: "",
			doj: "",
			address: "",
			city: "",
			email: "",
			phone: "",
			age: "",
			sex: "",
			salary: "",
			fathername: "",
			isChecked: false,
			confirmPassword: "",
			emailState: "",
			passwordState: "",
			confirmPasswordState: "",
			usernameState: "",
			emailVerified: false,
			alert: null,
			loader: null,
			Roles: [],
			Regions: [],
			fetchedRoles: [],
			pressed: false,
			roleSelected: [],
			selectedRegion: "",
			dropDown1array: [],
			regionDrop1: false,
			permissions: [],
			Roles_Permissions: [],
			checked: false,
			name: "",
			code: "",
			region: "",
			allowedRegions: [],
			files: null,
			image: "",
			selectedAllowedRegion: "",
			fieldSupervisor: false,
			commission: "",
		};
		this.successAlert = this.successAlert.bind(this);
		this.onFilesAdded = this.onFilesAdded.bind(this);
		this.updateprofile = this.updateprofile.bind(this);
	}
	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}
	onAddRolesPress() {
		this.setState({ pressed: true });
	}

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem("user"));
		var data = JSON.parse(user);
		var token = cookie.load("Token");
		let details = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};

		fetch(baseUrl + "/hrm/getProfileDetail/" + this.props.match.params.id, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + details.token,
				id: details.userid,
				reg: JSON.stringify(details.permissions),
			},
			method: "Post",
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					console.log(jsonResponse);
					this.setState({
						email: jsonResponse.Employee.email,
						username: jsonResponse.Employee.username,
						lastname: jsonResponse.Employee.lastname,
						fathername: jsonResponse.Employee.fathername,
						title: jsonResponse.Employee.title,
						CNIC: jsonResponse.Employee.CNIC,
						address: jsonResponse.Employee.address,
						city: jsonResponse.Employee.city,
						phone: jsonResponse.Employee.phone,
						age: jsonResponse.Employee.age,
						salary: jsonResponse.Employee.salary,
						sex: jsonResponse.Employee.sex,
						dob: jsonResponse.Employee.dob,
						doj: jsonResponse.Employee.doj,
						image: jsonResponse.Employee.image,
						commission: jsonResponse.Employee.commission,
						loader: null,
					});

					fetch(
						`${baseUrl}/auth/users/getUserDetail/${jsonResponse.Employee.user_id}`,
						{
							headers: {
								"Content-Type": "application/json",
								Accept: "application/json",
								Authorization: "Bearer " + details.token,
								id: details.userid,
								reg: JSON.stringify(details.permissions),
							},
							method: "Post",
						},
					)
						.then((response) => response.json())
						.then((jsonResponse) => {
							if (jsonResponse.success) {
								jsonResponse.User.Roles.map((role) => {
									if (role.Role === "Field Supervisor") {
										return this.setState({
											fieldSupervisor: true,
										});
									}
								});
							}
						})
						.catch((err) => {
							console.log(err);
						});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: "block", marginTop: "-100px" }}
					title="Success!"
					confirmBtnBsStyle="success"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=""
				>
					Profile Updated Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	errorAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: "block", marginTop: "-100px" }}
					title="Error"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle="danger"
					btnSize=""
				>
					Something Went Wrong !!!!!
				</ReactBSAlert>
			),
		});
	};

	updateprofile() {
		this.setState({
			innerloader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: "250px",
							marginLeft: "-80px",
						}),
					}}
					active={true}
					spinner={<Loader type="CradleLoader" height="100" width="100" />}
				></LoadingOverlay>
			),
		});
		var user = JSON.parse(window.localStorage.getItem("user"));
		var data = JSON.parse(user);
		var token = cookie.load("Token");

		let details = {
			token: token,
			id: data.id,
			permissions: data.Permissions,
		};
		const formData = new FormData();
		formData.append("myImage", this.state.files);

		if (this.state.files != null) {
			fetch(baseUrl + "/hrm/upload", {
				headers: {
					Authorization: "Bearer " + details.token,
					id: details.id,
					reg: JSON.stringify(details.permissions),
				},
				method: "Post",
				body: formData,
			})
				.then((res) => res.json())
				.then((jsonResponse) => {
					fetch(baseUrl + "/hrm/modifyProfile/" + this.props.match.params.id, {
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							Authorization: "Bearer " + details.token,
							id: details.id,
							reg: JSON.stringify(details.permissions),
						},
						method: "Post",
						body: JSON.stringify({
							username: this.state.username,
							lastname: this.state.lastname,
							fathername: this.state.fathername,
							title: this.state.title,
							CNIC: this.state.CNIC,
							address: this.state.address,
							city: this.state.city,
							email: this.state.email,
							dob: this.state.dob,
							doj: this.state.doj,
							phone: this.state.phone,
							salary: this.state.salary,
							sex: this.state.sex,
							age: this.state.age,
							image: jsonResponse.data,
							commission: this.state.commission,
						}),
					})
						.then((res) => res.json())
						.then((jsonResponse) => {
							this.setState({ loader: null });
							if (jsonResponse.success === true) {
								fetch(
									baseUrl +
										"/auth/users/modifiedUserProfile/" +
										this.props.match.params.id,
									{
										headers: {
											"Content-Type": "application/json",
											Accept: "application/json",
											Authorization: "Bearer " + details.token,
											id: details.id,
											reg: JSON.stringify(details.permissions),
										},
										method: "Post",
										body: JSON.stringify({
											username: this.state.username,
											email: this.state.email,
										}),
									},
								)
									.then((res) => res.json())
									.then((jsonResponse) => {
										this.setState({ innerloader: null });
										if (jsonResponse.success === true) {
											console.log(jsonResponse.success);
											this.successAlert();
										} else {
											this.errorAlert();
										}
										this.componentDidMount();
									})
									.catch((err) => {
										this.errorAlert();
									});
							} else {
								this.setState({ loader: null });
								this.errorAlert();
							}
						});
				})
				.catch((err) => {
					this.errorAlert();
				});
		} else {
			fetch(baseUrl + "/hrm/modifyProfile/" + this.props.match.params.id, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + details.token,
					id: details.id,
					reg: JSON.stringify(details.permissions),
				},
				method: "Post",
				body: JSON.stringify({
					username: this.state.username,
					lastname: this.state.lastname,
					fathername: this.state.fathername,
					title: this.state.title,
					CNIC: this.state.CNIC,
					address: this.state.address,
					city: this.state.city,
					email: this.state.email,
					dob: this.state.dob,
					doj: this.state.doj,
					phone: this.state.phone,
					salary: this.state.salary,
					sex: this.state.sex,
					age: this.state.age,
					commission: this.state.commission,
				}),
			})
				.then((res) => res.json())
				.then((jsonResponse) => {
					this.setState({ loader: null });
					if (jsonResponse.success === true) {
						fetch(
							baseUrl +
								"/auth/users/modifiedUserProfile/" +
								this.props.match.params.id,
							{
								headers: {
									"Content-Type": "application/json",
									Accept: "application/json",
									Authorization: "Bearer " + details.token,
									id: details.id,
									reg: JSON.stringify(details.permissions),
								},
								method: "Post",
								body: JSON.stringify({
									username: this.state.username,
									email: this.state.email,
								}),
							},
						)
							.then((res) => res.json())
							.then((jsonResponse) => {
								this.setState({ innerloader: null });
								if (jsonResponse.success === true) {
									console.log(jsonResponse.success);
									this.successAlert();
								} else {
									this.errorAlert();
								}
								this.componentDidMount();
							})
							.catch((err) => {
								this.errorAlert();
							});
					} else {
						this.setState({ loader: null });
						this.errorAlert();
					}
				});
		}
	}
	handleCancelButton = () => {
		this.props.history.push("/admin/Modified_Profile");
	};
	onFilesAdded(event) {
		this.setState({
			files: event.target.files[0],
		});
	}
	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		console.log(target);
		this.setState({
			[name]: value,
		});
	};

	render() {
		return (
			<>
				<div className="content">
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col md="4">
							<Card>
								<CardBody>
									<Col>
										<div>
											<img src={this.state.image} />

											<Row>
												<Col>
													<input
														type="file"
														name="myImage"
														onChange={this.onFilesAdded}
													/>
												</Col>
											</Row>

											<Col className="mr-md-1">
												<div>
													<p> Profile Picture</p>
													<p> Choose file to change Profile Picture</p>
												</div>
											</Col>
										</div>
									</Col>
								</CardBody>
							</Card>
						</Col>
						<Col md="8">
							<Card>
								<CardHeader>
									<h5 className="title">Edit Profile</h5>
								</CardHeader>
								<CardBody>
									<Form>
										<Row>
											<Col className="pr-md-1" md="6">
												<FormGroup>
													<label>Username</label>
													<Input
														onChange={this.handleChange}
														placeholder="User Name"
														name="username"
														value={this.state.username}
														type="text"
													/>
												</FormGroup>
											</Col>
											<Col className="pl-md-1" md="6">
												<FormGroup>
													<label>Email address</label>
													<Input
														onChange={this.handleChange}
														name="email"
														value={this.state.email}
														type="email"
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className="pr-md-1" md="4">
												<FormGroup>
													<label>Full Name</label>
													<Input
														onChange={this.handleChange}
														placeholder="Full Name"
														name="lastname"
														value={this.state.lastname}
														type="text"
													/>
												</FormGroup>
											</Col>
											<Col className="pr-md-1" md="4">
												<FormGroup>
													<label>Father Name</label>
													<Input
														onChange={this.handleChange}
														placeholder="Father Name"
														name="fathername"
														value={this.state.fathername}
														type="text"
													/>
												</FormGroup>
											</Col>
											<Col className="pl-md-1" md="4">
												<FormGroup>
													<label>CNIC</label>
													<Input
														onChange={this.handleChange}
														placeholder="CNIC"
														name="CNIC"
														value={this.state.CNIC}
														type="text"
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md="8">
												<FormGroup>
													<label>Address</label>
													<Input
														onChange={this.handleChange}
														value={this.state.address}
														name="address"
														placeholder="Home Address"
														type="text"
													/>
												</FormGroup>
											</Col>
											<Col className="pl-md-1" md="4">
												<FormGroup>
													<label>City</label>
													<Input
														onChange={this.handleChange}
														placeholder="City"
														name="city"
														value={this.state.city}
														type="text"
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md="3">
												<FormGroup>
													<label>Phone</label>
													<Input
														onChange={this.handleChange}
														name="phone"
														value={this.state.phone}
														placeholder="Phone Number"
														type="text"
													/>
												</FormGroup>
											</Col>

											<Col className="pr-md-1" md="3">
												<FormGroup>
													<label>Age</label>
													<Input
														onChange={this.handleChange}
														placeholder="Age"
														name="age"
														value={this.state.age}
														type="text"
													/>
												</FormGroup>
											</Col>
											<Col md="3">
												<FormGroup>
													<label>Gender</label>
													<Input
														onChange={this.handleChange}
														name="sex"
														value={this.state.sex}
														placeholder="Gender"
														type="text"
													/>
												</FormGroup>
											</Col>
											<Col md="3">
												<FormGroup>
													<label>Date of Birth </label>
													<Input
														type="text"
														name="dob"
														onChange={this.handleChange}
														value={this.state.dob}
														placeholder="dd/mm/yy"
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md="3">
												<FormGroup>
													<label>Designation</label>
													<Input
														onChange={this.handleChange}
														name="title"
														value={this.state.title}
														placeholder="Designation"
														type="text"
													/>
												</FormGroup>
											</Col>
											<Col md="3">
												<FormGroup>
													<label>Salary</label>
													<Input
														onChange={this.handleChange}
														name="salary"
														value={this.state.salary}
														placeholder="Salary"
														type="text"
													/>
												</FormGroup>
											</Col>

											<Col md="3">
												<FormGroup>
													<label>Date of Joining </label>
													<Input
														type="text"
														name="doj"
														placeholder="dd/mm/yy"
														onChange={this.handleChange}
														value={this.state.doj}
													/>
												</FormGroup>
											</Col>

											{this.state.fieldSupervisor && (
												<Col md="3">
													<FormGroup>
														<label>Salary Commission %</label>
														<Input
															type="text"
															name="commission"
															placeholder="12"
															onChange={this.handleChange}
															value={this.state.commission}
														/>
													</FormGroup>
												</Col>
											)}
										</Row>
									</Form>
								</CardBody>
								<CardFooter className="text-right">
									<Button color="primary" onClick={this.updateprofile}>
										Update
									</Button>
									<Button color="primary" onClick={this.handleCancelButton}>
										Cancel
									</Button>
								</CardFooter>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default EditProfile;
