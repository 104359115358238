/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import cookie from "react-cookies";
import { baseUrl } from "../../Shared/baseUrl";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Loader from "react-loader-spinner";
import LoadingOverlay from "react-loading-overlay";
import Switch from "react-bootstrap-switch";
import { withRouter } from "react-router-dom";
import Select from "react-select";
// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Label,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Button,
	Badge,
} from "reactstrap";
var dropDown;
var memo;
var uId;
var uName;

const initialState = {
	db_id: "",
	alert: null,
	loader: null,
	driverName: "",
	phoneNumber: "",
	cnic: "",
	status: "Available",
	checked: false,
	phoneVerified: true,
	cnicVerified: true,
	phoneVerifiedText: true,
	cnicVerifiedText: true,
};
class EditVehicleDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.successAlert = this.successAlert.bind(this);
		this.updateDriverDetails = this.updateDriverDetails.bind(this);
	}
	hide() {
		this.setState({
			isActive: false,
		});
	}
	successAlert(message) {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: "block", marginTop: "-100px" }}
					title="Success!"
					confirmBtnBsStyle="success"
					onConfirm={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					onCancel={
						message ? () => this.hideDeleteAlert() : () => this.hideAlert()
					}
					btnSize=""
				>
					{message ? message : `Driver Details updated Successfully !`}
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	hideDeleteAlert = () => {
		this.setState({
			alert: null,
		});

		this.props.history.push("/admin/driverList");
	};

	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: "block", marginTop: "-100px" }}
					title="Error"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle="danger"
					btnSize=""
				>
					{message ? message : `Something Went Wrong!!!!!`}
				</ReactBSAlert>
			),
		});
	};

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem("user"));
		var data = JSON.parse(user);
		var token = cookie.load("Token");

		fetch(baseUrl + "/fleet/getDriverDetails/" + this.props.match.params.id, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: "Post",
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						db_id: jsonResponse.driver.id,
						driverName: jsonResponse.driver.name,
						phoneNumber: jsonResponse.driver.phoneNo,
						cnic: jsonResponse.driver.cnic,
						status: jsonResponse.driver.status,
						loader: null,
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	}

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	};

	updateDriverDetails() {
		this.setState({
			innerloader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: "250px",
							marginLeft: "-80px",
						}),
					}}
					active={true}
					spinner={<Loader type="CradleLoader" height="100" width="100" />}
				></LoadingOverlay>
			),
		});

		var user = JSON.parse(window.localStorage.getItem("user"));
		var data = JSON.parse(user);
		var token = cookie.load("Token");

		let details = {
			driverName: this.state.driverName,
			phoneNumber: this.state.phoneNumber,
			cnic: this.state.cnic,
			status: this.state.status,
		};
		console.log(details);
		fetch(
			baseUrl + "/fleet/updateDriverDetails/" + this.props.match.params.id,
			{
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
					id: data.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: "Post",
				body: JSON.stringify({
					driverName: details.driverName,
					phoneNumber: details.phoneNumber,
					cnic: details.cnic,
					status: details.status,
				}),
			},
		)
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({ innerloader: null });
					this.successAlert();
				} else {
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert();
				throw new Error(err);
			});
	}
	handleCancelButton = () => {
		this.props.history.push("/admin/driverList");
	};

	handleDeleteButton = () => {
		var user = JSON.parse(window.localStorage.getItem("user"));
		var data = JSON.parse(user);
		var token = cookie.load("Token");

		fetch(`${baseUrl}/fleet/delete-driver/${this.props.match.params.id}`, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: "Delete",
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert(jsonResponse.message);
				} else {
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert();
			});
	};

	resetForm() {
		this.setState(initialState);
	}

	verifyPhone = (event) => {
		var value = event.target.value;
		var phoneRex = /^((03)([0-9]{9}))$/;
		if (phoneRex.test(value)) {
			this.setState({ phoneVerified: true, phoneVerifiedText: true });
			return true;
		}

		this.setState({ phoneVerifiedText: false });
		return false;
	};

	verifyCnic = (event) => {
		var value = event.target.value;
		var cnicRex = /^((3[0-9]{4}-)([0-9]{7}-)([0-9]{1}))$/;
		if (cnicRex.test(value)) {
			this.setState({ cnicVerified: true, cnicVerifiedText: true });
			return true;
		}

		this.setState({ cnicVerifiedText: false });
		return false;
	};

	validateForm = () => {
		return (
			this.state.phoneVerified &&
			this.state.cnicVerified &&
			this.state.phoneVerifiedText &&
			this.state.cnicVerifiedText
		);
	};

	render() {
		return (
			<>
				<div className="content">
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col className="mr-auto" md={{ size: 7, offset: 2 }}>
							<Form>
								<Card>
									<CardHeader>
										<CardTitle tag="h3">Edit Driver Details</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup className={"has-label"}>
											<label> Driver Name *</label>

											<Input
												type="text"
												name="driverName"
												onChange={this.handleChange}
												value={this.state.driverName}
											/>
										</FormGroup>
										<FormGroup className={"has-label"}>
											<label> Phone Number *</label>

											<Input
												type="text"
												name="phoneNumber"
												maxLength="11"
												placeholder="xxxxxxxxxxx"
												onChange={(e) => {
													this.handleChange(e);
													this.verifyPhone(e);
												}}
												value={this.state.phoneNumber}
											/>
											{!this.state.phoneVerifiedText && (
												<span className="text-danger ml-2">Invalid format</span>
											)}
										</FormGroup>
										<FormGroup className={"has-label"}>
											<label> CNIC *</label>

											<Input
												type="text"
												name="cnic"
												maxLength="15"
												placeholder="xxxxx-xxxxxxx-x"
												onChange={(e) => {
													this.handleChange(e);
													this.verifyCnic(e);
												}}
												value={this.state.cnic}
											/>
											{!this.state.cnicVerifiedText && (
												<span className="text-danger ml-2">Invalid format</span>
											)}
										</FormGroup>
									</CardBody>
									<CardFooter className="text-right">
										<Button
											className="btn-round"
											color="primary"
											onClick={this.updateDriverDetails}
											size="md"
											disabled={!this.validateForm()}
										>
											Update
										</Button>
										<Button
											className="btn-round"
											color="info"
											onClick={this.handleCancelButton}
											size="md"
										>
											Cancel
										</Button>
										<Button
											className="btn-round"
											color="danger"
											onClick={this.handleDeleteButton}
											size="md"
										>
											Delete
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default withRouter(EditVehicleDetails);
