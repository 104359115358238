import React, { Component } from 'react';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Label,
	Input,
	Row,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { baseUrl } from '../../Shared/baseUrl';
import Moment from 'moment';

var dropDown;
var currentDate;
class assignInventoryToFs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			whName: '',
			warehouseProducts: [],
			productName: [],
			productToInventory: [],
			productToInventoryForAPICallOnly: [],
			fs: [],
			allWarehouse: [],
			selectedFromWarehouse: '',
			selectedToWarehouse: '',
			selectedProduct: '',
			selectedProductCategory: '',
			selectedProductCat: '',
			selectedParentCat: '',
			prodQty: '',
			sku: [],
			prodQty: '',
			warehouse: '',
			selectedWarehouse: '',
			selectedFs: '',
			alert: null,
			sku: '',
			products: [
				{
					id: 1,
					label: 'Hand Gloves',
					value: 'Hand Gloves',
					sku: 'SJ099',
					productCat: 'Accessories',
					parentCat: 'Imported',
				},
			],
		};
		this.successAlert = this.successAlert.bind(this);
		this.onAddInventoryPress = this.onAddInventoryPress.bind(this);
	}

	// componentWillMount() {
	// 	var user = JSON.parse(window.localStorage.getItem('user'));
	// 	var data = JSON.parse(user);
	// 	var token = cookie.load('Token');

	// 	fetch(baseUrl + '/inventory/listWarehouse', {
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			Accept: 'application/json',
	// 			Authorization: 'Bearer ' + token,
	// 			id: data.id,
	// 			reg: JSON.stringify(data.Permissions),
	// 		},
	// 		method: 'Post',
	// 	})
	// 		.then((res) => res.json())
	// 		.then((jsonResponse) => {
	// 			if (jsonResponse.success === true) {
	// 				this.setState({
	// 					allWarehouse: jsonResponse.data.map((props) => {
	// 						return {
	// 							value: props.id,
	// 							label: props.whName,
	// 						};
	// 					}),
	// 				});
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			throw new Error(err);
	// 		});
	// }

	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/pos/productsList', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						products: jsonResponse.product.map((props) => {
							return {
								value: props.id,
								label: props.productName,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});

		fetch(baseUrl + '/inventory/getCurrentUserWarehouse', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				faId: data.id,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						yourWarehouse: jsonResponse.data.map((props) => {
							return {
								value: props.id,
								label: props.whName,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});

		fetch(baseUrl + '/auth/users/getFieldSupervisors', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				fa: data.username,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						fs: jsonResponse.data.map((props) => {
							return {
								value: props.id,
								label: props.username,
							};
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});

		// fetch(`${baseUrl}/auth/users/getUserDetail/${data.id}`, {
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 		Accept: 'application/json',
		// 		Authorization: 'Bearer ' + token,
		// 		id: data.id,
		// 		reg: JSON.stringify(data.Permissions),
		// 	},
		// 	method: 'Post',
		// })
		// 	.then((response) => response.json())
		// 	.then((jsonResponse) => {
		// 		if (jsonResponse.success) {
		// 			jsonResponse.User.Roles.map((prop) => {
		// 				if (prop.Role === 'Inventory Manager') {
		// 					this.setState({
		// 						yourWarehouse: [
		// 							...this.state.yourWarehouse,
		// 							this.state.allWarehouse.find((w) => w.label === 'HQ'),
		// 						],
		// 					});
		// 				}
		// 			});
		// 		}
		// 	});
	}
	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					Inventory Added Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : 'Something Went Wrong !!!!!'}
				</ReactBSAlert>
			),
		});
	};

	onAddInventoryPress() {
		this.setState({ pressed: true });
	}

	resetForm() {
		this.setState({
			whName: '',
			selectedFromWarehouse: '',
			selectedToWarehouse: '',
			selectedProduct: '',
			selectedFs: '',
			selectedWarehouse: '',
			productToInventory: [],
			productToInventoryForAPICallOnly: [],
		});
	}
	handleSubmit = (event) => {
		var product = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(product);
		var token = cookie.load('Token');
		var d1 = new Date();
		currentDate = Moment(d1).format('YYYY-MM-DD');

		fetch(baseUrl + '/inventory/assignToFs', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				inventory: this.state.productToInventoryForAPICallOnly,
				date: currentDate,
				warehouseId: this.state.selectedWarehouse.value,
				fsId: this.state.selectedFs.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.successAlert();
					this.resetForm();
				} else {
					this.errorAlert();
				}
			});
	};
	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	changeProduct = (event) => {
		if (!this.state.selectedWarehouse) {
			this.errorAlert('Please select Warehouse');
		} else {
			this.setState({
				selectedProduct: event,
			});

			var user = JSON.parse(window.localStorage.getItem('user'));
			var data = JSON.parse(user);
			var token = cookie.load('Token');

			fetch(baseUrl + '/inventory/getDetailsByNameandWarehouse', {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + token,
					id: data.id,
					reg: JSON.stringify(data.Permissions),
				},
				method: 'Post',
				body: JSON.stringify({
					productId: event.value,
					warehouseId: this.state.selectedWarehouse.value,
				}),
			})
				.then((res) => res.json())
				.then((jsonResponse) => {
					if (jsonResponse.success === true) {
						this.setState({
							productId: jsonResponse.data.productId,
							sku: jsonResponse.data.sku,
							prodQty: jsonResponse.data.currentQty,
							maxQuantity: jsonResponse.data.currentQty,
						});
					}
				})
				.catch((err) => {
					throw new Error(err);
				});
		}
	};

	savePressed = () => {
		if (this.state.prodQty > parseInt(this.state.maxQuantity)) {
			this.errorAlert(
				`Please select quantity less than or euqal to ${this.state.maxQuantity}`,
			);
		} else {
			let inserData = {
				product: this.state.selectedProduct,
				sku: this.state.sku,
				prodQty: this.state.prodQty,
			};
			this.setState({
				productToInventory: this.state.productToInventory.concat(inserData),
				productToInventoryForAPICallOnly: this.state.productToInventoryForAPICallOnly.concat({
					productId: this.state.selectedProduct.value,
					sku: this.state.sku,
					quantity: parseInt(this.state.prodQty)
				})
			});

			this.setState({ pressed: false });

			this.setState({ sku: '' });
			this.setState({ prodQty: '' });
			dropDown = null;
		}
	};

	onChangeWarehouse = (event) => {
		this.setState({
			selectedWarehouse: event,
		});
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		fetch(baseUrl + '/inventory/getProductsbyWarehouse', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				warehouseId: event.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						warehouseProducts: jsonResponse.data.map((props) => {
							return this.state.products.find(
								(p) => p.value === props.productId,
							);
						}),
					});
				}
			})
			.catch((err) => {
				throw new Error(err);
			});
	};

	fsChange = (event) => {
		this.setState({
			selectedFs: event,
		});
	};

	render() {
		if (this.state.pressed == true) {
			dropDown = (
				<>
					<Row>
						<Col md={8}>
							<label>Select Product</label>
							<Select
								onChange={this.changeProduct}
								options={this.state.warehouseProducts}
								value={this.state.selectedProduct}
								noOptionsMessage={() =>
									'No products available in selected warehouse'
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<label>SKU</label>
							<Input
								className='text-white'
								type='text'
								name='sku'
								value={this.state.sku}
								readOnly
							/>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<label>Quantity</label>
							<Input
								type='number'
								name='prodQty'
								value={this.state.prodQty}
								onChange={this.handleChange}
							/>
						</Col>
					</Row>

					<Row>
						<Col md={4}>
							<Button
								style={{
									background: 'transparent',
									marginTop: 21,
								}}
								onClick={this.savePressed}
							>
								Save
							</Button>
						</Col>
					</Row>
				</>
			);
		}
		return (
			<>
				<div className='content'>
					{this.state.alert}
					{this.state.loader}
					<Row>
						<Col className='mr-auto' md={{ size: 6, offset: 3 }}>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h3'>Move Inventory</CardTitle>
									</CardHeader>
									<CardBody>
										<Col md={12}>
											<FormGroup>
												<Label>Warehouse</Label>
												<Select
													onChange={this.onChangeWarehouse}
													options={this.state.yourWarehouse}
													value={this.state.selectedWarehouse}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Field Supervisor</Label>
												<Select
													onChange={this.fsChange}
													options={this.state.fs}
													value={this.state.selectedFs}
												/>
											</FormGroup>
											<FormGroup>
												<Row>
													<Col xs={4}>
														<Label>
															<h3>Products to Field Supervisor</h3>
														</Label>
													</Col>
													<Col
														xs={{
															size: 3,
															offset: 5,
														}}
													>
														<Button
															style={{
																background: 'transparent',
															}}
															onClick={this.onAddInventoryPress}
														>
															<span className='tim-icons icon-simple-add'></span>
														</Button>
													</Col>
												</Row>
											</FormGroup>
											<FormGroup>{dropDown}</FormGroup>
											<FormGroup>
												{this.state.productToInventory.map((prod, idx) => (
													<div key={idx}>
														<Row>
															<Col md='8'>
																<Label>Product Name</Label>
																<Input
																	className='text-white'
																	readOnly
																	value={prod.product.label}
																/>
															</Col>
														</Row>
														<Row>
															<Col md='8'>
																<Label>SKU</Label>
																<Input
																	className='text-white'
																	readOnly
																	value={prod.sku}
																/>
															</Col>
														</Row>
														<Row>
															<Col md='8'>
																<Label>Quantity</Label>
																<Input
																	className='text-white'
																	readOnly
																	value={prod.prodQty}
																/>
															</Col>
														</Row>
													</div>
												))}
											</FormGroup>
										</Col>
									</CardBody>
									<CardFooter className='text-right'>
										<Button color='primary' onClick={this.handleSubmit}>
											Move Inventory
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default assignInventoryToFs;
