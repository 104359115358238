/* eslint-disable no-cond-assign */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
import {
	GoogleMap,
	withScriptjs,
	withGoogleMap,
	InfoWindow,
	Marker,
} from 'react-google-maps';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
} from 'reactstrap';
import { baseUrl } from '../../Shared/baseUrl';
Geocode.setApiKey(window._env_.REACT_APP_GOOGLE_MAP_API_KEY);
Geocode.enableDebug();
var counter = 0;
let AsyncMap;
const initialState = {
	name: '',
	code: '',
	region: '',
	allowedRegions: [],
	selectedAllowedRegion: '',
	address: '',
	city: '',
	area: '',
	state: '',
	newLat: '',
	newLng: '',
	setLat: '',
	setLng: '',
	spVerified: false,
	addressArray: [],
	center: {
		lat: 31.484269618543166,
		lng: 74.3263890899658,
	},
	mapPosition: {
		lat: 31.484269618543166,
		lng: 74.3263890899658,
	},
	markerPosition: {
		lat: 31.484269618543166,
		lng: 74.3263890899658,
	},
};
class Routing extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.successAlert = this.successAlert.bind(this);
	}

	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}

	componentWillMount() {
		AsyncMap = withScriptjs(
			withGoogleMap((props) => (
				<GoogleMap
					google={this.props.google}
					defaultZoom={15}
					defaultCenter={{
						lat: this.state.mapPosition.lat,
						lng: this.state.mapPosition.lng,
					}}
				>
					<InfoWindow
						onClose={this.onInfoWindowClose}
						position={{
							lat: this.state.markerPosition.lat + 0.001,
							lng: this.state.markerPosition.lng,
						}}
					>
						<div>
							<span style={{ padding: 0, margin: 0 }}>
								{this.state.address}
							</span>
						</div>
					</InfoWindow>
					<Marker
						google={this.props.google}
						draggable={true}
						onDragEnd={this.onMarkerDragEnd}
						position={{
							lat: this.state.markerPosition.lat,
							lng: this.state.markerPosition.lng,
						}}
					/>
					<Marker />

					<Autocomplete
						style={{
							width: '100%',
							height: '40px',
							paddingLeft: '16px',
							marginTop: '2px',
							marginBottom: '100px',
						}}
						onPlaceSelected={this.onPlaceSelected}
						types={['(regions)']}
					/>
				</GoogleMap>
			)),
		);
	}

	componentDidMount() {
		Geocode.fromLatLng(
			this.state.mapPosition.lat,
			this.state.mapPosition.lng,
		).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);
				this.setState({
					address: address ? address : '',
					area: area ? area : '',
					city: city ? city : '',
					state: state ? state : '',
				});
			},
			(error) => {
				console.error(error);
			},
		);
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);

		if (data.permissions !== null) {
			const permissions = data.Permissions;
			const getRoleId = (Permissions) => Permissions.regionCode;
			const getRoleIdArray = (permissions) => permissions.map(getRoleId);
			const regions = getRoleIdArray(permissions);

			var regionCodes = regions.filter(function (el) {
				return el !== undefined;
			});

			const getNameId = (Permissions) => Permissions.regionName;
			const getNameIdArray = (permissions) => permissions.map(getNameId);
			const regionsname = getNameIdArray(permissions);
			var regionName = regionsname.filter(function (el) {
				return el !== undefined;
			});

			var regionsJson = [];

			for (var i in regionCodes) {
				var code = regionCodes[i];
				var label = regionName[i];

				regionsJson.push({
					code: code,
					label: label,
				});
			}
			var result = [
				...new Map(regionsJson.map((o) => [JSON.stringify(o), o])).values(),
			];

			var user = JSON.parse(window.localStorage.getItem('user'));
			var data = JSON.parse(user);
			var token = cookie.load('Token');
			let details = {
				userid: data.id,
				token: token,
				permissions: data.Permissions,
			};

			fetch(`${baseUrl}/auth/users/getRegion`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: 'Bearer ' + details.token,
					id: details.userid,
					reg: JSON.stringify(details.permissions),
				},
				method: 'Post',
			})
				.then((response) => response.json())
				.then((jsonResponse) => {
					if (jsonResponse.success) {
						jsonResponse.Regions.map((region) => {
							result.map((allowedRegion) => {
								if (allowedRegion.code === region.code) {
									this.setState({
										allowedRegions: [
											...this.state.allowedRegions,
											{ label: region.name, value: region.id },
										],
									});
								}
							});
						});
					}
				});
		}
	}
	salePointRegex = (event) => {
		var value = event.target.value;
		var spRex = /^(([A-Z]{3,4})([0-9]{1,4}))$/;
		if (spRex.test(value)) {
			this.setState({ spVerified: true });
			return true;
		}
		return false;
	};
	validateForm() {
		return this.state.code;
	}
	salePointCode = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Erorr'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					Salepoint Code already Exists !
				</ReactBSAlert>
			),
		});
	};
	settingStates = () => {
		this.setState({
			center: { lat: 30.37532, lng: 69.345116 },
			mapPosition: {
				lat: this.state.center.lat,
				lng: this.state.center.lng,
			},
			markerPosition: {
				lat: this.state.center.lat,
				lng: this.state.center.lng,
			},
			height: '300px',
		});
	};
	getCity = (addressArray) => {
		let city = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				'administrative_area_level_2' === addressArray[i].types[0]
			) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	getArea = (addressArray) => {
		let area = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if (
						'sublocality_level_1' === addressArray[i].types[j] ||
						'locality' === addressArray[i].types[j]
					) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};
	getState = (addressArray) => {
		let state = '';
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (
					addressArray[i].types[0] &&
					'administrative_area_level_1' === addressArray[i].types[0]
				) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};
	onInfoWindowClose = (event) => {};
	onMarkerDragEnd = (event) => {
		this.setState({ newLat: event.latLng.lat() });
		this.setState({ newLng: event.latLng.lng() });
		this.setState({
			markerPosition: { lat: event.latLng.lat(), lng: event.latLng.lng() },
		});
		let addressArray = [];
		Geocode.fromLatLng(this.state.newLat, this.state.newLng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);
				this.setState({
					address: address ? address : '',
					area: area ? area : '',
					city: city ? city : '',
					state: state ? state : '',
					markerPosition: {
						lat: this.state.newLat,
						lng: this.state.newLng,
					},
					mapPosition: {
						lat: this.state.newLat,
						lng: this.state.newLng,
					},
					center: {
						lat: this.state.newLat,
						lng: this.state.newLng,
					},
				});
			},
			(error) => {
				console.error(error);
			},
		);
	};
	setMarker = (event) => {
		let addressArray = [];
		Geocode.fromLatLng(this.state.newLat, this.state.newLng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = this.getCity(addressArray),
					area = this.getArea(addressArray),
					state = this.getState(addressArray);

				this.setState({
					address: address ? address : '',
					area: area ? area : '',
					city: city ? city : '',
					state: state ? state : '',
					markerPosition: {
						lat: parseFloat(this.state.newLat),
						lng: parseFloat(this.state.newLng),
					},
					mapPosition: {
						lat: parseFloat(this.state.newLat),
						lng: parseFloat(this.state.newLng),
					},
					center: {
						lat: parseFloat(this.state.newLat),
						lng: parseFloat(this.state.newLng),
					},
				});
			},
			(error) => {
				console.error(error);
			},
		);
	};

	addMapData = () => {
		console.log('checking new one');
	};
	onPlaceSelected = (place) => {
		const address = place.formatted_address,
			addressArray = place.address_components,
			city = this.getCity(addressArray),
			area = this.getArea(addressArray),
			state = this.getState(addressArray),
			latValue = place.geometry.location.lat(),
			lngValue = place.geometry.location.lng();

		this.setState({
			address: address ? address : '',
			area: area ? area : '',
			city: city ? city : '',
			state: state ? state : '',
			markerPosition: {
				lat: latValue,
				lng: lngValue,
			},
			mapPosition: {
				lat: latValue,
				lng: lngValue,
			},
		});
		this.state.globalState = this.state.city;
	};

	addingSalespoint() {
		this.setState({
			loader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: '250px',
							marginLeft: '-80px',
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			),
		});
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		let details = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};

		fetch(baseUrl + '/routing/addSalePoints', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + details.token,
				id: details.userid,
				reg: JSON.stringify(details.permissions),
			},
			method: 'Post',
			body: JSON.stringify({
				name: this.state.name,
				city: this.state.city,
				area: this.state.area,
				state: this.state.state,
				address: this.state.address,
				newLat: this.state.newLat,
				newLng: this.state.newLng,
				code: this.state.code,
				regionId: this.state.selectedAllowedRegion.value,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.successAlert();
					this.resetForm();
				} else {
					this.setState({ loader: null });
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.errorAlert();
			});
	}

	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					Sales Point Added Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.props.history.push(`/admin/Routing`);
		this.setState({
			alert: null,
		});
	};
	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : `Sales Point Could not be Added !!!!`}
				</ReactBSAlert>
			),
		});
	};
	resetForm() {
		this.setState({name: '', code: '', selectedAllowedRegion: ''});
	}

	handleSubmit = (event) => {
		counter = 0;
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');
		fetch(baseUrl + '/routing/listSalePoints', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
				id: data.id,
				reg: JSON.stringify(data.Permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				if (jsonResponse.success === true) {
					this.setState({
						data: jsonResponse.salepoints.map((prop, key) => {
							return {
								db_code: prop.code,
							};
						}),
					});
					for (let i = 0; i < this.state.data.length; i++) {
						if (this.state.data[i].db_code === this.state.code) {
							counter = counter + 1;
						}
					}
					if (counter !== 0) {
						this.salePointCode();
					} else {
						this.addingSalespoint();
					}
				}
			});
	};
	ChangeStatus = (event) => {
		this.setState({ selectedAllowedRegion: event });
	};

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	changeLatLong = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	};

	render() {
		let map;
		if (this.state.center.lat !== undefined) {
			map = (
				<div>
					<div>
						<div className='form-group'>
							<label htmlFor=''>City</label>
							<input
								type='text'
								name='city'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.city}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor=''>Area</label>
							<input
								type='text'
								name='area'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.area}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor=''>State</label>
							<input
								type='text'
								name='state'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.state}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor=''>Address</label>
							<input
								type='text'
								name='address'
								className='form-control'
								onChange={this.onChange}
								readOnly='readOnly'
								value={this.state.address}
							/>
						</div>
					</div>
					<AsyncMap
						googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${window._env_.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&v=3`}
						loadingElement={<div style={{ height: `100%` }} />}
						containerElement={<div style={{ height: '300px' }} />}
						mapElement={<div style={{ height: `100%` }} />}
					/>
				</div>
			);
		} else {
			map = <div style={{ height: '300px' }} />;
		}

		return (
			<>
				<div className='content'>
					{this.state.alert}

					{this.state.loader}
					<Row>
						<Col className='mr-auto' xs={{ size: 3, offset: 3 }} md='6'>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h4'>Add salepoint</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup className={'has-label'}>
											<label> Salepoint Name *</label>
											<Input
												type='text'
												name='name'
												onChange={this.handleChange}
												value={this.state.name}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Salepoint Code *</label>

											<Input
												type='text'
												name='code'
												maxLength='8'
												onChange={(e) => {
													this.handleChange(e);
													this.salePointRegex(e);
												}}
												value={this.state.code}
											/>
										</FormGroup>
										<FormGroup className={'has-label'}>
											<label> Region Name *</label>

											<Select
												onChange={this.ChangeStatus}
												options={this.state.allowedRegions}
												value={this.state.selectedAllowedRegion}
												placeholder='Select Region'
											></Select>
										</FormGroup>
										<FormGroup style={{ width: '94%', marginLeft: '22px' }}>
											{map}
										</FormGroup>
									</CardBody>
									<CardFooter className='text-right'>
										<Button
											color='primary'
											onClick={this.handleSubmit}
											disabled={!this.validateForm()}
										>
											Add Salepoint
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default Routing;
