import * as ActionType from './ActionTypes'

export const Users = (state = {
    user_Loading:true,
    user_Failed :null,
    get_Users:'',
},action) =>{

switch(action.type){
    case ActionType.user_Loading:
    return {...state, user_Loading:true, user_Failed :null,  get_Users:''}

    case ActionType.get_Users:
    return{ ...state,user_Loading:false, user_Failed :null,  get_Users:action.payload}


    case ActionType.user_Failed :
    return { ...state,user_Loading:false,user_Failed :action.payload, get_Users:''}

    default:
    return state;

}
}
