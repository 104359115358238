/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import cookie from 'react-cookies';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Loader from 'react-loader-spinner';
import LoadingOverlay from 'react-loading-overlay';

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Label,
	Input,
	Row,
	Col,
} from 'reactstrap';
import Select from 'react-select';
import { baseUrl } from '../../Shared/baseUrl';

const initialState = {
	salesTarget: '',
	targetDate: '1990-12',
	impDose: '',
	lclDose: '',
	selectedSt: '',
	selectedFs: '',
	pressed: false,
	selectedSubRoute: null,
};

class SetSalesTarget extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fs: [],
			...initialState,
			alert: null,
			loader: null,
			subRoutes: [],
		};
		this.successAlert = this.successAlert.bind(this);
	}

	componentWillUnmount() {
		var id = window.setTimeout(null, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}
	componentDidMount() {
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		let header = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};

		fetch(baseUrl + '/auth/users/getFieldSupervisors', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + header.token,
				id: header.userid,
				reg: JSON.stringify(header.permissions),
			},
			method: 'Post',
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.setState({
						fs: jsonResponse.data.map((props) => {
							return {
								value: props.id,
								label: props.username,
							};
						}),
					});
				} else {
					this.setState({ loader: null });
				}
			})
			.catch((err) => {
				this.setState({ loader: null });
			});

		fetch(`${baseUrl}/routing/getRouteDetails`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + header.token,
				id: header.userid,
				reg: JSON.stringify(header.permissions),
			},
			method: 'Post',
		})
			.then((response) => response.json())
			.then((jsonResponse) => {
				if (jsonResponse.success) {
					this.setState({
						subRoutes: jsonResponse.routes.map((route) => {
							return {
								label: route.name,
								value: route.id,
							};
						}),
					});
				}
			});
	}
	onAddRolesPress() {
		this.setState({ pressed: true });
	}

	successAlert() {
		this.setState({
			alert: (
				<ReactBSAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='Success!'
					confirmBtnBsStyle='success'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					btnSize=''
				>
					Sales Target Added Successfully !
				</ReactBSAlert>
			),
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};
	errorAlert = (message) => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					{message ? message : `Something Went Wrong !!!!!`}
				</ReactBSAlert>
			),
		});
	};

	dataAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Error'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					Sales Target already exists for this user !!!!!
				</ReactBSAlert>
			),
		});
	};

	fsChange = (event) => {
		this.setState({ selectedFs: event });
	};

	addingSalesTarget = (details) => {
		this.setState({
			loader: (
				<LoadingOverlay
					styles={{
						overlay: (base) => ({
							...base,
							marginTop: '250px',
							marginLeft: '-80px',
						}),
					}}
					active={true}
					spinner={<Loader type='CradleLoader' height='100' width='100' />}
				></LoadingOverlay>
			),
		});
		var user = JSON.parse(window.localStorage.getItem('user'));
		var data = JSON.parse(user);
		var token = cookie.load('Token');

		let header = {
			userid: data.id,
			token: token,
			permissions: data.Permissions,
		};

		fetch(baseUrl + '/pos/addSalesTarget', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + header.token,
				id: header.userid,
				reg: JSON.stringify(header.permissions),
			},
			method: 'Post',

			body: JSON.stringify({
				fsId: details.fsId,
				salesTarget: details.salesTarget,
				date: details.date,
				lclDose: details.lclDose,
				impDose: details.impDose,
				userId: header.userid,
				subRouteId: this.state.selectedSubRoute
					? this.state.selectedSubRoute.value
					: null,
			}),
		})
			.then((res) => res.json())
			.then((jsonResponse) => {
				this.setState({ loader: null });
				if (jsonResponse.success === true) {
					this.successAlert();
					this.resetForm();
				} else {
					this.setState({ loader: null });
					this.errorAlert(jsonResponse.message);
				}
			})
			.catch((err) => {
				this.setState({ loader: null });
				this.errorAlert();
			});
	};

	resetForm() {
		this.setState({ ...initialState });
	}
	handleSubmit = (event) => {
		let details = {
			fsId: this.state.selectedFs.value,
			impDose: this.state.impDose,
			lclDose: this.state.lclDose,
			salesTarget: this.state.salesTarget,
			date: this.state.targetDate,
		};

		this.addingSalesTarget(details);
	};

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};

	handleSubRouteChange = (event) => {
		this.setState({ selectedSubRoute: event });
	};

	render() {
		return (
			<>
				<div className='content'>
					{this.state.alert}

					{this.state.loader}
					<Row>
						<Col className='mr-auto' md={{ size: 6, offset: 3 }}>
							<Form id='RegisterValidation'>
								<Card>
									<CardHeader>
										<CardTitle tag='h3'>Set Sales Target</CardTitle>
									</CardHeader>
									<CardBody>
										<Row>
											<Col md={6}>
												<FormGroup>
													<Label>Field Supervisor *</Label>
													<Select
														options={this.state.fs}
														onChange={this.fsChange}
														value={this.state.selectedFs}
													></Select>
												</FormGroup>

												<FormGroup>
													<Label>Select Sub Route *</Label>
													<Select
														options={this.state.subRoutes}
														onChange={this.handleSubRouteChange}
														value={this.state.selectedSubRoute}
													/>
												</FormGroup>

												<FormGroup>
													<Label>Imported Doses (qty) *</Label>
													<Input
														type='text'
														name='impDose'
														onChange={this.handleChange}
														value={this.state.impDose}
													/>
												</FormGroup>

												<FormGroup>
													<Label>Local Doses (qty) *</Label>
													<Input
														type='text'
														name='lclDose'
														onChange={this.handleChange}
														value={this.state.lclDose}
													/>
												</FormGroup>

												<FormGroup>
													<Label>Sales Target *</Label>
													<Input
														type='text'
														name='salesTarget'
														onChange={this.handleChange}
														value={this.state.salesTarget}
													/>
												</FormGroup>

												<FormGroup className={'has-label'}>
													<Label>Target for the Month *</Label>
													<Input
														type='month'
														name='targetDate'
														onChange={this.handleChange}
														value={this.state.targetDate}
													/>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									<CardFooter className='text-right'>
										<Button color='primary' onClick={this.handleSubmit}>
											Add Sales Target
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default SetSalesTarget;
