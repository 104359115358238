/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from 'react';
import {
	loginFunction,
	error,
	logOut,
	unlock,
} from '../../Redux/ActionCreator';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { withRouter } from 'react-router-dom';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Col,
} from 'reactstrap';
import Maps from '../components/Maps';
import Select from 'react-select';

import { GoogleMap, withScriptjs, withGoogleMap, InfoWindow, Marker, DirectionsRenderer } from 'react-google-maps';
const mapStateToProps = (state) => {
	return {
		Login: state.Login,
		Lock: state.Lock,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logined: (details) => dispatch(loginFunction(details)),
		inValid: (errors) => dispatch(error(errors)),
		defaultState: () => dispatch(logOut()),
		unlock: () => dispatch(unlock()),
	};
};

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			emailVerified: false,
			locked: false,
			selectedOption: null,
			alert: null,
			options: []
		};

		this.registrationPage = this.registrationPage.bind(this);
		this.resetpasswordPage = this.resetpasswordPage.bind(this);
	}



	componentWillReceiveProps(nextProps) {
		if (nextProps.Login.INVALID_DATA !== null) {
			this.errorAlert();
		} else if (nextProps.Login.LOGGED_IN.success === false) {
			this.verifyEmailAlert();
		}
	}
	shouldComponentUpdate() {
		return this.state.alert === null;
	}
	routeChange() {
		return this.props.history.push('/admin/dashboard');
	}
	verifyEmailAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title='Email Not Verified !!!! '
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='success'
					btnSize=''
				>
					Please Verify Your Email Address To Login
				</ReactBSAlert>
			),
		});
	};
	errorAlert = () => {
		this.setState({
			alert: (
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '-100px' }}
					title='Wrong Details !!!'
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnBsStyle='danger'
					btnSize=''
				>
					Wrong Password or Email
				</ReactBSAlert>
			),
		});
	};
	hideAlert = () => {
		this.setState(
			{
				alert: null,
			},
			() => {
				this.props.defaultState();
			},
		);
	};

	verifyEmail = (event) => {
		var value = event.target.value;
		var emailRex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			this.setState({ emailVerified: true });
			return true;
		}
		return false;
	};
	validateForm() {
		return this.state.emailVerified === true && this.state.password.length > 0;
	}

	registrationPage() {
		return this.props.history.push('/auth/register');
	}
	resetpasswordPage() {
		return this.props.history.push('/auth/forgetpassword');
	}
	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	};



	handleSubmit = (event) => {
		let details = {
			email: this.state.email,
			password: this.state.password,
		};
		if (this.props.Login.LOGGED_IN.success === true) {
			if (this.props.Lock.LOCKED) {
				cookie.remove('Token');
				window.localStorage.removeItem('user');
				this.props.defaultState();
				this.props.logined(details);
				this.setState({ locked: true });
				event.preventDefault();
			}
		} else {
			this.props.logined(details);
			event.preventDefault();
		}
	};

	componentDidMount() {
		document.body.classList.toggle('login-page');
	}
	componentWillUnmount() {
		document.body.classList.toggle('login-page');
	}
	handleEnterPressed = (target) => {
		if (target.charCode == 13) {
			let details = {
				email: this.state.email,
				password: this.state.password,
			};
			if (this.props.Login.LOGGED_IN.success === true) {
				if (this.props.Lock.LOCKED) {
					cookie.remove('Token');
					window.localStorage.removeItem('user');
					this.props.defaultState();
					this.props.logined(details);
					this.setState({ locked: true });
				}
			} else {
				this.props.logined(details);
			}
		}
	};






	render() {

		// const AsyncMap =
		// 	withScriptjs(withGoogleMap((props) =>
		// 		<GoogleMap
		// 			defaultZoom={18}

		// 			center={{
		// 				lat: 31.561920,
		// 				lng: 74.348080
		// 			}}
		// 		>
		// 			<Marker position={{
		// 				lat: 31.561920,
		// 				lng: 74.348080
		// 			}} />
		// 		</GoogleMap>
		// 	));

		if (this.props.Login.LOGGED_IN.success === true) {
			if (this.props.Lock.UNLOCKED) {
				console.log(this.props.Login.LOGGED_IN.user.Permissions);
				console.log(this.props.Login.LOGGED_IN.user);

				cookie.save('Token', this.props.Login.LOGGED_IN.token, {
					maxAge: 36000000,
				});
				const userDetails = JSON.stringify({
					id: this.props.Login.LOGGED_IN.user.id,
					username: this.props.Login.LOGGED_IN.user.username,
					Roles: this.props.Login.LOGGED_IN.user.Roles,
					Permissions: this.props.Login.LOGGED_IN.user.Permissions,
				});

				window.localStorage.setItem(
					'username',
					this.props.Login.LOGGED_IN.user.username,
				);
				window.localStorage.setItem('user', JSON.stringify(userDetails));
				this.setState({ emailVerified: false });
				this.props.unlock();
				this.routeChange();
			} else if (this.props.Lock.LOCKED && this.state.locked === true) {
				console.log(this.props.Login.LOGGED_IN.user.Permissions);
				console.log(this.props.Login.LOGGED_IN.user);

				cookie.save('Token', this.props.Login.LOGGED_IN.token, {
					maxAge: 36000000,
				});
				const userDetails = JSON.stringify({
					id: this.props.Login.LOGGED_IN.user.id,
					username: this.props.Login.LOGGED_IN.user.username,
					Roles: this.props.Login.LOGGED_IN.user.Roles,
					Permissions: this.props.Login.LOGGED_IN.user.Permissions,
				});

				window.localStorage.setItem(
					'username',
					this.props.Login.LOGGED_IN.user.username,
				);
				window.localStorage.setItem('user', JSON.stringify(userDetails));
				this.setState({ emailVerified: false });
				this.props.unlock();
				this.setState({ locked: false });
				this.routeChange();
			}
		}

		return (
			<>
				<div className='content'>
					{this.state.alert}
					<Container>
						<Col className='ml-auto mr-auto' lg='4' md='6'>
							<Form className='form'>
								<Card className='card-login card-white'>
									<CardHeader>
										<img
											alt='...'
											src={require('assets/img/card-primary.png')}
										/>
										<CardTitle tag='h1'>Log in</CardTitle>
									</CardHeader>
									<CardBody>
										<InputGroup>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='tim-icons icon-email-85' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder='Email'
												type='text'
												name='email'
												onChange={(e) => {
													this.handleChange(e);
													this.verifyEmail(e);
												}}
												value={this.state.email}
											/>
										</InputGroup>
										<InputGroup>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText>
													<i className='tim-icons icon-lock-circle' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder='Password'
												type='password'
												name='password'
												onChange={this.handleChange}
												value={this.state.password}
												onKeyPress={this.handleEnterPressed}
											/>
										</InputGroup>
									</CardBody>
									<CardFooter>
										<Button
											block
											className='mb-3'
											color='primary'
											onClick={this.handleSubmit}
											size='lg'
											disabled={!this.validateForm()}
										>
											Get Started
										</Button>
										{/* <div className="pull-left">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={this.registrationPage}
                        >
                          Create Account
                        </a>
                      </h6>
                    </div> */}
										<div className='pull-right'>
											<h6>
												<a
													className='link footer-link'
													href='#pablo'
													onClick={this.resetpasswordPage}
												>
													Forget Password
												</a>
											</h6>
										</div>
									</CardFooter>
								</Card>
							</Form>

							{/* <AsyncMap
								googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${window._env_.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places&v=3`}
								loadingElement={
									<div style={{ height: '100%' }} />
								}
								containerElement={
									<div style={{ height: '300px' }} />
								}
								mapElement={
									<div style={{ height: '100%' }} />
								}
							/> */}
							{/* <Select
								// defaultValue={this.state.selectedOption}
								value={null}
								onChange={(value) => { console.log(value); this.setState({ selectedOption: value }); }}
								options={[
									{ value: '1', label: 'Chocolate' },
									{ value: '2', label: 'Strawberry' },
									{ value: '3', label: 'Vanilla' },
								]}
							/> */}
							{/* <Maps /> */}

						</Col>
					</Container>
				</div>
			</>
		);
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
